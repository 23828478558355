import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' 

import "./StarsInfo.css"

interface IStarsInfoProps {
    colorIcon: string,
    score: string;
    word: string
}

const StarsInfo = ({ colorIcon, score, word }:IStarsInfoProps) => {
  return (
    <div className="container-stars">
        <FontAwesomeIcon icon={solid('star')} className={`fs-16 ${colorIcon}`}/>
        <p className="fs-14 number-star mt-2 fw-500">{ score }</p>
        <p className="fs-14 mt-2 fw-500">{word}</p>
    </div>
  )
}

export default StarsInfo