import { UserIdImage } from "../../../redux/slices/components/VerifyId/VerifyIdDataSlice"
import SliderIdCard from "./SliderIdCard/SliderIdCard"
import "./SliderId.css"
import useCheckAccess from "../../hooks/useCheckAccess/useCheckAccess"

type SliderPictureProps = {
    sliderData: UserIdImage[]
}

const SliderId = ({ sliderData }: SliderPictureProps) => {
    
    const accessEdit = useCheckAccess({ id_Category: "Verify", id_Policy: "Id", id_Action: "Edit" })

    return (
        <div className="p-relative">
            <div id="con-gray-slider-profile-picture" className="rec-gray-slider-id"> 

                {
                    sliderData.map((user: UserIdImage, index: number) => {
                        return(
                            <SliderIdCard 
                                key={user.id}
                                user={user}
                                index={index}
                                lengthUsers={sliderData.length}
                                editable={accessEdit}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SliderId