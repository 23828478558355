import React from "react"
import { getProfilePictureByUserId } from "../../../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import LoadingSpinner from "../../../../../LoadingSpinner"



type ProfilePictureProps = {
    userId: string
}

type ProfilePictureState = {
    uri: string
}


class ProfilePicture extends React.PureComponent<ProfilePictureProps, ProfilePictureState> {

    constructor(props: ProfilePictureProps) {
        super(props)
        this.state = {
            uri: ""
        }
    }


    override componentDidMount(): void {
        getProfilePictureByUserId(this.props.userId, "small").then((data) => {
            this.setState({ uri: data })
        })


    }


    override render(): React.ReactNode {

        return (
            <div style={{ height: "5.28vh", width: "5.28vh", borderRadius: "5.28vh",  }}>
                {this.state.uri === "" ? (<LoadingSpinner fullPage={false} />) :


                    <img
                        src={this.state.uri}
                        style={{
                            height: '4vh',
                            width: '4vh',
                            borderRadius: "50%",
                        }}

                    />
                }
            </div>)
    }
}

export default ProfilePicture