import { IconDefinition, faAbacus, faAddressBook } from '@fortawesome/pro-solid-svg-icons';
import React from 'react'
import { BodyNotification } from './BodyNotification/indext';
import { IconNotification } from './IconNotification';
import { setNotificationAsViewedDB } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions';

interface GenericNotificationProps {
	touchNotification: () => void,
	icon?: IconDefinition | string,
	color?: string,
	showImg: boolean,
	numberTextLines?: number,
	
	id: string, 
	description: string, 
	timestamp: number, 
	viewed: boolean,
	title: string,
	
}

const GenericNotification = ({ touchNotification, showImg, icon, color, numberTextLines, id, description, timestamp, title, viewed}: GenericNotificationProps) => {

	const pressNotification = () => {
		touchNotification()
	}

	return(
		<div
			onClick={pressNotification}
			className="pointer flex flex-ai-center height-notifications grid-notifications"
			style={{
				backgroundColor: viewed ? 'white' : '#EFEFEF',
			}}
		>

			<div style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
				<BodyNotification 
					showImg={showImg} 
					title={title}
					description={description}
					timestamp={timestamp}
					numberTextLines={numberTextLines}
				/>	
			</div>

			<div style={{ alignItems: 'center', justifyContent: 'center' }}>
				<IconNotification color={color ?? "#000"} icon={icon ?? faAddressBook} />
			</div>

		</div>
	)
}

export default React.memo(GenericNotification)