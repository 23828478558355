import React from 'react'
import PublicNavBar from '../../../components/NavBarComponents/PublicNavBar/PublicNavBar'
import FooterLogin from '../../../components/Login/FooterLogin/FooterLogin'
import { useTranslation } from 'react-i18next'

const LegalNoticeScreen = () => {

    const { t } = useTranslation()

    const paragraphs = t("General:legalNotice").split("General:\n")

    return (
        <div className="doc-container">
            <PublicNavBar />
            <div className="doc-content">
                {
                    paragraphs.map((p, index) => {
                        if(!p)
                            return (<p key={index} style={{ marginTop: "2rem" }}></p>)
                        return  (
                            <p key={index} className="fs-14 mb-14"style={{ lineHeight: "2.3rem" }} >
                                {p}
                            </p>
                        )
                    })
                }
            </div>
            <FooterLogin />
        </div>
    )
}

export default LegalNoticeScreen