import { useTranslation } from "react-i18next";
import { getUserDataById } from "../requestsUtils/functionsRequests/user/userFunctions";
import { LanguageLabel } from "../../redux/slices/authSlice";


// Links

export const S3JobsNamesFile = `https://${process.env.REACT_APP_PUBLIC_GIMWORK}.s3.us-west-1.amazonaws.com//JobNames_${process.env.REACT_APP_STAGE}`

//export const S3DomainGimwork_users_files = "https://gimwork-user-files.s3.eu-west-3.amazonaws.com//";
export const S3DomainGimwork_users_files = `https://${process.env.REACT_APP_USER_FILES}.s3.us-west-1.amazonaws.com//`;

export const S3DomainGimwork_public_files = `https://${process.env.REACT_APP_PUBLIC_GIMWORK}.s3.us-west-1.amazonaws.com/`;

//export const S3DomainGimwork_categories_logo = "https://gimwork-categories-logo.s3.eu-west-3.amazonaws.com//";
export const S3DomainGimwork_categories_logo = "https://gimwork-categories-logo-v2.s3.us-west-1.amazonaws.com//";

//export const S3DomainGimwork_country_flags_w80 = "https://gimwork-country-flags.s3.eu-west-3.amazonaws.com/w80/";
export const S3DomainGimwork_country_flags_w80 = "https://gimwork-country-flags-v2.s3.us-west-1.amazonaws.com/w80/";

export const mainRouteAdmin = "";

export const getS3ImgUri = (imgKey: string) => {
	return `${S3DomainGimwork_categories_logo}${imgKey}`;
};


export const getS3GeneralInformationUri = (imgKey: string) => {
	console.log("`${S3DomainGimwork_public_files}${imgKey}`", `${S3DomainGimwork_public_files}${imgKey}`)
	return `${S3DomainGimwork_public_files}${imgKey}`;
};


export const getJobS3ImgUri = (imgKey: string) => {
	return `${S3DomainGimwork_categories_logo}${imgKey}`;
};

export type UserInfoCacheType = {
    name: string
    phoneNumber: string
    mail: string
	gimScore: number
	gimmerScore: number
}

export type Country = "Mexico" | "France" 

export const Countries: Country[] = ["Mexico", "France"]

export const Languages: LanguageLabel[] = [ "en", "es", "fr" ]

export const getMinimalUserInfoData = (userId: string) => {
    return new Promise((resolve: (value: UserInfoCacheType) => void, reject: any) => {
        getUserDataById(userId, { mail: 1, phoneNumber: 1, firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1 })
        .then((res) => {
            const { firstName, lastName, phoneNumber, mail, gimmerScore, gimScore } = res
            const wholeName = `${firstName} ${lastName}`
            UserInfoCache.addNameInfo(userId, { name: wholeName, phoneNumber, mail, gimScore, gimmerScore  })
            resolve({ name: wholeName, phoneNumber, mail, gimmerScore, gimScore  })
        }).catch((error) => { reject(error)})
    })
}
export class UserInfoCache{
    static userNameCache = new Map<string, UserInfoCacheType>()

    static addNameInfo(id: string, user: UserInfoCacheType) {
        this.userNameCache.set(id, user)
    }
}



export const GetOfferType = (type: string) => {
    const { t } = useTranslation();
    if(type === "Task")
        return t("General:label_038")
    else if(type === "Job")
        return t("General:label_039")
}

export const GetScreenName = (n: string) => {
    const { t } = useTranslation();
    return t(`General:label_${n}`)
}


export type Language = {
    label: string
    language: string
    img: string 
    name: string
}


export const languages: Language[] = [
    { label: "US", language: "en", img: "en-us.png", name: "English" },
    { label: "MX", language: "es", img: "es-mx.png", name: "Español" }
]


export type PhoneCodeInfo = {
	mask: string
    urlName: string
	format: string
	code: string
	name: string
	countryCode: string
}

export const phoneCodesInfo: { [key: string]: PhoneCodeInfo } = {
	MX: {
		mask: '[000] [000] [0000]',
		urlName: "MEXICO.svg",
		format: '000 000 0000',
		code: '+52',
		name: "Mexico",
		countryCode: "MX",
	},
	FR: {
		mask: '[0] [00] [00] [00] [00]',
		urlName: "FRANCE.svg",
		format: '0 00 00 00 00',
		code: '+33',
		name: "France",
		countryCode: "FR",
	},
	US: {
		mask: '[000] [000] [0000]',
        urlName: "USA.svg",
		format: '000 000 0000',
		code: '+1',
		name: "USA",
		countryCode: "US",
	},
	UY: { // Uruguay
		mask: '[00] [000] [00] [00]',
        urlName: "URUGUAY.svg",
		format: '00 000 00 00',
		code: '+598',
		name: "Uruguay",
		countryCode: "UY",
	},
	AR: { // Argentina
		mask: '[00] [0000] [0000]',
        urlName: "ARGENTINA.svg",
		format: '00 0000 0000',
		code: '+54',
		name: "Argentina",
		countryCode: "AR",
	}, CL: { // Chile
		mask: '[0] [0000] [0000]',
        urlName: "CHILE.svg",
		format: '0 0000 0000',
		code: '+56',
		name: "Chile",
		countryCode: "CL",
	},
	CO: { // Colombia
		mask: '[00] [000] [0000]',
        urlName: "COLOMBIA.svg",
		format: '00 000 0000',
		code: '+57',
		name: "Colombia",
		countryCode: "CO",
	},
	PE: { // Peru
		mask: '[0] [000] [0000]',
        urlName: "PERU.svg",
		format: '0 000 0000',
		code: '+51',
		name: "Peru",
		countryCode: "PE",
	}
};


export const getListPhoneCodeInfo = (): PhoneCodeInfo[] => {
	const listPhoneCodeInfo: PhoneCodeInfo[] = []
	for (const key in phoneCodesInfo) {
		listPhoneCodeInfo.push(phoneCodesInfo[key] ?? {} as PhoneCodeInfo)
	}
	return listPhoneCodeInfo;
}

export const listPhoneCodeInfo: PhoneCodeInfo[] = getListPhoneCodeInfo()