import { ImageNotification } from "./ImageNotification";
import { TitleNotification } from "./TitleNotification";
import { MessageNotification } from "./MessageNotification";
import { ReceivedTimeNotification } from "./ReceivedTimeNotification";

interface BodyNotificationProps {
	numberTextLines?: number,
	loadingImg?: boolean,
	imgUrl?: string,
	showImg: boolean,
	description: string,
	timestamp: number,
	title: string,
}

export const BodyNotification = (props: BodyNotificationProps) => {
	
	const { title, numberTextLines, loadingImg, imgUrl, showImg, description, timestamp } = props
	return(

		<div className="flex width-100">

			{
				showImg &&
				<div style={{ marginRight: 3  }}>
					<ImageNotification 
						loadingImage={loadingImg ? true : false} 
						urlImage={imgUrl ?? ""} 
					/>
				</div>
			}

			<div className="flex-1 height-100 ml-10">
				<TitleNotification title={title} />
				<MessageNotification 
					numberTextLines={numberTextLines} 
					message={description} 
				/>
				<ReceivedTimeNotification date={timestamp} />
			</div>

		</div>
	)
}
