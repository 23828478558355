import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { JobCategoryJob } from './managerJobDataSlice';

export type StateTableJobs = {
    jobSelected?:       JobCategoryJob
    gimStars:           number
    gimmerStars:        number 
    price:              number 
    malusGim:           number
    malusGimmer:        number 
    malusShareGim:      number 
    malusShareGimmer:   number 
    minRevenue:         number 
    tax:                number 
    timeHours:          number 
    productPrice:       number 
    timeAdded:          number
    rmpcProduct:        number
    kmCovered:          number
    tip:                number
}

const initialState: StateTableJobs = {
    jobSelected:        undefined,
    gimStars:           5,
    gimmerStars:        5, 
    price:              100, 
    malusGim:           3,
    malusGimmer:        3, 
    malusShareGim:      40, 
    malusShareGimmer:   70, 
    minRevenue:         10, 
    tax:                16, 
    timeHours:          1, 
    productPrice:       50, 
    timeAdded:          2,
    rmpcProduct:        5,
    kmCovered:          5,
    tip:                 30
};

export const managerJobsTablesSlice = createSlice({
  name: 'managerJobsTables',
  initialState,
  reducers: {
    setUpdateStateTablesJobsReducer: (state, action: PayloadAction<StateTableJobs>) => {
        state = action.payload
        return state;
    },
    resetValueFromStateManagerJobsTables: (state) => {
        return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
    setUpdateStateTablesJobsReducer,
    resetValueFromStateManagerJobsTables
} = managerJobsTablesSlice.actions

export default managerJobsTablesSlice.reducer