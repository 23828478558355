import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import "./EditProductDBModal.css"
import FormEditProductDBModal from './FormEditProductDBModal/FormEditProductDBModal';
import React from 'react';

const EditProductDBModal = () => {

  const { t } = useTranslation();
  const productDB = useSelector((state: RootState) => state.manageProductsData.EditProductDBModal);


  return (
    <div className="blank-wrapper-modals flex flex-d-column p-relative" style={{ width: "31.2rem" }}>
        {
            productDB &&
            <FormEditProductDBModal 
                product={productDB}
            />
        }
    </div>
  )
}

export default React.memo( EditProductDBModal )