import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import authSlice from '../slices/authSlice';
import managerJobDataSlice from '../slices/components/ManagerJobs/managerJobDataSlice';
import  managerJobModalsSlice  from '../slices/components/ManagerJobs/managerJobsModalsSlice';
import verifyPictureDataSlice from '../slices/components/VerifyPicture/verifyPictureDataSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import managerJobsGroupedDataSlice from '../slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import VerifyIdDataSlice from '../slices/components/VerifyId/VerifyIdDataSlice';
import VerifyCertificateDataSlice from '../slices/components/VerifyCertificate/VerifyCertificateDataSlice';
import manageOffersDataSlice from '../slices/components/ManageOffers/manageOffersDataSlice';
import manageDetailedOfferSlice from '../slices/components/ManageOffers/manageDetailedOfferSlice';
import manageUsersDataSlice from '../slices/components/ManageUsers/manageUsersDataSlice';
import manageUsersGroupedDataSlice  from '../slices/components/ManageUsers/manageUsersGroupedDataSlice';
import manageUsersModalsSlice  from '../slices/components/ManageUsers/manageUsersModalsSlice';
import manageUserReportsDataSlice from '../slices/components/ManageReports/manageReportsDataSlice';
import manageBugReportsDataSlice from "../slices/components/ManageBugReports/manageBugReportsDataSlice"
import manageDetailedUserReportDataSlice from '../slices/components/ManageReports/manageDetailedReportsDataSlice';
import manageDetailedBugReportSlice from '../slices/components/ManageBugReports/manageDetailedBugReportsDataSlice';
import manageRolesSlice from '../slices/components/ManageRoles/manageRolesSlice';
import myHistoryDataSlice from '../slices/components/MyHistory/myHistoryDataSlice';
import rulesSlice from '../slices/components/Rules/rulesSlice';
import marketplaceDataSlice from '../slices/components/Marketplace/marketplaceDataSlice';
import signUpSlice from '../slices/components/SignUp/SignUpSlice';
import helpSlice from '../slices/components/Help/HelpSlice';
import globalSlice from '../slices/components/Global/GlobalSlice';
import managerJobsTablesSlice from '../slices/components/ManagerJobs/managerJobsTables';
//import { persistStore, persistReducer } from 'redux-persist';
import { createStateSyncMiddleware, initStateWithPrevTab, initMessageListener } from 'redux-state-sync';
import productsSlice from '../slices/components/Products/ProductsSlice';
import manageProductsDataSlice from '../slices/components/ManageProducts/manageProductsDataSlice';
import requestProductsDataSlice from '../slices/components/RequestProducts/requestProductsDataSlice';
import homeOffersSlice from '../slices/components/HomeOffers/homeOffersSlice';

const config = {
  // TOGGLE_TODO will not be triggered in other tabs
  //blacklist: ['TOGGLE_TODO'],
};
const middlewares = [createStateSyncMiddleware(config)];

export const store = configureStore({
    reducer: {
        auth: authSlice,
        managerJobData: managerJobDataSlice,
        managerJobModals: managerJobModalsSlice,
        managerJobsGroupedData: managerJobsGroupedDataSlice,
        verifyPictureData: verifyPictureDataSlice,
        verifyIdData: VerifyIdDataSlice,
        VerifyCertificateData: VerifyCertificateDataSlice,
        manageOffersData: manageOffersDataSlice,
        manageDetailedOffer: manageDetailedOfferSlice,
        manageUsersData: manageUsersDataSlice,
        manageUsersGroupedData: manageUsersGroupedDataSlice,
        manageUsersModals: manageUsersModalsSlice,
        manageUserReports: manageUserReportsDataSlice,
        manageBugReportsData: manageBugReportsDataSlice,
        manageDetailedUserReport: manageDetailedUserReportDataSlice,
        manageDetailedBugReport: manageDetailedBugReportSlice,
        manageRoles: manageRolesSlice,
        myHistoryData: myHistoryDataSlice,
        rules: rulesSlice,
        marketplace: marketplaceDataSlice,
        signUp: signUpSlice,
        help: helpSlice,
        global: globalSlice,
        managerJobsTables: managerJobsTablesSlice,
        products: productsSlice,
        manageProductsData: manageProductsDataSlice,
        requestProductsData: requestProductsDataSlice,
        homeOffers: homeOffersSlice
    },
    //middleware: middlewares
})

// init state with other tabs
initStateWithPrevTab(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector