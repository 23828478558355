import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { CategoryJob } from '../ManagerJobs/managerJobDataSlice';
import { Job } from '../../authSlice';
import { InsideWorkPreference, OutsideWorkPreference, WorkPreference } from '../ManageOffers/manageOffersDataSlice';

export type IdCategoryJob = { categoryId: string, jobId: string }

export type SelectedWorkPreference = {
    inside: boolean
    outside: boolean
}

export type PhoneNumber = { phoneNumber: string, code: string }

export type SignUpData = {
    firstName: string
    lastName: string
    password: string
    phoneNumber: PhoneNumber
    mail: string
}

export type JobWithCategoryId = Job & { categoryId: string }

type InitialState = {
    categoryJobs: CategoryJob[]
    modalCategoryJobs: CategoryJob[]
    jobsAdded: JobWithCategoryId[]
    currentJob: JobWithCategoryId | null
    selectedWorkPreference: SelectedWorkPreference
    data: SignUpData
}

const initialState: InitialState = {
    categoryJobs: [],
    modalCategoryJobs: [],
    jobsAdded: [],
    currentJob: null,
    selectedWorkPreference: {
        inside: false,
        outside: false
    },
    data: {
        firstName: "",
        lastName: "",
        password: "",
        phoneNumber: { phoneNumber: "", code: "" },
        mail: ""
    }
}

export const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        setCategoryJobs: (state, action: PayloadAction<CategoryJob[]>) => {
            state.categoryJobs = action.payload
            return state
        },
        selectCategoryJobs: (state, action: PayloadAction<string>) => {
            if(action.payload === "All")
                state.modalCategoryJobs = state.categoryJobs
            else{
                state.modalCategoryJobs = state.categoryJobs.filter((c) => c.id === action.payload)
            }
            return state
        },
        setAddSkill: (state, action: PayloadAction<JobWithCategoryId>) => {
            //state.jobsAdded.push( action.payload )
            state.currentJob = action.payload
            return state
        },
        setRemoteWorkPreference: (state, action: PayloadAction<boolean>) => {
            if(state.currentJob)
                state.currentJob.workPreference.remote = action.payload
            return state
        },
        setInsideWorkPreference: (state, action: PayloadAction<InsideWorkPreference>) => {
            if(state.currentJob){
                state.currentJob.workPreference.inside = action.payload

            }
                
            return state
        },
        setOutsideWorkPreference: (state, action: PayloadAction<OutsideWorkPreference>) => {
            if(state.currentJob){
                state.currentJob.workPreference.outside = action.payload
            }
            return state
        },

        setSelectInsideWorkPreference: (state, action: PayloadAction<boolean>) => {
            state.selectedWorkPreference.inside = action.payload
            return state
        },
        setSelectOutsideWorkPreference: (state, action: PayloadAction<boolean>) => {
            state.selectedWorkPreference.outside = action.payload
            return state
        },

        setWorkPreferenceInSkill: (state, action: PayloadAction<WorkPreference>) => {
            if(state.currentJob){
                state.currentJob.workPreference = action.payload
            }

            return state
        },

        setNextSelectWhereWorkPreference: (state, action: PayloadAction<string>) => {
            const jobId = action.payload
            if(state.currentJob){
                for(let i=0; i< state.jobsAdded.length; i++){

                    if(state.jobsAdded[i].jobId === jobId){
                        if(!state.selectedWorkPreference.inside){
                            state.jobsAdded[i].workPreference.inside.address = ""
                        }else{
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            state.jobsAdded[i].workPreference.inside = state.currentJob?.workPreference.inside
                        }
                        if(!state.selectedWorkPreference.outside){
                            state.jobsAdded[i].workPreference.outside.address = ""
                        }else{
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            state.jobsAdded[i].workPreference.outside = state.currentJob?.workPreference.outside
                        }
                    }

                }
            }
            //return state
        },

        setFirstAndLastNameSignUp: (state, action: PayloadAction<{ firstName: string, lastName: string }>) => {
            const { firstName, lastName } = action.payload
            state.data = { ...state.data, firstName, lastName }
            return state
        },
        setPasswordSignUp: (state, action: PayloadAction<{ password: string }>) => {
            const { password} = action.payload
            state.data = { ...state.data, password }
            return state
        },
        setEmailSignUp: (state, action: PayloadAction<{ mail: string }>) => {
            const { mail } = action.payload
            state.data = { ...state.data, mail }
            return state
        },
        setPhoneNumberSignUp: (state, action: PayloadAction<{ phoneNumber: string, code: string }>) => {
            const { phoneNumber, code } = action.payload
            state.data = { ...state.data, phoneNumber: { phoneNumber, code } }
            return state
        },

        addJobSignUpReducer: (state, action: PayloadAction<JobWithCategoryId>) => {
            state.jobsAdded.push(action.payload)


            state.jobsAdded = state.jobsAdded.map((job) => {
                
                if(state.currentJob && job.jobId === state.currentJob.jobId ){
                 
                    job = JSON.parse( JSON.stringify( job ) )

                    if(!state.selectedWorkPreference.inside){
                        job.workPreference.inside = { ...state.currentJob?.workPreference.inside, address: "" }
                    }else{
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        job.workPreference.inside = { ...state.currentJob?.workPreference.inside }
                    }
                    console.log("state.selectedWorkPreference.outside",state.selectedWorkPreference.outside)
                    if(!state.selectedWorkPreference.outside){
                        job.workPreference.outside = { ...state.currentJob?.workPreference.outside, address: "" }
                    }else{
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        job.workPreference.outside = { ...state.currentJob?.workPreference.outside }
                    }

                }
                return job
            })
            
            /*
            if(state.currentJob){
                for(let i=0; i< state.jobsAdded.length; i++){

                    if(state.jobsAdded[i].jobId === state.currentJob.jobId){
                        if(!state.selectedWorkPreference.inside){
                            state.jobsAdded[i].workPreference.inside = { ...state.currentJob?.workPreference.inside, address: "" }
                        }else{
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            state.jobsAdded[i].workPreference.inside = { ...state.currentJob?.workPreference.inside }
                        }
                        console.log("state.selectedWorkPreference.outside",state.selectedWorkPreference.outside)
                        if(!state.selectedWorkPreference.outside){
                            state.jobsAdded[i].workPreference.outside = { ...state.currentJob?.workPreference.outside, address: "" }
                        }else{
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            state.jobsAdded[i].workPreference.outside = { ...state.currentJob?.workPreference.outside }
                        }

                    }
                }
            }
                */
            return state
        },
        editJobSignUpReducer: (state, action: PayloadAction<Job>) => {
        const job = action.payload
        state.jobsAdded = state.jobsAdded.map((Job) => {
            if(Job.jobId === job.jobId){
                Job = {
                    categoryId: Job.categoryId,
                    ...job
                }
            }
            return Job
        })
        return state
        },
        deleteJobSignUpReducer: (state, action: PayloadAction<Job>) => {
            const job = action.payload
            state.jobsAdded = state.jobsAdded.filter((Job) => Job.jobId !== job.jobId)
            return state
        },

        resetModalSignUp: (state) => {
            state.modalCategoryJobs = []
            return state
        },
        resetToAddNewSkill: (state) => {
            state.currentJob = null
            state.selectedWorkPreference = { outside: false, inside: false }
            return state
        },
        resetSignUpGoBack: (state, action: PayloadAction<string>) => {
            const jobId = action.payload
            state.selectedWorkPreference = {
                inside: false,
                outside: false
            }
            state.currentJob = null
            state.jobsAdded = state.jobsAdded.filter((job) => {
                console.log("redux", job.jobId !== jobId, job.jobId, jobId)
                return job.jobId !== jobId
            })
            return state
        },
    },

        
  },
);

// Action creators are generated for each case reducer function
export const {  
    setCategoryJobs,
    selectCategoryJobs,
    resetModalSignUp,
    setAddSkill,
    setWorkPreferenceInSkill,
    setRemoteWorkPreference,
    setInsideWorkPreference,
    setOutsideWorkPreference,
    setSelectInsideWorkPreference,
    setSelectOutsideWorkPreference,
    resetSignUpGoBack,
    setNextSelectWhereWorkPreference,
    resetToAddNewSkill,
    setFirstAndLastNameSignUp,
    setPasswordSignUp,
    setPhoneNumberSignUp,
    setEmailSignUp,
    addJobSignUpReducer,
    editJobSignUpReducer,
    deleteJobSignUpReducer,
} = signUpSlice.actions

export default signUpSlice.reducer