import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import MessagesList from '../MessagesList/MessagesList'

type MessagesRequestByProps = {
    tabUsers: number
    indexTab: number
}

const MessagesRequestBy = ({ tabUsers, indexTab }: MessagesRequestByProps) => {

    const messages = useSelector((state: RootState) => state.manageUserReports.messagesRequestBy)

    return (
        <MessagesList tabUsers={tabUsers} indexTab={indexTab} messages={messages} type={"RequestBy"} />
    )
}

export default React.memo( MessagesRequestBy )