import { useState } from "react";
import { useSelector } from 'react-redux';
import { useAppDispatch } from "../../../../../redux/store/store";
import { RootState } from '../../../../../redux/store/store';
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import UploadImageButton from "../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton";
import { editJobCategory, getJobCategoryImagesLinks, updateJobsNamesFile } from "../../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions";
import { updateEditedCategoryReducer } from "../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { resetValueFromState } from "../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice";
import LoadingSpinner from "../../../../LoadingSpinner";
import axios from "axios";
import "../../NewCategoryModal/NewCategoryModal.css"
import { S3DomainGimwork_categories_logo } from "../../../../../utils/staticVariables/staticVariables";
import PreviewImage from "../../../../UIcomponents/Modals/PreviewImage/PreviewImage";
import { MyYup } from "../../../../../utils/verifications/myYup";
import ImgFormats from "../../../../UIcomponents/ImgFormats/ImgFormats";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../../../utils/images/resizeImages";
import { ValuesForm } from "../../../../../utils/verifications/Forms";
import { setNameFileReducer } from "../../../../../redux/slices/components/Global/GlobalSlice";

type FormEditCategoryModalProps = {
    categoryId: string,
    categoryName: string,
    country: string,
    imgKey: string,
    photoKey: string,
    urlCurrentIcon: string,
    urlPreviewIcon: string,
    setUrlPreviewIcon: React.Dispatch<React.SetStateAction<string>>
}


const FormEditCategoryModal = ({categoryId, categoryName, urlCurrentIcon, urlPreviewIcon,setUrlPreviewIcon, country, imgKey, photoKey}: FormEditCategoryModalProps) => {
    
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const managerJobData = useSelector((state: RootState) => state.managerJobData);
    const jobsNamesFile = useSelector((state: RootState) => state.global.jobsNamesFile)
    
    const [inputValues, setInputValues] = useState<ValuesForm>({
        categoryName: { error: "", value: jobsNamesFile?.[categoryId]?.en ?? categoryName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        categoryName_es: { error: "", value: jobsNamesFile?.[categoryId]?.es ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        categoryName_fr: { error: "", value: jobsNamesFile?.[categoryId]?.fr ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        countryName: { error: "", value: country, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
    });  
    
    const [loading, setLoading] = useState<boolean>(false);
    const [categoryImage, setCategoryImage] = useState<any>(null)
    const [categoryImage_100, setCategoryImage_100] = useState<any>(null)
    const [categoryImage_200, setCategoryImage_200] = useState<any>(null)
    const [categoryImage_300, setCategoryImage_300] = useState<any>(null)
    const [categoryImage_400, setCategoryImage_400] = useState<any>(null)
    const [categoryImage_500, setCategoryImage_500] = useState<any>(null)
    const [categoryImage_600, setCategoryImage_600] = useState<any>(null)
    const [categoryImage_700, setCategoryImage_700] = useState<any>(null)
    const [categoryImage_800, setCategoryImage_800] = useState<any>(null)
    const [categoryIcon, setCategoryIcon] = useState<any>(null)
    
    const urlCurrentImage = `${S3DomainGimwork_categories_logo}${categoryId}/categoryImg`
    const [urlPreviewImage, setUrlPreviewImage] = useState<string>(urlCurrentImage)




    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        try{


            const body = {
                categoryName: inputValues["categoryName"].value,
                country: inputValues["countryName"].value,
                photoKey: `${categoryId}/categoryImg_icon`,
                imgKey: `${categoryId}/categoryImg`, 
                isPrincipal: false
            }

            setLoading(true)

            let options = {
                headers: {
                    'Content-Type': 'image/jpg, image/png',
                },
            };

            let photoKeyLink = ""
            let imgName = `${categoryId}/categoryImg`

            if( categoryImage ){

                const  data = await getJobCategoryImagesLinks({ imgName });

                const imgKeyLink = data.imgKey
                photoKeyLink = data.photoKey
                const imgKeyLink_100 = data.imgKey_100
                const imgKeyLink_200 = data.imgKey_200
                const imgKeyLink_300 = data.imgKey_300
                const imgKeyLink_400 = data.imgKey_400
                const imgKeyLink_500 = data.imgKey_500
                const imgKeyLink_600 = data.imgKey_600
                const imgKeyLink_700 = data.imgKey_700
                const imgKeyLink_800 = data.imgKey_800

                await Promise.all([
                    axios.put(imgKeyLink, categoryImage, options),
                    axios.put(imgKeyLink_100, categoryImage_100, options),
                    axios.put(imgKeyLink_200, categoryImage_200, options),
                    axios.put(imgKeyLink_300, categoryImage_300, options),
                    axios.put(imgKeyLink_400, categoryImage_400, options),
                    axios.put(imgKeyLink_500, categoryImage_500, options),
                    axios.put(imgKeyLink_600, categoryImage_600, options),
                    axios.put(imgKeyLink_700, categoryImage_700, options),  
                    axios.put(imgKeyLink_800, categoryImage_800, options),
                ])
                body.imgKey = imgName;
                
            }

            if(categoryIcon){

                if(!photoKeyLink){
                    const  data = await getJobCategoryImagesLinks({ imgName });
                    photoKeyLink = data.photoKey
                }

                const iconPath = `${imgName}_icon`;
                await axios.put(photoKeyLink, categoryIcon, options)
                body.photoKey = iconPath;
            }

            const { Attributes } = await editJobCategory(categoryId, body)

            const categoryName_en = inputValues["categoryName"].value;
            const categoryName_es = inputValues["categoryName_es"].value;
            const categoryName_fr = inputValues["categoryName_fr"].value;

            const bodyJobsNamesFile = {
                id: categoryId,
                names: {
                    en: categoryName_en,
                    es: categoryName_es,
                    fr: categoryName_fr
                }
            }

            await updateJobsNamesFile(bodyJobsNamesFile)
            //dispatch(getAllJobsCategoriesChunk())
            
            dispatch(updateEditedCategoryReducer({
                categoryId,
                categoryName: body.categoryName,
                country: body.country,
                imgKey: body.imgKey,
                photoKey: body.photoKey
            }))

            dispatch( setNameFileReducer(bodyJobsNamesFile) )
            
            setLoading(false)
            dispatch(resetValueFromState())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Editing the category")
        }


    }


    const setImageUploaded = async() => {

        const fileImage = (document.getElementById("new-category-image") as HTMLInputElement)?.files
        if(fileImage)
        {
            const file = fileImage[0]
            const [ 
                imageResized_100, 
                imageResized_200, 
                imageResized_300, 
                imageResized_400,
                imageResized_500,
                imageResized_600,
                imageResized_700,
                imageResized_800     
            ] = await Promise.all([
                resizeImage(file, 100, 100),
                resizeImage(file, 200, 200),
                resizeImage(file, 300, 300),
                resizeImage(file, 400, 400),
                resizeImage(file, 500, 500),
                resizeImage(file, 600, 600),
                resizeImage(file, 700, 700),
                resizeImage(file, 800, 800),
            ])
            setCategoryImage(file)

            setCategoryImage_100(imageResized_100)
            setCategoryImage_200(imageResized_200)
            setCategoryImage_300(imageResized_300)
            setCategoryImage_400(imageResized_400)
            setCategoryImage_500(imageResized_500)
            setCategoryImage_600(imageResized_600)
            setCategoryImage_700(imageResized_700)
            setCategoryImage_800(imageResized_800)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string")
                    setUrlPreviewImage(event.target?.result)
            }
        }
    }

    const setIconUploaded = () => {

        const fileIcon = (document.getElementById("new-category-icon") as HTMLInputElement)?.files
        if(fileIcon){
            const file = fileIcon[0]
            setCategoryIcon(file)
            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string")
                    setUrlPreviewIcon(event.target?.result)
            }
        }

    }
      

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
      }
    

      const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
      }

    
     
    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column flex-1" >

            <div className="con-scroll-job-modal">
                <div className="con-scroll-job-modal-aux">
                            
                    <div className="flex flex-d-column con-label-input-modal">
                        <label className="fs-14 black fw-600" htmlFor="categoryName">{`${t("General:label_115")} (${t("Language:Usa_lang")})`} <span className="c-asterik">*</span></label>
                        <div className="con-input">
                            <input
                                id="categoryName"
                                name="categoryName"
                                type="text"
                                className="input-modal"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["categoryName"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { inputValues["categoryName"].error && (
                                <p className="error-input-text">{inputValues["categoryName"].error  || ""}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-d-column con-label-input-modal">
                        <label className="fs-14 black fw-600" htmlFor="categoryName_es">{`${t("General:label_115")} (${t("Language:Mexico_lang")})`} <span className="c-asterik">*</span></label>
                        <div className="con-input">
                            <input
                                id="categoryName_es"
                                name="categoryName_es"
                                type="text"
                                placeholder={t("General:label_116") ?? ""}
                                className="input-modal"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["categoryName_es"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { inputValues["categoryName_es"].error && (
                                <p className="error-input-text">{inputValues["categoryName_es"].error  || ""}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-d-column con-label-input-modal">
                        <label className="fs-14 black fw-600" htmlFor="categoryName_fr">{`${t("General:label_115")} (${t("Language:France_lang")})`}<span className="c-asterik">*</span></label>
                        <div className="con-input">
                            <input
                                id="categoryName_fr"
                                name="categoryName_fr"
                                type="text"
                                placeholder={t("General:label_116") ?? ""}
                                className="input-modal"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["categoryName_fr"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { inputValues["categoryName_fr"].error && (
                                <p className="error-input-text">{inputValues["categoryName_fr"].error  || ""}</p>
                            )}
                        </div>
                    </div>


                    <div className="flex flex-d-column con-label-input-modal">
                        <label className="fs-14 black fw-600" htmlFor="categoryName">{t("General:label_117")} <span className="c-asterik">*</span></label>
                        <div className="con-input">
                            <select
                                name="countryName"
                                className="input-modal"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["countryName"].value}
                            >
                                <option value="">--{t("General:label_118")}--</option>
                                {
                                    managerJobData.countryNames.map((countryName: string, index: number) => (
                                            <option 
                                                key={index}
                                                value={countryName}
                                                className="black"
                                            >
                                                { countryName }
                                            </option>

                                    ))
                                }
                            </select>
                            { inputValues["countryName"].error && (
                                <p className="error-input-text">{inputValues["countryName"].error  || ""}</p>
                            )}
                        </div>
                    </div>
                

                    <input 
                        id="new-category-icon" 
                        className="display-none" 
                        type="file" 
                        accept="image/png"
                        onChange={setIconUploaded}
                    />
                    <input 
                        id="new-category-image"  
                        className="display-none" 
                        type="file" 
                        accept="image/jpeg, image/png"
                        onChange={setImageUploaded}
                    />

                    <div className="con-upload-btns">
                        <div className="con-input new-category-upload-btn">
                            <UploadImageButton 
                                idInputFile="new-category-icon" 
                                text={t("General:label_119")}
                                iconName="icons" 
                            />
                        </div>
                        <div className="con-input new-category-upload-btn">
                            <UploadImageButton 
                                idInputFile="new-category-image"  
                                text={t("General:label_097")}
                                iconName="folder-image" 
                            />
                        </div>
                    </div>
                                
                    <PreviewImage 
                        imgLink={urlPreviewImage}
                        height="32rem"
                    />
                    
                    <ImgFormats />
                </div>
            </div>

            <div className="flex flex-d-column">
                <ConfirmButton text={t("General:label_131")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
      </form>
    )
}

export default FormEditCategoryModal