import axios, { AxiosError, AxiosRequestConfig } from "axios"
import {
    adminApiUrl,
    getUserCredentials,
} from "../../../axiosUtils"
import { GimGimmer } from "../../offers/offersFunctions"
import { HistoryTransaction } from "../../../../dbModels/HistoryTransaction"

export type TransactionType = GimGimmer | "All" 

export const getTransactionHistory = (type: TransactionType, limit = 100, startDate: string | null = null) => {
    return new Promise((resolve: (value: { items: HistoryTransaction[] }) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { type, limit, startDate }
            };
            axios.get(`${adminApiUrl}/v1/transactions/history`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/transactions/history`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getHistoryTransaction = (id: string) => {
    return new Promise((resolve: (value: HistoryTransaction) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.get(`${adminApiUrl}/v1/transactions/history/transaction/${id}`, config)
            .then((res) => { 
                resolve(res.data.item)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/transactions/history/transaction/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
