import { faImage, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ButtonSelectFileBugProps = {
    onPress: () => void
    type: "Image" | "Video"
}


const ButtonSelectFileBug = ({ onPress, type }: ButtonSelectFileBugProps) => {

    const { t } = useTranslation()

    return (
        <button onClick={onPress} className="flex flex-ai-center width-100 pointer" style={{ height: "4.8vh" }}>
            <FontAwesomeIcon icon={type === "Image" ? faImage : faVideo} className="fs-22-vh color-text mr-10 ml-10" />
            <p className="fs-18-vh color-text ml-10 fw-500">{type === "Image" ? t("Help:reportBug:label_14") : t("Help:reportBug:label_15")}</p>
        </button>
    )
}

export default React.memo( ButtonSelectFileBug )