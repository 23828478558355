import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../utils/colors/colors'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'

const SelectProfileTypeScreen = () => {
    console.log("SelectProfileTypeScreen")
    const navigate = useNavigate()

    const [typeProfile, setTypeProfile] = useState<GimGimmer | null>(null)

    const [error, setError] = useState("")

    const onNext = useCallback(
        () => {
            if(typeProfile === "Gim")
                navigate("/gim-skill/select-skill")
            else
                navigate("/welcome")
        },
        [typeProfile],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const handleSelectType = (type: GimGimmer) => {
        if(type === typeProfile)
            return
        
        setTypeProfile(type)
    }
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="p-relative" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">
                        {
                          !typeProfile 
                          ? "Select your profile type"
                          : typeProfile === "Gim"
                              ? "I am a Gim"
                              : "I am a Gimmer"
                        }
                    </p>

                    <p className="fs-18-vh c-text" style={{ marginTop: "2.4vh" }}>{"You can easily switch between them later !"}</p>

                    <div className="flex flex-jc-sb" style={{ marginTop: "6.5vh" }}>
                        <button 
                            className={`${typeProfile === "Gim" ? "gim-bg" : "dusty-gray-bg"} border-radius-3 pointer`}
                            onClick={() => handleSelectType("Gim")}
                            style={{ height: "4.9vh", width: "45%" }}
                        >
                            <p className="fs-22-vh c-white">Gim</p>
                        </button>

                        <button 
                            className={`${typeProfile === "Gimmer" ? "gimmer-bg" : "dusty-gray-bg"} border-radius-3 pointer`}
                            onClick={() => handleSelectType("Gimmer")}
                            style={{ height: "4.9vh", width: "45%" }}
                        >
                            <p className="fs-22-vh c-white">Gimmer</p>
                        </button>
                    </div>

                    { 
                        typeProfile && 
                        <p style={{ bottom: "-6.5vh" }} className="fs-20-vh c-text p-absolute">
                            {
                              typeProfile === "Gim"
                              ? "I want to work!"
                              : "I have to assign a task !"
                            }
                        </p> 
                    }

                </div>

                <div className="width-100 flex flex-d-column p-relative" style={{ height: "40vh", marginLeft: "10rem" }}>
                    <div className="p-absolute" style={{ top: 0, left: 0, opacity: typeProfile === "Gimmer" ? 1 : .45 }}>
                        { ImageSignUp["Select_type_Gimmer"] }
                    </div>
                    <div className="p-absolute" style={{ bottom: 0, right: 0, opacity: typeProfile === "Gim" ? 1 : .45 }}>
                        { ImageSignUp["Select_type_Gim"] }
                    </div>
                </div>
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={"Next"} 
                    disabled={typeProfile ? false : true}
                />
            </div>

        </div>
    )
}

export default SelectProfileTypeScreen