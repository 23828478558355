import { generateClient } from 'aws-amplify/api';
import { createChatRoom, createChatRoomUser, createMessage, getChatRoom, messagesByChatRoom, updateChatRoom, updateChatRoomUser, updateMessage } from './Graphql/Queries';

const client = generateClient();

type MessageInfo = {
	transactionId?: string;
};

export type RescheduleInfo = {
	startTime: string;
	endTime: string;
	date: string;
	transactionId: string;
	currentStartTime: string;
};

export type ProductInfo = {
	productName: string;
	description: string;
	Price: number;
	fees: number;
	Tax: number;
	total: number;
	document?: any;
	transactionId: string;
	startTime: string;
};

export type OvertimeInfo = {
	description: string;
	transactionId: string;
	payment_id?: string;
};

export type ActivateTransaction = {
	transactionId: string;
	Date: string;
};

export type Checkout_Transaction = {
	transactionId: string;
};

export enum MessageType {
	MESSAGE = 'MESSAGE',
	RESCHEDULE = 'RESCHEDULE',
	REQUEST_OVERTIME = 'REQUEST_OVERTIME',
	REQUEST_PRODUCT = 'REQUEST_PRODUCT',
	FILE = 'FILE',
	DOCUMENT = 'DOCUMENT',
	ACTIVATE_TRANSACTION = 'ACTIVATE_TRANSACTION',
	CHECKOUT_TRANSACTION = 'CHECKOUT_TRANSACTION',
}

type MessageDefaultProps = {
	content: string;
	userID: string;
	chatRoomID: string;
	viewed?: boolean;
	otherUserID: string;
	type: MessageType;
	//information: MessageInfo | RescheduleInfo | ProductInfo | OvertimeInfo;
};

export type MessageNormalProps = MessageDefaultProps & {
	type: MessageType.MESSAGE;
	information: MessageInfo;
	id:string
};

type MessageReschedulelProps = MessageDefaultProps & {
	type: MessageType.RESCHEDULE;
	information: RescheduleInfo;
};

type MessageRequestOvertimeProps = MessageDefaultProps & {
	type: MessageType.REQUEST_OVERTIME;
	information: OvertimeInfo;
};

type MessageRequestProductProps = MessageDefaultProps & {
	type: MessageType.REQUEST_PRODUCT;
	information: ProductInfo;
};

type MessageDocsProductProps = MessageDefaultProps & {
	type: MessageType.FILE | MessageType.DOCUMENT;
	information: any;
};

type MessageTransactionActive = MessageDefaultProps & {
	type: MessageType.ACTIVATE_TRANSACTION;
	information: ActivateTransaction;
};

type MessageCheckOutTransaction = MessageDefaultProps & {
	type: MessageType.CHECKOUT_TRANSACTION;
	information: Checkout_Transaction;
};


export type MessageProps =
	| MessageNormalProps
	| MessageReschedulelProps
	| MessageRequestOvertimeProps
	| MessageDocsProductProps
	| MessageRequestProductProps
	| MessageTransactionActive
	| MessageCheckOutTransaction;

type MessegeFormat = {
	content: string;
	userID: string;
	chatRoomID: string;
	viewed: boolean;
	otherUserID: string;
	type: MessageType;
	information: string;
};


export type ChatRoom = {
	__typename: "ChatRoom";
	chatRoomUsers: ModelChatRoomUserConnection;
	createdAt: string;
	id: string;
	lastMessage: Message;
	lastMessageID: string;
	messages: ModelMessageConnection;
	updatedAt: string;
};

type ModelChatRoomUserConnection = {
	__typename: "ModelChatRoomUserConnection";
	nextToken: null;
};

type Message = {
	__typename: "Message";
	chatRoomID: string;
	content: string;
	createdAt: string;
	id: string;
	information: string; // This could be parsed as a JSON object for more specific typing, if necessary.
	otherUserID: string;
	type: "MESSAGE";
	updatedAt: string;
	userID: string;
	viewed: boolean;
};

type ModelMessageConnection = {
	__typename: "ModelMessageConnection";
	nextToken: null;
	items: Message[]
};

// Additional helper type for 'information' in Message, if necessary:
type MessageInformation = {
	transactionId: string;
};

export const getChatRoom_Service = (id: string): Promise<ChatRoom> => {
	return new Promise((resolve, reject) => {
		const getRoomPromise = client.graphql({
			query: getChatRoom,
			authMode: 'userPool',
			variables: { id: id },

		}) as Promise<any>;
		getRoomPromise.then(
			(Room) => {
				if (Room.data.getChatRoom) {
					resolve(Room.data.getChatRoom);
				} else {
					console.warn('The chatRoom does not exist');
					reject(true);
				}
			},
			(error) => {
				console.error('Error getting Chat Romm', error);
				reject(false);
			},
		);
	});
};


export const createNewSupportChatRoom = (reportId: string, supportID: string, userID: string) => {
	return new Promise((resolve, reject) => {

		const newUserChatRoomInfo = {
			chatRoomID: reportId,
			userID: supportID,
		};

		const createChatRoomUserPromise = client.graphql({
			query: createChatRoomUser,
			authMode: 'userPool',
			variables: { input: newUserChatRoomInfo },
		}) as Promise<any>;

		createChatRoomUserPromise.then(
			() => {
				const newUserChatRoomInfoUser = {
					chatRoomID: reportId,
					userID: userID,
				};
				const createOtherUserChatRoomPromise = client.graphql({
					query: createChatRoomUser,
					authMode: 'userPool',

					variables: { input: newUserChatRoomInfoUser },
				}) as Promise<any>;

				createOtherUserChatRoomPromise.then(
					() => {
						const CreateChatRoomPromise = client.graphql({
							query: createChatRoom,
							authMode: 'userPool',

							variables: { input: { id: reportId } },
						}) as Promise<any>;

						CreateChatRoomPromise.then(
							(newChatRoom) => {
								resolve(newChatRoom);
							},
							(error) => {
								console.error('Error Creating Chat Room');
								console.error(error);
								reject(error);
							},
						);
					},
					(error) => {
						console.error('Error Creating Chat Room for the other User');
						console.error(error);
						reject(error);
					},
				);
			},
			(error) => {
				console.error('Error Creating Chat Room User');
				console.error(error);
				reject(error);
			},
		);
	});
};


export const AddUserToChatSupport = (chatId: string, userId: string): Promise<any> => {
	return new Promise((resolve, reject) => {

		const newUserChatRoomInfo = {
			chatRoomID: chatId,
			userID: userId,
		};

		const createChatRoomUserPromise = client.graphql({
			query: createChatRoomUser,
			authMode: 'userPool',
			variables: { input: newUserChatRoomInfo },
		}) as Promise<any>;

		createChatRoomUserPromise.then((chatRoomUser) => {
			resolve(chatRoomUser)
		}).catch((error) => {
			reject(error)
		})

	})
}


export const getMessagesByChatRoom = (ChatRoomId: string) => {
	return new Promise((resolve, reject) => {
		const MessagesByChatRoom = client.graphql({
			query: messagesByChatRoom,
			authMode: 'userPool',
			variables: {
				chatRoomID: ChatRoomId,
				sortDirection: 'DESC',
			},
		}) as Promise<any>;

		MessagesByChatRoom.then(
			(messages: any) => {
				resolve(messages.data.messagesByChatRoom.items);
			},

			(error) => {
				console.error('Error getting chat Room messages');
				console.error(error);
				reject(null);
			},
		);
	});
};

export const onSendMessage = (Message: MessageProps) => {
	const NewMessage: MessegeFormat = {
		...Message,
		information: JSON.stringify(Message.information),
		viewed: false,
		
	};
	return new Promise((resolve, reject) => {
		const sendMesaggeQuery = client.graphql({
			query: createMessage,
			authMode: 'userPool',
			variables: {
				input:NewMessage,
			},
		}) as Promise<any>;

		sendMesaggeQuery
			.then((message) => {

				const updateChatRoomQuery = client.graphql({
					query: updateChatRoom,
					authMode: 'userPool',
					variables: {
						input: {
							id: Message.chatRoomID,
							lastMessageID: message.data.createMessage.id,
						},
					},
				}) as Promise<any>;

				updateChatRoomQuery
					.then((chatRoom) => {
						resolve(chatRoom);
					})
					.catch((error) => {
						console.error('Error Updating last chat room message: ');
						console.error(error);
						reject(null);
					});
			})
			.catch((error) => {
				console.error('Error Sending new message');
				console.error(error);
				reject(null);
			});
	});
};


export const UpdateChatMessage = (id: string) => {
	return new Promise((resolve, reject) => {
		const UpdateMessage = client.graphql({
			query: updateMessage,
			authMode: 'userPool',
			variables: {
				input: { id: id, viewed: true },
			},
		}) as Promise<any>;

		UpdateMessage.then(
			(message: MessageProps) => {
				resolve(message);
			},
			(error) => {
				console.error('Error updating status of message');
				console.error(error);
				reject(null);
			},
		);
	});
};
