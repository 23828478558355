import React, { useCallback, useEffect, useState } from 'react'
import ArrowGoBackButton from '../../../UIcomponents/ArrowGoBackButton/ArrowGoBackButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import TextPasswordRule from '../../../UIcomponents/TextPasswordRule/TextPasswordRule'
import "./CreateNewPassword.css"

type CreateNewPasswordprops = {
    goBack: (index: number) => void
    nextStep: (index: number) => void
    newPassword: string
    setNewPassword: React.Dispatch<React.SetStateAction<string>>
}

const CreateNewPassword = ({ goBack, nextStep, newPassword, setNewPassword }: CreateNewPasswordprops) => {

    const [password, setPassword] = useState(newPassword)
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState("")

    const [lengthRule, setLengthRule] = useState(false);
	const [CapRule, setCapRule] = useState(false);
	const [NumRule, setNumRule] = useState(false);


    const GoBack = useCallback(
        () => {
            goBack(1)
        },
        [],
    )

    useEffect(() => {
      
        onChangeHandler(newPassword)
    
    }, [])
    

    const onChangeHandler = (value: string) => {
		setPassword(value);

		let regex = new RegExp('^(?=.{8,})');
		let res = regex.test(value);
		setLengthRule(res);
		let regexNum = new RegExp('^(?=.*[0-9])');
		let resnum = regexNum.test(value);
		setNumRule(resnum);
		let regexCap = new RegExp('^(?=.*[A-Z])(?=.*[a-z])');
		let resCap = regexCap.test(value);
		setCapRule(resCap);

		if (error) {
			setError('');
		}
	};

    const handleSubmit = () => {
        if (!lengthRule || !CapRule || !NumRule) {
			return;
		}
        setNewPassword(password)
        nextStep(3)
    }
    
    const disableButton = !lengthRule || !CapRule || !NumRule

    return (
        <form className="con-forgot-password">
            <div className="flex">
                <ArrowGoBackButton goBack={GoBack} />
            </div>
            <p className="fs-26 color-text fw-700 mt-30">{"Create new password"}</p>
            <p className="fs-13 color-text mt-8">{
                "Your new password must be different from your previous used passwords."
            }</p>

            <p className="fs-14 color-text fw-600 mt-40">{"New Password"}</p>

            <div className="flex flex-d-column mt-40">
                <div className="input-login flex">
                    <input 
                        type={showPassword ? "text" : "password"} 
                        id="login-password-1" 
                        name="password" 
                        value={password}
                        onChange={(e) => {
                            onChangeHandler(e.target.value)
                        }}
                        className="fs-16 p-6 border-none input-password flex-1"
                    />
                    <button type="button" onClick={() => setShowPassword(!showPassword)}>
                        {
                            showPassword ?
                            <FontAwesomeIcon 
                                icon={ solid("eye-slash") }
                                className="fs-12"
                            />
                            :
                            <FontAwesomeIcon 
                                icon={ solid("eye") }
                                className="fs-12"
                            />
                        }
                    </button>
                </div>
            </div>

            <div className="mt-10">
                <TextPasswordRule approved={lengthRule} text={"At least 8 characters length"} /> 
                <TextPasswordRule approved={NumRule} text={"At least 1 Number"} /> 
                <TextPasswordRule approved={CapRule} text={"At least 1 Uppercase & 1 Lowercase alphabetical char"} /> 
            </div>

            <p className="p-absolute color-error fs-12 mt-6">
                {error}
            </p>
            

            <button 
                onClick={handleSubmit} 
                className={`mt-40 dark-gray-bg center width-100 btn-next-forgot-password ${disableButton ? "disable-btn": ""}`} 
                type="submit"
                disabled={disableButton}
            >
                    <p className="fs-15 c-white">{"Restablecer contraseña"}</p>
            </button>
        </form>
    )
}

export default React.memo( CreateNewPassword )