import React, { useEffect, useState } from 'react'
import "./RightHistoryTransaction.css"
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import StateTransactionHistoryLabel from '../../../UIcomponents/StateTransactionHistoryLabel/StateTransactionHistoryLabel'
import { GimGimmer } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { UserInfoCache, UserInfoCacheType, getMinimalUserInfoData } from '../../../../utils/staticVariables/staticVariables'
import { getProfilePictureByUserId } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const RightHistoryTransaction = () => {

    const { t } = useTranslation()

    const history = useSelector((state: RootState) => state.myHistoryData.historyTransaction)
    const userId = useSelector((state: RootState) => state.auth.user.id)

    const [userName, setUserName] = useState<string>("")
    const [userImg, setUserImg] = useState<string>("")

    const type: GimGimmer = history.gimId === userId ? "Gim" : "Gimmer"

    const price = type === "Gim" ? `+$ ${history.gimProfit}`
                    : history.state !== "CANCELLED" ? `$${history.cost}` : "--"
    

    useEffect(() => {

        const otherUserId = type === "Gim" ? history.gimmerId : history.gimId 

        const user = UserInfoCache.userNameCache.get(otherUserId)

        if(user?.name){
                setUserName(user.name)
            }else{
            getMinimalUserInfoData(otherUserId)
            .then((userInfo: UserInfoCacheType) => {
                UserInfoCache.addNameInfo(otherUserId, { name: userInfo.name, phoneNumber: userInfo.phoneNumber, mail: userInfo.mail, gimmerScore: userInfo.gimmerScore, gimScore: userInfo.gimScore })
                setUserName(userInfo.name)
            }).catch(() => {})
        }
        

        getProfilePictureByUserId(otherUserId, "small")
        .then((imgUrl) => {
            setUserImg(imgUrl)
        }).catch(() => {})
    }, [])


    return (
        <div className="right-history-transaction">
            <div className="flex flex-jc-sb flex-ai-center">
                <p className="fs-28 fw-600">{history.skill.jobName}</p>
                <StateTransactionHistoryLabel state={history.state} type={type} height={"3.5rem"} sizeLetter={"fs-14"} />
            </div>

            <div className="flex flex-jc-sb mt-30">
                <div className="flex">
                    <img className="img-circular-history circle" src={userImg} />
                    <div className="flex flex-d-column flex-jc-c ml-16">
                        <p className="fs-17 fw-600">{userName}</p>
                        <div className="flex mt-4">
                            <FontAwesomeIcon icon={solid("star")} className={`fs-13 ${type === "Gim" ? "color-gimmer" : "color-gim"}`} />
                            <p className="fs-13 ml-4">{type === "Gim" ? "Gimmer" : "Gim"}</p>
                        </div>
                    </div>
                </div>
                <p className="fs-24 fw-600 mt-2">{price}</p>
            </div>

            <div className="flex flex-jc-sb mt-30">
                <div className="">
                    <p className="fs-16 fw-600">{moment(history.schedule.startTime).format('dddd, MMMM Do YYYY')}</p>
                    <div className={`hour-label center border-radius-3 mt-8 c-white ${type === "Gim" ? "gim-bg" : "gimmer-bg"}`} style={{ width: "15rem" }} >
                        <p className="fs-15">{`${moment(history.schedule.startTime).format('hh:mm')} - ${moment(history.schedule.endTime).format('hh:mm')}`}</p>
                    </div>
                </div>

                <div className="flex flex-ai-end">
                    <button className="center btn-invoice pr-10 pl-10 border-radius-3">
                        <p className="fs-14">{t("History:label_11")}</p>
                        <FontAwesomeIcon icon={solid("file-invoice")} className="c-black fs-15 fw-500 ml-10" />
                    </button>
                </div>
            </div>

            <p className="fs-22 fw-600 mt-30">{t("History:label_12")}</p>

        </div>
    )
}

export default React.memo( RightHistoryTransaction )