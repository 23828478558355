import React from 'react'
import { useTranslation } from 'react-i18next'
import UserImgDropdown from '../../../UIcomponents/UserImgDropdown/UserImgDropdown'
import PersonsInvolvedList from './PersonsInvolvedList/PersonsInvolvedList'
import ActionsReportUser from './ActionsReportUser/ActionsReportUser'
import CardSupport from './CardSupport/CardSupport'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'

const RightSideUserReport = React.memo(() => {

    const { t } = useTranslation()

    const movements = useSelector((state: RootState) => state.manageUserReports.userReport.movements)

    const onSelect = (id: string) => {

    }

    return (
        <div className="pl-20 pb-10 mb-10 height-100" style={{ width: "40%", padding: "2rem" }}>
            
            {
                movements?.map((movement, index) => {
                    return(
                        <CardSupport 
                            key={`${movement.userId}-${index}`}
                            type={movement.supportRole}
                            actions={movement.actions}
                            userId={movement.userId}
                            start={movement.start}
                            end={movement.end}
                        />
                    )
                })
            }


        </div>
    )
})

export default React.memo( RightSideUserReport )