import { useEffect } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import HeaderCertificate from '../../../../components/VerifyCertificate/HeaderCertificate/HeaderCertificate';
import SliderCertificate from '../../../../components/VerifyCertificate/SliderCertificate/SliderCertificate';
import YourStatisticsCertificate from '../../../../components/VerifyCertificate/YourStatisticsCertificate/YourStatisticsCertificate';
import { getCertificateUsersImagesThunk, resetverifyCertificateImageReducer } from '../../../../redux/slices/components/VerifyCertificate/VerifyCertificateDataSlice';

const VerifyCertificateScreen = () => {

  const dispatch = useAppDispatch()
  const { sliderData, loadingContainer } = useSelector((state: RootState) => state.VerifyCertificateData);

  useEffect(() => {
      dispatch(getCertificateUsersImagesThunk())
      return () => {
          dispatch(resetverifyCertificateImageReducer())
      }
  }, [])

  return (
    <div className="flex p-relative height-100">
        <div>
            <HeaderCertificate />
            
            <div className="p-relative"> 
                { loadingContainer && <LoadingSpinner fullPage={false} /> }
                <SliderCertificate
                    sliderData={sliderData} 
                />
            </div> 

        </div>
        <YourStatisticsCertificate imagesRemaining={sliderData?.length} />
    </div>
  )
}

export default VerifyCertificateScreen