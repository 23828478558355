import { useState } from "react";
import { useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import { deleteJobCategory, deleteJobFromJobsNamesFile } from "../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions";
import LoadingSpinner from "../../../LoadingSpinner";
import CancelButton from '../../../UIcomponents/Modals/CancelButton/CancelButton'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./DeleteCategoryModal.css"
import { resetValueFromState } from "../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice";
import { removeCategoryReducer } from "../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { useTranslation } from "react-i18next";
import { deleteJobNameFileReducer } from "../../../../redux/slices/components/Global/GlobalSlice";


  

const DeleteCategoryModal = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { categoryId, categoryName, countryName } = useSelector((state: RootState) => state.managerJobModals.DeleteCategoryModal);
    const [loading, setLoading] = useState<boolean>(false);


    const handleSubmit = async(e :any) => {
        e.preventDefault();
        setLoading(true)

        try {
            
            await deleteJobCategory(categoryId)
            await deleteJobFromJobsNamesFile(categoryId)

            dispatch( deleteJobNameFileReducer(categoryId) )
            dispatch(resetValueFromState())
            dispatch(removeCategoryReducer(categoryId))
        } catch (error) {
            console.log("error", error)
            alert("General:Error")
        } finally{
            setLoading(false)
        }

    }

    const closeModal = () => {
        dispatch(resetValueFromState())
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
            <TitleModal title={t("General:label_010")} />
            <DescriptionModal description={`${t("General:label_104")}
                ${categoryName} 
                ${t("General:label_105")}
                ${countryName}?`} />
            <p className="fs-16 fw-500 mt-8 mb-30 color-persian-red">
                {t("General:label_106")}
            </p>
            <div className="flex flex-jc-end">
                <CancelButton onPress={closeModal} />
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default DeleteCategoryModal