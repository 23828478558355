import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { Colors } from '../../../../utils/colors/colors'
import { useTranslation } from 'react-i18next'

type JobCertifiedLabelProps = {
    jobName: string
    isCertifReq: boolean
    children?: ReactNode 
}

const JobCertifiedLabel = ({ jobName, isCertifReq, children }: JobCertifiedLabelProps) => {

    const { t } = useTranslation()

    return (
        <div className="flex flex-ai-center">
            {children}
            {
            isCertifReq 
            ? <div className="flex flex-ai-center">
                    <div className=" flex flex-ai-center border-radius-3 pr-12 pl-12" style={{ backgroundColor: Colors.PersianRed, height: "3.9vh",  }}>
                        <p className="fs-20-vh c-white mr-10 mt-2">{jobName}</p>
                        <FontAwesomeIcon icon={faGraduationCap} className="c-white fs-18-vh"  />
                    </div>

                    <div className="ml-20 center flex-d-column border-radius-3 pr-10 pl-10" style={{ backgroundColor: Colors.MercuryGray, height: "3.6vh",  }}>
                        <p className="fs-12-vh color-text">{t("SignUp:selectWhereScreen:label_07")}</p>
                        <p className="fs-12-vh color-text">{t("SignUp:selectWhereScreen:label_08")}</p>
                    </div>
                </div>
            :   <div className="pl-12 pr-12 center border-radius-3" style= {{ height: "3.9vh", backgroundColor: Colors.Gim }}>
                    <p className="fs-20-vh color-white fw-600">{jobName}</p>
                </div>
            }
        </div>
    )
}

export default React.memo( JobCertifiedLabel )