import { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { orderOffersReducer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import "./HeaderTableManageReports.css"
import { orderUserReportsReducer } from '../../../redux/slices/components/ManageReports/manageReportsDataSlice';


const HeaderTableManageReports = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.manageUserReports.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.manageUserReports.orderColumn)

    //const orderColumn = "a"
    //const orderDirection = false

    const orderArray = useCallback(
        ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {  
            dispatch(orderUserReportsReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
        },
        [],
    )

    return (
        <div className="flex con-header-table-report ff-roboto mt-24">

            <div className="report-item1 con-item-header-report pl-12">
                <ArrowColumn 
                    columnText={t("General:label_154")}
                    orderColumn={orderColumn} 
                    columnName={"id"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-item2 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_155")}
                    orderColumn={orderColumn} 
                    columnName={"requestedBy"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="report-item3 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_156")}
                    orderColumn={orderColumn} 
                    columnName={"reason"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="report-item4 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_157")}
                    orderColumn={orderColumn} 
                    columnName={"personsInvolved"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-item4_5 con-item-header-report center">
                <p className="column-text fs-13 fw-500 c-manatee text-align-center">{"Chat"}</p>
            </div>
            
            <div className="report-item5 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_158")}
                    orderColumn={orderColumn} 
                    columnName={"createdDate"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="report-item6 con-item-header-report">
                <ArrowColumn 
                    columnText={t("General:label_159")}
                    orderColumn={orderColumn} 
                    columnName={"userAssigned"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
        
        </div>
    )
}

export default HeaderTableManageReports