import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoryJob, JobCategoryJob } from './managerJobDataSlice';


export interface EditMultipleJobsModalData {
  categoryId: string,
  categoryName: string
}

export interface DeleteMultipleJobsModalData {
  categoryId: string,
  categoryName: string
}

export interface EditJobModalData {
  categoryId: string,
  job: JobCategoryJob
}

export interface ActivateJobModalData {
  newActivationState: boolean,
  categoryId: string,
  categoryName: string,
  jobId: string,
  jobName: string
}

export interface DeleteJobModalData {
  categoryId: string,
  jobId: string,
}

export interface AddNewJobModalData {
    categoryId: string,
    categoryName: string,
    imgKey: string
}

export interface ActivationJobModalData {
  newActivationState: boolean,
  job: JobCategoryJob,
  categoryJob: CategoryJob
}

export interface NewCategoryModalData {
  countryName: string
}

export interface DeleteCategoryModalData {
  categoryId: string,
  categoryName: string,
  countryName: string,
}

export interface EditCategoryModalData {
  categoryId: string,
  categoryName: string,
  country: string,
  imgKey: string,
  photoKey: string
}


const initialState = {
    modalName: "",
    ActivationJobModal: {} as ActivationJobModalData,
    DeleteCategoryModal: {} as DeleteCategoryModalData,
    EditCategoryModal: {} as EditCategoryModalData,
    AddNewJobModal: {} as AddNewJobModalData,
    DeleteJobModal: {} as DeleteJobModalData,
    ActivateJobModal: {} as ActivateJobModalData,
    EditJobModal: {} as EditJobModalData,
    EditMultipleJobsModal: {} as EditMultipleJobsModalData,
    DeleteMultipleJobsModal: {} as DeleteMultipleJobsModalData,
};

export const managerJobModalsSlice = createSlice({
  name: 'managerJobModals',
  initialState,
  reducers: {
    setActivationJobModalState: (state, action: PayloadAction<ActivationJobModalData>) => {
      state.modalName = "ActivationJobModal"
      state.ActivationJobModal = action.payload
      return state;
    },
    setNewCategoryModalState: (state) => {
      state.modalName = "NewCategoryModal"
      return state;
    },
    setDeleteCategoryState: (state, action: PayloadAction<DeleteCategoryModalData>) => {
      state.modalName = "DeleteCategoryModal";
      state.DeleteCategoryModal = {
        categoryId: action.payload.categoryId,
        categoryName: action.payload.categoryName,
        countryName: action.payload.countryName
      }
      return state;
    },
    setEditCategoryState: (state, action: PayloadAction<EditCategoryModalData>) => {
      state.modalName = "EditCategoryModal";
      state.EditCategoryModal = {
        categoryId: action.payload.categoryId,
        categoryName: action.payload.categoryName,
        country: action.payload.country,
        imgKey: action.payload.imgKey,
        photoKey: action.payload.photoKey
      }
      return state;
    },
    setAddNewJobState: (state, action: PayloadAction<AddNewJobModalData>) => {
      state.modalName = "AddNewJobModal"
      state.AddNewJobModal = action.payload
      return state;
    },
    setDeleteJobState: (state, action: PayloadAction<DeleteJobModalData>) => {
      state.modalName = "DeleteJobModal"
      state.DeleteJobModal = action.payload
      return state;
    },
    setActivateJobState: (state, action: PayloadAction<ActivateJobModalData>) => {
      state.modalName = "ActivateJobModal"
      state.ActivateJobModal = action.payload
      return state;
    },
    setEditJobState: (state, action: PayloadAction<EditJobModalData>) => {
      state.modalName = "EditJobModal"
      state.EditJobModal = action.payload
      return state;
    },
    setEditMultipleJobsModalState: (state, action: PayloadAction<EditMultipleJobsModalData>) => {
      state.modalName = "EditMultipleJobsModal"
      state.EditMultipleJobsModal = action.payload
      return state;
    },
    setDeleteMultipleJobsModalState: (state, action: PayloadAction<DeleteMultipleJobsModalData>) => {
      state.modalName = "DeleteMultipleJobsModal"
      state.DeleteMultipleJobsModal = action.payload
      return state;
    },
    resetValueFromState: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
    setActivationJobModalState, 
    setNewCategoryModalState,
    resetValueFromState,
    setDeleteCategoryState, 
    setEditCategoryState,
    setAddNewJobState,
    setDeleteJobState,
    setActivateJobState,
    setEditJobState,
    setEditMultipleJobsModalState,
    setDeleteMultipleJobsModalState,
} = managerJobModalsSlice.actions

export default managerJobModalsSlice.reducer