import React, { useState } from "react"
import { signOut } from 'aws-amplify/auth';
import "./LogoutButton.css"
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoadingSpinner from "../../LoadingSpinner";

const LogoutButton = () => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)

    const closeSession = () => {

      setLoading(true)
      signOut()
      .then(() => {
          window.location.href = process.env.REACT_APP_DOMAIN_URL ?? ""
      }).catch(() => {
          //alert("General:Error logout")
      }).finally(() => {
          setTimeout(() => {
              setLoading(false)  
          }, 1500); 
      })
    }

    if(loading)
        return( <LoadingSpinner fullPage={true} /> )

    return (
        <div onClick={closeSession}  className="logout-btn flex flex-ai-center pointer">
            <FontAwesomeIcon icon={solid("power-off")} className="color-torch fs-18" />
            <p className="color-torch ml-10 fs-16-vh fw-600">{t("General:label_103")}</p>
        </div>
    )
}

export default React.memo( LogoutButton )