import React, { useCallback, useState } from 'react'
import "./BanUserFields.css"
import InputList from '../../../../../UIcomponents/InputList/InputList'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import MyCheckboxCircular from '../../../../../UIcomponents/MyCheckboxCircular/MyCheckboxCircular'
import { ExtraUserReport } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { banUser } from '../../../../../../utils/requestsUtils/functionsRequests/reports/user/reportsFunctions'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../../../LoadingSpinner'
import { duration } from 'moment'

export type TimeBanned = "Temporary" | "Permanent" | ""

const BanUserFields = () => {

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)

    const [timeBanned, setTimeBanned] = useState<TimeBanned>("")
    const [userSelected, setUserSelected] = useState<ExtraUserReport | null>(null)
    const [reason, setReason] = useState("")
    const [numberDays, setNumberDays] = useState("")


    const userRequestBy = useSelector((state: RootState) => state.manageUserReports.userRequestBy)
    const userInvolved = useSelector((state: RootState) => state.manageUserReports.userInvolved)

    // if any of this returns false, disable button
    const formIsReady = !(
        loading 
        || !userSelected 
        || (!timeBanned 
                ? true
                : ( timeBanned === "Permanent" ? false : !(Number(numberDays) > 0) )
            ) 
        )

        //console.log(" ")
        //console.log("loading", loading)
        //console.log("userSelected", userSelected)
        //console.log("time", timeBanned)
        //console.log("Number.isInteger(Number(numberDays))", Number.isInteger(Number(numberDays)), Number(""))

    const onChangeTime = useCallback(
        (time: string) => {
            if(time === "Temporary") setTimeBanned("Temporary")
            else setTimeBanned("Permanent")
        },
        [],
    )

    const onSelectUser = (user: ExtraUserReport | null) => {
        setUserSelected(user)
    }

    const onBanUser = () => {

        if(!userSelected || !timeBanned)
            return

        const body = {
            time: timeBanned,
            duration: Number(numberDays),
            reason
        }

        setLoading(true)
        banUser(userSelected.id, body)
        .then(() => {

        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="mt-30 p-relative">

            { loading && <LoadingSpinner fullPage={false} />}

            <div className="flex">
                <div className="">
                    <p className="fs-13 mb-10">{t("Reports:user:label_16")}</p>
                    <InputList text={userSelected?.firstName ?? ""}  width="24rem" height="3.8rem" fontSize="fs-14">
                        {
                            [userRequestBy, userInvolved].map((user, index) => {
                                return(
                                    <div onClick={() => onSelectUser(user)} key={index} className="flex flex-ai-center mt-6 mb-6 pr-14 pl-14 pointer">
                                        <img 
                                            alt="user"
                                            src={user?.imgUri ?? "/user.svg"} 
                                            className="img-22 circle" 
                                        />
                                        <p className="fs-12 ml-8">{user?.firstName}</p>
                                    </div>
                                )
                            })
                        }
                    </InputList>
                </div>

                {
                    userSelected &&
                    <div className="flex flex-1 ml-40">

                        <div className="">
                            
                            <p className="fs-13 mb-10">{`${t("General:label_262")}:`}</p>

                            <div className="flex flex-ai-center">
                                <MyCheckboxCircular 
                                    value={"Permanent"} 
                                    onChange={onChangeTime} 
                                    valueCheckBox={timeBanned === "Permanent"} 
                                    size="1.8rem"
                                    sizeInner="1rem"
                                    backgroundColor="var(--dark-gimmer)"
                                />
                                <p className="fs-13 ml-8">{t("General:label_264")}</p>
                            </div>

                            <div className="flex flex-ai-center mt-6">
                                <MyCheckboxCircular 
                                    value={"Temporary"} 
                                    onChange={onChangeTime} 
                                    valueCheckBox={timeBanned === "Temporary"} 
                                    size="1.8rem"
                                    sizeInner="1rem"
                                    backgroundColor="var(--dark-gimmer)"
                                />
                                <p className="fs-13 ml-8">{t("General:label_263")}</p>

                            </div>

                        </div>

                        <div className="flex flex-ai-end">
                            {
                                timeBanned === "Temporary" &&
                                <input 
                                    placeholder="Number days"
                                    className="fs-12 p-3 input-without-focus ml-14 input-number-days border-radius-3"
                                    type="number"
                                    value={numberDays}
                                    onChange={(e) => {
                                        setNumberDays( Math.floor( Number( e.target.value.replace(/\./, "") ) ).toString() )
                                    }}
                                    style={{ padding: ".5rem" }}
                                />
                            }
                        </div>

                    </div>
                }
            </div>

            <p className="fs-13 mt-30 mb-10">{t("Reports:user:label_17")}</p>
            <input 
                className="width-100 fs-13 p-8 border-radius-3 input-reason-ban"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={`${t("General:label_266")}...`}
            />

            <button 
                onClick={onBanUser}
                disabled={!formIsReady}
                className={`btn-move-report ban-btn fw-600 fs-13 mt-24 mb-30 ${formIsReady ? "pointer" : ""}`}
                style={{ opacity: formIsReady ? 1 : 0.4 }}
            >
                {t("General:label_265")}
            </button>
        </div>
    )
}

export default React.memo( BanUserFields )