import { useState } from 'react';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import "./NewCategoryModal.css"
import FormNewCategoryModal from './FormNewCategoryModal/FormNewCategoryModal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { useAppDispatch } from '../../../../redux/store/store';

const NewCategoryModal = () => {

  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const [urlPreviewIcon, setUrlPreviewIcon] = useState<string>("")

  const closeModal = () => {
    dispatch(resetValueFromState())
  }

  return (
    <div className="blank-wrapper-modals flex flex-d-column height-job-modal-with-scroll p-relative" >
        <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
            <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
        </button>
        <div className="flex flex-jc-sb flex-ai-center pr-10">
            <TitleModal title={t("General:label_123")} />
            {
              urlPreviewIcon ?
                <div className="bg-image-cover preview-icon darken-icon" style={{ backgroundImage: `url("${urlPreviewIcon}")` }} ></div>
              : <div className="preview-icon not-icon circle center">
                    <p className="fs-22 c-white">Ico</p>
                </div>
            }
        </div>
        <FormNewCategoryModal 
            urlPreviewIcon={urlPreviewIcon}
            setUrlPreviewIcon={setUrlPreviewIcon}
        />
    </div>
  )
}

export default NewCategoryModal