import NavBarBtn from '../NavBarBtn/NavBarBtn'
import "./NavBar.css"
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useState } from 'react';
import SettingsList from '../SettingsList/SettingsList';
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside';
import ModalLanguageCurrency from '../../Language/ModalLanguageCurrency/ModalLanguageCurrency';
import { setShowModalLanguageCurrency } from '../../../redux/slices/authSlice';

const NavBar = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const [showList, setShowList] = useState(false)

    const auth = useAppSelector((state: RootState) => state.auth);
    const imgUrl = useAppSelector((state: RootState) => state.auth.user.imgUrl);
    

    const handleList = () => {
        setShowList(!showList)
    }

    const closeList = useCallback(
        () => {
            setShowList(false)
        },
        [],
    )

    const openLanguageCurrencyModal = () => {
        dispatch( setShowModalLanguageCurrency(true) )
    }
    

    return (
        <div className="NavBar-container center">
            <div className="max-width-container">

                <div className="pointer">
                    <img 
                        src="/name.svg" 
                        className="logo-word-gimwork"  
                        alt="gimwork" 
                    />
                </div>

                <div className="flex flex-ai-center">

                    <button onClick={openLanguageCurrencyModal}>
                        <img 
                            id="globe-btn"
                            src="/assets/svg/globe.svg" 
                            alt="globe" 
                            className="globe-img mr-24 pointer" 
                        />
                    </button>

                    <div className={`${auth.isLogged ? "navbar-btns-container" : "navbar-btns-container-disabled"}`}>
                        
                        <div className="center height-100">
                            <div 
                                id="settings-button"
                                onClick={handleList}
                                className="con-white-profile pointer p-relative white-bg flex flex-jc-sb flex-ai-center border-radius-3"
                            >
                                <FontAwesomeIcon icon={faBars} className="color-text fs-22-vh ml-8" />


                                <img 
                                    className="circle img-settings ml-10 mr-6" 
                                    src={imgUrl ? imgUrl : "/user.svg"}
                                    onError={(e) => { 
                                        (e.target as HTMLImageElement).src = "/user.svg" }
                                    }
                                    alt={"User"} 
                                />
                                {
                                    showList &&
                                    <ClickOutside onPress={closeList} exclude={["settings-button"]} >
                                        <SettingsList />
                                    </ClickOutside>
                                }

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <ModalLanguageCurrency isLogged={true} />

        </div>
    )
}

export default NavBar