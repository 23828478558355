import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import "./ArrowColumn.css"

interface ArrowColumn {
    columnText: string,
    orderColumn: string, // base column to order
    columnName: string,  // name of the table column
    orderDirection: boolean,  // true for ordering asc
    orderArray: ({ newOrderDirection, newOrderColumn }: {
        newOrderDirection: boolean;
        newOrderColumn: string;
    }) => void
}

const ArrowColumn = ({ orderColumn, columnName, orderDirection, orderArray, columnText }: ArrowColumn) => {

    const [animationArrow, setAnimationArrow] = useState<string>("")

    const handleSort = () => {
        
        orderArray({ newOrderDirection: !orderDirection, newOrderColumn: columnName })
    }

    return (
        <div className="flex flex-start">
            <div 
                className="flex"
                onMouseEnter={() => {
                    setAnimationArrow("out")
                }}

                onMouseLeave={() => {
                    setAnimationArrow("in")
                }}
            >
                <div className={`column-text fs-14-vh fw-600 
                    ${animationArrow === "out" ? "c-black" : "c-manatee"}`}
                >
                    {columnText}
                </div>
                <div 
                    className={`con-arrow-sort pointer
                        ${animationArrow === "in" && "animation-in"}
                        ${animationArrow === "out" && "animation-out"}
                    `}
                    onClick={handleSort}
                >

                    {
                        orderColumn === columnName ?
                            <>
                                {
                                    orderDirection ?
                                    <FontAwesomeIcon 
                                        icon={solid("arrow-up")}
                                        className={`little-sort-header
                                            ${animationArrow === "out" ? "c-black" : "c-manatee"}
                                        `}
                                    />
                                    :
                                    <FontAwesomeIcon 
                                        icon={solid("arrow-down")}
                                        className={`little-sort-header
                                            ${animationArrow === "out" ? "c-black" : "c-manatee"}
                                        `}
                                    />
                                }
                            </>
                        :
                        <FontAwesomeIcon 
                            icon={solid("arrow-up")}
                            className={`little-sort-header
                                ${animationArrow === "out" ? "c-black" : "c-manatee"}
                            `}
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default ArrowColumn