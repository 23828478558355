import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./ChatTab.css"
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { MessageProps, MessageType, createNewSupportChatRoom, getChatRoom_Service, getMessagesByChatRoom, onSendMessage } from '../../../../../utils/chats/ChatFunctions'
import ChatRoomMessage from '../../../../../utils/chats/ChatRoomMessage'
import { v4 as uuidv4 } from 'uuid';
import { UserReportType, setAddMessageReducer, setMessageAsViewedReducer, setMessagesReducer } from '../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import MessagesRequestBy from './MessagesRequestBy/MessagesRequestBy'
import MessagesPersonInvolved from './MessagesPersonInvolved/MessagesPersonInvolved'
import { Message } from '../../../../../utils/dbModels'
import { generateClient } from 'aws-amplify/api';
import { onNewMessageOnRoom, onUpdateMessageState } from '../../../../../utils/chats/Graphql/Queries'
import { OnNewMessageOnRoomEvent, OnUpdateMessageStateEvent } from '../../../../../utils/chats/types'
import { messageSeenUserReportToMessage, messageSendUserReportToMessage } from '../../../../../utils/chats/mappers'
import TabsUserReportChat from './TabsUserReportChat/TabsUserReportChat'
import InputChatUserReport from './InputChatUserReport/InputChatUserReport'

const client = generateClient();


type ChatTabProps = {

}

const ChatTab = ({ }: ChatTabProps) => {

    const dispatch = useAppDispatch()

    const reportId = useSelector((state: RootState) => state.manageUserReports.userReport.id)
    const requestById = useSelector((state: RootState) => state.manageUserReports.userReport.requestBy)
    const personInvolvedId = useSelector((state: RootState) => state.manageUserReports.userReport.personsInvolved)[0]
    const userAssignedToReport = useSelector((state: RootState) => state.manageUserReports.userReport.userAssigned)
    const supportId = useSelector((state: RootState) => state.auth.user.id)

    const chatRoomRequestById = `${reportId}_${requestById}`
    const chatRoomPersonInvolvedId = `${reportId}_${personInvolvedId}`

    const indexTab = useSelector((state: RootState) => state.manageUserReports.indexTab)

    const [tabUsers, setTabUsers] = useState(0)

    const inputRef = useRef<{ resetInputPersonInvolved: () => void, resetInputRequestBy: () => void }>(null)
    const listRequestByMessagesRef = useRef<HTMLDivElement>(null)
    const listPersonInvolvedMessagesRef = useRef<HTMLDivElement>(null)

    const getChatRoom = (reportID: string, otherUserId: string) => {
        return new Promise((resolve, reject) => {
            getChatRoom_Service(reportID)
            .then((res: any) => {
                //console.log("qwe", res)
                resolve("Ok")
            }).catch((error: any) => {
                createNewSupportChatRoom(reportID, supportId, otherUserId)
                .then((res: any) => {
                }).catch((error: any) => {
                }).finally(() => {
                    resolve("Ok")
                })
            })
        })
    }


    useEffect(() => {
        getChatRoom(chatRoomRequestById, requestById)
        getChatRoom(chatRoomPersonInvolvedId, personInvolvedId)

    }, [])
    

    useEffect(() => {
		const fetchMessage = async (chatRoomId: string, userReportType: UserReportType) => {
			getMessagesByChatRoom(chatRoomId).then(
				(messages: any) => {
                    dispatch(setMessagesReducer({ messages: messages.reverse(), type: userReportType }))
                    setTimeout(() => {
                        listRequestByMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                        listPersonInvolvedMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }, 15);
					//setChatMessage(messages);
					//ChatRoomMessage.setMessages(messages)	
            }).catch((error: any) => {
                console.log("---Error 34", error)
            })
		};
		fetchMessage(chatRoomRequestById, "RequestBy");
        fetchMessage(chatRoomPersonInvolvedId, "PersonInvolved");

		return () => {
			ChatRoomMessage.setMessages([])
		}
	}, []);


    useEffect(() => {
		const SetNewMessageSubcription = async (chatRoomID: string) => {
			const subscription = client.graphql({
				query: onNewMessageOnRoom,
				variables: { chatRoomID },
				authMode: 'userPool',
			}) as any

			const _subcription = subscription.subscribe({
				next: (data: OnNewMessageOnRoomEvent) => {
					if (data?.data?.onNewMessageOnRoom) {
						const messageArrived = messageSendUserReportToMessage(data?.data?.onNewMessageOnRoom)
                        console.log("messageArrived", messageArrived)
                        if(messageArrived.userID === requestById){
                            dispatch( setAddMessageReducer({ message: messageArrived, type: "RequestBy" }) )
                            setTimeout(() => {
                                listRequestByMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                            }, 15);
                        }
                        else if(messageArrived.userID === personInvolvedId){
                            dispatch( setAddMessageReducer({ message: messageArrived, type: "PersonInvolved" }) )
                            setTimeout(() => {
                                listPersonInvolvedMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                            }, 15);
                        }
						//if (newMessage?.chatRoomID !== ChatRoomId || (newMessage.userID === userId && newMessage.type === 'MESSAGE')) {
						//	return;
						//}
						//addNewMessage(newMessage);
					}
				},
				error: (error: any) => console.warn(error),
			});

			return () => {
				_subcription.unsubscribe();
				//setChatMessage([]);
				return null;
			};
		};

		SetNewMessageSubcription(chatRoomRequestById);
        SetNewMessageSubcription(chatRoomPersonInvolvedId);
	}, []);

    useEffect(() => {
		const setUpdateMessageStateSubscription = async (chatRoomID: string) => {
			const subscription = client.graphql({
				query: onUpdateMessageState,
				variables: { chatRoomID },
				authMode: 'userPool',
			}) as any;
			const _subcription = subscription.subscribe({
				next: (data: OnUpdateMessageStateEvent) => {
                    //console.log("newMessage", data?.data?.onUpdateMessageState)
                    const messageSeen = data?.data?.onUpdateMessageState

                    if(messageSeen.otherUserID === requestById)
                        dispatch( setMessageAsViewedReducer({ messageId: messageSeen.id, type: "RequestBy" }) )
                    else if(messageSeen.otherUserID === personInvolvedId)
                        dispatch( setMessageAsViewedReducer({ messageId: messageSeen.id, type: "PersonInvolved" }) )
                    /*
					if (newMessage.chatRoomID !== ChatRoomId) {
						return;
					}
					if (newMessage.userID === userId) {
						updateMessage(newMessage);
					}*/
				},
				error: (error: any) => console.warn(error),
			});

			return () => {
				_subcription.unsubscribe();
				//setChatMessage([]);
				return null;
			};
		};

		setUpdateMessageStateSubscription(chatRoomRequestById);
        setUpdateMessageStateSubscription(chatRoomPersonInvolvedId);

	}, []);




    const onSendPress = useCallback(
        (messageContent: string, type: UserReportType) => {

            //const messageContent = message;
            //setMessage('');
            const newId = uuidv4()
    
            const newMessage: MessageProps = {
                id: newId,
                content: messageContent,
                userID: supportId,
                chatRoomID: type === "RequestBy" ? chatRoomRequestById : chatRoomPersonInvolvedId,
                otherUserID: type === "RequestBy" ? requestById : personInvolvedId,
                viewed: false,
                type: MessageType.MESSAGE,
                information: { transactionId: '' },
            };
    
            const newMessageLocal: Message = {
                id: newId,
                __typename: MessageType.MESSAGE,
                content: messageContent,
                chatRoomID: type === "RequestBy" ? chatRoomRequestById : chatRoomPersonInvolvedId,
                userID: supportId,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                viewed: false,
                information: {}
            };
            //props.onNewMessage({ ...newMessage, updatedAt: moment().toISOString(), createdAt: moment().toISOString(), information: JSON.stringify(newMessage.information), viewed: false, user: { id: userId } })
            dispatch( setAddMessageReducer({ message: newMessageLocal, type }) )

            setTimeout(() => {
                if(type === "RequestBy"){
                    listRequestByMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                    inputRef.current?.resetInputRequestBy()
                }
                else{
                    listPersonInvolvedMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
                    inputRef.current?.resetInputPersonInvolved()
                }
                    
            }, 15);
            onSendMessage(newMessage)
            .then(() => {
                //dispatch( setAddMessageReducer({ message: newMessageLocal, type }) )
            })
            .catch((r) => {
                console.error('error onSendMessage', r)
            }).finally(() => {
            })
            
        },
        [],
    )

    const changeTab = useCallback(
        (n: number) => {
            if(n === tabUsers)
                return
            setTabUsers(n)
        },
        [tabUsers],
    )

    useEffect(() => {

        /*
        AddUserToChatSupport(chatRoomRequestById, supportId)
        .then((res) => {
            console.log("res", res)
        })
        .catch((error: any) => {
            console.log("error", error)
        })
        */

    }, [])
    
    

    return (
        <div className={`p-absolute height-100 flex flex-d-column width-100 white-bg ${indexTab === 2 ? "show-tab" : "hide-tab"}`} >

            <TabsUserReportChat tabUsers={tabUsers} changeTab={changeTab} />

            <div className="flex-1 athens-gray-bg con-messages-user-report flex flex-d-column">

                <div className="flex flex-1 p-relative">

                    <div className={`p-absolute aux-scroll ${tabUsers === 0 ? "show-tab" : "hide-tab"}`}>
                        <MessagesRequestBy tabUsers={tabUsers} indexTab={indexTab} />
                        <div ref={listRequestByMessagesRef} />
                    </div>

                    <div className={`p-absolute aux-scroll ${tabUsers === 1 ? "show-tab" : "hide-tab"}`}>
                        <MessagesPersonInvolved tabUsers={tabUsers} indexTab={indexTab} />
                        <div ref={listPersonInvolvedMessagesRef} />
                    </div>

                </div>

                <InputChatUserReport ref={inputRef} tabUsers={tabUsers} onSendPress={onSendPress} />

            </div>

        </div>
    )
}

export default React.memo( ChatTab )