import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconType } from  "react-icons";
import "./UploadImageButton.css"
import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';

interface UploadImageButtonProps {
    text: string
    iconName: string
    idInputFile: string
    style?: any
}

const UploadImageButton = ({ text, iconName, idInputFile, style }:UploadImageButtonProps) => {
  
  const getIconImg = () => {
    switch (iconName) {
      case "folder-image":
          return (<FontAwesomeIcon icon={solid("folder-image")} className="color-submit fs-18 mr-10"/>)
      case "file-arrow-up":
          return (<FontAwesomeIcon icon={faFileArrowUp} className="color-submit fs-18 mr-10" />)
      case "icons":
          return (<FontAwesomeIcon icon={solid("icons")} className="color-submit fs-18 mr-10"/>)
    
      default:
        break;
    }
  }

  return (
    <button
        onClick={(e) => {
          document.getElementById(idInputFile)?.click()
        }}
        type="button"
        className={`upload-img-btn pointer center`}
        style={style}
    >
        {
          getIconImg()
        }
        <span>{text}</span>
    </button>
  )
}
//        <Icon className="icon-upload-img"/>
export default UploadImageButton