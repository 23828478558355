import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import moment from 'moment'
import { MarketplaceProduct, MyProductBought } from '../../authSlice'

export type ProductType = "Marketplace" | "Purchased"

export type ModalNameMarkeplace = "" | "EditProduct" | "AddProduct" | "DeleteProduct"

export type DeleteProductData = {
    id: string
    productName: string
    type: ProductType
}

const getPaginated = <T>(items: T[], page: number, limit: number): T[] => {
    const skip = limit * (page - 1)
    return items.slice( skip, skip + limit );
}

export const numberProductsMarketplaceByPage: number[] = [ 10, 25, 50, 100 ]


const initialState = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    limit: numberProductsMarketplaceByPage[0],
    page: 1,
    marketplaceProductsBought: [] as MyProductBought[],
    marketplaceProductsPublished: [] as MarketplaceProduct[],

    marketplaceProductsBoughtToShow: [] as MyProductBought[],
    marketplaceProductsPublishedToShow: [] as MarketplaceProduct[],
    LastEvaluatedKey: "",
    //loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    modalName: "" as ModalNameMarkeplace,
    editProductModalData: {} as MarketplaceProduct,
    deleteProductModalData: {} as DeleteProductData,

    type: "Marketplace" as ProductType,

    totalProductsByCurrentTab: 0
}

export const marketplaceDataSlice = createSlice({
    name: 'marketplaceData',
    initialState,
    reducers: {
        setInitialProducts: (state, action: PayloadAction<{ productsBought: MyProductBought[], productsPublished: MarketplaceProduct[] }>) => {
            state.marketplaceProductsBought = action.payload.productsBought.filter((product) => product.typeProduct === "DIGITAL")
            state.marketplaceProductsPublished = action.payload.productsPublished

            state.marketplaceProductsBoughtToShow = action.payload.productsBought.slice(0, state.limit)
            state.marketplaceProductsPublishedToShow = action.payload.productsPublished.slice(0, state.limit)

            state.totalProductsByCurrentTab = state.marketplaceProductsPublished.length

            return state
        },
        setAddProductModalReducer: (state) => {
            state.modalName = "AddProduct"
            return state
        },
        setEditProductModalReducer: (state, action: PayloadAction<MarketplaceProduct>) => {
            state.modalName = "EditProduct"
            state.editProductModalData = action.payload
            return state
        },
        setDeleteProductModalReducer: (state, action: PayloadAction<{ id: string, type: ProductType, productName: string }>) => {
            state.modalName = "DeleteProduct"
            state.deleteProductModalData = action.payload
            return state
        },
        changeIndexTabMarketplaceReducer: (state, action: PayloadAction<number>) => {
            state.indexTab = action.payload
            if(action.payload === 1){
                state.type = "Marketplace"
                state.totalProductsByCurrentTab = state.marketplaceProductsPublished.length
            }
            else{
                state.type = "Purchased"
                state.totalProductsByCurrentTab = state.marketplaceProductsBought.length
            }
            return state
        },
        setAddMarketplaceProductReducer: (state, action: PayloadAction<MarketplaceProduct>) => {
            state.marketplaceProductsPublished.unshift( action.payload )
            state.marketplaceProductsPublishedToShow = getPaginated( state.marketplaceProductsPublished, state.page, state.limit )
            state.totalProductsByCurrentTab = state.totalProductsByCurrentTab + 1
            return state
        },
        setEditMarketplaceProductReducer: (state, action: PayloadAction<MarketplaceProduct>) => {
            for(let i=0; i<state.marketplaceProductsPublished.length; i++){
                if(state.marketplaceProductsPublished[i].id === action.payload.id)
                    state.marketplaceProductsPublished[i] = action.payload
            }
            state.marketplaceProductsPublishedToShow = getPaginated( state.marketplaceProductsPublished, state.page, state.limit )
            return state
        },
        setDeleteMarketplaceProductReducer: (state, action: PayloadAction<{ id: string, type: ProductType }>) => {
            const { id, type } = action.payload
            if(type === "Marketplace"){
                const index = state.marketplaceProductsPublished.findIndex((p) => p.id === id)
                state.marketplaceProductsPublished.splice(index, 1)
                state.marketplaceProductsPublishedToShow = getPaginated( state.marketplaceProductsPublished, state.page, state.limit )
            }else if(type === "Purchased"){
                const index = state.marketplaceProductsBought.findIndex((p) => p.id === id)
                state.marketplaceProductsBought.splice(index, 1)
                state.marketplaceProductsBoughtToShow = getPaginated( state.marketplaceProductsBought, state.page, state.limit )
            }
            state.totalProductsByCurrentTab = state.totalProductsByCurrentTab - 1

            return state
        },
        orderMarketplaceReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
            const { orderColumn, orderDirection  } = action.payload

            /*
            state.historyToShow = state.historyToShow.map((item: MarketplaceExtraData) => {
            //const user = UserInfoCache.userNameCache.get(item.userId)
            //if(user?.name)
            //  offer.userName = user.name
            //return offer
            })
            */
            //state.historyToShow = getOrderedState({ orderColumn, orderDirection, array: state.historyToShow })
            //state.orderColumn = orderColumn
            //state.orderDirection = orderDirection
            return state
        },
        paginateMarketplaceReducer: (state, action: PayloadAction<number>) => {
            state.page = action.payload

            if(state.type === "Marketplace"){
                state.marketplaceProductsPublishedToShow = getPaginated( state.marketplaceProductsPublished, state.page, state.limit )
            }else if(state.type === "Purchased"){
                state.marketplaceProductsBoughtToShow = getPaginated( state.marketplaceProductsBought, state.page, state.limit )
            }

            return state
        },
        changeNumberPagesMarketplaceReducer: (state, action: PayloadAction<number>) => {
            state.page = 1
            state.limit = action.payload
            if(state.type === "Marketplace"){
                state.marketplaceProductsPublishedToShow = getPaginated( state.marketplaceProductsPublished, state.page, state.limit )
            }else if(state.type === "Purchased"){
                state.marketplaceProductsBoughtToShow = getPaginated( state.marketplaceProductsBought, state.page, state.limit )
            }

            return state
        },
        resetManageOffersReducer: () => {
            return initialState
        },
        resetMarketplaceModalsReducer: (state) => {
            state.modalName = ""
            return state
        }
    },

    extraReducers: (builder) => {

    },

});

// Action creators are generated for each case reducer function
export const {  
    changeIndexTabMarketplaceReducer,
    resetManageOffersReducer,
    orderMarketplaceReducer,
    setInitialProducts,
    resetMarketplaceModalsReducer,

    setAddMarketplaceProductReducer,
    setEditMarketplaceProductReducer,
    setDeleteMarketplaceProductReducer,

    setAddProductModalReducer,
    setEditProductModalReducer,
    setDeleteProductModalReducer, 

    paginateMarketplaceReducer,
    changeNumberPagesMarketplaceReducer
} = marketplaceDataSlice.actions

export default marketplaceDataSlice.reducer