import axios from "axios"

export type Position = {
    latitude:   number
    longitude:  number
    address:    string
}

export type Coords = {
    latitude:   number
    longitude:  number
}

export const requestUserPosition = () => {
    return new Promise((resolve: (value: Coords) => void, reject) => {
        navigator.permissions.query({ name: "geolocation" })
        .then((result) => {
            if (result.state === "granted") {
                return navigator.geolocation.getCurrentPosition(
                    (result) => { 
                        resolve({
                            longitude: result.coords.longitude,
                            latitude: result.coords.latitude
                        })
                    },
                    (error) => { 
                        console.log("error s", error) 
                        reject(error)
                    }
                );
            } else if (result.state === "prompt") {
                return navigator.geolocation.getCurrentPosition(
                    (result) => { 
                        resolve({
                            longitude: result.coords.longitude,
                            latitude: result.coords.latitude
                        })
                    },
                    (error) => { 
                        console.log("error s", error) 
                        reject(error)
                    }
                );
            } else if (result.state === "denied") {
                reject("denied")
            }
            reject("NOTHING")
        }).catch((error) => {
            reject(error)
        })
    })
}

export const getLocalitation = (latitude: number, longitude: number): Promise<any> => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
			)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				console.error('Error Getting Location');
				if (error.response) {
					console.error(error.response.data);
					reject(error.response.data);
				}
				console.error(error.toJSON());
				console.error('ERROR ' + JSON.stringify(error));
				reject({});
			});
	});
};

export const getLatLngBounds = (latitude: any, longitude: any) => {
    // Create a new LatLngBounds object
    const bounds = new google.maps.LatLngBounds();
  
    // Extend the bounds to include the provided latitude and longitude
    bounds.extend({ lat: latitude, lng: longitude });
  
    return bounds;
};

export const DefaultPosition: Position = {
	latitude: 19.42710659843642,
	longitude: -99.16764226659043,
	address: "Av. P.º de la Reforma, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX"
} 

const degreesToRadians = (degrees: number): number => {
	return (degrees * Math.PI) / 180;
};

export const distanceInKmBetweenEarthCoordinates = (
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number,
): number => {
	var earthRadiusKm = 6371;

	var dLat = degreesToRadians(lat2 - lat1);
	var dLon = degreesToRadians(lon2 - lon1);

	lat1 = degreesToRadians(lat1);
	lat2 = degreesToRadians(lat2);

	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return earthRadiusKm * c;
};