import React, { Fragment } from 'react'
import "./CardPricesSimulator.css"

export type RowCardPriceSimulator = { name: string, value: number | string }

type CardPricesSimulatorProps = {
    title: string
    color: string
    data: RowCardPriceSimulator[]
}

const CardPricesSimulator = ({ title, color, data }: CardPricesSimulatorProps) => {
    return (
        <div className="p-relative card-prices-simulator" style={{ backgroundColor: color }}>
            <p className="fs-16-vh color-text p-absolute title-card-price-simulator p-3" style={{ backgroundColor: color }}>{title}</p>

            <div className="wrapper-grid-price-simulator-card fs-14-vh mt-10">
                {
                    data.map((row, index) => {
                        return (
                            <Fragment key={index}>
                                <p>{row.name}</p>
                                <p>=</p>
                                <p>{row.value}</p>
                            </Fragment>
                        )
                    })
                }
            </div>
            {
                /*
                            <table className="mt-20">
                <tbody>
                {
                    data.map((row) => {
                        return (
                            <tr className="fs-14-vh">
                                <td>{row.name}</td>
                                <td>=</td>
                                <td>{row.value}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
                */
            }

        </div>
    )
}

export default React.memo( CardPricesSimulator )