import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowTipsProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}

export type DataInputRowJob = { 
    name:           string
    placeholder:    string 
    width:          string 
    type:           string 
    step?:          string | number 
}

const RowTips = ({ inputValues, isDay, valueCheckBox, onChangeCheckbox, onChange, onFocus }: RowTipsProps) => {

    const { t }  =useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "tipMin_Day",               placeholder: t("ManageJobs:label_10"),  width: "13%",   type: "text",   step: undefined },
        { name: "tipGimIVA_Day",            placeholder: "Gim IVA",                 width: "13%",   type: "text",   step: undefined },
        { name: "tipGimmerIVA_Day",         placeholder: "Gimmer IVA",              width: "13%",   type: "text",   step: undefined },
        { name: "tipGimworkIVA_Day",        placeholder: "Gimwork IVA",             width: "13%",   type: "text",   step: undefined },
        { name: "tipISR_Day",               placeholder: "ISR",                     width: "13%",   type: "text",   step: undefined },
        { name: "tipRMP_Day",               placeholder: "RMP",                     width: "13%",   type: "text",   step: undefined },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "tipMin_Night",               placeholder: t("ManageJobs:label_10"),    width: "13%",   type: "text",   step: undefined },
        { name: "tipGimIVA_Night",            placeholder: "Gim IVA",                   width: "13%",   type: "text",   step: undefined },
        { name: "tipGimmerIVA_Night",         placeholder: "Gimmer IVA",                width: "13%",   type: "text",   step: undefined },
        { name: "tipGimworkIVA_Night",        placeholder: "Gimwork IVA",               width: "13%",   type: "text",   step: undefined },
        { name: "tipISR_Night",               placeholder: "ISR",                       width: "13%",   type: "text",   step: undefined },
        { name: "tipRMP_Night",               placeholder: "RMP",                       width: "13%",   type: "text",   step: undefined },
    ], []);

    // if it is undefined the checker will ignore if the field is empty
    const customDependentValue = isDay ? undefined : true  
    
    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#D4EDDA"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_12")}
                color="#D4EDDA"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowTips