import React, { useEffect } from 'react'
import "./CardTransactionHistory.css"
import { getJobS3ImgUri } from '../../../../utils/staticVariables/staticVariables'
import { GimGimmer } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import useGetUserInfo from '../../../hooks/useGetUserInfo/useGetUserInfo'
import WorkPreferenceColorDynamic from '../../../UIcomponents/WorkPreferenceColorDynamic/WorkPreferenceColorDynamic'
import { Colors } from '../../../../utils/colors/colors'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'

type CardTransactionHistoryProps = {
    id: string
    imgKey: string
    type: GimGimmer
    jobName: string
    userId: string
    typePlace: WorkPreferenceStr
    onPressCard?: (id: string) => void
}

const CardTransactionHistory = ({
    id,
    imgKey,
    type,
    jobName,
    userId,
    typePlace,
    onPressCard
}: CardTransactionHistoryProps) => {
    //console.log("getJobS3ImgUri(`${imgKey}_400`)", getJobS3ImgUri(`${imgKey}_400`))

    const { name, imgUrl } = useGetUserInfo(userId)

    return (
        <div onClick={() => onPressCard ? onPressCard(id) : () => {}} className={`card-transaction-history flex width-100 border-radius-3 gallery-bg mb-10 ${onPressCard ? "pointer" : ""}`}>
            <img src={getJobS3ImgUri(`${imgKey}_400`)} className="img-job-card" />

            <div className="flex-1 ml-24 con-name-img flex flex-d-column flex-jc-sb height-100">
              <p className={`fs-20-vh fw-500 ${type === "Gim" ? "color-gim" : "color-gimmer"}`}>{jobName}</p>
              <div className="flex flex-ai-center">
                  <img className="img-user-card circle mr-10" src={imgUrl} alt="user" />
                  <p className={`fs-20-vh color-text`}>{name}</p>
              </div>
            </div>

            <div className="mr-20 mt-6">
              <WorkPreferenceColorDynamic
                  type={typePlace} 
                  size="3.2vh"
                  color={Colors.TEXT}
              />
            </div>

        </div>
    )
}

export default React.memo( CardTransactionHistory )