import { twoDecimals, twoDecimalsToPercentage } from "../numbers/numbers"

export type TaxesDataProduct = {
    IVA_GIMMER:     number
    IVA_GIMWORK:    number
    ISR:            number
    RMPC:           number
}
 
type GetProductPriceProps = {
    price: number
    timeTransaction_ISR: number
} & TaxesDataProduct
 
export const getProductPrice = ({
    timeTransaction_ISR,
    price,
    RMPC,
    IVA_GIMMER,
    IVA_GIMWORK,
    ISR
}: GetProductPriceProps) => {
    const product_RMP = twoDecimalsToPercentage(RMPC)
    const product_gimmerIVA = twoDecimalsToPercentage(IVA_GIMMER)
    const timeTransactionISR = twoDecimalsToPercentage(timeTransaction_ISR)
    
    const productPrice = price
 
    const product_Gimwork_RMP = twoDecimals(productPrice * product_RMP)
     
    const product_GimmerTotal_HT = twoDecimals(productPrice + product_Gimwork_RMP)
    const product_GimmerIVA = twoDecimals(product_GimmerTotal_HT * product_gimmerIVA)
    const product_GimmePaid = twoDecimals(product_GimmerTotal_HT + product_GimmerIVA)
 
    const product_GimworkTotal_TTC = twoDecimals(product_Gimwork_RMP)
 
    const product_GimTotal_HT = twoDecimals(product_GimmerTotal_HT)
    const product_GimIVA = twoDecimals(product_GimmerIVA / 2)
    const product_GimISR = twoDecimals(timeTransactionISR * product_GimTotal_HT)
 
    const product_GimIncome_TVA = twoDecimals(product_GimTotal_HT + product_GimIVA - product_GimISR - product_GimworkTotal_TTC)
 
    return {
        product_GimmerTotal_HT,
        product_GimmerIVA,
        product_GimmePaid,
        product_Gimwork_RMP,
        product_GimIncome_TVA
    }
}