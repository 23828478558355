import { faCircleX } from "@fortawesome/pro-solid-svg-icons"
import { i18n } from "i18next"
import moment from "moment"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ProfilePicture from "./Components/ProfilePic"
import { setNotificationAsViewed } from "../../../../../redux/slices/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Colors } from "../../../../../utils/colors/colors"



type CanceledTransactionNotificationprops =
    {
        "id": string,
        "iso_timestamp": string,
        "notifData": {
            "categoryLogo": string,
            "fromUserId": string,
            "jobImgKey": string,
            "jobName": string,
            "name": string,
            "receivedFromType": string,
            "startTime": string,
            "transactionId": string,
            "type": "GIMMER" | "GIM",
            isTheUserTheOneWhoCancel: boolean | ("true" | "false")
        }, "notifMsg": string, "timestamp": string, "viewed": boolean

        "t": any,
        i18n: i18n;
        setNotificationAsViewed(id: string): void
    }




class CanceledTransactionNotification extends React.PureComponent<CanceledTransactionNotificationprops> {
    constructor(props: CanceledTransactionNotificationprops) {
        super(props)
        this.onPress = this.onPress.bind(this)
    }

    onPress() {
        if (!this.props.viewed) {
            this.props.setNotificationAsViewed(this.props.id);
        }
        
    }

    

    override render(): React.ReactNode {


        return (
            <div 
                className="flex flex-ai-center"
                onClick={this.onPress}
                style={{
                    backgroundColor: this.props.viewed ? 'white' : '#EFEFEF',
                    height: "9.2vh"
                }}
            >
                <div style={{   }}>
                    <ProfilePicture
                        userId={this.props.notifData.fromUserId}
                    />
                </div>

                <div style={{ flex: 1,  justifyContent: "flex-start" }}>
                    <p className="color-text fs-18-vh">
                        {this.props.notifData.type === "GIMMER" ? this.props.t('Notifications:index:label_58') : this.props.t('Notifications:index:label_59')}
                    </p>
                    <p className="color-text fs-13-vh mt-4 mb-4">
                        {
                            !this.props.notifData.isTheUserTheOneWhoCancel || this.props.notifData.isTheUserTheOneWhoCancel === "false" ?
                                `${this.props.t('Notifications:index:label_51')} ${this.props.t(this.props.notifData.type === "GIMMER" ? 'Notifications:index:label_56' : 'Notifications:index:label_55')} ${this.props.t(
                                    `Jobs:${this.props.notifData.jobName.replace(
                                        /\s/g,
                                        '',
                                    )}`,
                                )} ${this.props.t('Notifications:index:label_52')} ${moment(this.props.notifData.startTime).format('MMMM Mo')} ${this.props.t('Notifications:index:label_53')} ${moment(this.props.notifData.startTime).format('H:mm A')} ` :
                                `${this.props.notifData.name} ${this.props.t('Notifications:index:label_54')} ${this.props.t(this.props.notifData.type === "GIMMER" ? 'Notifications:index:label_56' : 'Notifications:index:label_55')} ${this.props.t(
                                    `Jobs:${this.props.notifData.jobName.replace(
                                        /\s/g,
                                        '',
                                    )}`,
                                )} ${this.props.t('Notifications:index:label_52')} ${moment(this.props.notifData.startTime).format('MMMM Mo')} ${this.props.t('Notifications:index:label_53')} ${moment(this.props.notifData.startTime).format('H:mm A')}`}

                    </p>
                    <p style={{ color: Colors.Manatee }}>
                        {moment(this.props.iso_timestamp).fromNow()}
                    </p>
                </div>

                <div className="center height-100">
                    <div 
                        className="center"
                        style={{
                            height: "6.7vh", width: "6.7vh", borderWidth: 2, borderColor: this.props.notifData.type === "GIMMER" ? Colors.secondaryColor : Colors.mainColor,
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faCircleX}
                            className="fs-30-vh"
                            color={this.props.notifData.type === "GIMMER" ? Colors.secondaryColor : Colors.mainColor}
                        />
                    </div>
                </div>

            </div>
        )

    }
}


const mapsStateprops = () => {
    return {}
}

const mapsDispatchprops = {
    setNotificationAsViewed
}


export default connect(mapsStateprops, mapsDispatchprops)(withTranslation()(CanceledTransactionNotification))