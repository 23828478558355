import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import { BugReportTableItem } from "../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice";
import "./BugReportRows.css"
import BugReportRow from "./BugReportRow/BugReportRow";
import { useEffect } from "react";

const BugReportRows = () => {

    const bugReportsToShow = useSelector((state: RootState) => state.manageBugReportsData.bugReportsToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageBugReportsData.loadingContainer);

    return (
      <div className="container-bug-reports-rows flex flex-1 flex-d-column ff-roboto">
        <div className="aux-scroll-bug-reports">

              { loadingContainer && <LoadingSpinner fullPage={false} /> }
              
              {
                  
                  bugReportsToShow.map((bugReport: BugReportTableItem) => {
                      return(
                        <BugReportRow
                            key={bugReport.id}
                            bugReport={bugReport}
                        />
                      )
                  })
                  
              }

          </div>
      </div>
    )
}

export default BugReportRows