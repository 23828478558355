import { ChangeEvent, useState } from "react";
import { useAppDispatch } from "../../../../../redux/store/store";
import LoadingSpinner from "../../../../LoadingSpinner";
import { MyYup } from "../../../../../utils/verifications/myYup";
import { useTranslation } from "react-i18next";
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from "../../../../../utils/verifications/Forms";
import { ProductDB } from "../../../../../redux/slices/components/Products/ProductsSlice";
import GeneralInputTitle from "../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle";
import MyCheckbox from "../../../../UIcomponents/MyCheckbox/MyCheckbox";
import { ProductUpdateBody, updtateProductDB } from "../../../../../utils/requestsUtils/functionsRequests/products/productsFunctions";
import { editProductReducer, removeDeletedProductReducer, resetProductsDBModalsReducer } from "../../../../../redux/slices/components/ManageProducts/manageProductsDataSlice";

type FormEditProductDBModalProps = {
    product: ProductDB
}


const FormEditProductDBModal = ({ product }: FormEditProductDBModalProps) => {
    
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    
    const [pdfCheckbox, setPdfCheckbox] = useState(product.typeProduct === "DIGITAL" ? product.fileType.includes("pdf") : false)
    const [zipCheckbox, setZipCheckbox] = useState(product.typeProduct === "DIGITAL" ? product.fileType.includes("zip") : false)
    const [errorCheckbox, setErrorCheckbox] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: product.productName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justValidCharacters()},
        RFCcode: { error: "", value: product.RFCcode, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
        ISR: { error: "", value: product.ISR.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMMER: { error: "", value: product.IVA_GIMMER.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMWORK: { error: "", value: product.IVA_GIMWORK.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        RMPC: { error: "", value: product.RMPC.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
    })

    
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput, inputValues))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        try{

            if(product.typeProduct !== "PHYSICAL" && !pdfCheckbox && !zipCheckbox){
                setErrorCheckbox(true)
                return
            }
    
            if(existsError)
                return

            setLoading(true)
    
            if(product.typeProduct === "DIGITAL"){
                const filesTypes = getFilesArray()
                const body: ProductUpdateBody = {
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    status: "VALIDATED",
                    typeProduct: product.typeProduct,
                    fileType: filesTypes
                }
                await updtateProductDB(product.id, body)
                dispatch( editProductReducer({
                    ...product,
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    status: "VALIDATED",
                    typeProduct: product.typeProduct,
                    fileType: filesTypes
                }) )
            }else{
                const body: ProductUpdateBody = {
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    status: "VALIDATED",
                    typeProduct: product.typeProduct,
                }
                await updtateProductDB(product.id, body)
                dispatch( editProductReducer({
                    ...product,
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    status: "VALIDATED",
                    typeProduct: product.typeProduct,
                }) )
            }
            
            setLoading(false)
            dispatch(resetProductsDBModalsReducer())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Editing the category")
        }

    }

    const handleSubmitReject = async(e: any) => {

        e.preventDefault()

        try{
            setLoading(true)

            await updtateProductDB(product.id, { status: "REJECTED" })
            dispatch(removeDeletedProductReducer(product.id))
            dispatch(resetProductsDBModalsReducer())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Editing the category")
        }

    }
      
    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }

    const getFilesArray = () => {
        const files = []
        if(pdfCheckbox) files.push("pdf")
        if(zipCheckbox) files.push("zip")

        return files
    }

     
    return (
        <div className="flex flex-d-column" >

            <div className="flex flex-d-column">
                <GeneralInputTitle 
                    title={`Product name`}
                    name={"productName"}
                    value={inputValues["productName"].value}
                    onChange={onChangeValueInput}
                    onFocus={onChangeFocusInput}
                    placeholder={"Product name"}
                    error={inputValues["productName"].error}
                    width="100%"
                />

                <div className="flex flex-jc-sb">
                    <GeneralInputTitle 
                        title={`RFC Code`}
                        name={"RFCcode"}
                        value={inputValues["RFCcode"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"RFC Code"}
                        error={inputValues["RFCcode"].error}
                        width="70%"
                    />
                    <GeneralInputTitle 
                        title={`ISR`}
                        name={"ISR"}
                        value={inputValues["ISR"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"ISR"}
                        error={inputValues["ISR"].error}
                        width="26%"
                    />
                </div>

                <div className="flex flex-jc-sb">
                    <GeneralInputTitle 
                        title={`IVA GIMMER`}
                        name={"IVA_GIMMER"}
                        value={inputValues["IVA_GIMMER"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"IVA GIMMER"}
                        error={inputValues["IVA_GIMMER"].error}
                        width="48%"
                    />
                    <GeneralInputTitle 
                        title={`IVA GIMWORK`}
                        name={"IVA_GIMWORK"}
                        value={inputValues["IVA_GIMWORK"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"IVA GIMWORK"}
                        error={inputValues["IVA_GIMWORK"].error}
                        width="48%"
                    />
                </div>

                <div className="flex flex-jc-sb">
                    <GeneralInputTitle 
                        title={`RMPC`}
                        name={"RMPC"}
                        value={inputValues["RMPC"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"RMPC"}
                        error={inputValues["RMPC"].error}
                        width="48%"
                    />
                </div>
            </div>

            {
                product.typeProduct === "DIGITAL" &&
                <div className="p-relative" style={{ height: "7vh" }}>
                    <div className="flex flex-ai-center width-100 flex-jc-sb con-filt-types">
                        <div className="flex flex-ai-center">
                            <MyCheckbox size={"1.3rem"} value={pdfCheckbox} onChange={() => { setPdfCheckbox(!pdfCheckbox); setErrorCheckbox(false) }} />
                            <img 
                                id="globe-btn"
                                src="/assets/svg/pdf.svg" 
                                alt="globe" 
                                style={{ height: "3.8vh" }}
                                className="ml-10"
                            />
                        </div>

                        <div className="flex flex-ai-center">
                            <MyCheckbox size={"1.3rem"} value={zipCheckbox} onChange={() => {setZipCheckbox(!zipCheckbox); setErrorCheckbox(false)}} />
                            <img 
                                id="globe-btn"
                                src="/assets/svg/zip.svg" 
                                alt="globe" 
                                style={{ height: "3.8vh" }}
                                className="ml-10"
                            />
                        </div>
                    </div>
                    {errorCheckbox && <p className="fs-12-vh color-error p-absolute mt-6">{"You must select one or two of these options"}</p>}
                </div>
            }

            <div className="flex flex-ai-center width-100 flex-jc-sb">

                <button
                    className="dark-gray-bg c-white fw-600 fs-15-vh pointer button-submit-product border-radius-3"
                    onClick={handleSubmit}
                >
                    {"Submit"}
                </button>

            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
      </div>
    )
}

export default FormEditProductDBModal