import { Colors } from "../../../../../../utils/colors/colors"

interface MessageNotificationProps {
	message: string,
	numberTextLines?: number
}

export const MessageNotification = ({ message, numberTextLines }: MessageNotificationProps) => {

	return (
		<div
			className="fs-18-vh width-100 color-text mt-4 mb-4"
			//numberOfLines = { numberTextLines ?? 3 }
		>
			<p className="fs-13-vh" >{message}</p>
			{ 
				numberTextLines !== undefined &&
				numberTextLines > 99 && 
				<p style={{ color: Colors.brillantBlue }} >
					{" Reduce"}
				</p> 
			}
		</div>
	)
}


