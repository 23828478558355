import  { useState } from 'react'
import "./BasicInfoForm.css";
import { RootState, useAppSelector } from '../../../redux/store/store';
import HeaderInfo from './HeaderInfo/HeaderInfo';
import StarsInfo from './StarsInfo/StarsInfo';
import FieldJustRead from './FieldJustRead/FieldJustRead';
import LoadingSpinner from '../../LoadingSpinner';
import BorderHexagon from './BorderHexagon/BorderHexagon';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';


const BasicInfoForm = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate()

    const user = useAppSelector((state: RootState) => state.auth.user);

    const [loadingSubmitUpdateUser, setLoadingSubmitUpdateUser] = useState<boolean>(false);

    const numberJobs = useAppSelector((state: RootState) => state.auth.user.jobs)?.length ?? 0
    const numberTasks = useAppSelector((state: RootState) => state.auth.user.gimmerJobs)?.length ?? 0

    return (
      <div className="container-basic-info-form">
        { loadingSubmitUpdateUser && <LoadingSpinner fullPage={false} />}
        <HeaderInfo />
        <div className="flex flex-jc-sb mt-20">
            <div className="center flex-d-column">
                <p className="fs-20 fw-700">{numberJobs}</p>
                <p className="fs-14 text-align-center mt-4 mb-4">{t("Profile:label_005")}</p>
                <StarsInfo 
                    colorIcon="blue-gim"  
                    score={user.gimmerScore ? user.gimScore.toFixed(1) : "0"}
                    word="Gim" 
                />
            </div>
            <div className="center flex-d-column">
                <p className="fs-20 fw-700">{numberTasks}</p>
                <p className="fs-14 text-align-center mt-4 mb-4">{t("Profile:label_006")}</p>
                <StarsInfo 
                    colorIcon="red-gimmer"  
                    score={user.gimmerScore ? user.gimmerScore.toFixed(1) : "0"}
                    word="Gimmer" 
                />
            </div>
        </div>


         
        <div className="mt-4">
            <FieldJustRead field={t("General:label_005")} value={user.mail} />

            <FieldJustRead field={t("Profile:label_007")} value={user.phoneNumber} />

            { user.description && <FieldJustRead field={"Description"} value={user.description} /> }
  
            {
                user.roleId === "Super_Admin" &&
                <FieldJustRead field={t("General:label_006")} value={t("General:label_007")} />
            }
        </div>

        <div className="mt-30">
            <button onClick={() => navigate("/my-profile/deletion-reason")} type="button" className="flex flex-ai-center p-6 pointer">
                <FontAwesomeIcon icon={solid("trash")} className="color-error fs-14" />
                <p className="color-error fs-15 fw-500 ml-10 mt-1">{t("Profile:label_008")}</p>
            </button>
        </div>

      </div>
    )
  }
  
  export default BasicInfoForm