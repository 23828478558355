import React, { useEffect, useState } from 'react'
import "./CompanyMenu.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next'

const CompanyMenu = () => {

    const { t } = useTranslation()

    const [showCompanyMenu, setShowCompanyMenu] = useState(false)
    const [screens] = useState([
        {label: t("General:label_222"), screen: "about-us"},
        {label: t("General:label_223"), screen: "investors-relations"},
        {label: t("General:label_224"), screen: "carrers"},
        {label: "Blog", screen: "blog"},
    ])

    const onShowCompanyMenu = () => {
        setShowCompanyMenu(!showCompanyMenu)
    }

    const goToScreen = (screen: string) => {
        window.location.href= `https://www.gimwork.com/${screen}`;
    }

    useEffect(() => {
        const closeList = (e: any) => {
            //if(showCompanyMenu) setShowCompanyMenu(false)
            const clickButtonCompany = document.getElementById("company-button")?.contains(e.target);
            if(clickButtonCompany)
                return
            const clickInsideModal = document.getElementById("company-menu")?.contains(e.target);
            if(!clickInsideModal)
                setShowCompanyMenu(false)
        }
        document.addEventListener('click', closeList);
        
        return () => {
            document.removeEventListener('click', closeList);
        }
    }, [showCompanyMenu])
    

    return (
        <div className="aux-container-company-menu">
            <div className="item-navbar flex flex-d-column center" onClick={onShowCompanyMenu} id={"company-button"}>

                <div className="flex flex-jc-center">
                    <p className="mr-8 fs-16 pointer-2 item-navbar-text c-white" onClick={onShowCompanyMenu}>
                        {t("General:label_260")}
                    </p>
                    <FontAwesomeIcon icon={solid("chevron-down")} className="fs-10 mt-3" />
                </div>

            </div>
            {
                showCompanyMenu &&
                <div className="list-company-menu" id="company-menu">
                    {
                        screens.map((screen) => {
                            return(
                                <div key={screen.screen} className="item-list-menu pointer" onClick={() => goToScreen(screen.screen)}>
                                    <p className="fs-16 ff-karla  c-black">
                                        {screen.label}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            }

        </div>
    )
}

export default CompanyMenu