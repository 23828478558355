import { v4 as uuidv4 } from 'uuid';
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../redux/store/store";
import { FaImage } from "react-icons/fa";
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import UploadImageButton from "../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton";
import { getJobCategoryImagesLinks, updateJobsNamesFile } from "../../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions";
import { getAllJobsCategoriesChunk, JobCategoryJob } from "../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { resetValueFromState } from "../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice";
import LoadingSpinner from "../../../../LoadingSpinner";
import axios from "axios";
import "./FormAddNewJobModal.css"
import PreviewImage from "../../../../UIcomponents/Modals/PreviewImage/PreviewImage";
import { createNewJob } from "../../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions";
import { MyYup } from "../../../../../utils/verifications/myYup";
import ImgFormats from "../../../../UIcomponents/ImgFormats/ImgFormats";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../../../../../utils/images/resizeImages";
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../utils/verifications/Forms';
import { setNameFileReducer } from '../../../../../redux/slices/components/Global/GlobalSlice';
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle';
import TwoSelectorBar, { SideBarSelector } from '../../../../UIcomponents/TwoSelectorBar/TwoSelectorBar';
import RowPrincipal from '../../components/RowPrincipal/RowPrincipal';
import RowNightHours from '../../components/RowNightHours/RowNightHours';
import RowFixe from '../../components/RowFixe/RowFixe';
import RowTimeTransaction from '../../components/RowTimeTransaction/RowTimeTransaction';
import RowTransportCosts from '../../components/RowTransportCosts/RowTransportCosts';
import RowTips from '../../components/RowTips/RowTips';
import RowProduct from '../../components/RowProduct/RowProduct';
import { getCheckDependentJobValues, setOptionalDataFromInputsJobCategory } from '../../../../../utils/categoryJobs/categoryJobs';
import moment from 'moment';

type FormAddNewJobModalProps = {
    categoryId: string,
    imgKey: string
}

const FormAddNewJobModal = ({ categoryId, imgKey: imgKeyCategory }:FormAddNewJobModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    //const managerJobData = useSelector((state: RootState) => state.managerJobData);

    const [isDay, setIsDay] = useState(true)
    const [nightCheckbox, setNightCheckbox] = useState(false)
    const [fixeCheckbox_Day, setFixeCheckbox_Day] = useState(false)
    const [timeTransactionCheckbox_Day, setTimeTransactionCheckbox_Day] = useState(false)
    const [transportCheckbox_Day, setTransportCheckbox_Day] = useState(false)
    const [tipCheckbox_Day, setTipCheckbox_Day] = useState(false)
    const [productCheckbox_Day, setProductCheckbox_Day] = useState(false)

    const [fixeCheckbox_Night, setFixeCheckbox_Night] = useState(false)
    const [timeTransactionCheckbox_Night, setTimeTransactionCheckbox_Night] = useState(false)
    const [transportCheckbox_Night, setTransportCheckbox_Night] = useState(false)
    const [tipCheckbox_Night, setTipCheckbox_Night] = useState(false)
    const [productCheckbox_Night, setProductCheckbox_Night] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        jobName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        jobName_es: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},
        jobName_fr: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justLetters()},

        price_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        malusGim_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusGimmer_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusShareGim_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusShareGimmer_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        IVA_Day: {  error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},

        price_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        malusGim_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusGimmer_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusShareGim_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        malusShareGimmer_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},
        IVA_Night: { group: "principal_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(100)},


        nightStartTime: { group: "hour", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isHour()},
        nightEndTime: { group: "hour", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isHour()},
        
        fixedPrice_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimIVA_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimmerIVA_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimworkIVA_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedISR_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedRMP_Day: { group: "fixe_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        fixedPrice_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimIVA_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimmerIVA_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedGimworkIVA_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedISR_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        fixedRMP_Night: { group: "fixe_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        timeTransactionGimIVA_Day: { group: "timeTransaction_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionGimmerIVA_Day: { group: "timeTransaction_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionGimworkIVA_Day: { group: "timeTransaction_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionISR_Day: { group: "timeTransaction_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionRMP_Day: { group: "timeTransaction_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        
        timeTransactionGimIVA_Night: { group: "timeTransaction_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionGimmerIVA_Night: { group: "timeTransaction_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionGimworkIVA_Night: { group: "timeTransaction_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionISR_Night: { group: "timeTransaction_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        timeTransactionRMP_Night: { group: "timeTransaction_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        
        transportCostsPrice_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimIVA_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimmerIVA_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimworkIVA_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsISR_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsRMP_Day: { group: "transportCosts_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        transportCostsPrice_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimIVA_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimmerIVA_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsGimworkIVA_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsISR_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        transportCostsRMP_Night: { group: "transportCosts_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        tipMin_Day: { group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimIVA_Day: {group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimmerIVA_Day: { group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimworkIVA_Day: { group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipISR_Day: { group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipRMP_Day: { group: "tip_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        tipMin_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimIVA_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimmerIVA_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipGimworkIVA_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipISR_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        tipRMP_Night: { group: "tip_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        productGimIVA_Day: { group: "product_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productGimmerIVA_Day: { group: "product_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productGimworkIVA_Day: { group: "product_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productRMPC_Day: { group: "product_day", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

        productGimIVA_Night: { group: "product_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productGimmerIVA_Night: { group: "product_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productGimworkIVA_Night: { group: "product_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
        productRMPC_Night: { group: "product_night", error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},

    });  
 
    const [checkboxIsCertifReq, setCheckboxIsCertifReq] = useState(false)
    const [checkboxIsPool, setCheckboxIsPool] = useState<boolean>(false)
    const [checkboxIsGimmable, setCheckboxIsGimmable] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false);
    const [jobImage, setJobImage] = useState<any>(null)
    const [categoryImage_100, setCategoryImage_100] = useState<any>(null)
    const [categoryImage_200, setCategoryImage_200] = useState<any>(null)
    const [categoryImage_300, setCategoryImage_300] = useState<any>(null)
    const [categoryImage_400, setCategoryImage_400] = useState<any>(null)
    const [categoryImage_500, setCategoryImage_500] = useState<any>(null)
    const [categoryImage_600, setCategoryImage_600] = useState<any>(null)
    const [categoryImage_700, setCategoryImage_700] = useState<any>(null)
    const [categoryImage_800, setCategoryImage_800] = useState<any>(null)
    const [urlPreviewImage, setUrlPreviewImage] = useState<string>("")

    const [errorImage, setErrorImage] = useState<string>("");

    
    useEffect(() => {
        
        if(nightCheckbox)
            setIsDay(false)
        else
            setIsDay(true)
        
    }, [nightCheckbox])

    
    const handleSubmit = async(e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){

            const checkDependentValue = getCheckDependentJobValues({
                nightCheckbox,
                fixeCheckbox_Day,
                timeTransactionCheckbox_Day,
                transportCheckbox_Day,
                tipCheckbox_Day,
                productCheckbox_Day,
            
                fixeCheckbox_Night,
                timeTransactionCheckbox_Night,
                transportCheckbox_Night,
                tipCheckbox_Night,
                productCheckbox_Night,
            },inputValues[nameInput].group)

            if(checkErrorsWhenSubmit(nameInput, inputValues, checkDependentValue))
                existsError = true


        }
        setInputValues({...inputValues})

        if(existsError)
            return

        if(!urlPreviewImage){
            setErrorImage(t("General:label_098") ?? "")
            return
        }


        const jobId = uuidv4()
        try{
            
            setLoading(true)

            //const imgName = Math.random().toString().replace(/\./, "0")
            const imgName = `${categoryId}/${jobId}/mainJobImg`;

            // get links
            const  data = await getJobCategoryImagesLinks({ imgName });
            const imgKeyLink = data.imgKey
            const imgKeyLink_100 = data.imgKey_100
            const imgKeyLink_200 = data.imgKey_200
            const imgKeyLink_300 = data.imgKey_300
            const imgKeyLink_400 = data.imgKey_400
            const imgKeyLink_500 = data.imgKey_500
            const imgKeyLink_600 = data.imgKey_600
            const imgKeyLink_700 = data.imgKey_700
            const imgKeyLink_800 = data.imgKey_800
            const options = { headers: { 'Content-Type': 'image/jpg, image/png' } };

            // save images

            await Promise.all([
                axios.put(imgKeyLink, jobImage, options),
                axios.put(imgKeyLink_100, categoryImage_100, options),
                axios.put(imgKeyLink_200, categoryImage_200, options),
                axios.put(imgKeyLink_300, categoryImage_300, options),
                axios.put(imgKeyLink_400, categoryImage_400, options),
                axios.put(imgKeyLink_500, categoryImage_500, options),
                axios.put(imgKeyLink_600, categoryImage_600, options),
                axios.put(imgKeyLink_700, categoryImage_700, options),  
                axios.put(imgKeyLink_800, categoryImage_800, options),
            ])
            

            const body: JobCategoryJob = {
                id: jobId,
                //categoryId,
                active: false,
                imgKey: imgName,
                isCertifReq: checkboxIsCertifReq,
                isGimmable: checkboxIsGimmable,
                isPool: checkboxIsPool,
                jobName: inputValues["jobName"].value.toString(),
                linkedJobs: [],
                pricingInformation: {
                    day: {
                        price: Number(inputValues["price_Day"].value),
                        malusGim: Number(inputValues["malusGim_Day"].value),
                        malusGimmer: Number(inputValues["malusGimmer_Day"].value),
                        malusShareGim: Number(inputValues["malusShareGim_Day"].value),
                        malusShareGimmer: Number(inputValues["malusShareGimmer_Day"].value),
                        IVA: Number(inputValues["IVA_Day"].value),
                        timeTransaction: {
                            gimIVA: Number(inputValues["timeTransactionGimIVA_Day"].value),
                            gimmerIVA: Number(inputValues["timeTransactionGimmerIVA_Day"].value),
                            gimworkIVA: Number(inputValues["timeTransactionGimworkIVA_Day"].value),
                            ISR: Number(inputValues["timeTransactionISR_Day"].value),
                            RMP: Number(inputValues["timeTransactionRMP_Day"].value)
                        }
                    }
                }
            }

            setOptionalDataFromInputsJobCategory({
                inputValues,

                nightCheckbox,
                fixeCheckbox_Day,
                timeTransactionCheckbox_Day,
                transportCheckbox_Day,
                tipCheckbox_Day,
                productCheckbox_Day,
            
                fixeCheckbox_Night,
                timeTransactionCheckbox_Night,
                transportCheckbox_Night,
                tipCheckbox_Night,
                productCheckbox_Night,
            
                jobCategoryJob: body
            })

            await createNewJob(categoryId, body)
            //await createNewJob(body)

            const jobName_en = inputValues["jobName"].value;
            const jobName_es = inputValues["jobName_es"].value;
            const jobName_fr = inputValues["jobName_fr"].value;

            const bodyJobsNamesFile = {
                id: jobId,
                names: {
                    en: jobName_en,
                    es: jobName_es,
                    fr: jobName_fr
                }
            }

            await updateJobsNamesFile(bodyJobsNamesFile)

            dispatch( setNameFileReducer(bodyJobsNamesFile) )

            setLoading(false)
            dispatch(getAllJobsCategoriesChunk())
            dispatch(resetValueFromState())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Creating the job")
        }


    }


    const setImageUploaded = async() => {

        if(errorImage)
            setErrorImage("")

        const fileImage = (document.getElementById("new-add-job-image") as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]
            const [ 
                imageResized_100, 
                imageResized_200, 
                imageResized_300, 
                imageResized_400,
                imageResized_500,
                imageResized_600,
                imageResized_700,
                imageResized_800     
                ] = await Promise.all([
                resizeImage(file, 100, 100),
                resizeImage(file, 200, 200),
                resizeImage(file, 300, 300),
                resizeImage(file, 400, 400),
                resizeImage(file, 500, 500),
                resizeImage(file, 600, 600),
                resizeImage(file, 700, 700),
                resizeImage(file, 800, 800),
            ])

            setJobImage(file)

            setCategoryImage_100(imageResized_100)
            setCategoryImage_200(imageResized_200)
            setCategoryImage_300(imageResized_300)
            setCategoryImage_400(imageResized_400)
            setCategoryImage_500(imageResized_500)
            setCategoryImage_600(imageResized_600)
            setCategoryImage_700(imageResized_700)
            setCategoryImage_800(imageResized_800)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
  


    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //console.log(" ")
        const checkDependentValue = getCheckDependentJobValues({
            nightCheckbox,
            fixeCheckbox_Day,
            timeTransactionCheckbox_Day,
            transportCheckbox_Day,
            tipCheckbox_Day,
            productCheckbox_Day,
        
            fixeCheckbox_Night,
            timeTransactionCheckbox_Night,
            transportCheckbox_Night,
            tipCheckbox_Night,
            productCheckbox_Night,
        },inputValues[newEvent.target.name].group)

        checkErrors(newEvent, inputValues, setInputValues, checkDependentValue)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }
    

    const onChangeSelectorBar = useCallback(
        (newSide: SideBarSelector) => {
            if(newSide === "left")
                setIsDay(true)
            else
                setIsDay(false)
        },
        [],
    )
    
    

    return (
        <div className="flex flex-d-column">
            <div className="flex flex-jc-end flex-ai-center width-100 mb-20" style={{ height: "3.6vh" }}>
                {
                    nightCheckbox &&
                    <TwoSelectorBar onChange={onChangeSelectorBar} initSelected='right' colorMoving='#3A4153' width={"30rem"}>
                        <div className="gallery-bg" style={{ height: "3.6vh" }}>
                            hola
                        </div>
                        <div className="dark-gray-bg" style={{ height: "3.6vh" }}>
                            hey
                        </div>
                    </TwoSelectorBar>
                }
            </div>
            <form onSubmit={handleSubmit} className="flex width-100 mb-10 flex-1">
                <div className="flex flex-d-column" style={{ width: "30%" }}>
                    <div className="con-scroll-job-modal">
                        <div className="con-scroll-job-modal-aux">


                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:Usa_lang")})`}
                                name={"jobName"}
                                value={inputValues["jobName"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName"].error}
                            />

                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:Mexico_lang")})`}
                                name={"jobName_es"}
                                value={inputValues["jobName_es"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName_es"].error}
                            />

                            <GeneralInputTitle 
                                title={`${t("General:label_072")} (${t("Language:France_lang")})`}
                                name={"jobName_fr"}
                                value={inputValues["jobName_fr"].value}
                                onChange={onChangeValueInput}
                                onFocus={onChangeFocusInput}
                                placeholder={t("General:label_072")}
                                error={inputValues["jobName_fr"].error}
                            />

                        
                            <div className="con-checkboxes-add-job">
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isCertifReq" 
                                        name="isCertifReq" 
                                        onChange={() =>  setCheckboxIsCertifReq(!checkboxIsCertifReq) }
                                        checked={checkboxIsCertifReq}
                                    />
                                    <label className="label-checkbox-add-job" htmlFor="isCertifReq">{t("General:label_094")} </label>
                                </div>
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isPool" 
                                        name="isPool" 
                                        onChange={() => setCheckboxIsPool(!checkboxIsPool)}
                                        checked={checkboxIsPool}
                                    />
                                    <label className="label-checkbox-add-job" htmlFor="isPool">{t("General:label_095")}</label>
                                </div>
                                <div className="con-checkbox-add-job">
                                    <input 
                                        type="checkbox" 
                                        id="isGimmable" 
                                        name="isGimmable" 
                                        onChange={() => setCheckboxIsGimmable(!checkboxIsGimmable)}
                                        checked={checkboxIsGimmable}
                                    />
                                    <label className="label-checkbox-add-job" htmlFor="isGimmable">{t("General:label_096")}</label>
                                </div>
                            </div>

                            <input 
                                id="new-add-job-image"  
                                className="display-none" 
                                type="file" 
                                accept="image/jpeg, image/png"
                                onChange={setImageUploaded}
                            />

                            <div className="con-upload-btns">
                                <div className="con-input add-job-upload-btn">
                                    <UploadImageButton 
                                        idInputFile="new-add-job-image"  
                                        text={t("General:label_097")}
                                        iconName="folder-image" 
                                    />
                                    { errorImage && <p className="error-input-file">{errorImage}</p> }
                                </div>
                            </div>

                            <PreviewImage 
                                imgLink={urlPreviewImage}
                                height="29.6vh"
                            />
                            
                        <ImgFormats />

                        </div>
                    </div>

                    <div className="flex flex-d-column">
                        <ConfirmButton text={t("General:label_016")} width="100%" />
                    </div>
                </div>

                <div className="flex-1 ml-20">


                    <RowPrincipal 
                        inputValues={inputValues}
                        isDay={isDay}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowTimeTransaction 
                        inputValues={inputValues}
                        isDay={isDay}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowNightHours 
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={nightCheckbox}
                        onChangeCheckbox={(value) => setNightCheckbox(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowFixe 
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? fixeCheckbox_Day : fixeCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setFixeCheckbox_Day(value) : setFixeCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />


                    <RowTransportCosts
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? transportCheckbox_Day : transportCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setTransportCheckbox_Day(value) : setTransportCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowTips
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? tipCheckbox_Day : tipCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setTipCheckbox_Day(value) : setTipCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                    <RowProduct
                        inputValues={inputValues}
                        isDay={isDay}
                        valueCheckBox={isDay ? productCheckbox_Day : productCheckbox_Night}
                        onChangeCheckbox={(value) => isDay ? setProductCheckbox_Day(value) : setProductCheckbox_Night(value)}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                    />

                </div>

                { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default React.memo( FormAddNewJobModal )