import React, { useCallback, useEffect, useState } from 'react'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import { Colors } from '../../../utils/colors/colors'
import { useLocation, useNavigate } from 'react-router-dom'
import { autoSignIn, confirmSignUp, fetchUserAttributes, resendSignUpCode} from 'aws-amplify/auth'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { getUserCredentials } from '../../../utils/requestsUtils/axiosUtils'
import VerificationInput from 'react-verification-input'
import { setLoadingAuth } from '../../../redux/slices/authSlice'
import Cronometer from '../../../components/SignUp/ConfirmEmail/Cronometer'
import { useTranslation } from 'react-i18next'

const ConfirmEmailScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { state } = useLocation()
    const { errorEmailScreen } = state ?? {}

    const firstName = useSelector((state: RootState) => state.signUp.data.firstName)
    const lastName = useSelector((state: RootState) => state.signUp.data.lastName)
    const password = useSelector((state: RootState) => state.signUp.data.password)
    const phoneNumber = useSelector((state: RootState) => state.signUp.data.phoneNumber)
    const code = useSelector((state: RootState) => state.signUp.data.phoneNumber.code)
    const email = useSelector((state: RootState) => state.signUp.data.mail)

    const [codeConfirmation, setCodeConfirmation] = useState("")
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState(errorEmailScreen ? errorEmailScreen : "")
    const [showCronometer, setShowCronometer] = useState(false)

    const [loadingResendCode, setLoadingResendCode] = useState(false)

    useEffect(() => {
      
        if(!firstName || !lastName || !password || !phoneNumber || !code){
            navigate("/login")
            return
        }


    }, [])

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const handleCode = (code: string) => {

        if(error)
            setError("")

        if(code.length > 6)
            return
        
        setCodeConfirmation(code)
    }

    const onNext = () => {
		setLoading(true);
		const userName = email.replace('@', '_');
        const currency = {
			currName: 'Peso Mexicano',
			currSymbol: '$',
			currCode: '$',
		};
        /*
		messaging()
			.getToken()
			.then((token) => {
            */
				confirmSignUp({
					username: userName, confirmationCode: codeConfirmation, options: {
						clientMetadata: {
							firstName: firstName,
							lastName: lastName,
							deviceToken: "",
							countryName: "Mexico",
							currencyName: currency.currName ? currency.currName : '',
							currencySymbol: currency.currSymbol ? currency.currSymbol : '',
							currencyCode: currency.currCode ? currency.currSymbol : '',
							userName: userName,
                            platform: "web"
							//plataform: Platform.OS === 'android' ? 'android' : 'ios',
						},
					}
				})
                .then((data) => {
                    if (data.isSignUpComplete && data.nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
                        autoSignIn()
                        .then((data) => {
                            if (data.isSignedIn) {
                                fetchUserAttributes()
                                .then((attributes) => {
                                    console.log("attributes", attributes)
                                    if (attributes.phone_number && attributes.email && attributes.sub) {
                                        setLoading(false);
                                        dispatch( setLoadingAuth(true) )
                                        navigate("/profile-picture")
                                    } else {
                                        console.error('Attibutes not availables => fetchUserAttributes error')
                                        setError(t("SignUp:confirmEmailScreen:label_01") ?? "")
                                        //navigation.popToTop()
                                        setLoading(false);
                                    }
                                }).catch((error) => {
                                    console.error('fetchUserAttributes', error)
                                    setLoading(false);
                                    //navigation.popToTop()
                                });
                            }
                        }).catch((error: any) => {
                            console.error(error)
                        })
                    }else{
                        setLoading(false);
                    }
                })
                .catch((error: any) => {
                    console.log("error:::", error)
                    console.log("message", error.message)
                    setCodeConfirmation('');
                    if (error.message === 'Invalid verification code provided, please try again.'){
                        setError(t("SignUp:confirmEmailScreen:label_02") ?? "");
                    }
                    else if (error.message === 'User cannot be confirmed. Current status is CONFIRMED'){
                        setError(t("SignUp:confirmEmailScreen:label_03") ?? "");
                    }else {//Invalid code provided, please request a code again. ----this is the case
                        setError(t("SignUp:confirmEmailScreen:label_04") ?? "");
                    }
                    //Invalid code provided, please request a code again.
                    setLoading(false);
                })
                .finally(() => { });
                    /*
			})
			.catch((error: any) => {
				console.error(error);
				setLoading(false);
				setError('Error connecting with the app, please try later');
			});
            */
	}


    const onFinishCronometer = useCallback(
        () => {
            setShowCronometer(false)
        },
        []
    )

    const resendCode = () => {
        setLoadingResendCode(true)
        const userName = email.replace('@', '_');
        resendSignUpCode({ username: userName })
        .then((res) => {
            //this.props.saveMail(email);
            //this.props.navigation.navigate('MailValidationScreen', { type: 'USER-EXIST' });
            //resolve('');
            console.log("res resendCode", res)
            setShowCronometer(true)
        })
        .catch((error) => {
            console.log('Error resendSignUp', error);
            //this.props.navigation.popToTop();
        }).finally(() => {
            setLoadingResendCode(false)
        })
    }

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:confirmEmailScreen:label_05")}</p>

                    <VerificationInput
                        onChange={(e: any) => {
                            handleCode(e)
                        }}
                        value={codeConfirmation}
                        length={6}
                        validChars={"0-9"}
                        autoFocus={true}
                        classNames={{
                            container: "container",
                            character: "cell color-text",
                            characterInactive: "inactive-cell",
                            characterSelected: "selected-cell",
                            characterFilled: "filled-cell",
                        }}
                    />

                    <div className="p-relative">
                        <p className="p-absolute color-error fs-14 mt-6">
                            {error}
                        </p>
                    </div>

                    <div className="mt-80 p-absolute">
                    {
                        loadingResendCode ?
                        <div className="p-relative">
                            <LoadingSpinner fullPage={false} />
                        </div>
                        : (
                            showCronometer ?
                                <div className="center flex-d-column">
                                    <p className="fs-16-vh mb-4">{t("SignUp:confirmEmailScreen:label_06")}</p>
                                    <Cronometer onFinishCronometer={onFinishCronometer} />
                                    <p className="fs-16-vh mt-4">{t("SignUp:confirmEmailScreen:label_08")}</p>
                                </div>
                                : 
                                <button
                                    onClick={resendCode} 
                                    className="color-text fs-16-vh pointer underline"
                                >
                                    {t("SignUp:confirmEmailScreen:label_09")}
                                </button>
                        )
                    }
                    </div>
                </div>

                { ImageSignUp["ConfirmEmail"] }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:confirmEmailScreen:label_10")} 
                    disabled={false}
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default ConfirmEmailScreen