import React, { useEffect, useState } from 'react'
import ButtonGoogle from '../buttons/ButtonGoogle/ButtonGoogle'
import ButtonEmail from '../buttons/ButtonEmail/ButtonEmail'
import ButtonApple from '../buttons/ButtonApple/ButtonApple'
import { signInWithRedirect, signOut } from 'aws-amplify/auth'
import LoadingSpinner from '../../LoadingSpinner'
import { Hub } from "aws-amplify/utils";
import { getMyUser } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { User, getProfilePicture, login, setLoadingAuth } from '../../../redux/slices/authSlice'
import { useAppDispatch } from '../../../redux/store/store'
import ButtonSignUp from '../buttons/ButtonSignUp/ButtonSignUp'
import { useNavigate } from 'react-router-dom'

type SignInMethodsProps = {
    setShowSignMethods: React.Dispatch<React.SetStateAction<boolean>>
}


const SignInMethods = ({ setShowSignMethods }: SignInMethodsProps) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)
    const [textError, setTextError] = useState("")

    /*
    useEffect(() => {
      
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
              case "signInWithRedirect":
                console.log("1", payload)
                getMyUser()
                .then(({ user }: { user: User }) => {
                    console.log("user", user)
                    dispatch(getProfilePicture(user.id))
                    dispatch(login({ isLogged: true, user }))
                    //navigate("/my-profile")
                })
                .catch(() => {
                    setTextError("Error user")
                })
                .finally(() => { setLoading(false)})
                break;
              case "signInWithRedirect_failure":
                console.log("2", payload)
                break;
              case "customOAuthState":
                console.log("3", payload)
                break;
            }
        });

        return unsubscribe;
    }, [])
    */

    const onPressGoogle = () => {
        dispatch( setLoadingAuth(true)  )
        signInWithRedirect({ 
            provider: "Google"
        })
    }


    const onPressApple = () => {
        signInWithRedirect({ 
            provider: "Apple"
        })
    }

    const onPressEmail = () => {
        setShowSignMethods(false)
    }

    const onPressSignUp = () => {
        navigate("/name")
    }

    return (
        <div className="flex flex-d-column flex-ai-center p-relative">

            <div className="mb-20">
                <ButtonApple onPress={onPressApple} />
            </div>

            <div className="mb-20">
                <ButtonGoogle onPress={onPressGoogle} />
            </div>
            
            <div className="gray-h-line"></div>

            <div className="mt-20 mb-20">
                <ButtonEmail onPress={onPressEmail} />
            </div>

            <div className="gray-h-line"></div>

            <div className="mt-20">
                <ButtonSignUp onPress={onPressSignUp} />
            </div>

            {
                /*
                <div className="mt-20">
                    <button onClick={() => {
                        setLoading(true)  
                        signOut()
                        .then(() => {
                            window.location.href = process.env.REACT_APP_DOMAIN_URL ?? ""
                        }).catch(() => {
                            alert("General:Error logout")
                        }).finally(() => {
                            setLoading(false)  
                        })
                    }} style={{ padding: "2rem" }}>logout</button>
                </div>
                */
            }

            <div className="p-relative mt-20">
                <p className="p-absolute color-error fs-12 mt-6">
                    {textError}
                </p>
            </div>

            { loading &&  <LoadingSpinner fullPage={false} /> }
            
        </div>
    )
}

export default React.memo( SignInMethods )