
import axios, { AxiosRequestConfig } from "axios"
import { 
    adminApiUrl,
    getUserCredentials
} from "../../../axiosUtils"

const videoSuffixes = ["mp4", "mov"]
//const imageSuffixes = ["png", "jpg", "jpeg"]

export type FileInfo = {
    url: string
    type: "image" | "video"
    suffix: string
    numberFile: string
}

export const getFilesReport = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { id }
            };
            axios.get(`${adminApiUrl}/v1/reports/app-bug/files`, config)
            .then((res) => { 
                let countVideos = 0
                let countImages = 0
                const urlsFiles = res.data.links
                const filesInfo: FileInfo[] = urlsFiles.map((url: string) => {
                    const urlArray = url.split(".")
                    const suffix = urlArray[urlArray.length - 1]
                    if(videoSuffixes.includes(suffix)){
                        countVideos++
                        const counter = countVideos.toString()
                        return {
                            url,
                            type: "video",
                            suffix: suffix,
                            numberFile: counter.length === 1 ? "0"+counter : counter
                        }
                    }else{
                        countImages++
                        const counter = countImages.toString()
                        return {
                            url,
                            type: "image",
                            suffix: suffix,
                            numberFile: counter.length === 1 ? "0"+counter : counter
                        }
                    }
                })
    
                resolve(filesInfo)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/files`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export type FilePutLink = {
    idBug: string
    sufix: string
    ContentType: string
    fileName: string
}

export const getFilesAppBugReportPutLinks = (body: FilePutLink) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.put(`${adminApiUrl}/v1/reports/app-bug/files`, body, config)
            .then((res) => { 
                resolve(res.data.link)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/files`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


