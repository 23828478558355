import React from 'react'
import HeaderManageProducts from '../../../../components/ManageProducts/HeaderManageProducts/HeaderManageProducts'
import TabBarManageProducts from '../../../../components/ManageProducts/TabBarManageProducts/TabBarManageProducts'
import HeaderTableManageProducts from '../../../../components/ManageProducts/HeaderTableManageProducts/HeaderTableManageProducts'
import ProductsTableRows from '../../../../components/ManageProducts/ProductsTableRows/ProductsTableRows'
import PaginationManageProductsTableRow from '../../../../components/ManageProducts/PaginationManageProductsTableRow/PaginationManageProductsTableRow'
import ManageProductsModals from '../../../../components/ManageProducts/ManageProductsModals/ManageProductsModals'

const ManageProductsScreen = () => {
    return (
        <div className="flex flex-d-column height-100">
            <HeaderManageProducts />
            <TabBarManageProducts />
            <HeaderTableManageProducts />
            <ProductsTableRows />
            <PaginationManageProductsTableRow />
            <ManageProductsModals />
        </div>
    )
}

export default ManageProductsScreen