import  React, { useState } from 'react'
import "./HeaderInfo.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { RootState, useAppSelector } from '../../../../redux/store/store'
import { useTranslation } from 'react-i18next'

const HeaderInfo = () => {

  const { t } = useTranslation()

  const [showMessages, setShowMessages] = useState(false)

    const firstName = useAppSelector((state: RootState) => state.auth.user.firstName);
    const lastName = useAppSelector((state: RootState) => state.auth.user.lastName);
    const imgUrl = useAppSelector((state: RootState) => state.auth.user.imgUrl);

    const isProfilePicUploaded = useAppSelector((state: RootState) => state.auth.user.isProfilePicUploaded);
    const isProfilePicVerified = useAppSelector((state: RootState) => state.auth.user.isProfilePicVerified);

    const isIdUploaded = useAppSelector((state: RootState) => state.auth.user.isIdUploaded);
    const isIdVerified = useAppSelector((state: RootState) => state.auth.user.isIdVerified);

    const messages: string[] = []
    let type: "VERIFIED" | "PENDING" = "PENDING"

    if(!isProfilePicUploaded)
        messages.push( t("Profile:label_001") )
    else if(!isProfilePicVerified)
        messages.push( t("Profile:label_003") )

    if(!isIdUploaded)
        messages.push( t("Profile:label_002") )
    else if(!isIdVerified)
        messages.push( t("Profile:label_004") )

    if(isProfilePicUploaded && isProfilePicVerified && isIdUploaded && isIdVerified)
        type = "VERIFIED"
 
    const onMouseEnterImg = () => {
        if(type === "VERIFIED")
            return

        setShowMessages(true)
    }

    const onMouseLeaveImg = () => {
        if(type === "VERIFIED")
            return

        setShowMessages(false)
    }

    return (
      <div className="flex flex-d-column mb-20" >

          <div className="center">
            <div
                onMouseEnter={onMouseEnterImg}
                onMouseLeave={onMouseLeaveImg} 
              className="p-relative con-img-profile"
            >

                <img 
                    className="img-circle-profile" 
                    alt="profile" 
                    src={imgUrl ? imgUrl : "/user.svg"}
                    onError={(e) => { 
                        (e.target as HTMLImageElement).src = "/user.svg" }
                    }
                    //onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }} 
                />
                
                <div className="p-relative">
                    <div 
                      className={`status-photo center p-absolute ${type === "VERIFIED" ? "apple-bg" : "alert-bg"}`}
                    >
                        <FontAwesomeIcon icon={type === "VERIFIED" ? solid("check") : solid("exclamation")} className="fs-18 c-white" />
                    </div>

                    {
                      showMessages &&
                      <div className="info-status-photo p-absolute border-radius-3 pt-6 pb-6 pr-10 pl-10">
                          {
                              messages.map((message: string) => {
                                  return(
                                      <p key={message} className="fs-12 color-alert mt-4 mb-4">
                                          {message}
                                      </p>
                                  )
                              })
                          }
                    </div>
                    }
                </div>

            </div>
          </div>
      
          <p className="fs-26 fw-600 text-align-center">{firstName + " " + lastName}</p>

          
      </div>
    )
}

export default React.memo( HeaderInfo )