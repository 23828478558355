import React, { useEffect, useState } from 'react'
import { getImagesSkillUser } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import "./ImagesSkillSlider.css"
import { TypeOffers } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getJobS3ImgUri } from '../../../../utils/staticVariables/staticVariables'

type ImagesSkillSliderProps = {
    id: string
    type: TypeOffers
    jobId: string
    imgKey: string
}

const ImagesSkillSlider = ({ id, type, jobId, imgKey }: ImagesSkillSliderProps) => {

    const [indexSelected, setIndexSelected] = useState<number>(0)
    const [skillImages, setSkillImages] = useState<string[]>([])

    useEffect(() => {

        getImagesSkillUser(id, type, jobId, "small")
        .then((links: string[]) => {

            /*
            			const dataToArray: string[] = [];
			const firstjobImgKey = `${getS3ImgUri(jobImgKey)}_600`
			if (this.props.isPublic) {
				getUserSkillPicturesPublic(userId, profileType, jobId, 'small')
					.then((data) => {
						data.forEach((picSkill) => {
							dataToArray.push(picSkill.url);
						});
						this.setState({
							pictures: [firstjobImgKey, ...dataToArray],
							isLoaded: true,
						});
					})
					.catch(() => {
						this.setState({
							pictures: [firstjobImgKey],
							isLoaded: true,
						});
					});
            */
            //...res.links.map((url: string) => url.replace(/\?/g, "%3F"))
            setSkillImages([getJobS3ImgUri(`${imgKey}_400`), ...links])
            //setSkillImages(["#f00", "#0f0", "00f"])
        }).catch(()=>{
            setSkillImages([getJobS3ImgUri(`${imgKey}_400`)])
        })

        return () => {

        }
    }, [])
    

    const handleSliderScroll = (index: number) => {

        if(index < 0 || index > skillImages.length - 1)
            return

        const card = document.getElementById(`slider-skill-${index.toString()}`);
        const scrollContainer = document.getElementById("skill-imgs-slider");
        if(card && scrollContainer){
            const grayContainerData = scrollContainer.getBoundingClientRect();

            const cardData = card.getBoundingClientRect();

            //const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft - grayContainerData.left)
            const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft )

            const newPosition = cardLeftWithContainerBorderLeftPosition - (grayContainerData.width/2) + (cardData.width/2)
                
            setIndexSelected(index)
            scrollContainer.scrollTo({
                left: newPosition,
                behavior: "smooth"
            })
        }
    }

    return (
        <div className="p-relative flex-1 height-100 con-whole-slider">

            {
                skillImages.length > 1 &&
                <>
                    <div 
                        onClick={() => handleSliderScroll(indexSelected - 1)} 
                        className={`p-absolute arrow-skill-slider arrow-skill-left ${indexSelected > 0 ? "arrow-skill-hover pointer" : ""}`}
                    > 
                        <FontAwesomeIcon 
                            icon={solid("chevron-left")} 
                            color={indexSelected > 0 ? "#ffffff" : "#ffffff77" }
                            className="fs-20 p-absolute center-absolute mr-1" 
                        />
                    </div>

                    <div 
                        onClick={() => handleSliderScroll(indexSelected + 1)} 
                        className={`p-absolute arrow-skill-slider arrow-skill-right ${ indexSelected < skillImages.length - 1 ? "arrow-skill-hover pointer" : ""}`}
                    > 
                        <FontAwesomeIcon 
                            icon={solid("chevron-right")} 
                            color={indexSelected < skillImages.length - 1 ? "#ffffff" : "#ffffff77" }
                            className="fs-20 p-absolute center-absolute ml-1" 
                        />
                    </div>
                </>
            }

            <div id={"skill-imgs-slider"} className="con-img-job-detailed-skill p-relative">

                {
                    skillImages.map((url: string, index) => {
                        return(
                            <div
                                key={index}
                                id={`slider-skill-${index.toString()}`}
                                style={{ 
                                    backgroundImage: `url("${url}")`
                                    //backgroundColor: url
                                 }} 
                                className="img-slider-skill"
                            >

                            </div>
                        )
                    })
                }
            </div>
            <div className="p-absolute flex flex-jc-sb width-100 pr-10 pl-10" style={{ bottom: ".5rem" }}>
                {
                    skillImages.map((url: string, index) => {
                        return(
                            <div
                                key={index}
                                style={{ 
                                    //backgroundImage: `url("${url}")`
                                    height: "4px",
                                    borderRadius: "3px",
                                    width: `calc( (100% / ${skillImages.length}) - (5px))`
                                }} 
                                className={index === indexSelected ? "dark-gray-bg" : "white-bg" }
                            >

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( ImagesSkillSlider )