import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import "./TabBarManageProducts.css"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { canBeInteger } from '../../../utils/verifications/strings'
import { TypeProduct } from '../../../redux/slices/components/Products/ProductsSlice'
import { changeIndexTabProductsReducer, GetProductsDBThunk } from '../../../redux/slices/components/ManageProducts/manageProductsDataSlice'
import { isUUID } from '../../../utils/verifications/uuid/uuid'
import { getAllJobsCategoriesChunk, JobCategoryJob } from '../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import InputCategoryJobs from '../../UIcomponents/InputCategoryJobs/InputCategoryJobs'


type TabOfferOption = { index: number }

const colorsAnimation = ["gray","black", "black"]
const typesOffers = ["all", "DIGITAL", "PHYSICAL"]


const TabBarManageProducts = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const [leftLine, setLeftLine] = useState<number>(0)
    const [optionJobSelected, setOptionJobSelected] = useState<JobCategoryJob>()

    const categoryJobs = useSelector((state: RootState) => state.managerJobData.categoryJobs);
    const indexTab = useSelector((state: RootState) => state.manageProductsData.indexTab);
    const limit = useSelector((state: RootState) => state.manageProductsData.limit);
    const jobId = useSelector((state: RootState) => state.manageProductsData.jobId);
    const page = useSelector((state: RootState) => state.manageProductsData.page);
    const typeProduct = useSelector((state: RootState) => state.manageProductsData.typeProduct);
    //console.log("indexTab",indexTab)

    useEffect(() => {
            // Get a specific parameter value
        const limitParam = searchParams.get("limit") ?? "10"
        const jobIdParam = searchParams.get("jobId") ?? undefined
        const typeParam = searchParams.get("typeProduct") ?? "DIGITAL"

        let typeProduct: TypeProduct = "DIGITAL"
        if(typeParam === "PHYSICAL")
            typeProduct = "PHYSICAL"

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        
        let jobId = ""
        if( jobIdParam && isUUID(jobIdParam) )
            jobId = jobIdParam

        let index = 1
        if(typeProduct === "PHYSICAL")
            index = 2

        setTab({ index })
        dispatch(changeIndexTabProductsReducer({ indexTab: index }))
        dispatch(GetProductsDBThunk({ typeProduct, page, limit, jobId }))
        dispatch( getAllJobsCategoriesChunk() )
 
    }, [searchParams.get("typeProduct"), searchParams.get("jobId"), searchParams.get("limit")])


    useEffect(() => {
        if(indexTab < 0)
            setLeftLine(0)
    }, [indexTab])
    

    const setTab = ({ index }: TabOfferOption) => {
        //console.log("index setTab", index)
        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line") 

        if(smallLine){

            const marginBetweenOptions = 1; // rem
            const widthOption = 20; // rem

            let remsToMove = 0
            if(indexTab > 0)
                remsToMove = ((index - indexTab) * (widthOption+marginBetweenOptions) );
            else
                remsToMove = ((index - 1) * (widthOption+marginBetweenOptions) ); // case after search bar

            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
            dispatch(changeIndexTabProductsReducer({indexTab : index}))
            navigate(`/manage-products?limit=${limit}&typeProduct=${index === 1 ? "DIGITAL" : "PHYSICAL"}&jobId=${jobId}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }

    const onPressJob = useCallback(
        (job: JobCategoryJob) => {
            dispatch(GetProductsDBThunk({ typeProduct, page, limit, jobId: job.id }))
            setOptionJobSelected(job)
        },
        [typeProduct, limit, page, optionJobSelected],
    )
    

    return (
        <div id="container-tab" className="con-all-options-tab p-relative">

            <div 
                className={`option-tab-products ${indexTab === 1 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 1 })}
            >
                <p className={`text-option-tab-products fs-18-vh ${indexTab === 1  ? "italic" : ""}`}>{t("ManageProducts:table:label_01")}</p>
                <div id="1-option" className="option-line-products"></div>
            </div>
            
            <div 
                className={`option-tab-products ${indexTab === 2 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 2  })}
            >
                <p className={`text-option-tab-products fs-18-vh ${indexTab === 2  ? "italic" : ""}`}>{t("ManageProducts:table:label_02")}</p>
                <div id="2-option" className="option-line-products"></div>
            </div>

            <div 
                id="small-line" 
                style={{ left: `${leftLine.toString()}rem` }} 
                className={`small-line-products ${colorsAnimation[indexTab]}-line ${indexTab < 0 ? "visibility-hidden" : ""}`}
            ></div>
            
            <div className="whole-line"></div>

            <div className="p-absolute center height-100" style={{ right: 0 }}>
                <InputCategoryJobs 
                    idJobSelected={optionJobSelected?.id}
                    categoryJobs={categoryJobs}
                    onPressJob={onPressJob}
                />
            </div>

        </div>
    )
}

export default React.memo( TabBarManageProducts )