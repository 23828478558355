import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import LoadingSpinner from '../../LoadingSpinner'
import { ContractInfo } from '../../../redux/slices/components/Rules/rulesSlice'
import RowRules from './RowRules/RowRules'

const RowsRules = () => {

    const informations = useSelector((state: RootState) => state.rules.contracts)
    const loadingContainer = useSelector((state: RootState) => state.rules.loadingContainer)

    return (
        <div>
            {
                informations.map((contract: ContractInfo, index: number) => {
                    return(
                        <RowRules 
                            key={contract.countryName}
                            index={index}
                            countryName={contract.countryName}
                            contract={contract.contract}
                        />
                    )
                })
            }
            { loadingContainer && <LoadingSpinner fullPage={false} backgroundColor="#fff" /> }
        </div>
    )
}

export default RowsRules