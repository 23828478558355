import React from 'react'
import { TypePrice } from '../../ManagerJobs/SimulatorJobs/SimulatorGraphic/SimulatorGraphic'



type BarSegment = {
    start: number
    end: number
    color: string
}

type BarSmulatorProps = {
    segments: BarSegment[]
    start: number
    top: string
    width: number
    type: TypePrice
    cssClass: string
    onMouseOver: (type: TypePrice) => void
    onMouseOut: (type: TypePrice) => void
    onPress: (type: TypePrice) => void
}

const BarSimulator = ({ segments, start, top, width, type, cssClass, onMouseOver, onMouseOut, onPress }: BarSmulatorProps) => {
    //console.log("segments", segments)
    return (
        <div
            className={`${cssClass} pointer`}
            style={{
                width: `${width}px`,
                height: "1.47vh",
                border: "1px solid #000",
                position: "absolute",
                left: `${start}px`,
                top,
                zIndex: 1 
            }}
            onMouseOver={() => onMouseOver(type)} 
            onMouseOut={() => onMouseOut(type)}
            onClick={() => onPress(type)}
        >
            {
                segments.map((segment: BarSegment, index) => {
                    return(
                        <div
                            key={index}
                            style={{
                                position: "absolute",
                                top: 0,
                                height: "1.29vh",
                                left: `${segment.start}px`,
                                width: `${segment.end - segment.start}px`,
                                backgroundColor: segment.color
                            }}
                        >
                        </div>
                    )
                })
            }
        </div>
    )
}

export default React.memo( BarSimulator )