import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowProductProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}

export type DataInputRowJob = { 
    name:           string
    placeholder:    string 
    width:          string 
    type:           string 
    step?:          string | number 
}

const RowProduct = ({ inputValues, isDay, valueCheckBox, onChangeCheckbox, onChange, onFocus }: RowProductProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "productGimIVA_Day",           placeholder: "Gim IVA",           width: "13%",   type: "text",   step: undefined },
        { name: "productGimmerIVA_Day",           placeholder: "Gimmer IVA",           width: "13%",   type: "text",   step: undefined },
        { name: "productGimworkIVA_Day",          placeholder: "Gimwork IVA",         width: "13%",   type: "text",   step: undefined },
        { name: "productRMPC_Day",                placeholder: "RMP",      width: "13%",   type: "text",   step: undefined },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "productGimIVA_Night",           placeholder: "Gim IVA",           width: "13%",   type: "text",   step: undefined },
        { name: "productGimmerIVA_Night",           placeholder: "Gimmer IVA",           width: "13%",   type: "text",   step: undefined },
        { name: "productGimworkIVA_Night",          placeholder: "Gimwork IVA",         width: "13%",   type: "text",   step: undefined },
        { name: "productRMPC_Night",                placeholder: "RMP",      width: "13%",   type: "text",   step: undefined },
    ], []);
    

    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#F6F3FE"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_11")}
                color="#F6F3FE"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowProduct