import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionDate } from '../../../../components/Help/ListCardsTransactionHistory/ListCardsTransactionHistory'

type InitialState = {
    threeTransactions: TransactionDate[]
    transactionsAssistance: TransactionDate[]
} 

const initialState: InitialState = {
    threeTransactions: [],
    transactionsAssistance: []
};

export const helpSlice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        setThreeTransactionsReducer: (state, action: PayloadAction<TransactionDate[]>) => {
            state.threeTransactions = action.payload
            return state;
        },
        setTransactionsAssistanceReducer: (state, action: PayloadAction<TransactionDate[]>) => {
            state.transactionsAssistance = action.payload
            return state;
        },
    },
    extraReducers: (builder) => {

    }
});

// Action creators are generated for each case reducer function
export const { 
    setThreeTransactionsReducer,
    setTransactionsAssistanceReducer
} = helpSlice.actions

export default helpSlice.reducer