import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaLaptop } from 'react-icons/fa'
import { WorkPreference } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import WhereMap from './WhereMap/WhereMap'
import "./WhereMaps.css"


const WhereMaps = ({ remote, inside, outside  }: WorkPreference) => {
    const { t } = useTranslation();
  return (
    <div className="flex flex-d-column mt-30">

        <p className="fs-26 fw-600">{t("General:label_023")}</p>

        <div className="flex flex-jc-sb flex-wrap width-100 mt-16">

                {
                    inside?.latitude !== 0 && inside?.longitude !== 0 &&
                    <div className="con-map-data mt-10">
                        <div className="con-work-preference flex flex-ai-center p-10">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_024")}</p>
                        </div>
                        {
                            inside?.address &&
                            <div className="p-12 gallery fs-12">
                                <p className="address-text-offer">  { inside?.address } </p>
                            </div>
                        }
                        <WhereMap id="1" longitude={inside?.longitude} latitude={inside?.latitude} />
                    </div>
                }

                {
                    outside?.latitude !== 0 && outside?.longitude !== 0 &&
                    <div className="con-map-data mt-10">
                        <div className="con-work-preference flex flex-ai-center p-10 ">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_025")}</p>
                        </div>

                        {
                            outside?.address &&
                            <div className="p-12 gallery fs-12">
                                <p className="address-text-offer fs-12"> 
                                    { outside?.radius &&  <span className="red-gimmer">{`${outside?.radius} ${t("General:label_027")}`}</span>  }
                                    { outside?.address }
                                </p>
                            </div>
                        }

                        <WhereMap id="2" longitude={outside?.longitude} radius={outside?.radius} latitude={outside?.latitude} />
                    </div>
                }
                {
                    remote &&
                    <div className="con-map-data mt-10">
                        <div className="con-work-preference flex flex-ai-center p-10">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_026")}</p>
                        </div>
                    </div>

                }
        </div>

    </div>
  )
}

export default React.memo( WhereMaps )