import React from 'react'
import { useTranslation } from 'react-i18next'
import { privacyPolicy_EN, privacyPolicy_ES } from '../../../utils/htmlsDocs/PrivacyPolicyContent'
import PublicNavBar from '../../../components/NavBarComponents/PublicNavBar/PublicNavBar'
import FooterLogin from '../../../components/Login/FooterLogin/FooterLogin'

const PrivacyPolicyScreen = () => {

  const { t, i18n } = useTranslation()

  let html = privacyPolicy_EN.html
  if(i18n.language === "es")
    html = privacyPolicy_ES.html

  return (
      <div className="doc-container">
          <PublicNavBar />
          <div className="doc-content" dangerouslySetInnerHTML={{__html: html }} />
          <FooterLogin />
      </div>
  )
}

export default PrivacyPolicyScreen