
import { LastEvaluatedKeyBugReports } from "../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice"
import { PriorityReport, StatusReport } from "../../../../dbModels"
import { 
    getUserCredentials,
    adminApiUrl
} from "../../../axiosUtils"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

type GetBugReportsBody = {
    LastEvaluatedKey?: string,
    limit: number,
    bringTotal: boolean
    status?: StatusReport
}

export const getBugReports = (body: GetBugReportsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: body
            };
            axios.get(`${adminApiUrl}/v1/reports/app-bug`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getBugReportById = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/reports/app-bug/${id}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateBugReportSeenWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/seen`, { }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type UpdateBugReport = {
    //requestBy?: "string", 
    //subject?: "string", 
    //status?: StatusReport, 
    priority?: PriorityReport,
    //title?: "string", 
    //description?: "string"
}
export const updateBugReport = (id: string, body: UpdateBugReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}


export const updateAssignmentAppBugWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/assignment`, {  }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/assignment`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const updateUnAssignmentWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/unassign`, {  }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/unassign`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateStatusAppBugWeb = (id: string, status: StatusReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/status`, { status }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/status`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type CreateReportBugBody = {
	id: string;
	requestBy: string;
	subject: string;
	status: StatusReport;
	priority: PriorityReport;
	title: string;
	description: string;
	seen: boolean;
};

export const createBugReport = (body: CreateReportBugBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/reports/app-bug`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 