import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type ArrowGoBackSignUpProps = {
    onPress: () => void
}

const ArrowGoBackSignUp = ({ onPress }: ArrowGoBackSignUpProps) => {
    return (
        <button onClick={onPress} className="pointer">
            <FontAwesomeIcon icon={faArrowLeft} className="fs-30 c-text" />
        </button>
    )
}

export default React.memo( ArrowGoBackSignUp )