import React, { useState } from 'react'

export type SideBarSelector = "left" | "right"

type TwoSelectorBarProps = {
    children: React.ReactNode[]
    width: string
    initSelected: SideBarSelector
    colorMoving: string
    onChange: (value: SideBarSelector) => void
}

const TwoSelectorBar = ({ children, width, initSelected, onChange, colorMoving }: TwoSelectorBarProps) => {

    const [sideSelected, setSideSelected] = useState<SideBarSelector>(initSelected)

    const leftSideSelected = sideSelected === "left" 

    const clickBar = (newSide: SideBarSelector) => {
        onChange(newSide)
        setSideSelected(newSide)
    }

    const leftStyles: any = {}
    leftStyles["backgroundColor"] = leftSideSelected ? colorMoving : undefined;

    const rightStyles: any = { height: "3.6vh" }
    rightStyles["backgroundColor"] = !leftSideSelected ? colorMoving : undefined;

    return (
        <div className="flex flex-jc-end" style={{ width }}>
            <div 
                className={`width-50 gallery-bg fs-16-vh center ${leftSideSelected ? "c-white" : "pointer"}`}
                style={leftStyles}
                onClick={() => {
                    if(!leftSideSelected)
                        clickBar("left")
                }}
            >
                Day
            </div>

            <div 
                className={`width-50 gallery-bg fs-16-vh center ${!leftSideSelected ? "c-white" : "pointer"}`}
                style={rightStyles}
                onClick={() => {
                    if(leftSideSelected)
                        clickBar("right")
                }}
            >
                Night
            </div>
        </div>
    )
}

export default React.memo( TwoSelectorBar )