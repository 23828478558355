import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BugReportTableItem } from './manageBugReportsDataSlice';
import { PriorityReport, StatusReport } from '../../../../utils/dbModels';


const initialState = {
  bugReport: {} as BugReportTableItem,
  show: false
}

export const manageDetailedBugReportSlice = createSlice({
  name: 'manageDetailedBugReport',
  initialState,
  reducers: {
    setDetailedBugReportReducer: (state, action: PayloadAction<{ bugReport: BugReportTableItem }>) => {
        state.bugReport = action.payload.bugReport
        state.show = true    
        return state
    },
    setNewStatusBugReportReducer: (state, action: PayloadAction<{newStatus: StatusReport}>) => {
      state.bugReport.status = action.payload.newStatus  
      return state
    },
    setNewPriorityBugReportReducer: (state, action: PayloadAction<{newPriority: PriorityReport}>) => {
      state.bugReport.priority = action.payload.newPriority  
      return state
    },
    resetDetailedBugReportReducer: (state) => {
        return initialState
    }
  },
});

// Action creators are generated for each case reducer function
export const {  
    setDetailedBugReportReducer, 
    resetDetailedBugReportReducer,
    setNewStatusBugReportReducer,
    setNewPriorityBugReportReducer
} = manageDetailedBugReportSlice.actions

export default manageDetailedBugReportSlice.reducer