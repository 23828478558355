import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SliderProduct from '../../../../components/VerifyProduct/SliderProduct/SliderProduct'
import ScrollProducts from '../../../../components/VerifyProduct/ScrollProducts/ScrollProducts'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { GetProductsRequestedThunk } from '../../../../redux/slices/components/Products/ProductsSlice'
import { useSelector } from 'react-redux'

const VerifyProductScreen = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const products = useSelector((state: RootState) => state.products.products)

    useEffect(() => {
        
        dispatch( GetProductsRequestedThunk() )

        return () => {
        
        }
    }, [])
    

    return (
        <div className="width-100 height-100">
            <p className="title-screen">{"Product verification"}</p>
            <div className="flex mt-20 height-100">
                <SliderProduct sliderData={products} />
                <ScrollProducts sliderData={products} />
            </div>
        </div>
    )
}

export default VerifyProductScreen