import { useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import ActivationJobModal from './ActivationJobModal/ActivationJobModal'
import DeleteCategoryModal from './DeleteCategoryModal/DeleteCategoryModal'
import "./ManagerJobsModals.css"
import NewCategoryJobModal from './NewCategoryModal/NewCategoryModal'
import { resetValueFromState } from '../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice'
import EditCategoryModal from './EditCategoryModal/EditCategoryModal'
import AddNewJobModal from './AddNewJobModal/AddNewJobModal'
import DeleteJobModal from './DeleteJobModal/DeleteJobModal'
import EditJobModal from './EditJobModal/EditJobModal'
import EditMultipleJobsModal from './EditMultipleJobsModal/EditMultipleJobsModal'
import { DeleteMultipleJobsModal } from './DeleteMultipleJobsModal/DeleteMultipleJobsModal'


const ManagerJobsModals = () => {

  const dispatch = useAppDispatch()
  const modalName = useSelector((state: RootState) => state.managerJobModals.modalName);

  const handleClickOutsideModal = (e: any) => {
    const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
    if(!clickOutsideModal && e.target.id === "darkened-background-modal")
        dispatch(resetValueFromState())
  }

  return (
    <>
      {
        modalName &&
        <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
          <div id={"background-aux-modal"}>
            <ManagerJobsSelectModal modalName={modalName} />
          </div>
        </div>
      }
    </>
  )
}

interface ManagerJobsSelectModalProps {
  modalName: string
}

const ManagerJobsSelectModal = ({ modalName }: ManagerJobsSelectModalProps) => {
  
  switch(modalName){
    case "ActivationJobModal":
      return (<ActivationJobModal />)
    case "NewCategoryModal":
        return (<NewCategoryJobModal />)
    case "DeleteCategoryModal":
        return (<DeleteCategoryModal />)
    case "EditCategoryModal":
        return (<EditCategoryModal />)
    case "AddNewJobModal":
        return (<AddNewJobModal />)
    case "DeleteJobModal":
        return (<DeleteJobModal />)
    case "ActivateJobModal":
        return (<ActivationJobModal />)
    case "EditJobModal":
        return (<EditJobModal />)
    case "EditMultipleJobsModal":
        return (<EditMultipleJobsModal />)
    case "DeleteMultipleJobsModal":
        return (<DeleteMultipleJobsModal />)
    default:
      return(
        <h1>Error modals</h1>
      )
  }
  
}

export default ManagerJobsModals