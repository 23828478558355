import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { Offer, TypeOffers } from "../../../../redux/slices/components/ManageOffers/manageOffersDataSlice"
import {
    GetOffersByUsers,
    getUserCredentials,
    adminApiUrl
} from "../../axiosUtils"
import { Moment } from "moment-timezone"



export const getOffer = (id: string) => {
    return new Promise((resolve: (value: Offer) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/offers/${id}`, config)
            .then((res) => {resolve(res.data.offer)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/offers/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getOfferPublic = (id: string) => {
    return new Promise((resolve: (value: Offer) => void, reject) => {
        const config: AxiosRequestConfig = { 
            headers: { },
        };
        axios.get(`${adminApiUrl}/v1/public/offers/${id}`, config)
        .then((res) => {resolve(res.data.offer)})
        .catch((error: AxiosError) => { 
            console.log("Error: ", `${adminApiUrl}/v1/offers/${id}`, error); 
            reject(error) 
        })
    })
} 

export type GimGimmer = "Gim" | "Gimmer"
export type gimgimmer = "gim" | "gimmer"

export type SearchParams = {
    columns: string
	limit: number
	page: number
	startTime?: string | Moment
	endTime?: string | Moment
	outside?: { latitude: number, longitude: number, radius: number }
	inside?: { latitude: number, longitude: number, radius: number }
	remote?: boolean
	jobId?: string
	myUserId?: string
	userId?: string
	categoryName?: string
	offerType?: GimGimmer
	country?: string
    bringTotal?: boolean
}

export const getOffers = (params: SearchParams) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { 
                    Authorization: `Bearer ${token}`, 
                },
                params
            };
            axios.get(`${adminApiUrl}/v1/offers`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/offers`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getOffersPublic = (params: SearchParams) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        const config: AxiosRequestConfig = { 
            params
        };
        axios.get(`${adminApiUrl}/v1/public/offers`, config)
        .then((res) => { 
            resolve(res.data)
        })
        .catch((error) => { 
            console.log("Error: ", `${adminApiUrl}/v1/public/offers`, error); 
            reject(error) 
        })
    })
} 


type GetOffersByUsersBody = {
    LastEvaluatedKey?: string,
    type?: TypeOffers,
    limit: number,
    bringTotal: boolean,
    stringToSearch: string
}

export const getOffersByUsers = (body: GetOffersByUsersBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        GetOffersByUsers(body)
        .then((res) => {resolve(res.data)})
        .catch((error) => { console.log("error", error); reject(error) })
    })
} 


export const deleteOffer = (id: string, type: TypeOffers) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { type }
            };

            axios.delete(`${adminApiUrl}/v1/offers/${id}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/offers/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateSeenOffer = (id: string, type: TypeOffers) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: { type }
            };
            console.log("type", type)
            axios.patch(`${adminApiUrl}/v1/offers/${id}/seen`, {}, config)
            .then((res) => { console.log("res", res.data); resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/offers/${id}/seen`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
