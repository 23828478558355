import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';


type IdImageProps = {
    url: string
    onClick: () => void
}

const IdImage = ({
    url,
    onClick
}: IdImageProps) => {
    return (
        <div 
            className="id-img-view pointer" 
            style={{ 
                "backgroundImage":  `url(${url})`,
            }}
            onClick={onClick}
        >
            <div className="id-img-hover center">
                <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} className="fs-30 icon-expand-id-img" />
            </div>
        </div>
    )
}

export default React.memo( IdImage )