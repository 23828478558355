import React from 'react';
import {
	DeclinedRequestNotification,
	NewTransaction,
	PublishOffer,
	RequestNotification
} from './GeneralNotif';
import DefaultNotification from '../NotificationsCards/Default';
import CertiVerificationNotification from '../NotificationsCards/fileVerifications/CertiVerificationNotification';
import IdVerificationNotification from '../NotificationsCards/fileVerifications/IdVerificationNotification';
import PictureVerificationNotification from '../NotificationsCards/fileVerifications/PictureVerificationNotification';
import ExpiredOfferNotification from '../NotificationsCards/offers/ExpiredOfferNotification';
import NewOvertimeAcceptedNotification from '../NotificationsCards/overtimes/NewOvertimeAcceptedNotification';
import NewOvertimeDeclinedNotification from '../NotificationsCards/overtimes/NewOvertimeDeclinedNotification';
import NewOvertimeProposalNotification from '../NotificationsCards/overtimes/NewOvertimeProposalNotification';
import NewProductAcceptedNotification from '../NotificationsCards/products/NewProductAcceptedNotification';
import NewProductDeclinedNotification from '../NotificationsCards/products/NewProductDeclinedNotification';
import NewProductProposalNotification from '../NotificationsCards/products/NewProductProposalNotification';
import CanceledTransactionNotification from '../NotificationsCards/transactions/TransactionWasCancel';
import AdminMessageNotification from '../NotificationsCards/AdminMessageNotification';
import ExpiredRequestNotification from '../NotificationsCards/Request/ExpiredRequestNotification';

type FactoryNotificationProps = {
	item: any;
	index: number;
	onSetNotificationAsView: (id: string) => void;
	navigation: any;
	deleteNotification: (notificationID: string) => void
}

const FactoryNotification = ({
	item,
	index,
	onSetNotificationAsView,
	navigation,
	deleteNotification
}: FactoryNotificationProps) => {
	switch (item.notifData.receivedFromType) {
		case 'New-Request':
			return (
				<RequestNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					key={item.id}
					navigation={navigation}
					deleteNotification={deleteNotification}
				/>
			);

		case 'Declined-Request':
			return (
				<DeclinedRequestNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
					deleteNotification={deleteNotification}
				/>
			);

		case 'Publish_Offer':
			return (
				<PublishOffer
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
					deleteNotification={deleteNotification}
				/>
			);

		case 'New-Transaction':
			return (
				<NewTransaction
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
					navigation={navigation}
					deleteNotification={deleteNotification}
				/>
			);
		case 'admin-message':
			return (
				<AdminMessageNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'id-verification':
			return (
				<IdVerificationNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'picture-verification':
			return (
				<PictureVerificationNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'certi-verification':
			return (
				<CertiVerificationNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-overtime-proposal':
			return (
				<NewOvertimeProposalNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-overtime-accepted':
			return (
				<NewOvertimeAcceptedNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-overtime-declined':
			return (
				<NewOvertimeDeclinedNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-product-proposal':
			return (
				<NewProductProposalNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-product-accepted':
			return (
				<NewProductAcceptedNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'new-product-declined':
			return (
				<NewProductDeclinedNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'expired-offer':
			return (
				<ExpiredOfferNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'expired-request':
			return (
				<ExpiredRequestNotification
					onSetNotificationAsView={onSetNotificationAsView}
					item={item}
					index={index}
					key={item.id}
				/>
			);

		case 'Transaction-Cancelled':
			return (
				<CanceledTransactionNotification
					{...item}
				/>
			);

		default:
			return <DefaultNotification {...item} />
	}
};

export default React.memo(FactoryNotification);
