import React from "react";
import "./SideBar.css"
import { sidebarOptionsGeneral, sidebarOptionsGeneralSuperAdmin } from '../../../utils/sidebarOptions/sidebarOptions'
import { ISideBarOption } from "../../../utils/sidebarOptions/sidebarOptions"
import SideBarOptionsList from '../SideBarOptionsList/SideBarOptionsList';
import LogoutButton from '../LogoutButton/LogoutButton';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";

const SideBar = React.memo(() => {

    const { t } = useTranslation()
    
    const userPolicies = useSelector((state: RootState) => state.auth.user.roleData?.policies)
    const name = useSelector((state: RootState) => state.auth.user.roleData?.name)

    // user.roleId 
    const userOptions: ISideBarOption[] = sidebarOptionsGeneral.filter((sideBar) => {
        let haveAccess = false
        sideBar.role.forEach((role) => {
            if(role.id_Category === "Access_Management")
                haveAccess = true

            const policy = userPolicies.find(p => 
                p.id_Category === role.id_Category &&
                p.id_Policy === role.id_Policy &&
                p.id_Action === role.id_Action
            )
            if(policy)
                haveAccess = true
        })
        return haveAccess
    })
    const adminOptions: ISideBarOption[] = sidebarOptionsGeneralSuperAdmin.filter((sideBar) => {
        let haveAccess = false
        sideBar.role.forEach((role) => {
            const policy = userPolicies.find(p => 
                p.id_Category === role.id_Category &&
                p.id_Policy === role.id_Policy &&
                p.id_Action === role.id_Action
            )
            if(policy)
                haveAccess = true
        })
        return haveAccess
    })

    return (
        <div className="sidebar">
            <div className="container-user-options">
                <SideBarOptionsList  sideBarOptionsList={userOptions} />
            </div>

            {
                adminOptions.length > 0 &&
                <div className="container-admin-options">
                    <p className="super-admin-text">- {name.replace(/_/g, " ")} -</p>
                    <SideBarOptionsList  sideBarOptionsList={adminOptions} />
                </div>
            }


            <div className="container-logout-option">
              <LogoutButton />
            </div>
        </div>
    )
})

export default SideBar