import React from "react"
import { useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { resetValueFromState } from '../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice'
import ModalAddProduct from './ModalAddProduct/ModalAddProduct'
import { ModalNameMarkeplace, resetMarketplaceModalsReducer } from '../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import ModalEditProduct from "./ModalEditProduct/ModalEditProduct"
import ModalDeleteProduct from "./ModalDeleteProduct/ModalDeleteProduct"


const ManagerMarketplaceModals = () => {

  const dispatch = useAppDispatch()
  const modalName = useSelector((state: RootState) => state.marketplace.modalName);

  const handleClickOutsideModal = (e: any) => {

    const clickInsideModal = document.getElementById("background-aux-modal")?.contains(e.target);

    if(!clickInsideModal && e.target.id === "darkened-background-modal")
        dispatch(resetMarketplaceModalsReducer())
  }

  return (
    <>
      {
        modalName &&
        <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
          <div id={"background-aux-modal"}>
            <ManagerMarketplaceSelectModal modalName={modalName} />
          </div>
        </div>
      }
    </>
  )
}

interface ManagerMarketplaceSelectModalProps {
  modalName: ModalNameMarkeplace
}

const ManagerMarketplaceSelectModal = ({ modalName }: ManagerMarketplaceSelectModalProps) => {
  
  switch(modalName){
    case "AddProduct":
      return (<ModalAddProduct />)
    case "EditProduct":
        return (<ModalEditProduct />)
    case "DeleteProduct":
        return (<ModalDeleteProduct />)
    default:
      return(
        <h1>Error modals</h1>
      )
  }
  
}

export default React.memo( ManagerMarketplaceModals )