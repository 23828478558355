import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { useAppDispatch } from '../../../../redux/store/store'
import { getProfilePictureByUserId } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { UserInfoCache, UserInfoCacheType, getMinimalUserInfoData } from "../../../../utils/staticVariables/staticVariables"
import "./ReportRow.css"
import { UserReportTableItem, updateSeenUserReportReducer } from "../../../../redux/slices/components/ManageReports/manageReportsDataSlice"
import { updateUserReportSeenWeb } from "../../../../utils/requestsUtils/functionsRequests/reports/user/reportsFunctions"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { isBetween24HoursAgo } from "../../../../utils/dates/dates"
import { getChatRoom_Service } from "../../../../utils/chats/ChatFunctions"
import { generateClient } from "aws-amplify/api"
import { onNewMessageOnRoom } from "../../../../utils/chats/Graphql/Queries"
import { OnNewMessageOnRoomEvent } from "../../../../utils/chats/types"
import { messageSendUserReportToMessage } from "../../../../utils/chats/mappers"

const client = generateClient();

export interface UserReportRowProps {
    userReport: UserReportTableItem
    supportId: string
}

const UserReportRow = React.memo(({ userReport, supportId }: UserReportRowProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const isFirstRender = useRef(true)
    
    const [userNameRequestBy, setUserNameRequestBy] = useState<string>("")
    const [userSupportAssigned, setUserSupportAssigned] = useState<string>("")
    const [userImgRequestBy, setUserImgRequestBy] = useState<string>("")
    const [userInvolvedImg, setUserInvolvedImg] = useState<string>("")
    const [counterMessageNotRead, setCounterMessageNotRead] = useState(0)

    const isFromThisDay = useMemo(() => isBetween24HoursAgo(userReport.timestamp), [userReport.timestamp]);

    const colorText = userReport.requestByUserType === "Gim" ? "color-dark-gim" : "color-dark-gimmer"
    const colorPoint = userReport.requestByUserType === "Gim" ? "dark-gim-bg" : "dark-gimmer-bg"


    useEffect(() => {

        const getData= () => {
            const user = UserInfoCache.userNameCache.get(userReport.requestBy)
            if(user?.name){
                setUserNameRequestBy(user.name)
            }else{
                getMinimalUserInfoData(userReport.requestBy)
                .then((userInfo: UserInfoCacheType) => {
                    setUserNameRequestBy(userInfo.name)
                })
            }
    
            if(userReport.userAssigned){
                const userPersonInvolved = UserInfoCache.userNameCache.get(userReport.userAssigned)
                if(userPersonInvolved?.name){
                    setUserSupportAssigned(userPersonInvolved.name)
                }else{
                    getMinimalUserInfoData(userReport.userAssigned)
                    .then((userInfo: UserInfoCacheType) => {
                        setUserSupportAssigned(userInfo.name)
                    }).catch((error: any) => {console.log("error", error)})
                }
            }
    
    
            getProfilePictureByUserId(userReport.requestBy, "small")
            .then((imgUrl) => {
                setUserImgRequestBy(imgUrl)
            })
    
            if(userReport.personsInvolved.length > 0){
                getProfilePictureByUserId(userReport.personsInvolved[0], "small")
                .then((imgUrl) => {
                    setUserInvolvedImg(imgUrl)
                })
            }
        }

        const getDataChatRoom = async () => {
            const chatRoomRequestById = `${userReport.id}_${userReport.requestBy}`
            const chatRoomPersonInvolvedId = `${userReport.id}_${userReport.personsInvolved[0]}`

            const responses = await Promise.all([ getChatRoom_Service(chatRoomRequestById), getChatRoom_Service(chatRoomPersonInvolvedId) ])

            let counter = 0
            responses.forEach((res) => {
                res.messages.items.forEach((item: any) => {
                    //if(userReport.id === "40f9e983-0d0c-4ddb-a8c5-e75fc99b1f07")
                        //console.log("item", item)

                    if(item.otherUserID === supportId && !item.viewed){
                        counter++
                    }
                })
            })
            setCounterMessageNotRead(counter)
        }

        getDataChatRoom()

        getData()
        return () => {

        }
    }, [])


    useEffect(() => {
		const SetNewMessageSubcription = async (chatRoomID: string) => {
			const subscription = client.graphql({
				query: onNewMessageOnRoom,
				variables: { chatRoomID },
				authMode: 'userPool',
			}) as any

			const _subcription = subscription.subscribe({
				next: (data: OnNewMessageOnRoomEvent) => {
					if (data?.data?.onNewMessageOnRoom) {
						const messageArrived = messageSendUserReportToMessage(data?.data?.onNewMessageOnRoom)
                        if(messageArrived.userID === userReport.requestBy || messageArrived.userID === userReport.personsInvolved[0]){
                            setCounterMessageNotRead( counterMessageNotRead + 1 )
                        }
						//if (newMessage?.chatRoomID !== ChatRoomId || (newMessage.userID === userId && newMessage.type === 'MESSAGE')) {
						//	return;
						//}
						//addNewMessage(newMessage);
					}
				},
				error: (error: any) => console.warn(error),
			});

			return () => {
				_subcription.unsubscribe();
				//setChatMessage([]);
				return null;
			};
		};

        if(isFirstRender.current){
            SetNewMessageSubcription(`${userReport.id}_${userReport.requestBy}`);
            SetNewMessageSubcription(`${userReport.id}_${userReport.personsInvolved[0]}`);
        }

	}, [counterMessageNotRead]);
    

    const clickOnOffer = () => {
        
        navigate(`/manage-reports/${userReport.id}`)
        updateUserReportSeenWeb( userReport.id )
        .then(() => {
            dispatch( updateSeenUserReportReducer({ id: userReport.id }) )
        }).catch((error) => {
            console.log("error", error)
        })
        //dispatch(setDetailedOfferReducer({userReport, imgUrl: userImg}))
        
    }

    return (
        <div onClick={clickOnOffer} className="container-row-report flex mt-8 mb-8 pointer pl-10">
            
            {
                // Seen circle
                !userReport.seen &&
                <div className="con-circle-saw center">
                    <div className={`circle-saw-report circle ${colorPoint}`}></div>
                </div>
            }

            {
                // report Id
            }
            <div className="report-item1 height-100 center">
                <p title={userReport.id} className={`id-report fs-14 fw-500 ${colorText}`}>{userReport.id}</p>
            </div>

            {
                // request by
            }
            <div className="report-item2 height-100 flex flex-ai-center">
                <div className="img-circular-row circle" style={{ backgroundImage: `url("${userImgRequestBy}")` }}></div>
                <p className={`ml-10 fs-14 fw-500 three-dot ${colorText}`}>{userNameRequestBy}</p>
            </div>

            {
                // reason
            }
            <div className="report-item3 height-100 flex flex-ai-center">
                <p className={`fs-14 fw-500 three-dot ${colorText}`}>{userReport.reason}</p>
            </div>

            {
                // persons involved
            }
            <div className="report-item4 height-100 flex flex-ai-center" >
                <div className="img-circular-row circle" style={{ backgroundImage: `url("${userInvolvedImg}")` }}></div>
            </div>

            {
                // Chat
            }
            <div className="report-item4_5 height-100 flex flex-ai-center p-relative center" >
                {
                    counterMessageNotRead > 0 &&
                    <div className="p-relative">
                        <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8 center" style={{ right: "-.6rem", top: "-.7rem" }}>
                            <p className={`c-white fw-600 fs-11 mt-2`}>{counterMessageNotRead}</p>
                        </div>
                        <FontAwesomeIcon icon={solid("comments")} className="c-oslo-gray fs-24" />
                    </div>
                }
            </div>

            {
                // Creation Date
                // 2024-05-10T15:49:08.173Z
            }
            <div className="report-item5 height-100 flex flex-ai-center">
                <p className={`fs-14 fw-600 ${colorText}`}>
                    { isFromThisDay ? moment.utc(userReport.creationDate).fromNow() : moment.utc(userReport.creationDate).format("DD MMM YYYY") }
                </p>
            </div>

            {
                // userAssigned
            }
            <div className="report-item6 height-100 flex flex-ai-center">
                <p className={`fs-14 fw-600 ${colorText}`}>{userSupportAssigned}</p>
            </div>


        </div>
    )
})

export default UserReportRow