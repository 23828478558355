import React from 'react'
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName'
import { TransactionDate } from '../ListCardsTransactionHistory'
import CardTransactionHistory from '../CardTransactionHistory/CardTransactionHistory'
import moment from 'moment'

type CardTransactionHistoryWithDateProps = {
    userId: string
    transaction: TransactionDate
    onPressCard?: (id: string) => void
}

const CardTransactionHistoryWithDate = ({ userId, transaction, onPressCard }: CardTransactionHistoryWithDateProps) => {
    const jobName = useGetJobName(transaction.skill.jobId)
    return(
        <div className="" key={transaction.id}>
            {
                transaction.date &&
                <div className="flex flex-ai-center mt-12 mb-12">
                    <p className="fs-20-vh color-text mr-10">
                        {moment(transaction.schedule.startTime).fromNow()}
                    </p>

                    <div className="line-gray-help flex-1 mt-2"></div>
                
                </div>
            }
            <CardTransactionHistory 
                imgKey={transaction.skill.imgKey}
                id={transaction.id}
                type={userId === transaction.gimId ? "Gim" : "Gimmer"}
                jobName={jobName}
                userId={userId === transaction.gimId ? transaction.gimmerId : transaction.gimId}
                typePlace={transaction.workPreference.type}
                onPressCard={onPressCard}
            />
        </div>
    )
}

export default React.memo( CardTransactionHistoryWithDate )