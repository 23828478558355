import { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { orderOffersReducer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import "./HeaderTableOffer.css"


const HeaderTableOffer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.manageOffersData.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.manageOffersData.orderColumn)

    const orderArray = useCallback(
            ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {  
                dispatch(orderOffersReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
            },
            [],
    )

    return (
        <div className="flex con-header-table-offer ff-roboto">

            <div className="offer-item1 con-item-header-offer">
                <ArrowColumn 
                    columnText={t("General:label_030")}
                    orderColumn={orderColumn} 
                    columnName={"id"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="offer-item2 con-item-header-offer">
                <ArrowColumn 
                    columnText={t("General:label_031")}
                    orderColumn={orderColumn} 
                    columnName={"jobName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="offer-item3 con-item-header-offer">
                <ArrowColumn 
                    columnText={t("General:label_017")}
                    orderColumn={orderColumn} 
                    columnName={"type"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="offer-item4 con-item-header-offer">
                <ArrowColumn 
                    columnText={t("General:label_021")}
                    orderColumn={orderColumn} 
                    columnName={"timestampMilliseconds"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="offer-item5 con-item-header-offer">
                <ArrowColumn 
                    columnText={t("General:label_032")}
                    orderColumn={orderColumn} 
                    columnName={"userName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="offer-item6 con-item-header-offer">
                {t("General:label_033")}
            </div>
            
            <div className="offer-item7 con-item-header-offer">
                {t("General:label_034")}
            </div>
            
            <div className="offer-item8 con-item-header-offer">
                {t("General:label_035")}
            </div>
        
        </div>
    )
}

export default HeaderTableOffer