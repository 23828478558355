import React, { useCallback } from 'react'
import { faTimer } from '@fortawesome/pro-solid-svg-icons'
import GenericNotification from '../GenericNotification'
import { Colors } from '../../../../utils/colors/colors'


interface NewOvertimeDeclinedNotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			approved: string, 
			receivedFromType: string, 
            userName: string,
            transactionId: string
		},
	},
	onSetNotificationAsView: (id: string) => void,
	index: number
}



const NewOvertimeDeclinedNotification = ({ item, onSetNotificationAsView }: NewOvertimeDeclinedNotificationProps) => {
	const { id, notifMsg, timestamp, viewed, notifData: { approved } } = item

	const touchNotification =  useCallback(
	  () => {
        if(!viewed)
            onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			showImg={false}

			id={id}
			timestamp={timestamp}
			viewed={viewed}
			description={"User declined overtime"}
			title={ "Username has declined add overtime to the meet" }

			icon={ faTimer }
			color={ Colors.secondaryColor }
		/>
	)
}

export default NewOvertimeDeclinedNotification