import React from 'react'
import HeaderTimerChat from './HeaderTimerChat/HeaderTimerChat'

const ChatSide = () => {
    return (
        <div className="flex-1">
            <HeaderTimerChat />
        </div>
    )
}

export default React.memo( ChatSide )