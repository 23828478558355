import React, { useEffect, useState } from 'react'
import CardTransactionHistory from '../../../components/Help/ListCardsTransactionHistory/CardTransactionHistory/CardTransactionHistory'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getHistoryTransaction } from '../../../utils/requestsUtils/functionsRequests/transactions/history';
import { HistoryTransaction } from '../../../utils/dbModels/HistoryTransaction';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import BackButton from '../../../components/UIcomponents/Buttons/BackButton/BackButton';
import InputList from '../../../components/UIcomponents/InputList/InputList';
import "./ReportTransactionScreen.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const ReportTransactionScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [transaction, setTransaction] = useState<HistoryTransaction | null>(null)
    const [reasonSelected, setReasonSelected] = useState("")
    const [description, setDescription] = useState("")
    const [items] = useState([
        { name: t("Help:help:label_12") },
        { name: t("Help:help:label_13") },
        { name: t("Help:help:label_14") },
        { name: t("Help:help:label_15") },
        { name: t("Help:help:label_16")},
        { name: t("Help:help:label_17") },
        { name: t("Help:help:label_18") },
        { name: t("Help:help:label_19") },
        { name: t("Help:help:label_20") }
    ])

    const userId = useSelector((state: RootState) => state.auth.user.id)

    const allObligatoryFieldsReady = (reasonSelected && description)

    useEffect(() => {
      

        if(!id)
            return

        getHistoryTransaction(id)
        .then((item) => {
            setTransaction(item)
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    
        return () => {
            
        }
    }, [id])

    const goBack = () => {
        navigate(-1)
    }

    const onPress = (reason: string) => {
        setReasonSelected(reason)
    }

    const handleSubmit = () => {

    }


    if(loading){
        return (
            <div className="p-relative height-100 width-100">
                <LoadingSpinner fullPage={false} />
            </div>
        )
    }

    if(!transaction){
        return (
            <div className="height-100 width-100 center">
                <p className="fs-22 color-text fw-500">{t("Help:help:label_11")}</p>
            </div>
        )
    }
    

    return (
        <div className="width-100 height-100 center">

            <div className="container-help height-100">

                <CardTransactionHistory 
                    imgKey={transaction.skill.imgKey}
                    id={transaction.id}
                    type={userId === transaction.gimId ? "Gim" : "Gimmer"}
                    jobName={transaction.skill.jobName}
                    userId={userId === transaction.gimId ? transaction.gimmerId : transaction.gimId}
                    typePlace={transaction.workPreference.type}
                />

                <div className="flex flex-ai-center mt-13 mt-8">
                    <BackButton goBack={goBack} />
                    <div className="line-gray-help"></div>
                </div>

                <p className="fs-22-vh mt-10 mb-30">{t("Help:help:label_07")}</p>

                <InputList text={reasonSelected} height="4.6vh" width="100%" >
                    {
                        items.map((item) => {
                            return(
                                <button key={item.name} onClick={() => onPress(item.name)} className="width-100 flex flex-ai-center pointer" style={{ height: "4.6vh" }}>
                                    <p className="fs-16-vh ml-14 color-text">{item.name}</p>
                                </button>
                            )
                        })
                    }
                </InputList>

                <div className="mt-40 p-relative">
                    <textarea
                        placeholder={t("Help:help:label_21") ?? ""} 
                        className="resize-none description-report-transaction-input border-radius-6 width-100 fs-18-vh center hide-scroll outline-none color-text" 
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                    <p className="fs-16-vh p-absolute pl-6 pr-6 white-bg txt-description-report-transaction-input">
                        {t("Help:help:label_08")}<span className="color-error">*</span>
                    </p>
                </div>

                <div className="center flex-d-column mt-60">
                    <FontAwesomeIcon icon={faTriangleExclamation} className="color-error fs-36-vh" />
                    <p className="fs-18-vh color-error mt-10">{t("Help:help:label_09")}</p>
                </div>

                <div className="flex center mt-40">
                    <button
                        onClick={handleSubmit}
                        disabled={!allObligatoryFieldsReady}
                        className={`border-radius-3 pl-20 pr-20 ${allObligatoryFieldsReady ? "dark-gray-bg pointer" : "disable-btn"}`}
                        style={{ height: "4.6vh" }}
                    >
                        <p className="fs-20-vh c-white">{t("Help:help:label_10")}</p>
                    </button>
                </div>

            </div>

        </div>
    )
}

export default ReportTransactionScreen