import React, { useEffect, useState } from 'react'
import SelectorBar from '../SelectorBar/SelectorBar'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { GetRequestProductsDBThunk } from '../../../../redux/slices/components/RequestProducts/requestProductsDataSlice'
import { JobCategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import InputCategoryJobs from '../../../UIcomponents/InputCategoryJobs/InputCategoryJobs'

const SelectorBarRequestProducts = () => {

    const dispatch = useAppDispatch()

    const limit = useSelector((state: RootState) => state.requestProductsData.limit)
    const typeProduct = useSelector((state: RootState) => state.requestProductsData.typeProduct)
    const page = useSelector((state: RootState) => state.requestProductsData.page)
    const jobId = useSelector((state: RootState) => state.requestProductsData.jobId)
    const categoryJobs = useSelector((state: RootState) =>state.managerJobData.categoryJobs)

    const onPress = (leftSelected: boolean) => {
        dispatch( GetRequestProductsDBThunk({ limit, page, jobId, typeProduct: leftSelected ? "DIGITAL" : "PHYSICAL" }) )
    }

    const onSelectJob = (job: JobCategoryJob) => {
        dispatch( GetRequestProductsDBThunk({ limit, page, jobId: job.id, typeProduct }) )
    }

    useEffect(() => {
      
        if(categoryJobs.length > 0){
            const job = categoryJobs[0].jobs[0]
            if(job){
                const jobId = job.id
                dispatch(GetRequestProductsDBThunk({ limit, page, typeProduct, jobId }))
            }
        }

    }, [categoryJobs.length])
    

    return (
        <div className="flex flex-ai-center flex-jc-sb width-100">
            <InputCategoryJobs idJobSelected={jobId} onPressJob={onSelectJob} categoryJobs={categoryJobs} />
            <SelectorBar onPress={onPress} />
        </div>

    )
}

export default React.memo( SelectorBarRequestProducts )