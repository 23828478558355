import React, { MemoExoticComponent, useLayoutEffect, useState } from 'react';
import SideBar from '../../../SideBar/SideBar/SideBar';
import "./LargeManagerJobWrapperScreen.css"

interface GeneralWrapperScreen {
    RouteComponent: any
}

const LargeManagerJobWrapperScreen = React.memo(({ RouteComponent }:GeneralWrapperScreen) => {
    
    const [measuresManagerJobScreen, setMeasuresManagerJobScreen] = useState({ width: "0", left: "0" })

    useLayoutEffect(() => {

        const handleResize = () => {
            const sideBarAux = document.getElementById("con-aux-side-bar-resize-manager-job-screen")
            let box = { left: 0, top: 0, right: 0, bottom: 0 };
            if(!sideBarAux)
                return
            try {
                box = sideBarAux.getBoundingClientRect();
                const screenWidth = window.innerWidth;
                if(screenWidth === 0)
                    return
                const marginRight = 60
                const leftMnagerJobScreen = Math.round(box.right/10);
                const widthManagerJobScreen = Math.round((screenWidth - box.right - marginRight)/10);
                setMeasuresManagerJobScreen({
                    width: `${widthManagerJobScreen}rem`, 
                    left: `${leftMnagerJobScreen}rem`,
                })
            } 
            catch(error) 
            { 
                console.log("error", error)  
                setMeasuresManagerJobScreen({
                    width: `120rem`, 
                    left: `25rem`,
                })
            }
        }

        handleResize()

        window.addEventListener('resize', handleResize, false);

        return () => {
            //window.removeEventListener('resize', handleResize, false);
        }
    }, [])
    
    //console.log("LargeManagerJobWrapperScreen")

    return (
        <div className="container-screens-large-manager-jobs">
            <div className="container-content-large-manager-jobs">

                <div className="container-sidebar-large-manager-jobs">
                    <SideBar />
                    <div 
                        id="con-aux-side-bar-resize-manager-job-screen" 
                        className="con-aux-sidebar"
                    >
                    </div>
                </div>
                {
                    /* 
                        With help of media queries, the large screen 
                        will have display none in screens < 1500, 
                        and viceversa
                    */

                }

                {
                    // Desktop not centered screen, > 1500 px
                    measuresManagerJobScreen.width !== "0" && 
                    <div className="container-screen-desktop-manager-jobs">
                        <div className="container-aux-design-screen-desktop-manager-jobs" >
                            <div className="con-large-for-manager-jobs" style={{ width: measuresManagerJobScreen.width, left: measuresManagerJobScreen.left}}>
                                <RouteComponent />
                            </div>
                        </div>
                    </div>
                }

                {
                    /*
                        uncomment when you open developer tools
                        <div style={{ width: "100rem" }}>
                            <RouteComponent />
                        </div>
                    */
                }


                {
                    // Laptop not centered screen, > 1500 px
                    /*
                    measuresManagerJobScreen.width !== "0" && 
                    <div className="container-screen-laptop-manager-jobs">
                        <div className="container-aux-design-screen-laptop-manager-jobs" >
                            <RouteComponent />
                        </div>
                    </div>
                    */
                }


            </div>
        </div>
      )
})

export default LargeManagerJobWrapperScreen