import React, { useCallback } from 'react'
import ArrowGoBackSignUp from '../../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../../components/UIcomponents/NextButton/NextButton'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { useNavigate } from 'react-router-dom'
import { S3DomainGimwork_categories_logo } from '../../../../utils/staticVariables/staticVariables'
import { Colors } from '../../../../utils/colors/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import WorkPreferenceColorDynamic from '../../../../components/UIcomponents/WorkPreferenceColorDynamic/WorkPreferenceColorDynamic'
import { resetToAddNewSkill } from '../../../../redux/slices/components/SignUp/SignUpSlice'
import useGetAllJobNames from '../../../../components/hooks/useGetAllJobNames/useGetJobName'
import { useTranslation } from 'react-i18next'

const ViewYourSkillsScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const jobsNames = useGetAllJobNames()

    const jobs = useSelector((state: RootState) => state.signUp.jobsAdded)
    const userJobs = useSelector((state: RootState) => state.auth.user.jobs)
    const jobsAdded = useSelector((state: RootState) => state.signUp.jobsAdded)
    //console.log(" ")
    //console.log("userJobs select", userJobs)
    //console.log("jobsAdded select", jobsAdded)
    const onNext = useCallback(
        () => {
            window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/my-profile`
            //navigate("/welcome")
        },
        [],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const goSelectSkill = () => {
        dispatch( resetToAddNewSkill() ) 
        navigate("/gim-skill/select-skill")
    }

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ paddingTop: "10vh" }}>

            <div className="flex flex-jc-sb">
                <p className="fs-36-vh c-text">{t("SignUp:resumeSkillsScreen:label_01")}</p>
                <button onClick={goSelectSkill} className="circle pointer" style={{ width: "4vh", height: "4vh", backgroundColor: Colors.Gim }} >
                    <FontAwesomeIcon icon={faPlus} className="c-white fs-26-vh ml-1" />
                </button>
            </div>

            <div className="flex hide-scroll" style={{ overflowX: "scroll" }}>
                {
                    jobs.map((job) => {
                        return(
                            <div key={job.jobId} className="mr-6 ml-6" style={{ width: "27rem" }}>

                                <p className="fs-20-vh color-text mb-6">{jobsNames[job.categoryId]}</p>

                                <div 
                                    className="gallery-bg flex flex-d-column border-radius-3" 
                                    style={{ height: "38vh", overflow: "hidden", border: `1px solid ${Colors.Gim}`, width: "27rem" }}
                                >
                                    <img src={`${S3DomainGimwork_categories_logo}${job.categoryId}/${job.jobId}/mainJobImg_300`} alt={"job"} style={{ height: "44%", width: "100%" }}  />
                                    <div className="flex-1 flex flex-d-column" style={{ padding: "3.5%" }}>

                                        <p className="fs-16-vh color-text mt-2 mb-2">{jobsNames[job.jobId]}</p>

                                        <div className="flex-1">
                                            {
                                                job.workPreference.remote &&
                                                <div className="flex flex-ai-center" style={{ height: "33%" }}>
                                                    <WorkPreferenceColorDynamic type="remote" color={Colors.TEXT} size="2.4vh" />
                                                    <p className="fs-12-vh ml-8">{t("SignUp:resumeSkillsScreen:label_02")}</p>
                                                </div>
                                            }

                                            {
                                                job.workPreference.inside.address &&
                                                <div className="flex flex-ai-center" style={{ height: "33%" }}>
                                                    <WorkPreferenceColorDynamic type="inside" color={Colors.TEXT} size="2.4vh" />
                                                    <p className="fs-12-vh ml-8 flex-1">{job.workPreference.inside.address}</p>
                                                </div>
                                            }

{
                                                job.workPreference.outside.address &&
                                                <div className="flex flex-ai-center" style={{ height: "33%" }}>
                                                    <WorkPreferenceColorDynamic type="outside" color={Colors.TEXT} size="2.4vh" />
                                                    <p className="fs-12-vh ml-8 flex-1">{job.workPreference.outside.address}</p>
                                                </div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:resumeSkillsScreen:label_03")} 
                />
            </div>
        </div>
    )
}

export default ViewYourSkillsScreen