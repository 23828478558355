import Resizer from "react-image-file-resizer";

export const resizeImage = (file: any, width = 100, height = 100) =>
    new Promise((resolve) => {
        const type = file.type.split("General:/")
        const extension = type[type.length - 1]
        Resizer.imageFileResizer(
            file,
            width,
            height,
            extension,
            100,
            0,
            (fileBlob) => {
                resolve(fileBlob);
            },
            "blob"
        ); 
    }
);