import { useEffect, useState } from "react"
import "./RowTableUsers.css"
import { ManageUser } from "../../../../../redux/slices/components/ManageUsers/manageUsersDataSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { getProfilePictureByUserId } from "../../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { useAppDispatch } from "../../../../../redux/store/store"
import { addUserGroupedReducer, removeUserGroupedReducer } from "../../../../../redux/slices/components/ManageUsers/manageUsersGroupedDataSlice"
import { setDeleteUserModalStateReducer, setEditUserRoleModalStateReducer, setSendMessageUserModalStateReducer } from "../../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice"
import useCheckAccess from "../../../../hooks/useCheckAccess/useCheckAccess"


interface RowTableUsersProps {
    user: ManageUser,
    checkboxValue: boolean
}

const RowTableUsers = ({ user, checkboxValue }: RowTableUsersProps) => {

    const dispatch = useAppDispatch()
    const [userImg, setUserImg] = useState<string>("")

    const accessSendMessage = useCheckAccess({ id_Category: "Manage_Users", id_Policy: "Message", id_Action: "Create" })
    const accessEditUserRole = useCheckAccess({ id_Category: "Manage_Users", id_Policy: "Users", id_Action: "Edit" })
    const accessDeleteUser = useCheckAccess({ id_Category: "Manage_Users", id_Policy: "Users", id_Action: "Delete" })

    useEffect(() => {
        getProfilePictureByUserId(user.id, "small")
        .then((imgUrl) => {
            setUserImg(imgUrl)
        })
        return () => {}
    }, [])

    const handleUserCheckbox = (event: any) => {

        // These two lines avoid to select the row when we click in open menu or delete job
        const styleClasses: string = event.target.className.baseVal ?? event.target.className ?? ""
        const styleClassesParent: string = event.target.parentNode.className.baseVal ?? event.target.parentNode.className ?? ""

        if(styleClasses.indexOf("no-select") > -1 || styleClassesParent.indexOf("no-select") > -1)
            return

        if(!checkboxValue){
            dispatch(addUserGroupedReducer(
                user
            ))
        }else{
            dispatch(removeUserGroupedReducer({
                id: user.id
            }))
        }
    }
    
    const handleSendMessage = () => {
        dispatch(setSendMessageUserModalStateReducer({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            mail: user.mail
        }))
    }

    const handleEditUserRole = () => {
        dispatch(setEditUserRoleModalStateReducer({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.roleId
        }))
    }

    const handleDeleteUser = () => {
        dispatch(setDeleteUserModalStateReducer({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName
        }))
    }

    return (
        <div onClick={handleUserCheckbox} id={`user-row-${user.id}`}  className={`con-row-user con-row width-100 flex fw-500 pointer ${checkboxValue ? "blue-row-background" : ""}`}>

            <div className="item1-table-user center">
                <input 
                    type="checkbox" 
                    className="pointer" 
                    onChange={() => {}}
                    checked={checkboxValue}
                />
            </div>
            
            <div className="item2-table-user center-start">
                <img 
                    className="img-circular-row circle" 
                    src={userImg}
                    alt="user"
                    onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}
                />
            </div>

            <div className="item3-table-user center-start">
                <p className="fs-13 fs-600 three-dot pr-8" title={user.id}>{user.id}</p>
            </div>

            <div className="item4-table-user center-start">
                <p className="fs-13 fs-600">{user.lastName}</p>
            </div>

            <div className="item5-table-user center-start">
                <p className="fs-13 fs-600">{user.firstName}</p>
            </div>

            <div className="item6-table-user center-start">
            <p className="fs-13 fs-600">{user.roleId}</p>
            </div>
            
            <div className="item7-table-user center-start">
                <p className="fs-13 fs-600">{user.countryName}</p>
            </div>

            <div className="flex flex-1 flex-ai-center flex-jc-end">
                {
                    accessSendMessage &&
                    <button onClick={handleSendMessage} type="button" className="center pointer bg-transparent mr-6 circle hover-bg-gray-icon icon-user-row no-select">
                        <FontAwesomeIcon icon={regular("paper-plane")} className="fs-14 no-select" />
                    </button>
                }
                {
                    accessEditUserRole &&
                    <button onClick={handleEditUserRole} type="button"  className="center pointer bg-transparent mr-6 ml-6 circle hover-bg-gray-icon icon-user-row no-select">
                        <FontAwesomeIcon icon={regular("user-gear")} className="fs-14 no-select" />
                    </button>
                }
                {
                    accessDeleteUser &&
                    <button onClick={handleDeleteUser} type="button"  className="center pointer bg-transparent ml-6 mr-6 cirlce hover-icon-red icon-user-row no-select">
                        <FontAwesomeIcon icon={regular("trash")} className="fs-14 no-select" />
                    </button>
                }
            </div>
        </div>
    )
}

export default RowTableUsers