import React from 'react'
import { useTranslation } from 'react-i18next'
import "./ButtonsSubmitFormProduct.css"

type ButtonsSubmitFormProductProps = {
    onPress: (value: boolean) => void
}

const ButtonsSubmitFormProduct = ({ onPress }: ButtonsSubmitFormProductProps) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-ai-center width-100 flex-jc-sb">

            <button
                className="white-bg border-color-text color-text fw-600 fs-15-vh pointer button-submit-product border-radius-3"
                onClick={() => onPress(false)}
            >
                {t("General:label_057")}
            </button>

            <button
                className="dark-gray-bg c-white fw-600 fs-15-vh pointer button-submit-product border-radius-3"
                onClick={() => onPress(true)}
            >
                {t("General:label_056")}
            </button>
            

        </div>
    )
}

 export default React.memo( ButtonsSubmitFormProduct )