import React from 'react'
import { ProductInfo } from '../../../utils/chats/ChatFunctions'
import ScrollProductCard from './ScrollProductCard/ScrollProductCard'
import "./ScrollProducts.css"
import { ProductDB } from '../../../redux/slices/components/Products/ProductsSlice'

type ScrollProductsProps = {
    sliderData: ProductDB[]
}

const ScrollProducts = ({ sliderData }: ScrollProductsProps) => {
    return (
        <div className="p-relative flex-1">
            <div className="ml-20 con-scroll-product-cards aux-scroll">
                {
                    sliderData.map((product, index) => {
                        return(
                            <ScrollProductCard key={index} product={product} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ScrollProducts