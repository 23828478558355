import { useEffect } from 'react'
import HeaderManageRoles from '../../../../components/ManageRoles/HeaderManageRoles/HeaderManageRoles'
import TableManageRoles from '../../../../components/ManageRoles/TableManageRoles/TableManageRoles'
import { getAllowedRolesChunk } from '../../../../redux/slices/components/ManageRoles/manageRolesSlice'
import { useAppDispatch } from '../../../../redux/store/store'
import ManageRolesModals from '../../../../components/ManageRoles/ManageRolesModals/ManageRolesModals'

const ManageRolesScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
      
        dispatch( getAllowedRolesChunk() )
    
        return () => {}
    }, [])
    

    return (
        <div className="flex flex-d-column height-100">
            <HeaderManageRoles />
            <TableManageRoles />
            <ManageRolesModals />
        </div>
    )
}

export default ManageRolesScreen

