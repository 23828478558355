import React from 'react'
import "./DashboardCircularChart.css"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import StatisticInfo from '../../StatisticInfo/StatisticInfo';
import HorizontalSeparator from '../../../UIcomponents/HorizontalSeparator/HorizontalSeparator';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
];

const COLORS = ['#E54B4B', '#6290C3'];

const RADIAN = Math.PI / 180;

const DashboardCircularChart = () => {

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
    };
    const u = 2856
    return (
        <div className="segment-dashboard flex flex-ai-center" style={{ height: "55rem" }}>

            <div className="con-chart-circular ml-30" >
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={800} height={800}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          //label={renderCustomizedLabel}
                          outerRadius={190}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>

            <div className="flex ml-40 mb-100">
                <div className="">
                    <div className="flex flex-ai-center mb-20">
                        <div className="square-d type-color-Task"></div>
                        <p className="fs-14 color-oslo ml-14">Income Gimmer</p>
                    </div>
                    <div className="flex flex-ai-center">
                        <div className="square-d type-color-Job"></div>
                        <p className="fs-14 color-oslo ml-14">Income Gim</p>
                    </div>
                </div>

                <HorizontalSeparator distance='10rem'/>

                <div className="flex flex-d-column flex-jc-sb flex-wrap" style={{ height: "20rem", width: "40rem"  }}>
                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={true}
                    />

                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={false}
                    />

                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={true}
                    />
                    
                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={false}
                    />

                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={true}
                    />

                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={false}
                    />

                    <StatisticInfo
                        value={u.toFixed(0)}
                        text={"Turnover"}
                        percentage={"12.4"}
                        grow={true}
                    />


                </div>
            </div>

            
        </div>
    )
}

export default DashboardCircularChart