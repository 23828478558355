import React from "react"
import { useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import AddContractModal from './AddContractModal/AddContractModal'
import DeleteContractModal from './DeleteContractModal/DeleteContractModal'
import EditContractModal from './EditContractModal/EditContractModal'
import { ModalNameRules, resetModalsValueFromRulesState } from "../../../redux/slices/components/Rules/rulesSlice"


const RulesModals = () => {

  const dispatch = useAppDispatch()
  const modalName = useSelector((state: RootState) => state.rules.modalName);

  const handleClickOutsideModal = (e: any) => {
    const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
    if(!clickOutsideModal && e.target.id === "darkened-background-modal")
        dispatch(resetModalsValueFromRulesState())
  }

  return (
    <>
      {
        modalName &&
        <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
          <div id={"background-aux-modal"}>
            <RulesSelectModal modalName={modalName} />
          </div>
        </div>
      }
    </>
  )
}

type RulesModalsProps = {
    modalName: ModalNameRules
}

const RulesSelectModal = ({ modalName }: RulesModalsProps) => {
  
    switch(modalName){
        case "AddContractModal":
            return (<AddContractModal />)
        case "DeleteContractModal":
            return (<DeleteContractModal />)
        case "EditContractModal":
            return (<EditContractModal />)
        default:
            return(
                <h1>Error modals</h1>
        )
    }
}

export default React.memo( RulesModals )