import { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { removeVerifiedIdImageReducer, setLoadingVerifyIdReducer, UserIdImage } from '../../../../redux/slices/components/VerifyId/VerifyIdDataSlice'
import { getIdImgByUserId, getProfilePictureByUserId, verifyUserIdImage } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import "./SliderIdCard.css"
import { useTranslation } from 'react-i18next';
import IdImage from './IdImage/IdImage';
import ExpandedImage from './ExpandedImage/ExpandedImage';
import VerticalSeparator from '../../../UIcomponents/VerticalSeparator/VerticalSeparator';
import { AxiosError } from 'axios';
import FormsSliderIdCard from './FormsSliderIdCard/FormsSliderIdCard';
import { Body_INE } from '../../../../utils/INE/INE';

interface SliderIdCardProps {
    user: UserIdImage,
    index: number,
    lengthUsers: number
    editable: boolean
}


const SliderIdCard = ({ user, index, editable }: SliderIdCardProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [showExpandedIdFrontImage, setShowExpandedIdFrontImage] = useState<boolean>(false);
    const [showExpandedIdBackImage, setShowExpandedIdBackImage] = useState<boolean>(false);
    const [showExpandedIdVideo, setShowExpandedIdVideo] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>("")

    const [idFrontImgUrl, setIdFrontImgUrl] = useState<string>("")
    const [idBackImgUrl, setIdBackImgUrl] = useState<string>("")
    const [idVideoImgUrl, setIdVideoImgUrl] = useState<string>("")

    const [INEwasValidated, setINEwasValidated] = useState<"YES" | "NO" | "">("YES")

    useEffect(() => {
        getProfilePictureByUserId(user.id, "mid")
        .then((url) => {
            setImgUrl(url)
        })

        getIdImgByUserId(user.id, "Front_id")
        .then((url) => {
            console.log("url", url)
            setIdFrontImgUrl(url)
        }).catch(() => {})

        getIdImgByUserId(user.id, "Back_id")
        .then((url) => {
            setIdBackImgUrl(url)
        }).catch(() => {})

        getIdImgByUserId(user.id, "Video_id")
        .then((url) => {
            setIdVideoImgUrl(url)
        }).catch(() => {})

    }, [])

    const validPicture = () => {
        dispatch( setLoadingVerifyIdReducer(true) )
        verifyUserIdImage(user.id, { newVerifiedState: true })
        .then((res) => {
            dispatch( removeVerifiedIdImageReducer({ id: user.id, user: user}) )
        }).finally(() => { dispatch(setLoadingVerifyIdReducer(false)) })
    }
    
    const inValidPicture = () => {
        dispatch( setLoadingVerifyIdReducer(true) )
        verifyUserIdImage(user.id, { newVerifiedState: false })
        .then((res) => {
            dispatch( removeVerifiedIdImageReducer({ id: user.id, user: user}) )
        }).catch((error: AxiosError) => {
            console.log("error", error)
        }).finally(() => { dispatch(setLoadingVerifyIdReducer(false)) })
    }

    const openFrontImage = useCallback( () => { setShowExpandedIdFrontImage(true) }, [], )
    const openBackImage = useCallback( () => { setShowExpandedIdBackImage(true) }, [], )
    const openVideo = useCallback( () => { setShowExpandedIdVideo(true) }, [], )

    const closeFrontImage = useCallback( () => { setShowExpandedIdFrontImage(false) }, [], )
    const closeBackImage = useCallback( () => { setShowExpandedIdBackImage(false) }, [], )
    const closeVideo = useCallback( () => { setShowExpandedIdVideo(false) }, [], )
    
    const onSuccessINEcheck = useCallback(
        () => {
            setINEwasValidated("YES")
        },
        [],
    )
    
    const onFailedINEcheck = useCallback(
        () => {
            setINEwasValidated("NO")
        },
        [],
    )
    
    return (
        <div id={`slider-profile-img-${index.toString()}`} className="con-id-img-card p-relative " >
            <div className="card-id-img flex flex-jc-c flex-ai-center pt-20 pb-20">

                <div className="flex flex-d-column flex-jc-c">

                    <IdImage url={idFrontImgUrl} onClick={openFrontImage} />

                    <div className="mt-20 mb-20">
                        <IdImage url={idBackImgUrl} onClick={openBackImage} />
                    </div>

                    <IdImage url={"./assets/images/video_preview.jpg"} onClick={openVideo} />
                    
                </div>

                {
                    showExpandedIdFrontImage &&
                    <ExpandedImage url={idFrontImgUrl} onClick={closeFrontImage} isVideo={false} />
                }

                {
                    showExpandedIdBackImage &&
                    <ExpandedImage url={idBackImgUrl} onClick={closeBackImage} isVideo={false} />
                }

                {
                    showExpandedIdVideo &&
                    <ExpandedImage url={idVideoImgUrl} onClick={closeVideo} isVideo={true} />
                }
                
                <div className="con-data-card-id-img flex-d-column ml-14">

                    <div className="con-img-name-country-id flex flex-ai-center">
                        <div className="img-profile-id-card mr-8" style={{ "backgroundImage": `url(${imgUrl})`}}></div>
                        <div className="flex flex-d-column" >
                            <p className="fw-600 fs-18 mb-4">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="fw-500 mt-4 fs-16">{`${t("General:label_055")}: ${user.countryName}`}</p>
                        </div>
                    </div>

                    {
                        editable &&
                        <div className="" style={{ minHeight: "32rem" }}>
                            <FormsSliderIdCard
                                onSuccessINEcheck={onSuccessINEcheck}
                                onFailedINEcheck={onFailedINEcheck}
                            />
                        </div>
                    }


                    {
                        editable &&
                        <div className="center">
                            {
                                INEwasValidated === "NO" &&
                                <button
                                    className="btn-slider-card-id fw-600  btn-slider-card-invalid-id pointer color-persian-red"
                                    onClick={inValidPicture}
                                >
                                    {t("General:label_057")}
                                </button>
                            }

                            {
                                INEwasValidated === "YES" &&
                                <button
                                    className="btn-slider-card-id fw-600 btn-slider-card-valid-id pointer color-green-pass"
                                    onClick={validPicture}
                                >
                                    {t("General:label_056")}
                                </button>
                            }

                        </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default SliderIdCard