import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StatusReport } from '../../../../utils/dbModels'

type StatusReportProps = {
    type: StatusReport
}

const StatusReportText = ({ type }: StatusReportProps) => {

    const { t } = useTranslation();

    switch (type) {
        case "OPENED":
            return(
                <div className="flex flex-ai-center" >
                    <FontAwesomeIcon icon={regular("circle")} className={"fs-8 mr-4 color-apple"} />
                    <p className="fs-12 fw-600 color-apple" style={{ marginTop: "3px" }} >{ t("General:label_165") }</p>
                </div>
            )
        case "PENDING":
            return(
                <div className="flex flex-ai-center" >
                    <FontAwesomeIcon icon={regular("circle")} className={"fs-8 mr-4 color-alert"} />
                    <p className="fs-12 fw-600 color-alert" style={{ marginTop: "3px" }} >{ t("General:label_166") }</p>
                </div>
            )

        case "CLOSED":
            return(
                <div className="flex flex-ai-center" >
                    <FontAwesomeIcon icon={regular("circle")} className={"fs-8 mr-4 color-gimmer"} />
                    <p className="fs-12 fw-600 color-gimmer" style={{ marginTop: "3px" }} >{ t("General:label_167") }</p>
                </div>
            )
        default:
            return(
                <></>
            )
    }
}

export default StatusReportText