import React, { useCallback, useState } from 'react'
import InputList from '../../../../UIcomponents/InputList/InputList'
import { Body_INE, FormINEProps, INE } from '../../../../../utils/INE/INE'
import FormTypeC from '../Forms/FormTypeC/FormTypeC'
import FormTypeE from '../Forms/FormTypeE/FormTypeE'
import FormTypeD from '../Forms/FormTypeD/FormTypeD'
import { checkINERequest } from '../../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import LoadingSpinner from '../../../../LoadingSpinner'

const typesINE: { name: string, value: INE }[] = [
    { name: "C", value: INE.C },
    { name: "D", value: INE.D },
    { name: "E, F, G y H", value: INE.E },
]

type FormsSliderIdCardProps = {
    onSuccessINEcheck: () => void
    onFailedINEcheck: () => void
}

const FormsSliderIdCard = ({ onSuccessINEcheck, onFailedINEcheck }: FormsSliderIdCardProps) => {

    const [typeSelected, setTypeSelected] = useState({ name: "", value: "" })
    const [loading, setLoading] = useState(false)

    const onPressType = (type: { name: string, value: INE }) => {
        setTypeSelected(type)
    }

    const onSubmitINEvalidation = useCallback(
        async(body: Body_INE) => {
            setLoading(true)
            try {
                const res = await checkINERequest(body)
                console.log("res", res)
                onSuccessINEcheck()
            } catch (error) {
                console.log("Error onSubmitINEvalidation: ", error)
                onFailedINEcheck()
            }
            setLoading(false)
        },
        [],
    )

    return (
        <div className="width-100 mt-20 p-relative">

            <InputList text={typeSelected.name} height="4.6vh" width="100%" >
                {
                    typesINE.map((item) => {
                        return(
                            <button key={item.name} onClick={() => onPressType(item)} className="width-100 flex flex-ai-center pointer" style={{ height: "4.6vh" }}>
                                 <p className="fs-16-vh ml-14 color-text">{item.name}</p>
                            </button>
                        )
                    })
                }
            </InputList>
            
            <div className="mt-30">
            {
                typeSelected.value === "c" && <FormTypeC onSubmit={onSubmitINEvalidation} />
                || typeSelected.value === "d" && <FormTypeD onSubmit={onSubmitINEvalidation} />
                || typeSelected.value === "e" && <FormTypeE onSubmit={onSubmitINEvalidation} />
            }
            </div>


            { loading && <LoadingSpinner fullPage={false} /> }


        </div>    
    )
}

export default React.memo( FormsSliderIdCard )