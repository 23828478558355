import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getProfileUsersImages, uploadedUserProfileImage } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions';


export interface UserProfileImage {
    id: string, 
    firstName: string,
    lastName: string,
  }
  



export const getProfileUsersImagesThunk = createAsyncThunk(
    'verifyPictureData/getProfileUsersImagesThunk',
    async () => {
      try {
        const { usersToReturn, LastEvaluatedKey} = await getProfileUsersImages()
        return {
            usersToReturn: usersToReturn as UserProfileImage[],
            LastEvaluatedKey: LastEvaluatedKey
        }
        
      } catch (error) {
        console.log("error", error)
        return {
          usersToReturn: [] as UserProfileImage[],
          LastEvaluatedKey: null
        }
      }
    }
)


  export const undoProfilePictureThunk = createAsyncThunk(
    'verifyPictureData/undoProfilePictureThunk',
    async (user: UserProfileImage) => {
      try { 
        await uploadedUserProfileImage(user.id)
        return user
        
      } catch (error) {
        console.log("error", error)
        throw("Error undo update user")
      }
    }
  )

  const initialState = {
    sliderData: [] as UserProfileImage[],
    lastUserVerified: {} as UserProfileImage,
    loadingContainer: true
  }

export const verifyPictureDataSlice = createSlice({
  name: 'verifyPictureData',
  initialState,
  reducers: {

    removeVerifiedPictureImageReducer: (state, action: PayloadAction<{ id: string, user: UserProfileImage }>) => {
        state.lastUserVerified = action.payload.user;
        state.sliderData = state.sliderData.filter((u: UserProfileImage) => u.id !== action.payload.user.id)
        return state
    },
    setLoadingPictureImageSliderReducer: (state, action: PayloadAction<boolean>) => {
      state.loadingContainer = action.payload
      return state
    },
    resetVerifyPictureImageReducer: (state) => {
        return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileUsersImagesThunk.fulfilled, (state, action: any) => {
        state.sliderData = action.payload.usersToReturn
        state.loadingContainer = false;
        return state
      }).addCase(getProfileUsersImagesThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state
      }).addCase(getProfileUsersImagesThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      }).addCase(undoProfilePictureThunk.fulfilled, (state, action: PayloadAction<UserProfileImage>) => {
        console.log("1")
        state.sliderData.unshift(action.payload)
        state.lastUserVerified = {} as UserProfileImage;
        state.loadingContainer = false;
        return state
      }).addCase(undoProfilePictureThunk.pending, (state, action: any) => {
        console.log("2")
        state.loadingContainer = true
        return state     
      }).addCase(undoProfilePictureThunk.rejected, (state, action: any) => {
        console.log("3")
        //const failState = {...initialState}
        state.loadingContainer = false
        return state
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
    removeVerifiedPictureImageReducer, 
    resetVerifyPictureImageReducer,
    setLoadingPictureImageSliderReducer
} = verifyPictureDataSlice.actions

export default verifyPictureDataSlice.reducer