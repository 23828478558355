import { useAppDispatch } from '../../../redux/store/store';
import { FaUndo } from 'react-icons/fa';
import { undoProfilePictureThunk, UserProfileImage } from '../../../redux/slices/components/VerifyPicture/verifyPictureDataSlice';
import "./HeaderPicture.css"
import { useTranslation } from 'react-i18next';

interface HeaderPictureProps {
    lastUserVerified: UserProfileImage
}

const HeaderPicture = ({ lastUserVerified }:HeaderPictureProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const undo = () => {
        dispatch(undoProfilePictureThunk(lastUserVerified))
    }

    return (
        <div className="con-title-slider-profile">
            {
            // header
            }
            <div className="flex pb-10">
            <p className="title-screen">
                {t("General:label_062")}
            </p>
            <button 
                onClick={undo}
                disabled={ !(lastUserVerified?.id?.length > 0)}
                className={`${!(lastUserVerified?.id?.length > 0) ? "btn-beside-title-screen-disable" : "btn-beside-title-screen"}`}
            >
                <FaUndo className="undo-btn-icon" />
                <span className="text-btn-title-screen">{t("General:label_061")}</span>
            </button>
            </div>

        </div>
    )
}

export default HeaderPicture