import React from 'react'
import "./ContainerOfferCards.css"
import OfferCard from '../OfferCard/OfferCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'

type ContainerOfferCardsProps = {
}

const ContainerOfferCards = ({  }: ContainerOfferCardsProps) => {

    const offers = useSelector((state: RootState) => state.homeOffers.offersToShow)

    return (
        <div className="con-offer-cards flex mt-20">
          {
              offers.map((offer) => {
                  return (
                    <OfferCard key={offer.id} id={offer.id} />
                  )
              })
          }
        </div>
    )
}

export default React.memo( ContainerOfferCards )