import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import React from 'react';
import { changeNumberPagesMarketplaceReducer, numberProductsMarketplaceByPage, paginateMarketplaceReducer } from '../../../../redux/slices/components/Marketplace/marketplaceDataSlice';
import { getPaginatedData } from '../../../../utils/strings/strings';
import { useTranslation } from 'react-i18next';


const PaginatedMarketplaceTableRow = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    
    const page = useSelector((state: RootState) => state.marketplace.page);
    const limit = useSelector((state: RootState) => state.marketplace.limit);
    //const loadingContainer = useSelector((state: RootState) => state.marketplace.loadingContainer);
    const totalProductsByCurrentTab = useSelector((state: RootState) => state.marketplace.totalProductsByCurrentTab);

    const indexTab = useSelector((state: RootState) => state.marketplace.indexTab);
    const numberProductsPublished = useSelector((state: RootState) => state.marketplace.marketplaceProductsPublishedToShow).length
    const numberProductsBought = useSelector((state: RootState) => state.marketplace.marketplaceProductsBoughtToShow).length

    const numberCurrentProducts = indexTab === 1 ? numberProductsPublished : numberProductsBought

    const isThereMoreProductsNextPage = (limit * (page - 1) + numberCurrentProducts) < totalProductsByCurrentTab

    const paginate = (direction: string) => {
        //console.log("lastEvaluatedKey", LastEvaluatedKey)
        if(direction === "forward")
            dispatch(paginateMarketplaceReducer(page + 1))
        else if(direction === "backward" && page !== 1){
            dispatch(paginateMarketplaceReducer(page - 1))
        }

    }

    const changeNumberOfPages = (newNumberOfPages: number) => {

        dispatch( changeNumberPagesMarketplaceReducer( newNumberOfPages) )
    }
/*
    const changeNumberOfPagesSearchBar = (newNumberOfPages: number) => {
        
        if(!stringToSearch)
            return

        const totalBugReportssUntilNow = (page - 1) * limit + bugReportsToShow.length 

        if(newNumberOfPages > totalBugReportssUntilNow ){
            // bring page 1 with same type
            //dispatch(GetBugReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch }))
        }else{
            
            dispatch(GetBugReportsBySearchThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch}))
            
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            
        }
    }
*/
    return (
        <div className="con-row-pagination flex flex-jc-sb flex-ai-center width-100">
            
            <div className=""></div>
            
            <div className="flex flex-ai-center">
                <button 
                    onClick={() => paginate("backward")} 
                    className={`fs-24 mr-12 ${page === 1 ? "disable-color" : "c-black pointer"}`}
                    disabled={page === 1 ? true : false} 
                >
                    <FontAwesomeIcon icon={solid("arrow-left-long")}  />
                </button>
                <p className="fs-15 fw-700">{page}</p>
                <button 
                    onClick={() => paginate("forward")}  
                    className={`fs-24 ml-12 ${isThereMoreProductsNextPage?  "c-black pointer" : "disable-color"}`}
                    disabled={!isThereMoreProductsNextPage ? true : false}
                >
                    <FontAwesomeIcon icon={solid("arrow-right-long")}  />
                </button>
            </div>
            
            <div className="flex">
                {
                    numberProductsMarketplaceByPage.map((numberPages: number, index: number) => {
                        return (
                            <button 
                                key={index}
                                disabled={numberPages === limit}
                                className={`fs-14 fw-500 mr-12 ${numberPages === limit ? "c-black" : "disable-color pointer"}`}
                                onClick={() => { 
                                    //if(stringToSearch)
                                    //    changeNumberOfPagesSearchBar(numberPages)
                                    //else
                                        changeNumberOfPages(numberPages)
                                }}
                            >
                                {numberPages}
                            </button>
                        )
                    })
                }
                <p className="fw-500 fs-14 ml-10">
                    {`${getPaginatedData(page, limit, totalProductsByCurrentTab)} ${t("General:label_267")} ${totalProductsByCurrentTab}`}
                </p>
            </div>

        </div>
    )
}

export default React.memo( PaginatedMarketplaceTableRow )