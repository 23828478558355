import React from 'react'
import "./HeaderMarketplace.css"
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../redux/store/store'
import { setAddProductModalReducer } from '../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import { useNavigate } from 'react-router-dom'

const HeaderMarketplace = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const onAddProduct = () => {
        dispatch( setAddProductModalReducer() )
    }

    const goProductsRequest = () => {
        navigate("/request-product")
    }

    return (
        <div className="flex flex-ai-center">
            <p className="title-screen">{t("Marketplace:label_01")}</p>

            <button 
                className="btn-beside-title-screen"
                onClick={goProductsRequest}
            >
                <FontAwesomeIcon icon={regular("plus")} className="fs-18-vh" />
                <p className="text-btn-title-screen">{t("ManageProducts:header:label_01")}</p>
            </button> 
        </div>
    )
}

export default React.memo( HeaderMarketplace )