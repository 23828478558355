import React from 'react'
import { termsConditions_EN, termsConditions_ES } from '../../../utils/htmlsDocs/TermConditionsContent'
import PublicNavBar from '../../../components/NavBarComponents/PublicNavBar/PublicNavBar'
import FooterLogin from '../../../components/Login/FooterLogin/FooterLogin'
import { useTranslation } from 'react-i18next'

const TermConditionScreen = () => {
  const { t, i18n } = useTranslation()

  let html = termsConditions_EN.html
  if(i18n.language === "es")
    html = termsConditions_ES.html

  return (
      <div className="doc-container">
          <PublicNavBar />
          <div className="doc-content" dangerouslySetInnerHTML={{__html: html }} />
          <FooterLogin />
      </div>
  )
}

export default TermConditionScreen