import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils/strings/strings';
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName';
import "./LabelJobDate.css"

//const timezone = moment.tz.guess();

type LabelJobDateProps = {
    setHeightLabelJobName?(n: number): void

    date: string
    position?: "relative" | "absolute"
    jobId: string
    backgroundColor?: string
}

export const capitalizeDate = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const words = s.split(" ")
    const weekDay = words[0]?.indexOf(".") === -1 ? words[0] + "." : words[0]
    const month = words[1]?.indexOf(".") === -1 ? words[1] + "," : words[1]
    return `${capitalize(weekDay)} ${capitalize(month).replace(/\./g, ",")} ${capitalize(words[2])}`
};

export const capitalizeFrenchDate = (s: string) => {
    if (typeof s !== 'string') {
        return '';
    }
    const words = s.split(" ")
    const weekDay = words[0]?.indexOf(".") === -1 ? words[0] + "." : words[0]
    const month = words[2]?.indexOf(".") === -1 ? words[2] + "," : words[2]
    return `${capitalize(weekDay)} ${capitalize(words[1])} ${capitalize(month)}`
};

const LabelJobDate = ({
    setHeightLabelJobName,

    date,
    position = "absolute",
    backgroundColor = "#ffffffbb",
    jobId
}: LabelJobDateProps) => {
    const { i18n } = useTranslation(); 

    const job_Name = useGetJobName(jobId)

    moment.locale(i18n.language);
    const dateFormatted = i18n.language === "fr" ?
        capitalizeFrenchDate(moment(date).format('ddd DD MMM'))
        : capitalizeDate(moment(date).format('ddd MMM DD'))
    return (
        <div className="flex flex-ai-center flex-jc-sb p-absolute width-100 con-label-job-date" style={{ backgroundColor, position, height: "3vh" }}>
            <p className="fs-20-vh fw-600 color-text">{job_Name}</p>
            <p className="fs-20-vh fw-600 color-text">{dateFormatted}</p>
        </div>
    )
}

export default React.memo(LabelJobDate)