import { useCallback, useState } from 'react'
import "./Login.css"
import FooterLogin from '../../components/Login/FooterLogin/FooterLogin'
import PublicNavBar from '../../components/NavBarComponents/PublicNavBar/PublicNavBar'
import LoginProcess from '../../components/Login/LoginProcess/LoginProcess';
import CheckEmail from '../../components/Login/ForgottenPassword/CheckEmail/CheckEmail';
import CreateNewPassword from '../../components/Login/ForgottenPassword/CreateNewPassword/CreateNewPassword';
import EnterCode from '../../components/Login/ForgottenPassword/EnterCode/EnterCode';


const Login = () => {

    const [flowForgottenPassword, setFlowForgottenPassword] = useState(0)
    const [showSignMethods, setShowSignMethods] = useState(true)
    const [newPassword, setNewPassword] = useState("")
    const [email, setEmail] = useState("")

    const nextStep = useCallback(
        (index: number) => {

            if(index === 4){ //final process succesful
                setNewPassword("")
                setEmail("")
                setShowSignMethods(false)
                setFlowForgottenPassword(0)
            }else{
                setFlowForgottenPassword(index)
            }

        },
        [],
    )

    const goBack = useCallback(
        (index: number) => {
            setFlowForgottenPassword(index)
            if(index === 0)
                setShowSignMethods(false)
        },
        [],
    )
       
    return (
        <div className="height-100 all-login-container">

            <div className="container-content-login">

                <div className="login-left-content center flex-d-column">

                {
                    (flowForgottenPassword === 0 && <LoginProcess showSignMethods={showSignMethods} setShowSignMethods={setShowSignMethods} nextStep={nextStep} />) ||
                    (flowForgottenPassword === 1 && <CheckEmail email={email} setEmail={setEmail} nextStep={nextStep} goBack={goBack} />)  ||
                    (flowForgottenPassword === 2 && <CreateNewPassword nextStep={nextStep} goBack={goBack} newPassword={newPassword} setNewPassword={setNewPassword} />)  ||
                    (flowForgottenPassword === 3 && <EnterCode email={email} newPassword={newPassword} nextStep={nextStep} goBack={goBack} />)
                }

                </div>

                <div className="login-right-content">
                    <div className="main-image-login"></div>
                </div>
            
            </div>

            <FooterLogin />
        </div>
    )
}

export default Login


