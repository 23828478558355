import React, { useCallback, useRef } from 'react'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'
import { useTranslation } from 'react-i18next'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'

const HeaderManageProducts = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const searchBarRef = useRef()

    const accessCreate = useCheckAccess({ id_Category: "Manage_Products", id_Policy: "Products", id_Action: "Create" })

    const searchSubmit = useCallback(
        () => {
            
        },
        [],
    )
    

    return (
        <div className="flex flex-ai-center">
            <p className="title-screen">{t("General:label_273")}</p>
            <SearcherBar style={{ width: "28rem" }} ref={searchBarRef} searchSubmit={searchSubmit} placeholder={t("General:label_029")} />
        </div>
    )
}

export default React.memo( HeaderManageProducts )