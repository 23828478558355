import React from 'react'
import "./SliderProduct.css"
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'
import { ProductInfo } from '../../../utils/chats/ChatFunctions'
import SliderProductCard from './SliderProductCard/SliderProductCard'
import { ProductDB } from '../../../redux/slices/components/Products/ProductsSlice'

interface SliderProductProps {
  sliderData: ProductDB[]
}

const SliderProduct = ({ sliderData }: SliderProductProps) => {

    const accessEdit = useCheckAccess({ id_Category: "Verify", id_Policy: "Certificate", id_Action: "Edit" })

    return (
        <div className="p-relative mt-20">
          <div id="con-gray-slider-product" className="rec-gray-slider-product"> 
              {
                  sliderData.map((product: any, index: number) => {
                      return(
                          <SliderProductCard
                              key={product.id}
                              product={product}
                              index={index}
                              lengthProduct={sliderData.length}
                              editable={accessEdit}
                          />
                      )
                  })
              }
          </div>
      </div>
    )
}

export default React.memo( SliderProduct )