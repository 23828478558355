

export default class ChatRoomMessage {
    private static messages: any[]

    static setMessages(messages: any[]) {
        this.messages = messages
    }

    static addNewMessage(message: any) {
        
        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id === message.id) {
                return
            }
        }

        this.messages = [message, ...this.messages]
    }

    static updateMessage(message: any) {
        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id === message.id) {
                this.messages[i] = message
            }
        }

    }

    static deleteMessage(id: string) {
        const newMessages = []

        for (let i = 0; i < this.messages.length; i++) {
            if (this.messages[i].id !== id) {
                newMessages.push(this.messages[i]);
            }
        }
        this.messages = newMessages
    }

    static getMessages() {
        return this.messages
    }

} 