import React, { useLayoutEffect, useState } from 'react'
import CompanyMenu from '../../Login/CompanyMenu/CompanyMenu'
import HamburgerMenu from '../../Login/HamburgerMenu/HamburgerMenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Language, languages } from '../../../utils/staticVariables/staticVariables';
import "./PublicNavBar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ModalLanguageCurrency from '../../Language/ModalLanguageCurrency/ModalLanguageCurrency';
import { setShowModalLanguageCurrency } from '../../../redux/slices/authSlice';
import { useAppDispatch } from '../../../redux/store/store';

const PublicNavBar = () => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const [languageSelected, setLanguageSelected] = useState<Language>({} as Language);
    const [showLanguageSelector, setShowLanguageSelector] = useState(false);


    const goToScreen = (screen: string) => {
        window.location.href= `https://www.gimwork.com/${screen}`;
    }

    const changeLanguage = (newLanguage: Language) => {
        localStorage.setItem("language", newLanguage.language)
        i18n.changeLanguage(newLanguage.language);
        setLanguageSelected(newLanguage)
    }


/*    
    useLayoutEffect(() => {
      
        const language = localStorage.getItem("language")

        if(language && language.length === 2){

            const languageObj = languages.find((l) => l.language === language)
            console.log("change language error 6")
            i18n.changeLanguage(languageObj?.language ?? "en");
            if(languageObj?.label)
                setLanguageSelected(languageObj)
            else
                setLanguageSelected(languages[0] ?? {} as Language )

        }else{
            localStorage.setItem("language", "en")
            setLanguageSelected(languages[0])
        }
    
    }, [])
*/

    const openLanguageCurrencyModal = () => {
        dispatch( setShowModalLanguageCurrency(true) )
    }
    

    return (
        <header className="navbar-login center width-100">

            <div className="flex flex-ai-center cotainer-login height-100 width-100">
                
                <a href="https://www.gimwork.com" className="img-navbar-logo-big">
                    <img className="navbar-logo" src={"./assets/images/LOGO.svg"} alt="Logo" />
                </a>

                <a href="https://www.gimwork.com" className="img-navbar-logo-small">
                    <img className="navbar-logo-small" src="https://www.gimwork.com/wp-content/uploads/2023/07/logo-gimwork-white-small.png" alt="Logo" />
                </a>

                <div className="flex links-navbar" style={{ marginLeft: "7rem" }}>
                    <CompanyMenu />
                    <div className="with-margin-left item-navbar flex flex-d-column center" onClick={() => goToScreen("services")}>
                        <p className="fs-16 pointer-2 item-navbar-text c-white" >
                            {t("General:label_237")}
                        </p>
                    </div>
                    <div className="with-margin-left item-navbar flex flex-d-column center" onClick={() => goToScreen("safety")}>
                        <p className="fs-16 pointer-2 item-navbar-text c-white" >
                            {t("General:label_232")}
                        </p>
                    </div>
                    <div className="with-margin-left item-navbar flex flex-d-column center" onClick={() => goToScreen("help")}>
                        <p className="fs-16 pointer-2 item-navbar-text c-white" >
                            {t("General:label_231")}
                        </p>
                    </div>
                </div>

                <div className="flex flex-1 flex-jc-end flex-ai-center height-100">
                    <button onClick={openLanguageCurrencyModal}>
                        <img 
                            id="globe-btn"
                            src="/assets/svg/globe.svg" 
                            alt="globe" 
                            className="globe-img mr-24 pointer" 
                        />
                    </button>
                    {
                        /*
                    <div className="p-relative flex height-100 flex-ai-center">
                        <div className="">
                            <button onClick={() => setShowLanguageSelector(!showLanguageSelector)} className="flex flex-ai-center pointer pr-10 pl-10 btn-language-navbar">
                                <img className="language-icon" src={`../assets/images/languages/${languageSelected.img}`} alt={languageSelected.img} />
                                <p className="ml-8 fs-15 fw-500 c-white">{t(languageSelected.language)}</p>
                                <FontAwesomeIcon icon={solid("chevron-down")} className="fs-12 ml-16 mt-3 c-white mb-4" />
                            </button>
                            
                            {
                                showLanguageSelector &&
                                <div className="p-absolute list-languages-navbar z-index-1">
                                    <div className="">
                                        {
                                            languages.filter((l) => l.language !== languageSelected.language)
                                            .map((l: Language) => {
                                                return(
                                                    <div
                                                        key={l.label}
                                                        className="flex flex-ai-center pointer item-list-lang darks-white-bg"
                                                        onClick={() => {
                                                            setShowLanguageSelector(false)
                                                            changeLanguage(l)
                                                        }}
                                                    >
                                                        <img className="language-icon" src={`../assets/images/languages/${l.img}`} alt={l.img} />
                                                        <p className="ml-8 fs-15 fw-400 c-black">{l.name}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    */}

                </div>
                {
                    // <HamburgerMenu />
                }

            </div>
            <ModalLanguageCurrency isLogged={false} />
        </header>
    )
}

export default React.memo( PublicNavBar )