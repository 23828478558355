import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../utils/colors/colors'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { getProfilePicturePutUrlByUserId } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import axios from 'axios'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'

const ProfilePictureScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [image, setImage] = useState<File | null>(null)
    const [urlPreviewImage, setUrlPreviewImage] = useState("")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const firstName = useSelector((state: RootState) => state.auth.user.firstName)

    const onNext = () => {

        if(urlPreviewImage){
            setLoading(true)
            getProfilePicturePutUrlByUserId()
            .then((presignedLink) => {

                const options = { headers: { 'Content-Type': "image/*, video/*" } };
    
                axios.put(presignedLink, image, options)
                .then(() => {
                    console.log("Ok")
                    navigate("/id-front")
                }).catch((error) => {
                    console.log("errorr",  error)
                    setError(t("SignUp:photoScreen:label_07") ?? "")
                }).finally(() => {
                    setLoading(false)
                })
            }).catch(() => {
                setLoading(false)
                setError(t("SignUp:photoScreen:label_06") ?? "")
            })
        }else{
            navigate("/id-front")
        }

    }

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const setImageUploaded = async() => {

        if(error)
            setError("")

        const fileImage = (document.getElementById("sign-up-profile-picture") as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]

            setImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text" style={{ marginBottom: "2.2vh" }}>{`${t("SignUp:photoScreen:label_01")} ${firstName} !`}</p>

                    <p className="fs-22-vh c-text" style={{ marginBottom: "4.4vh" }}>{t("SignUp:photoScreen:label_02")}</p>

                    <input 
                        id="sign-up-profile-picture"  
                        className="display-none" 
                        type="file" 
                        //accept="image/jpeg, image/png"
                        accept="image/jpeg"
                        onChange={setImageUploaded}
                    />


                    {
                        urlPreviewImage 
                        ?
                            <div className="center">
                                <div className="p-relative">
                                    <img src={urlPreviewImage} style={{ width: "17.7vh", height: "17.7vh" }} className="circle" alt="user"/>
                                    <button
                                        onClick={(e) => {
                                        document.getElementById("sign-up-profile-picture")?.click()
                                        }}
                                        className="dark-gray-bg circle center pointer p-absolute"
                                        style={{ width: "4vh", height: "4vh", right: ".7rem", bottom: ".7rem" }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="c-white fs-22" />
                                    </button>
                                </div>
                            </div>
                        :
                        <div
                            style={{
                                border: `1px dashed ${Colors.TEXT}`,
                                height: "18.4vh",
                            }}
                            className="border-radius-3 center"
                        >
                            <button
                                onClick={(e) => {
                                document.getElementById("sign-up-profile-picture")?.click()
                                }}
                                className="dark-gray-bg circle center pointer"
                                style={{ width: "4vh", height: "4vh" }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="c-white fs-22" />
                            </button>
                        </div>
                    }


                    <div className="p-relative">
                        <p className="p-absolute color-error fs-12 mt-6">
                            {error}
                        </p>
                    </div>

                    <p className="fs-18-vh c-text" style={{ marginTop: "4.4vh" }}>{t("SignUp:photoScreen:label_03")}</p>
                </div>

                { ImageSignUp["ProfilePicture"] }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={ urlPreviewImage ? t("SignUp:photoScreen:label_04") : t("SignUp:photoScreen:label_05")} 
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default ProfilePictureScreen