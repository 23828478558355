import React from 'react'
import { ProductDB } from '../../../../redux/slices/components/Products/ProductsSlice'
import "./ProductsRequestsItems.css"
import { S3DomainGimwork_public_files } from '../../../../utils/staticVariables/staticVariables'

type ProductsRequestsItemsProps = {
    product: ProductDB
    onPress: (product: ProductDB) => void
}

const ProductsRequestsItems = ({ product, onPress }: ProductsRequestsItemsProps) => {

    return (
        <button onClick={() => onPress(product)} className="con-card-request-item flex flex-d-column flex-jc-start pointer mr-10">
            {
                product.typeProduct === "DIGITAL" ?
                <div className="card-request-item center">
                    {
                        product.fileType.map((file) => {
                            return(
                                <img 
                                    key={file}
                                    id="globe-btn"
                                    src={`/assets/svg/${file}.svg`} 
                                    alt="globe" 
                                    style={{ height: "3.8vh" }}
                                    className=""
                                />
                            )
                        })
                    }
                </div>
                :
                <img 
                    src={`${S3DomainGimwork_public_files}/${product.userId}/${product.id}/physical_img`}
                    alt="" 
                    style={{ width: "100%", height: "10.2vh", objectFit: "cover" }}
                />
            }
            <p className="fs-24-vh color-text mt-10">{product.productName}</p>
        </button>
    )
}

export default ProductsRequestsItems