import { useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../../redux/store/store'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'

const HeaderManageBugReportScreen = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const resetSearch = useRef<any>()

  //const { indexTab, limit } = useSelector((state: RootState) => state.manageOffersData);
  const indexTab = 0
  const submitSearchBar = (searchString: string) => {
    /*
      dispatch(changeIndexTabReducer({ indexTab: -1 }))
      if(isUUID(searchString)){
          dispatch(GetOfferByIdThunk({ id: searchString }))
      }else{
        dispatch(GetOfferByUsersThunk({ page: 1, limit: 100,  bringTotal: true, stringToSearch: searchString }))
      }
      */
  }

  const searchSubmit = useCallback(
    (searchString: string) => {
      submitSearchBar(searchString)
  },[],)
  
  useEffect(() => {
  
    if(indexTab>0 && resetSearch.current)
        resetSearch.current.submit()

    return () => {
    }
  }, [indexTab])

  
  return (
    <div className="flex flex-jc-sb con-header-offers">
        <div className="flex flex-d-column">
            <p className="title-screen">{t("General:label_160")}</p>
            <p className="color-oslo fs-15 fw-100 mt-6" >{t("General:label_161")}</p>
        </div>
        {
          //<SearcherBar style={{ width: "28rem" }} ref={resetSearch} searchSubmit={searchSubmit} placeholder={"Search user name, id..."} />
        }
    </div>
  )
}

export default HeaderManageBugReportScreen