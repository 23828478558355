import React from 'react'
import { useTranslation } from 'react-i18next'
import UserImgDropdown from '../../../UIcomponents/UserImgDropdown/UserImgDropdown'
import StatusDropdown from './StatusDropdown/StatusDropdown'
import PriorityDropdown from './PriorityDropdown/PriorityDropdown'
import CardAssignedBugReport from './CardAssignedBugReport/CardAssignedBugReport'
import { RootState } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'

const RightSideBugReport = React.memo(() => {

    const { t } = useTranslation()

    const myUserId = useSelector((state: RootState) => state.auth.user.id)
    const userAssignedId = useSelector((state: RootState) => state.manageBugReportsData.bugReport.userAssigned)
    const reportId = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)
    const datesIncident = useSelector((state: RootState) => state.manageBugReportsData.bugReport.datesIncident)

    const onSelect = (id: string) => {

    }

    return (
        <div className="pl-20 pb-10 mb-10 mt-80" style={{ width: "30%" }}>
            { 
                userAssignedId && 
                <CardAssignedBugReport 
                    id={userAssignedId} 
                    isMyUserAssigned={myUserId === userAssignedId} 
                    datesIncident={datesIncident} 
                    reportId={reportId}
                /> 
            }
        {
            /*
                        <p className="fs-13 mb-10 mt-80">{t("General:label_184")}</p>
            <UserImgDropdown 
                showOptionByDefault={true}
                onSelect={onSelect}
                ids={["adc394ad-454a-470a-bea9-29ff13b31600","6c10c38d-0551-4a77-8a7f-bee466202d65","5b4225fe-2bcc-4538-9380-6daec0d0ed22"]}
            />
            */
        }
            
        </div>
    )
})

export default RightSideBugReport