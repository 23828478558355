import { useTranslation } from 'react-i18next'
import { GetOfferType } from '../../../../utils/staticVariables/staticVariables'

interface HeaderDetailedOfferProps {
    jobName: string,
    id: string,
    type: string
}

const HeaderDetailedOffer = ({ jobName, id, type}: HeaderDetailedOfferProps ) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-jc-sb">

        <p className="title-screen">{`${t("General:label_018")} ${jobName} ${t("General:label_019")}`}</p>

        <div className="flex flex-d-column flex-ai-end">

            <p className="fs-16 fw-500 mb-4">{`#id: ${id}`}</p>

            <div className="flex flex-ai-end mt-2">
                <p className="fw-400 fs-17 mr-6">{t("General:label_017")}: </p>
                <button className={`btn-type pt-4 pb-4 fw-600 fs-14 c-white type-color-${type}`}>
                    {GetOfferType(type)}
                </button>
            </div>

        </div>
    </div>
  )
}

export default HeaderDetailedOffer