import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetManageUsersGroupedReducer } from '../../../../../redux/slices/components/ManageUsers/manageUsersGroupedDataSlice';
import { setDeleteMultipleUserModalStateReducer, setEditMultipleUserRoleModalStateReducer } from '../../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice';
import { RootState, useAppDispatch } from '../../../../../redux/store/store';

const GroupedUsersSide = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
  
    const { usersGrouped } = useSelector((state: RootState) => state.manageUsersGroupedData)
    const userSelected = usersGrouped.length

    const handleDeleteMultipleUsers = () => {
        dispatch(setDeleteMultipleUserModalStateReducer())
    }
  
    const handleEditMultipleUsersRole  = () => {
        dispatch(setEditMultipleUserRoleModalStateReducer())
    }
  
    const handleClear = () => {
        dispatch(resetManageUsersGroupedReducer())
    }
  
  return (
    <div className="flex flex-jc-sb fs-12">
        <div className="flex flex-ai-center">
            <div className="center ml-10 mr-10">
                <input type="checkbox" disabled={true} />
            </div>
            <p className="fw-500">{`${userSelected} ${t("General:label_149").toLowerCase()}`}</p>
            <button
                onClick={handleClear}
                className="color-clear height-100 fw-500 bg-transparent ml-20 mr-28 ml-10 pointer"
            >
                {t("General:label_066")}
            </button>
            <p className="mr-2"><i>{t("General:label_067")}</i></p>
            
            <div onClick={handleEditMultipleUsersRole} className={`pointer  flex flex-ai-center ml-10 ${userSelected === 0 ? "disable-div" : ""}`}>
                <FontAwesomeIcon icon={solid("pen")} className="color-gim fs-12 mr-4" />
                <p className="fw-500 mt-2">{t("General:label_148")}</p>
            </div>
  
            <div onClick={handleDeleteMultipleUsers} className={`pointer flex flex-ai-center ml-14 ${userSelected === 0 ? "disable-div" : ""}`}>
                <FontAwesomeIcon icon={solid("trash-can")} className="color-gimmer fs-12 mr-4" />
                <p className="fw-500 mt-2">{t("General:label_069")}</p>
            </div>
            
        </div>
    </div>
  )
}

export default GroupedUsersSide