import { useSelector } from 'react-redux'
import BasicInfoForm from '../../../../components/MyProfile/BasicInfoForm/BasicInfoForm';
import { RootState } from '../../../../redux/store/store';
import "./MyProfileScreen.css";
import StatisticsUser from '../../../../components/MyProfile/StatisticsUser/StatisticsUser';

const MyProfileScreen = () => {
 
    const auth = useSelector((state: RootState) => state.auth);
    const user = auth.user;

    return (
        <div className="flex flex-jc-sb">
            <BasicInfoForm />
            <StatisticsUser />
        </div>
    )
}

export default MyProfileScreen