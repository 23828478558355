import React, { useCallback } from 'react'
import { faTimer } from '@fortawesome/pro-solid-svg-icons'
import GenericNotification from '../GenericNotification'


interface NewOvertimeProposalNotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			approved: string, 
			receivedFromType: string, 
            userName: string,
            transactionId: string
		},
	},
	onSetNotificationAsView: (id: string) => void,
	index: number
}


const NewOvertimeProposalNotification = ({ item, onSetNotificationAsView }: NewOvertimeProposalNotificationProps) => {
	const { id, notifMsg, timestamp, viewed, notifData: { approved } } = item

	const touchNotification =  useCallback(
	  () => {
        if(!viewed)
            onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			showImg={false}

			id={id}
			timestamp={timestamp}
			viewed={viewed}
			description={"User wants to add overtime"}
			title={ "Username wants to add overtime to the meet" }

			icon={ faTimer }
            color={ "#5d5d5d" }
		/>
	)
}

export default React.memo(NewOvertimeProposalNotification)