import React, { useState } from 'react'
import { RootState } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonScreenHelp from '../../../components/Help/ButtonScreenHelp/ButtonScreenHelp'
import "./HelpScreen.css"
import CardsTransactionsHistoryHelp from '../../../components/Help/CardsTransactionHistoryHelp/CardsTransactionsHistoryHelp'
import { useTranslation } from 'react-i18next'

const HelpScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const firstName = useSelector((state: RootState) => state.auth.user.firstName)


    return (
        <div className="width-100 height-100 center">

            <div className="container-help height-100">

                <p className="fs-24-vh color-text mt-10">{`${t("Help:help:label_01")} ${firstName},`}</p>

                <p className="fs-24-vh color-text mt-4">{t("Help:help:label_02")}</p>

                <p className="fs-22-vh color-text mt-30">{t("Help:help:label_03")}</p>

                <CardsTransactionsHistoryHelp />

                <div className="line-gray-help mt-10"></div>

                <ButtonScreenHelp text={t("Help:help:label_04")} onPress={() => navigate("/assistance-job-task")} />

                <div className="line-gray-help"></div>

                <ButtonScreenHelp text={t("Help:help:label_05")} onPress={() => navigate("/report-bug")} />

                <div className="line-gray-help"></div>

                <ButtonScreenHelp text={t("Help:help:label_06")} onPress={() => navigate("/my-reports")} />

                <div className="line-gray-help"></div>

            </div>

        </div>
    )
}

export default HelpScreen