import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import LoadingSpinner from '../../../../LoadingSpinner'
import { useTranslation } from 'react-i18next'
import UploadImageButton from '../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { FileProduct, MarketplaceProduct, setAddMarketplaceProductUserReducer } from '../../../../../redux/slices/authSlice'
import TitleInBorderForInput from '../../../../UIcomponents/TitleInBorderForInput/TitleInBorderForInput'
import { createProductMarketplace, getLinkToPutFileProduct } from '../../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions';
import axios from 'axios';
import { useAppDispatch } from '../../../../../redux/store/store';
import { resetMarketplaceModalsReducer, setAddMarketplaceProductReducer } from '../../../../../redux/slices/components/Marketplace/marketplaceDataSlice';

const FormModalAddProduct = () => {

    const { t,  i18n } = useTranslation()

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState<File | null>(null)
    const [fileProduct, setFileProduct] = useState<FileProduct | undefined>(undefined)
    const [errorFile, setErrorFile] = useState("")

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
        description: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
        price: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
    });  


    const onLoadFile = async() => {

        if(errorFile)
            setErrorFile("")

        const fileLoaded = (document.getElementById("new-add-product-file") as HTMLInputElement)?.files
    
        if(fileLoaded?.length)
        {
            const file = fileLoaded[0]

            const words = file.name.split(".").reverse()
            setFile(file)
            setFileProduct({
                name: file.name,
                size: file.size,
                type: words[0] ?? "unknown"
            })

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    //setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
  
    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    const handleSubmit = async(e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        const options = { headers: { 'Content-Type': '*' } };

        const productId = uuidv4()

        try{
            
            setLoading(true)

            const body: MarketplaceProduct = {
                id: productId,
                productName: inputValues["productName"].value,
                description: inputValues["description"].value,
                price: Number( inputValues["price"].value ),
                date: ""
            }

            if(file){
                // get links
                const signedLink = await getLinkToPutFileProduct(productId, file?.name);

                // save images
                await Promise.all([
                    axios.put(signedLink, file, options),
                ])

                body["File"] = fileProduct

            }



            await createProductMarketplace(body)

            dispatch( setAddMarketplaceProductReducer(body) )
            dispatch( setAddMarketplaceProductUserReducer(body) )

            dispatch(resetMarketplaceModalsReducer())

            setLoading(false)
            //dispatch(getAllJobsCategoriesChunk())
            //dispatch(resetValueFromState())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Creating the job")
        }


    }

    return (
        <form className="mt-10" onSubmit={handleSubmit}>

            <div className="flex height-100">


                <div style={{ width: "60%" }}>
                    <div className="flex flex-d-column">
                        <div className="con-input padding-to-show-error">
                            <TitleInBorderForInput name={"productName"} title={t("Marketplace:label_12")} />   
                            <input
                                id="productName"
                                name="productName"
                                type="text"
                                placeholder={t("Marketplace:label_12") ?? ""}
                                className="input-modal"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["productName"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { inputValues["productName"].error && (
                                <p className="error-input-text">{inputValues["productName"].error  || ""}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-d-column mt-10 mb-6">
                        <div className="con-input padding-to-show-error p-relative">
                            <p className="fs-13 fw-500">{t("Marketplace:label_13")}</p>
                            <textarea 
                                id="description"
                                name="description"
                                placeholder={t("Marketplace:label_14") ?? ""}
                                className="input-modal resize-none hide-scroll"
                                rows={2}
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                style={{ height: "5rem", paddingTop: "1rem", paddingBottom: "1rem" }}
                                value={inputValues["description"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { inputValues["description"].error && (
                                <p className="error-input-txt p-absolute" style={{ bottom: ".6rem" }}>{inputValues["description"].error  || ""}</p>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-ai-center" style={{ }}>
                        <p className="fs-13 fw-500 mb-12 mr-14">{`${t("Marketplace:label_04")} ($)`}</p>
                        <div className="con-input padding-to-show-error">
                            <input
                                id="price"
                                name="price"
                                type="text"
                                placeholder={""}
                                className="input-modal width-100"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                style={{ height: "3.6rem" }}
                                value={inputValues["price"].value}
                                onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                            />
                            { 
                                inputValues["price"].error && (
                                    <p className="error-input-txt p-absolute" style={{ bottom: ".6rem" }}>{inputValues["price"].error  || ""}</p>
                                )
                            }
                        </div>
                    </div>

                </div>

                <div className="ml-20 mr-20 silver-bg" style={{ width: "1px", height: "20rem"}}></div>

                <div className="flex-1">

                    <p className="fs-13 mb-6 mt-6">{t("Marketplace:label_15")}</p>
                    <input 
                        id="new-add-product-file"  
                        className="display-none" 
                        type="file" 
                        accept="*"
                        onChange={onLoadFile}
                    />

                    <div className="con-upload-btns">
                        <div className="con-input add-job-upload-btn">
                            <UploadImageButton 
                                idInputFile="new-add-product-file"  
                                text={t("Marketplace:label_16")}
                                iconName="folder-image" 
                            />
                            { errorFile && <p className="error-input-file">{errorFile}</p> }
                        </div>
                    </div>

                    {
                      fileProduct &&
                      <div className="mercury-bg border-manatee p-6 border-radius-2">
                          <p className="fs-13">{`${fileProduct.name} (${(fileProduct.size / 1024 / 1024).toFixed(2)} Mb)`}</p>
                      </div>
                    }

                </div>

            </div>


            <div className="flex flex-d-column mt-20">
                <ConfirmButton text={t("General:label_016")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default FormModalAddProduct