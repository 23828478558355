import moment from "moment";
import { DetailsSchedule } from "../../redux/slices/components/ManageOffers/manageOffersDataSlice";

const Language = "en"
//const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
//const days = ["Sunday", "Monday", "Thuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const words = { 
  "en": {
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      days: ["Sunday", "Monday", "Thuesday", "Thursday", "Friday", "Saturday"],
      "1": "st",
      "2": "nd",
      "3": "rd",
      default: "th"
  }, "es": {
      months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      days: ["Domingo", "Monday", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
      "1": "ro",
      "2": "do",
      "3": "ro",
      default: "to"
  } 
}

export const getAbbrDay = (day: number) => {
    switch (day) {
      case 1:
        return words[Language]["1"]
      case 2: 
        return words[Language]["2"]
      case 3: 
        return words[Language]["3"]
      default:
        return words[Language].default
    }
  }
  
  export const getTwoDigitNumber = (num: string): string => {
    return num.length > 1 ? num : "0"+num
  }
  
 export const getWhenDate = ({ start, end }: { start: string, end:string }): { date: string, hour: string } => {

      const startHour = moment(start).format('HH:mm');
      const endHour = moment(end).format('HH:mm');
  
      const date = moment(start).format('dddd MMMM Do');

      return {
        date,
        hour: `${startHour} - ${endHour}`
      }
  
  }
// ${getAbbrDay(Number(startDay))}
  
  export const getCreationDate = (date: string) => {
      const dateObj = new Date(date)
      if(isNaN(dateObj.getDay()))
        return ""
      return moment(date).format('DD MMMM YYYY')
  }


  export const isBetween24HoursAgo = (utcDate: string) => {
    // Convert the UTC date string to a Moment object in UTC
    const momentDate = moment.utc(utcDate);
  
    // Get the current moment in UTC
    const now = moment.utc();
  
    // Calculate the difference between now and the UTC date in milliseconds
    const diffInMs = now.diff(momentDate);
  
    // Check if the difference is between 42 hours and 0 hours (now)
    return diffInMs >= 0 && diffInMs < moment.duration(24, 'hours').asMilliseconds();
  }
  

  export const getCardDate = (utcDate: string) => {
    // Ensure moment object is in UTC
    const momentDate = moment(utcDate);

    // Extract and format date parts
    const day = momentDate.format('D');
    const month = momentDate.format('MMMM'); // Full month name
    const year = momentDate.format('YYYY');
    const hours = momentDate.format('HH'); // Use 24-hour format

    // Build and return the formatted string
    return `${day} ${month} ${year} - ${hours}h`;
}

// Python creates timestamp of 10 numbers plus decimals, we need to move the decimal point three places
// to the right
export const convertPythonTimestamp = (timestamp?: number | string) => {
  if(!timestamp)
      return ""
	if(typeof timestamp === "number"){
		if(timestamp && timestamp.toString().indexOf(".") > -1)
		return timestamp * 1000
	}else{
		return timestamp
	}
	return timestamp
}

export type OFFER_TIME_SLOT = {
	end: string;
	id: string;
	start: string;
};

export const orderSchedulesByStartDate = (details?: OFFER_TIME_SLOT[]): OFFER_TIME_SLOT[] => {
  if(!details)
    return [{ start: "", end: "", id: ""}]
	return details.sort((a: OFFER_TIME_SLOT, b: OFFER_TIME_SLOT) => {
		const date1 = new Date(a.start).getTime()
		const date2 = new Date(b.start).getTime()

		if(date1 > date2)
			return 1
		else
			return -1
	})
}