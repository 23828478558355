import React, { useEffect, useState } from 'react'
import "./TabUserReport.css"
import { RootState, useAppDispatch } from '../../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import manageReportsDataSlice, { changeIndexTabReducerUserReport } from '../../../../../redux/slices/components/ManageReports/manageReportsDataSlice';

const colorsAnimation = ["gray", "blue", "red"]

const TabUserReport = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    //const navigate = useNavigate()

    //const [searchParams] = useSearchParams();

    const [leftLine, setLeftLine] = useState<number>(0)

    const marginBetweenOptions = 1; // rem
    const widthOption = 12; // rem

    const indexTab = useSelector((state: RootState) => state.manageUserReports.indexTab);

    useEffect(() => {
        if(indexTab < 0)
            setLeftLine(0)
        else if(indexTab === 2)
            setLeftLine((1) * (widthOption+marginBetweenOptions))
    }, [indexTab])
    

    const setTab = (index: number) => {

        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line") 

        if(smallLine){

            let remsToMove = 0
            if(indexTab > 0)
                remsToMove = ((index - indexTab) * (widthOption+marginBetweenOptions) );
            else
                remsToMove = ((index - 1) * (widthOption+marginBetweenOptions) ); // case after search bar

            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
            dispatch(changeIndexTabReducerUserReport(index))
            //navigate(`/manage-offers?limit=${limit}&page=${1}&type=${typesOffers[index] === "Task" ? "Task" : "Job"}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }

    return (
        <div id="container-tab" className="con-all-options-tab-history">

            <div 
                className={`option-tab-user-report ${indexTab === 1 ? "" : "pointer"}`}  
                onClick={() => setTab(1)}
            >
                <p className={`text-option-tab ${indexTab === 1  ? "color-text fw-500" : "italic c-oslo-gray"}`}>{t("Reports:user:label_18")}</p>
                <div id="1-option" className="option-line"></div>
            </div>
            
            <div 
                className={`option-tab-user-report ${indexTab === 2 ? "" : "pointer"}`}  
                onClick={() => setTab(2)}
            >
                <p className={`text-option-tab ${indexTab === 2  ? "color-text fw-500" : "italic c-oslo-gray"}`}>{"Chat"}</p>
                <div id="2-option" className="option-line"></div>
            </div>

            <div 
                id="small-line" 
                style={{ left: `${leftLine.toString()}rem` }} 
                className={`small-line-user-report dark-gray-bg  ${indexTab < 0 ? "visibility-hidden" : ""}`}
            ></div>
            
            <div className="whole-line"></div>

        </div>
    )
}

export default React.memo( TabUserReport )