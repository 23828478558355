import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PriorityReport } from '../../../../utils/dbModels'

type PriorityReportProps = {
    type: PriorityReport
}

const PriorityReportLabel = ({ type }: PriorityReportProps) => {

    const { t } = useTranslation();

    switch (type) {
        case "HIGH":
            return(
                <div className="flex flex-ai-center blue-unnamed-bg pb-6 pt-6 pr-12 pl-12 border-radius-3" >
                    <p className="fs-12 fw-600 color-manatee mt-2"  >{ t("General:label_168") }</p>
                </div>
            )
        case "MEDIUM":
            return(
                <div className="flex flex-ai-center flex-jc-center blue-unnamed-bg pb-6 pt-6 pr-12 pl-12 border-radius-3" >
                    <p className="fs-12 fw-600 color-manatee mt-2">{ t("General:label_169") }</p>
                </div>
            )

        case "LOW":
            return(
                <div className="flex flex-ai-center blue-unnamed-bg pb-6 pt-6 pr-12 pl-12 border-radius-3" >
                    <p className="fs-12 fw-600 color-manatee mt-2" >{ t("General:label_170") }</p>
                </div>
            )
        default:
            return(
                <></>
            )
    }
}

export default PriorityReportLabel