import { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import HeaderManageJobs from '../../../../components/ManagerJobs/HeaderManageJobs/HeaderManageJobs'
import JobDropdowns from '../../../../components/ManagerJobs/JobDropdowns/JobDropdowns'
import "./ManageJobsScreen.css";
import "../../../../components/UIcomponents/Modals/Modals.css";
import ManagerJobsModals from '../../../../components/ManagerJobs/ManagerJobsModals/ManagerJobsModals';
import { resetManagerJobsGroupedDataSlice } from '../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import React from 'react';
import { updateJobsNamesFile } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions';

const ManageJobsScreen = React.memo(() => {

    const dispatch = useAppDispatch();

    useEffect(() => {
/*
        updateJobsNamesFile({
            id: "cbdb0853-3495-4c2b-9558-a718012ccc22",
            names: {
                en: "Cleaning staff",
                es: "Personal de limpieza",
                fr: "Le personnel de nettoyage"
            }
        })
        .then(() => {

        }).catch(() => {

        })
*/
        return () => {
            dispatch(resetManagerJobsGroupedDataSlice())
        }
    }, [])

    return (
        <div className="container-manage-jobs">
            <HeaderManageJobs />
            <JobDropdowns />
            <ManagerJobsModals />
        </div>
    )
})

export default ManageJobsScreen