import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowGoBackSignUp from '../../ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../UIcomponents/NextButton/NextButton'
import ImageSignUp from '../../ImageSignUpProcess/ImageSignUpProcess'
import { Colors } from '../../../../utils/colors/colors'
import { putIdImgByUserId } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import axios from 'axios'
import LoadingSpinner from '../../../LoadingSpinner'

type IdPhotoContentProps = {
    type: "Front_id" | "Back_id"
}

const IdPhotoContent = ({ type }: IdPhotoContentProps) => {

    const navigate = useNavigate()

    const [image, setImage] = useState<File | null>(null)
    const [urlPreviewImage, setUrlPreviewImage] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const nextScreen = () => {
        if(type === "Front_id"){
            if(image)
                navigate("/id-back")
            else
                navigate("/select-profile")
        }
        else
            navigate("/video-id")
    }

    const onNext = () => {
        if(!image){
            nextScreen()
        }else{
            setLoading(true)
            putIdImgByUserId(type)
            .then((url) => {
                const options = { headers: { 'Content-Type': "image/*, video/*" } };
                axios.put(url, image, options)
                .then(() => {
                    nextScreen()
                }).catch(() => {

                }).finally(() => {
                    setLoading(false)
                })
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const setImageUploaded = async() => {

        if(error)
            setError("")

        const fileImage = (document.getElementById("sign-up-profile-picture") as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]

            setImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
    
    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text" style={{ marginBottom: "2.2vh" }}>{type === "Front_id" ? "Upload the front of your ID" : "Upload the back of your ID"}</p>

                    <p className="fs-22-vh c-text" style={{ marginBottom: "4.4vh" }}>{"Help us to secure our community"}</p>

                    <input 
                        id="sign-up-profile-picture"  
                        className="display-none" 
                        type="file" 
                        accept="image/jpeg, image/png"
                        onChange={setImageUploaded}
                    />


                    {
                        urlPreviewImage 
                        ?
                            <div className="center">
                                <div className="p-relative">
                                    <img src={urlPreviewImage} style={{ height: "17.7vh" }} className="" alt="user"/>
                                    <button
                                        onClick={(e) => {
                                        document.getElementById("sign-up-profile-picture")?.click()
                                        }}
                                        className="dark-gray-bg circle center pointer p-absolute"
                                        style={{ width: "4vh", height: "4vh", right: "-.7rem", bottom: "-.7rem" }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="c-white fs-18 ml-1" />
                                    </button>
                                </div>
                            </div>
                        :
                        <div
                            style={{
                                border: `1px dashed ${Colors.TEXT}`,
                                height: "18.4vh",
                            }}
                            className="border-radius-3 center"
                        >
                            <button
                                onClick={(e) => {
                                document.getElementById("sign-up-profile-picture")?.click()
                                }}
                                className="dark-gray-bg circle center pointer"
                                style={{ width: "4vh", height: "4vh" }}
                            >
                                <FontAwesomeIcon icon={faPlus} className="c-white fs-14 ml-1" />
                            </button>
                        </div>
                    }


                    <div className="p-relative">
                        <p className="p-absolute color-error fs-12 mt-6">
                            {error}
                        </p>
                    </div>

                    <p className="fs-18-vh c-text" style={{ marginTop: "4.4vh" }}>{"Upload the front and back of your ID Card, Passepport or Drive licence."}</p>
                </div>

                { ImageSignUp["Id"] }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={urlPreviewImage ? "Upload" :"Skip"} 
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default IdPhotoContent