import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowGoBackButton from '../../../../../../components/UIcomponents/ArrowGoBackButton/ArrowGoBackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import ClickOutside from '../../../../../../components/UIcomponents/ClickOutside/ClickOutside';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { signIn, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { deleteMyUser, deleteUser } from '../../../../../../utils/requestsUtils/functionsRequests/user/userFunctions';
import { RootState } from '../../../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ConfirmDeletionScreen = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    // Access specific parameter:
    const option = queryParams.get("option") ?? "";
    const description = queryParams.get("description") ?? "";

    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [errorPassword, setErrorPassword] = useState("")

    const deleteWord = t("Profile:deleteAccount:label_08")

    useEffect(() => {
        if(!option)
            navigate("/my-profile/deletion-reason")

    }, [])

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const openModal = () => {
        setShowModal(true)
    }   
    
    const closeModal = () => {
        setShowModal(false)
    }   


    const handleSubmit = async() => {

        setLoading(true)

        try {
            //await signIn({ username: userName, password })
            
            deleteMyUser(option, description)
            .then(() => {
                signOut({ global: true })
                .then(() => {
                    window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/login`
                }).catch(() => {

                }).finally(() => { 
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                })
            })
            .catch(() => { 
                setTimeout(() => {
                    setLoading(false)
                }, 500); 
            })
            .finally(() => {  })
            
        } catch (error) {
            console.log("Error deleting", error)
            setLoading(false)
        }

        
    }

    return (
        <div className="pl-10 width-50">
            <ArrowGoBackButton goBack={goBack} />
            <p className="fs-20 fw-600 color-text mt-30">{t("Profile:deleteAccount:label_01")}</p>
            <p className="fs-14 color-text mt-8 mb-20">{`${t("Profile:deleteAccount:label_02")}:`}</p>

            <div className="mt-40">
                <div className="flex flex-ai-center">
                    <FontAwesomeIcon icon={regular("circle-xmark")} className="color-persian-red fs-15" />
                    <p className="color-text fs-14 ml-6">{t("Profile:deleteAccount:label_03")}</p>
                </div>
                <div className="flex flex-ai-center mt-10">
                    <FontAwesomeIcon icon={regular("circle-xmark")} className="color-persian-red fs-15" />
                    <p className="color-text fs-14 ml-6">{t("Profile:deleteAccount:label_04")}</p>
                </div>
            </div>

            <div className="flex mt-40">
                <button 
                    id={"button-confirm-deletion"}
                    //onClick={openModal}
                    onClick={handleSubmit} 
                    type="button" 
                    className="persian-red-bg border-radius-3 center pointer pl-10 pr-10" 
                    style={{ height: "3.7rem" }}
                >
                    <p className="fs-15 fw-500 c-white">{t("Profile:deleteAccount:label_05")}</p>
                </button>
            </div>

            <p className="fs-12 c-manatee mt-10">
                {t("Profile:deleteAccount:label_06")}
            </p>

            {
                showModal &&
                <div className="p-asolute background-gray center">
                    <ClickOutside onPress={closeModal} exclude={["button-confirm-deletion"]}>
                        <div className="blank-wrapper-modals flex flex-d-column flex-ai-center" style={{ width: "35rem" }}>
                            <p className="color-text fs-15 mb-40 text-align-center">{`${t("Profile:deleteAccount:label_07")} ${t("Profile:deleteAccount:label_08")} ${t("Profile:deleteAccount:label_09")}`}</p>
                            <div className="p-relative width-100">
                                <input 
                                    className="fs-15 p-3 width-100"
                                    placeholder={t("Profile:deleteAccount:label_08") ?? ""}
                                    style={{
                                        border: "none",
                                        borderBottom: "1px solid var(--text)"
                                    }}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                />
                                <p className="p-absolute error-input-txt" style={{ bottom: "-1.5rem" }}>{errorPassword}</p>
                            </div>
                            <button 
                                onClick={handleSubmit} 
                                type="button" 
                                className={`persian-red-bg border-radius-3 center mt-40 width-100 pointer ${deleteWord !== inputValue ? "disable-btn" : ""}`} 
                                style={{ height: "3.7rem" }}
                                disabled={deleteWord !== inputValue}
                            >
                                <p className="fs-15 fw-500 c-white">{t("Profile:deleteAccount:label_10")}</p>
                            </button>
                        </div>
                    </ClickOutside>
                </div>
            }

            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default ConfirmDeletionScreen