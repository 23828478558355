import { useSelector } from "react-redux";
import { Offer } from "../../../redux/slices/components/ManageOffers/manageOffersDataSlice";
import { RootState } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import OfferRow from "./OfferRow/OfferRow";
import "./OfferRows.css"

const OfferRows = () => {
  const offersToShow = useSelector((state: RootState) => state.manageOffersData.offersToShow);
  const loadingContainer = useSelector((state: RootState) => state.manageOffersData.loadingContainer);

  return (
    <div className="container-offer-rows flex flex-1 flex-d-column ff-roboto">
      <div className="aux-scroll-offers">

            { loadingContainer && <LoadingSpinner fullPage={false} /> }
            {
                offersToShow.map((offer: Offer, index: number) => {
                    return(
                      <OfferRow 
                          key={offer.id}
                          offer={offer}
                      />
                    )
                })
            }

        </div>
    </div>
  )
}

export default OfferRows