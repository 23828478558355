import axios, { AxiosRequestConfig } from 'axios'
import React, { useEffect } from 'react'
import { useAppDispatch } from '../../redux/store/store'
import { setJobsNamesFileReducer } from '../../redux/slices/components/Global/GlobalSlice'
import { S3JobsNamesFile } from '../staticVariables/staticVariables'

const GetInitialData = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        
        const getData = () => {
            //axios.get(`https://${process.env.REACT_APP_PUBLIC_GIMWORK}.s3.us-west-1.amazonaws.com//JobNames_dev`)
            const config: AxiosRequestConfig = { 
                headers: {  ContentType: 'application/json', 'Cache-Control': 'no-cache'  },
            };
            axios.get(S3JobsNamesFile, config)
            .then((res: any) => {
                dispatch( setJobsNamesFileReducer(res.data) )
            }).catch(() => {
  
            })
        }

        getData()
    
    }, [])
    

    return (
        <></>
    )
}

export default React.memo( GetInitialData )