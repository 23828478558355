import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import GenericNotification from "./GenericNotification";

interface AdminMessageNotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			message: string, 
			receivedFromType: string
		},
	},
	onSetNotificationAsView: (id: string) => void,
	index: number
}

const AdminMessageNotification = ({ item, onSetNotificationAsView  }: AdminMessageNotificationProps) => {
	
	const { id, notifMsg, timestamp, viewed, notifData} = item
	const [numberTextLines, setNumberTextLines] = useState<number>(3);

	const { t } = useTranslation()

	const touchNotification =  useCallback(
	  () => {

		if(numberTextLines === 3)
			setNumberTextLines(100)
		else
			setNumberTextLines(3)
		
		if(!viewed)
			onSetNotificationAsView(id)
	  },
	  [numberTextLines],
	)

	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			showImg={false}
			icon={"Wgimwork"}
			numberTextLines={numberTextLines}
			color={"#f00"}

			id={id}
			timestamp={timestamp}
			viewed={viewed}
			description={notifData.message}
			title={t("Notifications:index:label_36")}
		/>
	)

}

export default AdminMessageNotification