import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { MyYup } from '../../../../utils/verifications/myYup'
import LoadingSpinner from '../../../LoadingSpinner'
import { createRole } from '../../../../utils/requestsUtils/functionsRequests/roles/roles'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { createRoleReducer, openCloseModalRoleReducer } from '../../../../redux/slices/components/ManageRoles/manageRolesSlice'
import { Country } from '../../../../utils/staticVariables/staticVariables'
import { useSelector } from 'react-redux'
import { ValuesForm } from '../../../../utils/verifications/Forms'

const hierarchies = [ 
    { alias: "Country Global", hierarchy: 30 }, 
    { alias: "Country worker", hierarchy: 20 }, 
]

const ModalAddRole = () => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const myHierarchy = useSelector((state: RootState) => state.auth.user.roleData.hierarchy)
    const myRole = useSelector((state: RootState) => state.auth.user.roleData.id)
    const myCountry = useSelector((state: RootState) => state.auth.user.roleData.country)

    const [countries] = useState(myRole === "Super_Admin" ? ["Mexico", "France"] : [myCountry])
    const [loading, setLoading] = useState(false)
    const [inputValues, setInputValues] = useState<ValuesForm>({
        roleName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
        hierarchy: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
        country: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()}
    });  

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
    
    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        const hierarchy = hierarchies.find(h => inputValues["hierarchy"].value === h.alias)
        if(!hierarchy)
            return   

        const body = {
            hierarchy: hierarchy.hierarchy,
            name: inputValues["roleName"].value,
            country: inputValues["country"].value as Country
        }

        setLoading(true)
        createRole(body)
        .then(() => {
            dispatch( createRoleReducer(body) )
            dispatch( openCloseModalRoleReducer("") )
        }).catch(() => {
            alert("General:Error creating role, please report to Gimwork team")
        })
        .finally(() => { 
            setLoading(false)
        })
    }

    return (
        <form onSubmit={handleSubmit} className="blank-wrapper-modals p-relative flex flex-d-column flex-jc-sb" style={{ height: "65vh" }}>
            
            { loading && <LoadingSpinner fullPage={true} /> }

            <div className="">

                <p className="fs-28">
                  {t("General:label_242")}
                </p>


                <div className="flex flex-d-column con-label-input-modal">
                    <label className="fs-14 black fw-600 mb-2" htmlFor="roleName">{t("General:label_243")} <span className="c-asterik">*</span></label>
                    <div className="con-input">
                        <input
                            id="roleName"
                            name="roleName"
                            type="text"
                            placeholder={t("General:label_116") ?? ""}
                            className="input-modal"
                            onChange={(e: any) => {
                                checkErrors(e)
                            }}
                            value={inputValues["roleName"].value}
                            onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                        />
                        { inputValues["roleName"].error && (
                            <p className="error-input-text">{inputValues["roleName"].error  || ""}</p>
                        )}
                    </div>
                </div>


                <div className="flex flex-d-column con-label-input-modal">
                    <label className="fs-14 black fw-600 mb-2" htmlFor="hierarchy">{t("General:label_245")}</label>
                    <div className="con-input">
                        <select
                            name="hierarchy"
                            className="input-modal pr-10"
                            onChange={(e: any) => {
                                checkErrors(e)
                            }}
                            value={inputValues["hierarchy"].value}
                        >
                            <option value="">- {t("General:label_250")} -</option>
                            {
                                hierarchies.filter((h) => h.hierarchy < myHierarchy).map((hierarchy: any, index: number) => (
                                    <option 
                                        key={index}
                                        value={hierarchy.alias}
                                    >
                                        { hierarchy.alias }
                                    </option>
                                ))
                            }
                        </select>
                        {   inputValues["hierarchy"].error && (
                                <p className="error-input-text">{inputValues["hierarchy"].error  || ""}</p>
                        )}
                    </div>
                </div>

                {
                    <div className="flex flex-d-column con-label-input-modal mt-10">
                        <label className="fs-14 black fw-600 mb-2" htmlFor="country">{t("General:label_117")}</label>
                        <div className="con-input">
                            <select
                                name="country"
                                className="input-modal pr-10"
                                onChange={(e: any) => {
                                    checkErrors(e)
                                }}
                                value={inputValues["country"].value}
                            >
                                <option value="">- {t("General:label_250")} -</option>
                                {
                                    countries.map((country: string, index: number) => (
                                        <option 
                                            key={index}
                                            value={country}
                                        >
                                            { country }
                                        </option>
                                    ))
                                }
                            </select>
                            {   
                                inputValues["country"].error && (
                                    <p className="error-input-text" style={{ top: "5rem" }}>{inputValues["country"].error  || ""}</p>
                                )
                            }
                        </div>
                    </div>
                }

            </div>

            <ConfirmButton width="100%" text={t("General:label_016")} />
        </form>
    )
}

export default ModalAddRole