import { useLayoutEffect, useState } from 'react'
import DetailedBugReportScreen from '../../../../../components/ManageBugReportScreen/DetailedBugReportScreen/DetailedBugReportScreen'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useNavigate, useParams } from 'react-router-dom';
import { GetBugReportByIdThunk, resetDetailedBugReportReducer, setLoadingBugReportReducer } from '../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import { updateAssignmentAppBugWeb } from '../../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { AxiosError } from 'axios'

const BugReportScreen = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [error, setError] = useState("")

    const loadingIndividualPage = useSelector((state: RootState) => state.manageBugReportsData.loadingIndividualPage)
    const idReportBrought = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)

    const { id } = useParams();

    useLayoutEffect(() => {


        if(id){
            //dispatch( setLoadingUserReportReducer(true) )
            updateAssignmentAppBugWeb(id)
            .then(({ STATUS_OPERATION }: { STATUS_OPERATION: "NEW_ASSIGNMENT" }) => {
                //AddUserToChatSupport
                //console.log("STATUS_OPERATION", STATUS_OPERATION)
                dispatch( GetBugReportByIdThunk({ id }) )
            })
            .catch((error: AxiosError) => {
                if(error.response?.data === "USER_WAS_ALREADY_ASSIGNED" || error.response?.data === "USER_IS_JUST_VIEWER" ){
                    dispatch( GetBugReportByIdThunk({ id }) )
                    return
                }else if(error.response?.data === "BUG_REPORT_NOT_FOUND"){
                    setError("Report does not exists")
                }else if(error.response?.status === 403){
                    setError("You don't have access to this resource")
                }

                dispatch( setLoadingBugReportReducer(false) )
            })
            
        }
    
        return () => {
            dispatch(resetDetailedBugReportReducer())
        };
    }, [])


    if (loadingIndividualPage) 
        return (
            <div className="width-100 height-100 p-relative">
                <LoadingSpinner fullPage={false} /> 
            </div>
        )

    if(error)
        return (
            <div className="center height-100">
                <p className="fs-28 fw-500">{error}</p>
            </div>
        )

    if(!idReportBrought)
        return (
            <div className="center height-100">
                <p className="fs-28 fw-500">Report not found</p>
            </div>
        )

    return (
        <div className="flex  height-100 p-relative">
            <DetailedBugReportScreen />
        </div>
    )
}

export default BugReportScreen