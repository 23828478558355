import React from 'react'

type ReactangleDottedProps = {
    width: string
    height: string
}

const ReactangleDotted = ({ width, height}: ReactangleDottedProps) => {
    return (
        <div style={{ width, height, border: "1px dashed #000" }} className=""></div>
    )
}

export default ReactangleDotted