import React, { useEffect, useState } from 'react'
import { HistoryExtraData } from '../../../../redux/slices/components/MyHistory/myHistoryDataSlice'
import "./HistoryRow.css"
import { UserInfoCache, UserInfoCacheType, getMinimalUserInfoData, getS3ImgUri } from '../../../../utils/staticVariables/staticVariables'
import { getProfilePictureByUserId } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import moment from 'moment'
import WorkPreferenceIcon from '../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import StateTransactionHistoryLabel from '../../../UIcomponents/StateTransactionHistoryLabel/StateTransactionHistoryLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'

type HistoryRowProps = {
    history: HistoryExtraData
}

const HistoryRow = ({ history }: HistoryRowProps) => {

    const navigate = useNavigate()

    const [userName, setUserName] = useState<string>("")
    const [userImg, setUserImg] = useState<string>("")
    const [imgUserNotAvailable, setImgUserNotAvailable] = useState(false)
    

    useEffect(() => {
      
      const otherUserId = history.type === "Job" ? history.gimmerId : history.gimId 
      
      const user = UserInfoCache.userNameCache.get(otherUserId)

      if(user?.name){
            setUserName(user.name)
        }else{
          getMinimalUserInfoData(otherUserId)
          .then((userInfo: UserInfoCacheType) => {
            UserInfoCache.addNameInfo(otherUserId, { name: userInfo.name, phoneNumber: userInfo.phoneNumber, mail: userInfo.mail, gimmerScore: userInfo.gimmerScore, gimScore: userInfo.gimScore })
              setUserName(userInfo.name)
          }).catch(() => {})
      }
    

      getProfilePictureByUserId(otherUserId, "small")
      .then((imgUrl) => {
          setUserImg(imgUrl)
      }).catch(() => {})
    }, [])


    const onPress = () => {
        navigate(`/my-history/${history.id}`)
    }
    
    console.log("imgUserNotAvailable", imgUserNotAvailable)
    return (
        <div onClick={onPress} className="history-row-card flex mt-8 ml-10 pointer">
            <div className="history-item1 flex flex-ai-center fs-14 mt-4 c-manatee">
                <img className="img-history-row" src={`${getS3ImgUri(history.skill.imgKey)}_100`} alt="history" />
            </div>

            <div className="history-item2 flex flex-ai-center fs-14 mt-4">
                <p className="fs-14 fw-600">{history.skill.jobName}</p>
            </div>
            
            <div className="history-item3 flex flex-ai-center fs-14 mt-4">
                <p className="fs-14 fw-600">{history.schedule.startTime ? moment(history.schedule.startTime).format('MMM DD, YYYY') : "--"}</p>
            </div>
            
            <div className="history-item4 flex flex-ai-center fs-13 mt-4">
                {
                    (userImg && !imgUserNotAvailable) ? 
                    <img className="img-circular-row-history circle" src={userImg} onError={() => { console.log("userImg", userImg, "img", imgUserNotAvailable); setImgUserNotAvailable(true) }} />
                    : <div className="img-circular-row-history circle"></div>
                     
                }
                <p title={userName} className="fs-14 fw-500 ml-8 text-3-points">{userName}</p>
            </div>
            
            <div className="history-item5 flex flex-ai-center fs-14 mt-4">
                <WorkPreferenceIcon type={history.workPreference.type} size={2.8} />
            </div>
            
            <div className="history-item6 flex flex-ai-center fs-14 mt-4">
              <p className="fs-14 fw-600">{history.state === "CANCELLED" ? "--" : `$${history.costTransaction.toFixed(2)}`}</p>
            </div>
            
            <div className="history-item7 flex flex-ai-center fs-13 mt-4">
                <StateTransactionHistoryLabel state={history.state} type={history.type === "Job" ? "Gim" : "Gimmer"} />
            </div>
            
            <div className="history-item8 flex flex-ai-center fs-13 mt-4">
                <FontAwesomeIcon icon={regular("download")} className="color-text fs-18" />
            </div>

            <div className="history-item9 flex flex-ai-center fs-13 mt-4">
                <FontAwesomeIcon icon={solid("chevron-right")} className="color-silver fs-18"  />
            </div>
        </div>
    )
}

export default React.memo( HistoryRow )