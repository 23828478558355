import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaLaptop } from 'react-icons/fa'
import { WorkPreferenceStr } from '../../../utils/dbModels/HistoryTransaction'
import WhereMap from '../../ManageAllOffers/DetailedOfferScreen/WhereMaps/WhereMap/WhereMap'

type BlockWorkPreferenceProps = {
    type: WorkPreferenceStr
    longitude?: number
    latitude?: number
    address?: string
    radius?: number
}

const BlockWorkPreference = ({ type, latitude=0, longitude=0, address="", radius=0 }: BlockWorkPreferenceProps) => {
    const { t } = useTranslation();
  return (
    <div className="flex flex-d-column mt-30">

        <div className="flex flex-jc-sb flex-wrap width-100 mt-16">

                {
                    type === "inside" &&
                    <div className="width-100">
                        <div className="con-work-preference flex flex-ai-center p-10">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_024")}</p>
                        </div>
                        {
                            address &&
                            <div className="p-12 gallery fs-12">
                                <p className="address-text-offer">  { address } </p>
                            </div>
                        }
                        <WhereMap id="1" longitude={longitude} latitude={latitude} />
                    </div>
                }

                {
                    type === "outside" &&
                    <div className="width-100">
                        <div className="con-work-preference flex flex-ai-center p-10 ">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_025")}</p>
                        </div>

                        {
                            address &&
                            <div className="p-12 gallery fs-12">
                                <p className="address-text-offer fs-12"> 
                                    { radius &&  <span className="red-gimmer">{`${radius} ${t("General:label_027")}`}</span>  }
                                    { address }
                                </p>
                            </div>
                        }

                        <WhereMap id="2" longitude={longitude} radius={radius} latitude={latitude} />
                    </div>
                }
                {
                    type === "remote" &&
                    <div className="width-100">
                        <div className="con-work-preference flex flex-ai-center p-10">
                            <div className="where-icon-map center circle c-white fs-14"><FaLaptop /></div>
                            <p className="fw-500 fs-16 ml-8">{t("General:label_026")}</p>
                        </div>
                    </div>

                }
        </div>

    </div>
  )
}

export default React.memo( BlockWorkPreference )