import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FormCedula from "../SliderCertificate/FormCedula/FormCedula";
import useCheckAccess from "../../hooks/useCheckAccess/useCheckAccess";
import LoadingSpinner from "../../LoadingSpinner";
import { BodyCedula, checkCedulaRequest } from "../../../utils/requestsUtils/functionsRequests/user/userFunctions";
import { useAppDispatch } from "../../../redux/store/store";
import { setCedulawasValidatedVerifyCertificateReducer } from "../../../redux/slices/components/VerifyCertificate/VerifyCertificateDataSlice";

interface YourStatisticsCertificateProps {
    imagesRemaining: number
}

const YourStatisticsCertificate = ({ imagesRemaining }:YourStatisticsCertificateProps) => {
  
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const accessEdit = useCheckAccess({ id_Category: "Verify", id_Policy: "Certificate", id_Action: "Edit" })

    const [loading, setLoading] = useState(false)

    const onSubmitCedulavalidation = useCallback(
        async(body: BodyCedula) => {
            setLoading(true)
            try {
                const res = await checkCedulaRequest(body)
                console.log("res", res)
                dispatch( setCedulawasValidatedVerifyCertificateReducer("YES") )
            } catch (error) {
                console.log("Error onSubmitCedulavalidation: ", error)
                dispatch( setCedulawasValidatedVerifyCertificateReducer("NO") )
            }
            setLoading(false)
        },
        [],
    )

    return (
      <div className="flex flex-1 flex-d-column flex-ai-center">

          <p className="your-statistics-profile-title fw-500"> {t("General:label_058")}</p>
          <button className="remaining-profiles-imgs-btn flex flex-d-column flex-ai-center">
              <span className="fs-22 fw-600 mb-4">{ imagesRemaining }</span>
              <span className="fs-16">{t("General:label_059")}</span>
          </button>

          {
              accessEdit && imagesRemaining > 0 && 
              <div className="p-relative" style={{ minHeight: "32rem" }}>
                  <FormCedula onSubmit={onSubmitCedulavalidation}/>
                  { loading && <LoadingSpinner fullPage={false} /> }
              </div>
          }

      </div>
    )
}

export default React.memo(YourStatisticsCertificate)