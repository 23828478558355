import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../redux/store/store';
import { useParams } from 'react-router-dom';
import { GetHistoryTransactionThunk } from '../../../../../redux/slices/components/MyHistory/myHistoryDataSlice';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import HistoryTransaction from '../../../../../components/MyHistory/HistoryTransaction/HistoryTransaction';

const HistoryScreen = () => {

    const dispatch = useAppDispatch()

    const loadingIndividualPage = useSelector((state: RootState) => state.myHistoryData.loadingContainer)

    const { id } = useParams();

    useLayoutEffect(() => {

        if(id){
            dispatch( GetHistoryTransactionThunk({ id }) )
        }
    
        return () => {

        };
    }, [])

    return (
        <div className="flex flex-d-column height-100 p-relative">
            {
                loadingIndividualPage ?
                <LoadingSpinner fullPage={false} /> 
                :
                <HistoryTransaction />
            }
        </div>
    )
}

export default HistoryScreen