import React, { useState } from 'react'
import { Colors } from '../../../utils/colors/colors'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'

type SideBarSelector = "left" | "right"

type TwoSelectorBarProps = {

}

const TwoSelectorBarGimGimmer = ({  }: TwoSelectorBarProps) => {

    const navigate = useNavigate()

    const [sideSelected, setSideSelected] = useState<SideBarSelector>("left")

    const page = useSelector((state: RootState) => state.homeOffers.page)
    const limit = useSelector((state: RootState) => state.homeOffers.limit)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const type = useSelector((state: RootState) => state.homeOffers.type)

    const leftSideSelected = sideSelected === "left" 

    const onChange = (side: SideBarSelector) => {
        const searchParams = createSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            jobbId: jobId?.toString() ?? "",
            type: side === "left" ? "Gim" : "Gimmer"
        });
        setSideSelected(side)
        navigate({
            pathname: '/',
            search: searchParams.toString(),
        });
    }

    const clickBar = (newSide: SideBarSelector) => {
        onChange(newSide)
        setSideSelected(newSide)
    }


    return (
        <div className="flex flex-jc-end mt-30 ml-10" style={{ width: "20rem", height: "3vh" }}>
            <div 
                className={`width-50 gallery-bg fs-16-vh center ${leftSideSelected ? "c-white" : "pointer"}`}
                style={{
                    backgroundColor: leftSideSelected ? Colors.Gim : "white"
                }}
                onClick={() => {
                    if(!leftSideSelected)
                        clickBar("left")
                }}
            >
                Gim
            </div>

            <div 
                className={`width-50 gallery-bg fs-16-vh center ${!leftSideSelected ? "c-white" : "pointer"}`}
                style={{
                    backgroundColor: !leftSideSelected ? Colors.Gimmer : "white"
                }}
                onClick={() => {
                    if(leftSideSelected)
                        clickBar("right")
                }}
            >
                Gimmer
            </div>
        </div>
    )
}

export default React.memo( TwoSelectorBarGimGimmer )