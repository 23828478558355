import React, { ChangeEvent, useEffect, useState } from 'react'
import { ProductDB } from '../../../../../redux/slices/components/Products/ProductsSlice'
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../utils/verifications/Forms'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../../LoadingSpinner'
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle'
import UploadImageButton from '../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton'
import moment from 'moment'
import { useAppDispatch } from '../../../../../redux/store/store'
import { v4 as uuidv4 } from 'uuid';
import { FileProduct, MarketplaceProduct, setAddMarketplaceProductUserReducer } from '../../../../../redux/slices/authSlice'
import { CreateProductMarketplace, createProductMarketplace, getLinkToPutFileProduct } from '../../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions'
import axios from 'axios'
import { resetRequestProductsDBModalsReducer } from '../../../../../redux/slices/components/RequestProducts/requestProductsDataSlice'
import { setAddMarketplaceProductReducer } from '../../../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import { getProductDB } from '../../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'
import { getProductPrice } from '../../../../../utils/prices/prices'

type FormAddProductDBModalProps = {
    product: ProductDB
}

const FormAddProductDBModal = ({ product }: FormAddProductDBModalProps) => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)
    const [productFile, setProductFile] = useState<File>()
    const [fileProduct, setFileProduct] = useState<FileProduct>()
    const [productDB, setProductDB] = useState<ProductDB>()
    const [taxesDataProduct, setTaxesDataProduct] = useState({
        IVA_GIMMER:  0,
        IVA_GIMWORK: 0,
        ISR:         0,
        RMPC:        0
    })

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: product.productName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justValidCharacters()},
        description: { error: "", value: product.description, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
        price: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
    })

    useEffect(() => {

        const getData = () => {
            getProductDB(product.id)
            .then((product) => {
                setProductDB(product)
                setTaxesDataProduct({
                    IVA_GIMMER: product.IVA_GIMMER,
                    IVA_GIMWORK: product.IVA_GIMWORK,
                    ISR: product.ISR,
                    RMPC: product.RMPC
                })
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        getData()
    }, [])
    

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }

    const setFileUploaded = async() => {

        const fileFile = (document.getElementById("new-add-product-file") as HTMLInputElement)?.files
        if(fileFile?.length)
        {
            const file = fileFile[0]
            setProductFile(file)

            let entire_type = file.type.split("/")[1]
            let type = ""
            if(entire_type.includes("zip"))
                type = "zip"
            else if(entire_type.includes("pdf"))
                type = "pdf"

            const fileP: FileProduct = {
                name: file.name,
                size: file.size,
                type
            }

            setFileProduct(fileP)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    //setUrlPreviewFile(event.target?.result)
                }
            }
        }
    }

    const handleSubmit = async(e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput, inputValues))
                existsError = true
        }
        setInputValues({...inputValues})
        


        if(existsError)
            return

        try {
            setLoading(true)
            const newId = uuidv4()

            const body: CreateProductMarketplace = {
                id:             newId,
                description:    inputValues["description"].value,
                price:          Number(inputValues["price"].value),
                productId:      product.id,
                File:           fileProduct
            }

            await createProductMarketplace(body)

            if(product.typeProduct === "DIGITAL"){
                if(!fileProduct)
                    return

                const presignedLink = await getLinkToPutFileProduct(newId, fileProduct?.name)

                const options = { headers: { 'Content-Type': '*' } };
    
                await axios.put(presignedLink, productFile, options)
            }

            const newProduct: MarketplaceProduct = {
                ...body,
                date: new Date().toISOString(),
                jobId: product.jobId,
                productName: product.productName,
                typeProduct: product.typeProduct
            }

            dispatch( setAddMarketplaceProductReducer(newProduct) )
            dispatch( setAddMarketplaceProductUserReducer(newProduct) )
            dispatch( resetRequestProductsDBModalsReducer() )
        } catch (error) {
            console.log("error", error)
            alert("Error submitting product")
        }finally{
            setLoading(false)
        }
    }


    const { product_GimmerIVA, product_GimmePaid } = getProductPrice({
        IVA_GIMMER: taxesDataProduct.IVA_GIMMER,
        IVA_GIMWORK: taxesDataProduct.IVA_GIMWORK,
        ISR:    taxesDataProduct.ISR,
        RMPC: taxesDataProduct.RMPC,
        timeTransaction_ISR: 1,
        price: Number(inputValues["price"].value)
    })
    return (
        <div className="flex flex-d-column flex-1">

            <p className="fs-28-vh fw-600 mb-16">{t("ManageProducts:modals:label_02")}</p>

            <GeneralInputTitle 
                title={t("ManageProducts:modals:label_03")}
                name={"productName"}
                value={inputValues["productName"].value}
                onChange={onChangeValueInput}
                onFocus={onChangeFocusInput}
                placeholder={t("ManageProducts:modals:label_04")}
                error={inputValues["productName"].error}
                width="100%"
                readOnly={true}
            />

            <GeneralInputTitle 
                title={t("ManageProducts:modals:label_05")}
                name={"description"}
                value={inputValues["description"].value}
                onChange={onChangeValueInput}
                onFocus={onChangeFocusInput}
                placeholder={t("ManageProducts:modals:label_06")}
                error={inputValues["description"].error}
                width="100%"
                textArea={{ height: "7vh" }}
            />

            <GeneralInputTitle 
                title={t("ManageProducts:modals:label_09")}
                name={"price"}
                value={inputValues["price"].value}
                onChange={onChangeValueInput}
                onFocus={onChangeFocusInput}
                placeholder={""}
                error={inputValues["price"].error}
                width="100%"
                type="number"
            />


            <div className="mt-10">
                {
                    product.typeProduct === "PHYSICAL"
                    ? <></>
                    : 
                    <div className="flex flex-ai-center flex-jc-sb">
                        <p className="fs-18-vh color-text">{t("ManageProducts:modals:label_11")}</p>

                        <input 
                            id="new-add-product-file"  
                            className="display-none" 
                            type="file" 
                            accept={product.fileType.map(file => `application/${file}`).join(",")}
                            onChange={setFileUploaded}
                        />
                        
                        <UploadImageButton 
                            idInputFile="new-add-product-file"  
                            text={t("ManageProducts:modals:label_10")}
                            iconName="file-arrow-up" 
                            style={{ fontSize: "1.48vh", height: "3.8vh" }}
                        />

                        
                    </div>
                }
                <div className="flex flex-jc-end">
                    <p className="fs-14-vh">{fileProduct?.name}</p>
                </div>

                <div className="flex flex-jc-sb flex-ai-center mt-10">
                    <p className="fs-16-vh fw-300 color-text">{t("ManageProducts:modals:label_12")}</p>
                    <p className="fs-16-vh fw-600 color-text">{moment(product.updatedAt).format('DD/MM/YYYY')}</p>
                </div>

                {
                    product.typeProduct === "DIGITAL" &&
                    <div className="flex flex-jc-sb flex-ai-center mt-10">
                        <p className="fs-16-vh fw-300 color-text">{t("ManageProducts:modals:label_13")}</p>
                        {
                            fileProduct?.type &&
                            <img 
                                id="file"
                                src={`/assets/svg/${fileProduct?.type}.svg`} 
                                alt="file" 
                                style={{ height: "2vh" }}
                            />
                        }
                    </div>
                }
            </div>

            <div className="flex-1 center flex-d-column">
                <div className="flex flex-jc-sb flex-ai-center mt-10 width-100">
                    <p className="fs-18-vh fw-500 color-text">{t("ManageProducts:modals:label_14")}</p>
                    <p className="fs-16-vh fw-500 color-text">{`${product_GimmerIVA} MXN$`}</p>
                </div>

                <div className="flex flex-jc-sb flex-ai-center mt-12 width-100">
                    <p className="fs-18-vh fw-500 color-text">{t("ManageProducts:modals:label_15")}</p>
                    <p className="fs-16-vh fw-500 color-text">{`${product_GimmePaid} MXN$`}</p>
                </div>
            </div>
            
            {
                productDB &&
                <div className="pt-20">
                    <ConfirmButton 
                        text={t("ManageProducts:modals:label_16")} 
                        width="100%"
                        onPress={handleSubmit}
                        disabled={!inputValues["productName"].value || !inputValues["description"].value || !Number(inputValues["price"].value) || (product.typeProduct === "DIGITAL" && !productFile?.name)}
                    />
                </div>
            }

            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default React.memo( FormAddProductDBModal )