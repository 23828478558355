import React from 'react'
import { useLocation } from "react-router-dom"
import { ISideBarOption } from '../../../utils/sidebarOptions/sidebarOptions'
import SideBarOption from '../SideBarOption/SideBarOption'

interface ISideBarOptionsList {
    sideBarOptionsList: ISideBarOption[]
}

const SideBarOptionsList = ({ sideBarOptionsList }: ISideBarOptionsList) => {
    const location = useLocation();
    // Remove / from url
    const pathUrlSideBar = location.pathname.slice(1, location.pathname.length);
    return (
        <>
            {
                sideBarOptionsList.map((option: ISideBarOption, index: number) => {
                    const props = {
                        name: option.name, 
                        icon: option.icon,
                        path: option.path, 
                        isSelected: false, 
                        numberNotifications: 0,
                        color: option.color   
                    }
                    if(pathUrlSideBar === option.path)
                        props.isSelected = true;
                        
                    return (
                        <SideBarOption key={index} {...props} />
                    )
                })
            }
        </>
    )
}

export default React.memo( SideBarOptionsList )