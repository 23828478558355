import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getCreationDate, getWhenDate } from '../../../../utils/dates/dates'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { GimGimmer, getOffer, getOffers, getOffersByUsers, getOffersPublic } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import { Offer, WorkPreference } from '../ManageOffers/manageOffersDataSlice'


type ExtraDataOffer = {
    type: GimGimmer,
    limit: number,
    page: number
    jobId?:         string
    where?:         WorkPreference,
    when?:           { startTime: string, endTime: string }
}


const addRemainingFieldsOffer = (offer: Offer) => {
    offer.seen = offer.seen ?? false
    offer.timestampMilliseconds = new Date(offer.timestamp).getTime()
    offer.type = offer.estimatedTime ? "Job" : "Task"
}

const changeNameToUppercase = (offer: Offer) => {
    //@ts-expect-error
    offer.detailsSchedule = offer.detailsschedule
    //@ts-expect-error
    delete offer["detailsschedule"]

    //@ts-expect-error
    offer.workPreference = offer.workpreference
    //@ts-expect-error
    delete offer["workpreference"]

    //@ts-expect-error
    offer.jobName = offer.jobname
    //@ts-expect-error
    delete offer["jobname"]

    //@ts-expect-error
    offer.jobId = offer.jobid
    //@ts-expect-error
    delete offer["jobid"]

    //@ts-expect-error
    offer.userId = offer.userid
    //@ts-expect-error
    delete offer["userid"]
    
}


//const columns = "id,categoryName,detailsSchedule,userId,offerType,jobName,jobId,timestamp,workPreference"
const columns = "id"

export const GetOffersHomeThunk = createAsyncThunk(
    'manageOffersData/GetOffersThunk',
    async (props: ExtraDataOffer) => {
        try { 
            const { type, page, limit } = props

            const params = {
                offerType: type, 
                limit, 
                page,
                columns
            }
            console.log("params", params)
            const res = await getOffersPublic(params)
            /*
            res.results = res.results.map((offer: Offer) => {
                changeNameToUppercase(offer)
                addRemainingFieldsOffer(offer)
                return offer
            })*/
            return {
                ...props,
                results: res.results as Offer[],
                total: res.total
            }
        } catch (error) {
            console.log("error", error)
            return {
                ...props,
                results: [] as Offer[],
                total: 0
            }
        }
    }
)


export const numberOffersByPage: number[] = [ 10, 25, 50, 100 ]

type InitialState = {
    total:          number
    limit:          number
    page:           number
    offersToShow:   Offer[]
    type:           GimGimmer
    jobId?:         string
    where?:         WorkPreference,
    when?:          { startTime: string, endTime: string }
    loadingOffers:  boolean
}


const initialState: InitialState = {
    total: 0,
    limit: numberOffersByPage[0],
    page: 1,
    offersToShow: [],
    type: "Gim",
    loadingOffers: true
}

export const homeOffersSlice = createSlice({
    name: 'homeOffers',
    initialState,
    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(GetOffersHomeThunk.fulfilled, (state, action: PayloadAction<{ results: Offer[], total: number } & ExtraDataOffer>) => {
            const { results, type, page, limit } = action.payload  


            state.offersToShow = results
            state.type = type
            state.loadingOffers = false
            state.page = page
            state.limit = limit ?? 0

            return state
        }).addCase(GetOffersHomeThunk.pending, (state, action: any) => {
            state.loadingOffers = true
            return state     
        }).addCase(GetOffersHomeThunk.rejected, (state, action: any) => {
            const failState = {...initialState}
            failState.loadingOffers = false
            return failState
        })
    },

});

// Action creators are generated for each case reducer function
export const {  

} = homeOffersSlice.actions

export default homeOffersSlice.reducer