import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { Colors } from '../../../../../utils/colors/colors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ListSearchSkills from '../ListSearchSkills/ListSearchSkills'
import { useTranslation } from 'react-i18next'
import "./SearchBarSkills.css"

const SearchBarSkills = () => {

    const { t } = useTranslation()
    const [textSearchBar, setTextSearchBar] = useState("")
    const [showList, setShowList] = useState(false)

    const onCloseModal = useCallback(
        () => {
            setShowList(false)
        },
        [],
    )
    

    return (
        <div className="p-relative aux-con-search-bar-skill" style={{ zIndex: 3 }}>
            <div className={`p-absolute con-search-bar-skill ${showList ? "big-search-bar" : "small-search-bar"}`}>
                <div    
                    style={{ 
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid", 
                        borderBottomColor: Colors.TEXT,
                    }} 
                    className="flex flex-ai-center input-search-bar"
                >
                    <FontAwesomeIcon icon={solid("magnifying-glass")} className="color-text fs-15-vh pr-10 pl-10" />
                    <input 
                        id="input-search-bar-skills"
                        className="outline-none fs-18-vh"
                        placeholder={t("SignUp:selectSkillScreen:label_06") ?? ""}
                        value={textSearchBar}
                        onChange={(e) => {
                            setTextSearchBar(e.target.value)
                        }}
                        onFocus={() => { setShowList(true) }}
                    />
                </div>

                {
                    showList &&
                    <div className="aux-scroll list-skills-bar pt-6">
                        <ListSearchSkills 
                            textSearchBar={textSearchBar} 
                            onCloseModal={onCloseModal}
                        />
                    </div>
                }
                </div>

        </div>
    )
}

export default React.memo( SearchBarSkills )