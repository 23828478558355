import { useRef, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GetUserByIdThunk, GetUsersByStringThunk, GetUsersThunk } from '../../../redux/slices/components/ManageUsers/manageUsersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { isUUID } from '../../../utils/verifications/uuid/uuid'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'

const HeaderManageUsers = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const resetSearch = useRef<any>()

    const limit =useSelector((state:RootState) => state.manageUsersData.limit)

    const submitSearchBar = (searchString: string) => {
        if(isUUID(searchString)){
            //dispatch(GetUserByIdThunk({ id: searchString }))
        }else{
            dispatch(GetUsersByStringThunk({ page: 1, limit,  bringTotal: true, stringToSearch: searchString }))
        }
    }
  
    const searchSubmit = useCallback(
      (searchString: string) => {
        submitSearchBar(searchString)
    },[],)

    const resetSearchBar = useCallback(
      () => {
        dispatch(GetUsersThunk({ page: 1, limit, bringTotal: true}))
    },[],)

    return (
      <div className="flex flex-ai-center con-header-offers">
          <p className="title-screen">{t("General:label_132")}</p>
          <SearcherBar style={{ width: "28rem" }} 
              ref={resetSearch} 
              searchSubmit={searchSubmit} 
              placeholder={t("General:label_133")} 
              width="31rem"
              addReset={true}
              resetSearchBar={resetSearchBar}
          />
      </div>
    )
}

export default HeaderManageUsers