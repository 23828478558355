import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

type BackButtonProps = {
    goBack: () => void
}

const BackButton = ({ goBack }: BackButtonProps) => {

    const { t } = useTranslation()

    return (
        <button onClick={goBack} className="back-offer-detailed fs-14 center pointer mt-4 mb-4">
            <FontAwesomeIcon icon={solid("arrow-left")}/>
            <span className="fw-500 ml-4 fs-13 mt-2">{t("General:label_009")}</span>
        </button>
    )
}

export default BackButton