import React from 'react';
import LoadingSpinner from '../../../../../LoadingSpinner';

interface ImageNotificationProps {
	urlImage: string;
	loadingImage: boolean;
}

export const ImageNotification = React.memo(
	({ urlImage, loadingImage }: ImageNotificationProps) => {
		return (
			<div
				style={{
					height: "",
					width: "",
					backgroundColor: 'white',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{loadingImage || urlImage === '' ? (
					<LoadingSpinner fullPage={false} />
				) : (
					<img
						alt="la"
						src={ urlImage }
						style={{
							height: "5.28vh",
							width: "5.28vh",
							borderRadius: 400,
						}}
					/>
				)}
			</div>
		);
	},
);
