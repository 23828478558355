import React from 'react'
import "./HeaderTimerChat.css"

const HeaderTimerChat = () => {
    return (
        <div className="">
            <div className="header-timer-chat width-100 gallery-bg flex flex-jc-sb flex-ai-center">
                <p className="fs-22 fw-600">{"Lawyer"}</p>
                <p className="fs-19 fw-600">{"01:48:05"}</p>
            </div>
            <div className="time-bar-chat width-100">
                <div className="time-bar-chat-progress dark-gim-bg">

                </div>
            </div>
        </div>
    )
}

export default React.memo( HeaderTimerChat )