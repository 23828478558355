import React from 'react'
import "./RowPolicyRole.css"
import MyCheckbox from '../../../../../UIcomponents/MyCheckbox/MyCheckbox'
import { Id_Action, Data_Policy, updateRoleReducerChunk } from '../../../../../../redux/slices/components/ManageRoles/manageRolesSlice'
import { useAppDispatch } from '../../../../../../redux/store/store'


type RowPolicyRoleProps = {
    id_Category: string
    policy: Data_Policy
    actionsAllowed: Array<Id_Action>
    enableEdit: boolean
}

const RowPolicyRole = React.memo(({
    id_Category,
    policy,
    actionsAllowed,
    enableEdit
}: RowPolicyRoleProps) => {

    const dispatch = useAppDispatch()
    

    //console.log("RowPolicyRole")
    return (
        <div className="flex flex-ai-center flex-jc-sb row-policy-role pl-18 pr-18">
            <p className="fs-14">
                {policy.id_Policy}
            </p>
            <div className="flex width-actions">
                {
                actionsAllowed.map((action: Id_Action) => {
                    const actionData = policy.actions.find(a => action === a.action)
                    if(!actionData){
                        return(
                            <div key={action} className="center width-25">
                            </div>
                        )
                    }
                    const onChange = (value: boolean) => {
                        if(enableEdit){
                            dispatch( updateRoleReducerChunk({
                                id_Category,
                                id_Policy: policy.id_Policy,
                                id_Action: actionData.action,
                                checked: !actionData.selected
                            }) )
                        }
                    }
                    
                    return(
                        <div key={actionData.action} className="center width-25">
                            <MyCheckbox 
                                value={actionData.selected}
                                onChange={onChange}
                                size={"1.4rem"}
                            />       
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
})

export default RowPolicyRole