

export const canBeInteger = (str: string) => {
    // Remove leading/trailing whitespace (optional)
    str = str.trim();
  
    // Try parsing the string to an integer
    const parsedInt = parseInt(str, 10);
  
    // Check if parsing was successful and didn't result in NaN
    return !isNaN(parsedInt) && parsedInt.toString() === str;
}


export const getHierarchyNumberOfSupport = (roleId: string) => {
    if(roleId === "Support_1") return 1
    else if(roleId === "Support_2") return 2
    else if(roleId === "Support_3") return 3
    else if(roleId === "Support_Admin") return 4
    else if(roleId === "Super_Admin") return 4
    else return -1
}

export const AdminRole = "Super_Admin"