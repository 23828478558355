import { APIProvider, AdvancedMarker, Map, MapMouseEvent, useAdvancedMarkerRef,  } from "@vis.gl/react-google-maps";
import { useState } from "react";
import PlaceAutocomplete from "../PlaceAutocomplete/PlaceAutocomplete";
import MapHandler from "../MapHandler/MapHandler";
import { Circle } from "../Geometry/Circle/Circle";
import { Position, getLatLngBounds } from "../../../../utils/maps/locations";
import { Colors } from "../../../../utils/colors/colors";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""

type MapViewProps = {
    initialLongitude: number
    initialLatitude: number
    radius: number
    showRadius: boolean
    onChangePositionMap: (newPosition: Position, radius?: number) => void
}

const maxZoom = 14
const minZoom = 10
const maxRadius = 30
const minRadius = 0

const MapView = ({ initialLongitude, initialLatitude, radius, showRadius, onChangePositionMap }: MapViewProps) => {

    /*
                    latitude: placeAutocomplete.getPlace().geometry?.location?.lat() ?? 0,
                longitude: placeAutocomplete.getPlace().geometry?.location?.lng()?? 0,
                address: placeAutocomplete.getPlace().formatted_address ?? ""
    */
    
    //const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);

    const [markerRef, marker] = useAdvancedMarkerRef();

    const [mapAlignment, setMapAlignment] = useState<{ 
        bounds: google.maps.LatLngBounds | null,
        location: google.maps.LatLng | null  
        zoom: number | null,
        longitude: number | null,
        latitude: number | null
    }>({ bounds: null, location: null, zoom: null, longitude: null, latitude: null });

    const currentLongitude =  mapAlignment.longitude ?? initialLongitude
    const currentLatitude = mapAlignment.latitude ?? initialLatitude



    const getProportionalZoom = () => {
        const rangeZoom = maxZoom - minZoom + 1
        const rangeRadius = maxRadius - minRadius

        const zoom = showRadius ? Math.round( (radius / rangeRadius) * (rangeZoom) + minZoom ) : 14
        return zoom
    }

    

    const onClickMap = async(event: MapMouseEvent) => {
        //console.log("event", event.map.getBounds())
        const latitude = event.detail.latLng?.lat ?? null
        const longitude = event.detail.latLng?.lng ?? null
        if(showRadius){
            onChangePositionMap({
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
                address: ""
            }, radius)
        }else{
            onChangePositionMap({
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
                address: ""
            }) 
        }

        const bounds = event.map.getBounds()
        const location = event.map.getCenter()
        const zoom = event.map.getZoom()
        const newBounds = getLatLngBounds(latitude, longitude)
        if(bounds && location && zoom){
            setMapAlignment({ bounds: newBounds, location: null, zoom, latitude, longitude })
        }

    }

    const setNewAutocompleteBarPlace = (selectedPlace: google.maps.places.PlaceResult | null) => {
        if(selectedPlace){

            const latitude = selectedPlace.geometry?.location?.lat() ?? null
            const longitude = selectedPlace.geometry?.location?.lng() ?? null

            if(showRadius){
                onChangePositionMap({
                    latitude: latitude ?? 0,
                    longitude: longitude ?? 0,
                    address: selectedPlace.formatted_address ?? ""
                }, radius)
            }
            else{
                onChangePositionMap({
                    latitude: latitude ?? 0,
                    longitude: longitude ?? 0,
                    address: selectedPlace.formatted_address ?? ""
                })
            }
                
            const bounds = selectedPlace.geometry?.viewport
            const location = selectedPlace.geometry?.location
            if(bounds && location){
                setMapAlignment({ bounds, location, zoom: 12, latitude, longitude })
            }
        }
    }

    return (
        <APIProvider
            apiKey={API_KEY}
            solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'
        >
            <Map
                mapId={'bf51a910020fa25a'}
                defaultZoom={getProportionalZoom()}
                defaultCenter={{ lat: initialLatitude, lng: initialLongitude }}
                //center={{ lat: latitude, lng: longitude }}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                onClick={onClickMap}
            >
                <AdvancedMarker ref={markerRef} position={{ lat: currentLatitude, lng: currentLongitude }} />
                {
                    showRadius &&
                    <Circle
                        radius={radius * 1000}
                        center={{ lat: currentLatitude, lng: currentLongitude }}
                        //onRadiusChanged={onChangeRadius}
                        //onCenterChanged={(e: any) => { console.log("onCenterChanged", e) }}
                        strokeColor={Colors.Gim}
                        strokeOpacity={1}
                        strokeWeight={3}
                        fillColor={Colors.Gim}
                        fillOpacity={0.3}
                        clickable={false}
                        //editable
                        //draggable
                    />
                }
            </Map>
            <div className="p-absolute" style={{ top: "-1.8vh", left: "-1.4rem" }}>
                <div className="autocomplete-control">
                    <PlaceAutocomplete onPlaceSelect={setNewAutocompleteBarPlace} />
                </div>
            </div>


            {
                <MapHandler mapAlignment={mapAlignment} marker={marker} />
            }
        </APIProvider>
    );
};

export default MapView