import React, { useEffect, useState } from 'react'
import { getMinimalUserInfoData, UserInfoCache, UserInfoCacheType } from '../../../utils/staticVariables/staticVariables'
import { getProfilePictureByUserId } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'

const useGetUserInfo = (userId: string) => {

    const [name, setName] = useState("")
    const [imgUrl, setImgUrl] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      
        
        const user = UserInfoCache.userNameCache.get(userId)
  
        if(user?.name){
            setName(user.name)
          }else{
            getMinimalUserInfoData(userId)
            .then((userInfo: UserInfoCacheType) => {
                UserInfoCache.addNameInfo(userId, { name: userInfo.name, phoneNumber: userInfo.phoneNumber, mail: userInfo.mail, gimmerScore: userInfo.gimmerScore, gimScore: userInfo.gimScore })
                setName(userInfo.name)
            }).catch(() => {})
        }
      
  
        getProfilePictureByUserId(userId, "small")
        .then((imgUrl) => {
            setImgUrl(imgUrl)
        }).catch(() => {})
      }, [])

    return {
        //loading,
        imgUrl,
        name
    }
}

export default useGetUserInfo