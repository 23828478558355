import React, { useCallback, useContext, useEffect, useState } from 'react'
import ArrowGoBackSignUp from '../../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import { NavigationType, UNSAFE_NavigationContext, useNavigate, useNavigationType } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import NextButton from '../../../../components/UIcomponents/NextButton/NextButton'
import { useSelector } from 'react-redux'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'
import { addJobSignUpReducer, editJobSignUpReducer, resetSignUpGoBack, setNextSelectWhereWorkPreference, setRemoteWorkPreference, setSelectInsideWorkPreference, setSelectOutsideWorkPreference } from '../../../../redux/slices/components/SignUp/SignUpSlice'
import SelectSkillLocation from '../../../../components/UIcomponents/SelectSkillLocation/SelectSkillLocation'
import { addSkillDB, deleteSkillDB, updateSkillDB } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { addJobUserReducer, deleteJobUserReducer, editJobUserReducer } from '../../../../redux/slices/authSlice'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const SelectLocationScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const job = useSelector((state: RootState) => state.signUp.currentJob) ?? null
    const selectedWorkPreference = useSelector((state: RootState) => state.signUp.selectedWorkPreference)
    const userJobs = useSelector((state: RootState) => state.auth.user.jobs)
    //const jobsAdded = useSelector((state: RootState) => state.signUp.jobsAdded)
    //console.log(" ")
    //console.log("userJobs select", userJobs)
    //console.log("jobsAdded select", jobsAdded)

    const [loading, setloading] = useState(false)
    console.log("selectedWorkPreference", selectedWorkPreference)
    const onNext = () => {

        setloading( true )
        if(job){
            const objectDB = { ...job }
            //@ts-expect-error
            delete objectDB.categoryId
            if( userJobs.find((j) => j.jobId === job?.jobId) ){
                updateSkillDB(objectDB)
                .then(() => {
                    console.log("then 1")
                    dispatch( editJobSignUpReducer(job) )
                    //dispatch( editJobUserReducer(objectDB) )
                    navigate("/gim-skill/add-pictures")
                }).catch((error) => {
                    console.log("error update", error)
                }).finally(() => {
                    setloading(false)
                })
            }else{
                addSkillDB(objectDB)
                .then(() => {
                    console.log("then 2")
                    dispatch( addJobSignUpReducer(job) )
                    dispatch( addJobUserReducer(job) )
                    //dispatch( setNextSelectWhereWorkPreference(job?.jobId) )
                    navigate("/gim-skill/add-pictures")
                }).catch((error) => {
                    console.log("error add", error)
                }).finally(() => {
                    setloading(false)
                })
            }
    
        }

    }

    const goBack = () => {
        if(job){
            deleteSkillDB(job?.jobId)
            .then(() => {
                dispatch( deleteJobUserReducer(job?.jobId) )
                dispatch( resetSignUpGoBack(job?.jobId) )
            }).catch(() => {

            })
        }   
        navigate(-1)
    }

    const handleWorkPreference = useCallback(
        (workPreferenceStr: WorkPreferenceStr) => {

            if(!job)
                return 

            if(workPreferenceStr === "remote"){
                dispatch( setRemoteWorkPreference(!job.workPreference.remote) )
            }else if(workPreferenceStr === "inside"){
                dispatch( setSelectInsideWorkPreference( !selectedWorkPreference.inside ) )
            }else {
                dispatch( setSelectOutsideWorkPreference( !selectedWorkPreference.outside ) )
            }
        },
        [job?.workPreference, selectedWorkPreference.inside, selectedWorkPreference.outside],
    )

    useEffect(() => {
      
        if(!job)
            navigate("/gim-skill/select-skill")
    }, [])
    
    
    


    if(!job){
        return <></>
    } 
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ paddingTop: "6vh" }}>

            <SelectSkillLocation 
                handleWorkPreference={handleWorkPreference}
                jobId={job.jobId}
                isCertifReq={job.isCertifReq}
                workPreference={job.workPreference}  
                remoteSelected={job.workPreference.remote}   
                insideSelected={selectedWorkPreference.inside}
                outsideSelected={selectedWorkPreference.outside}           
            />

            <div className="flex flex-jc-sb" style={{ marginBottom: "10.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:selectWhereScreen:label_01")} 
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default SelectLocationScreen