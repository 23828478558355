import React, { useState } from 'react'
import ButtonBack from '../../../components/ManageAllOffers/DetailedOfferScreen/ButtonBack/ButtonBack'
import { useTranslation } from 'react-i18next'
import InputList from '../../../components/UIcomponents/InputList/InputList'
import "./ReportBugScreen.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import ButtonSelectFileBug from '../../../components/Help/ReportBug/ButtonSelectFileBug/ButtonSelectFileBug'
import MyCheckboxCircular from '../../../components/UIcomponents/MyCheckboxCircular/MyCheckboxCircular'
import MyCheckbox from '../../../components/UIcomponents/MyCheckbox/MyCheckbox'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import { FilePutLink, getFilesAppBugReportPutLinks } from '../../../utils/requestsUtils/functionsRequests/reports/files/filesFunctions'
import axios from 'axios'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { createBugReport, CreateReportBugBody } from '../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { useNavigate } from 'react-router-dom'

const idInputImage = "report-bug-image"
const idInputVideo = "report-bug-video"

type FileBug = "Video" | "Image"

const ReportBugScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const userId = useSelector((state: RootState) => state.auth.user.id)

    const [reasonSelected, setReasonSelected] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [checkBoxCircularSelected, setCheckBoxCircularSelected] = useState<"No" | "Yes">("No")
    const [checkbox, setCheckbox] = useState(false)
    const [files, setFiles] = useState<{ file: File, localUrl: string, type: FileBug }[]>([])
    const [loading, setLoading] = useState(false)
    const [items] = useState([
        { name: t("Help:reportBug:label_01") },
        { name: t("Help:reportBug:label_02") },
        { name: t("Help:reportBug:label_03") },
        { name: t("Help:reportBug:label_04") },
        { name: t("Help:reportBug:label_05") },
    ])

    const allObligatoryFieldsReady = (reasonSelected && description && title)


    const onPress = (reason: string) => {
        setReasonSelected(reason)
    }

    const selectVideo = () => {
        document.getElementById(idInputVideo)?.click()
    }

    const selectImage = () => {
        document.getElementById(idInputImage)?.click()
    }

    const handleCircularCheckbox = (value: string) => {
        setCheckBoxCircularSelected(value as "No" | "Yes")
    }

    const setImageUploaded = async() => {

        const fileImage = (document.getElementById(idInputImage) as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]

            //setImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setFiles([...files, { file, localUrl: event.target?.result, type: "Image" }])
                    //setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }



    const setVideoUploaded = async() => {

        const fileImage = (document.getElementById(idInputVideo) as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]
            //setImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setFiles([...files, { file, localUrl: event.target?.result, type: "Video" }])
                    //setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
    

    const removeFile = (indexFile: number) => {
        const newFiles = files.filter((file, index) => index !== indexFile) 
        setFiles(newFiles)
    }

    const getFileData = (file: File) => {
        const ContentType = file.type
        const sufix = file.type.split("/")[1]
        const fileName = Math.random().toString().replace(/\./, "0")

        return {
            ContentType,
            sufix,
            fileName,
        }
    }

    const handleSubmit = async() => {

        setLoading(true)
        try {

            const idBug =  uuidv4()

            const data: FilePutLink[] = files.map((file) => ({ ...getFileData(file.file), idBug } ))
            const promises = data.map((data) => getFilesAppBugReportPutLinks(data))

            const links = await Promise.all(promises)
/*

            const link = links[0].split("?")[0]
            console.log("link", link)

            await axios.put(link, files[0], { headers: { 'Content-Type': "image/jpeg, image/png"} } )
*/
            //console.log("links", links)

            const promisesFilesToUpload = data.map((data, index) => axios.put(
                links[index], 
                files[index].file,
                { headers: { 'Content-Type': data.ContentType } }
            ))

            await Promise.all(promisesFilesToUpload)

            const body: CreateReportBugBody = {
				id: idBug,
				requestBy: userId,
				subject: reasonSelected,
				status: "OPENED",
				priority: "MEDIUM",
				title,
				description,
				seen: false,
            }

            await createBugReport(body)

            alert("Report Create succesfully")
            navigate(-1)

        } catch (error) {
            console.log("error: m", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="width-100 height-100 center p-relative">

            <div className="aux-scroll center">

                <div className="container-help height-100">
                    
                    <p className="fs-28-vh fw-500 color-text">{t("Help:reportBug:label_06")}</p>

                    <div className="flex flex-ai-center mt-10">
                        <ButtonBack />
                        <div className="line-gray-help"></div>
                    </div>

                    <p className="mt-14 mb-26 color-text fs-16-vh">{t("Help:reportBug:label_07")}</p>

                    <InputList text={reasonSelected} height="4.6vh" width="100%" >
                        {
                            items.map((item) => {
                                return(
                                    <button key={item.name} onClick={() => onPress(item.name)} className="width-100 flex flex-ai-center pointer" style={{ height: "4.6vh" }}>
                                        <p className="fs-16-vh ml-14 color-text">{item.name}</p>
                                    </button>
                                )
                            })
                        }
                    </InputList>


                    <div className="mt-26 p-relative">
                        <textarea
                            placeholder={t("Help:reportBug:label_08") ?? ""}
                            className="resize-none description-bug-input border-radius-6 width-100 fs-18-vh center hide-scroll outline-none color-text" 
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                        <p className="fs-16-vh p-absolute pl-6 pr-6 white-bg txt-description-bug-input">
                            {t("Help:reportBug:label_09")}<span className="color-error">*</span>
                        </p>
                    </div>
                    

                    <div className="mt-30 p-relative">
                        <textarea
                            placeholder={t("Help:reportBug:label_10") ?? ""} 
                            className="resize-none description-bug-input border-radius-6 width-100 fs-18-vh center hide-scroll outline-none color-text" 
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            style={{ height: "9.5vh" }}
                        />
                        <p className="fs-16-vh p-absolute pl-6 pr-6 white-bg txt-description-bug-input">
                            {t("Help:reportBug:label_11")}<span className="color-error">*</span>
                        </p>
                    </div>

                    <div className="mt-40 width-100 gallery-bg flex flex-ai-center border-radius-3 attachments-labels">
                        <FontAwesomeIcon icon={faPaperclip} className="fs-20-vh color-text ml-24" />
                        <p className="fs-18-vh color-text ml-30">{t("Help:reportBug:label_12")}</p>
                    </div>

                    <div className="flex flex-wrap">
                        {
                            files.map((file, index) => {
                                return(
                                    <div key={index} className="file-bug-square border-radius-10 mr-40 mt-16 p-relative">
                                        {
                                            file.type === "Image"
                                            ?   <img className="width-100 height-100" src={file.localUrl} alt={"img"} />
                                            :   <video className="width-100 height-100" src={file.localUrl} /> 
                                        }
                                        <button
                                            onClick={() => removeFile(index)}
                                            className="p-absolute remove-file-btn pointer"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="color-text fs-28-vh" />
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <input 
                        id={idInputImage}  
                        className="display-none" 
                        type="file" 
                        accept="image/jpeg, image/png"
                        onChange={setImageUploaded}
                    />

                    <input 
                        id={idInputVideo}  
                        className="display-none" 
                        type="file" 
                        accept="video/mp4, video/mov"
                        onChange={setVideoUploaded}
                    />


                    <div className="line-gray-help mt-20"></div>

                    <ButtonSelectFileBug type="Video" onPress={selectVideo} />

                    <div className="line-gray-help"></div>

                    <ButtonSelectFileBug type="Image" onPress={selectImage} />

                    <div className="line-gray-help"></div>


                    <div className="flex center mt-40">
                        <button
                            onClick={handleSubmit}
                            disabled={!allObligatoryFieldsReady}
                            className={`border-radius-3 pl-20 pr-20 ${allObligatoryFieldsReady ? "dark-gray-bg pointer" : "disable-btn"}`}
                            style={{ height: "4.6vh" }}
                        >
                            <p className="fs-20-vh c-white">{t("Help:reportBug:label_13")}</p>
                        </button>
                    </div>


                    {
                        /*
                    <p className="fs-14-vh color-persian-red mt-26">{"Do you also want to close the current work session ?"}</p>

                    <div className="flex mt-10">
                        <div className="flex flex-ai-center">
                            <MyCheckboxCircular 
                                value={"No"}
                                valueCheckBox={ checkBoxCircularSelected === "No"? true : false }
                                onChange={handleCircularCheckbox}
                                size={"2.2vh"}
                                sizeInner={"1.3vh"}
                                backgroundColor={"#CE2828"}
                                borderThickness="1px"
                            />
                            <p className="fs-14-vh color-text ml-6">{"No"}</p>
                        </div>

                        <div className="flex flex-ai-center ml-40">
                            <p className="fs-14-vh color-text ml-70 mr-6">{"Yes"}</p>
                            <MyCheckboxCircular 
                                value={"Yes"}
                                valueCheckBox={ checkBoxCircularSelected === "Yes"? true : false }
                                onChange={handleCircularCheckbox}
                                size={"2.2vh"}
                                sizeInner={"1.3vh"}
                                backgroundColor={"#CE2828"}
                                borderThickness="1px"
                            />
                        </div>
                    </div>

                    <div className="flex flex-ai-center mt-30">
                        <MyCheckbox 
                            onChange={() => setCheckbox(!checkbox)}
                            value={checkbox}
                            size="1.6vh"
                            iconSize="1.2vh"
                            noSelectedBackgroundColor="#F3F3F3"
                            borderColor="#00000080"
                            borderThickness="1px"
                            borderRadius="3px"
                        />
                        <p className="fs-14-vh c-black ml-10">{"You authorize us to ask you for more information or to send you information by e-mail"}</p>
                    </div>

                    <div className="flex center mt-40">
                        <button
                            onClick={handleSubmit}
                            disabled={!allObligatoryFieldsReady}
                            className={`border-radius-3 pl-20 pr-20 ${allObligatoryFieldsReady ? "dark-gray-bg pointer" : "disable-btn"}`}
                            style={{ height: "4.6vh" }}
                        >
                            <p className="fs-20-vh c-white">{"Send report"}</p>
                        </button>
                    </div>
                        */
                    }

                </div>

            </div>
            { loading && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default ReportBugScreen