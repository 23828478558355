import { useAppDispatch } from '../../../../../../../redux/store/store'
import "./BottomJobsRow.css"
import { setDeleteMultipleJobsModalState, setEditMultipleJobsModalState } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice'
import { clearJobsGroupedByCategory } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import useCheckAccess from '../../../../../../hooks/useCheckAccess/useCheckAccess'

interface BottomJobsRowProps {
    categoryId: string,
    categoryName: string,
    jobsSelected: number
}


const BottomJobsRow = ({ categoryId, categoryName, jobsSelected }: BottomJobsRowProps ) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const accessDeleteJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Job", id_Action: "Delete" })
    const accessEditJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Job", id_Action: "Edit" })


    const handleEditMultipleJobs = () => {
        dispatch(setEditMultipleJobsModalState({ categoryId, categoryName }))
    }

    const handleDeleteMultipleJobs = () => {
        dispatch(setDeleteMultipleJobsModalState({ categoryId, categoryName }))
    }

    const handleClear = () => {
        dispatch(clearJobsGroupedByCategory({ categoryId }))
    }

    return (
        <div className="con-row-options-table flex flex-jc-sb fs-12">
            <div className="con-left-row-options-table flex flex-ai-center">
                <div className="aux-checkbox-con">
                    <input type="checkbox" disabled={true} />
                </div>
                <p className="fw-500">{`${jobsSelected} ${t("General:label_065")}`}</p>
                <button
                    onClick={handleClear}
                    className="color-clear height-100 fw-500 bg-transparent ml-20 mr-28 ml-10 pointer"
                >
                    {t("General:label_066")}
                </button>
                <p className="mr-2"><i>{t("General:label_067")}</i></p>
                
                {
                    accessEditJob &&
                    <div onClick={handleEditMultipleJobs} className={`pointer  flex flex-ai-center ml-10 ${jobsSelected === 0 ? "disable-div" : ""}`}>
                        <FontAwesomeIcon icon={solid("pen")} className="color-gim fs-12 mr-4" />
                        <p className="fw-500 mt-2">{t("General:label_068")}</p>
                    </div>
                }

                {
                    accessDeleteJob &&
                    <div onClick={handleDeleteMultipleJobs} className={`pointer flex flex-ai-center ml-14 ${jobsSelected === 0 ? "disable-div" : ""}`}>
                        <FontAwesomeIcon icon={solid("trash-can")} className="color-gimmer fs-12 mr-4" />
                        <p className="fw-500 mt-2">{t("General:label_069")}</p>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default BottomJobsRow