import React from 'react'
import { FaHouseUser, FaLaptop, FaWalking } from 'react-icons/fa'
import { WorkPreferenceStr } from '../../../utils/dbModels/HistoryTransaction'

type WorkPreferenceIconProps = {
    type: WorkPreferenceStr
    size: string
    color: string
}

const WorkPreferenceColorDynamic = ({ type, size, color }: WorkPreferenceIconProps) => {

    const style = { width: size, height: size, backgroundColor: color }
    const numericPart = parseFloat(size.replace(/[a-z][A-Z]/g, ""))
    const termination = size.replace(/\d/g, "").replace(/\./g, "")

    const sizeIcon = numericPart * 0.52

    const sizeIconStr = `${sizeIcon}${termination}`

    if(type === "remote")
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaLaptop style={{ fontSize: sizeIconStr }} /></div> )

    else if(type === "inside" )
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaHouseUser style={{ fontSize: sizeIconStr }}  /></div> )

    else // outside
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaWalking style={{ fontSize: sizeIconStr }}  /></div> )
}

export default React.memo( WorkPreferenceColorDynamic )