import React, { useState } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms';
import { useAppDispatch } from '../../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { MyYup } from '../../../../../utils/verifications/myYup';
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import LoadingSpinner from '../../../../LoadingSpinner';
import { deleteContract } from '../../../../../utils/requestsUtils/functionsRequests/rules';
import { setDeleteContractState } from '../../../../../redux/slices/components/Rules/rulesSlice';

type FormDeleteContractModalProps = {
    countryName: string
    closeModal: () => void
}

const FormDeleteContractModal = ({ countryName, closeModal }: FormDeleteContractModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)



    const handleSubmit = async(e: any) => {

        e.preventDefault()

        setLoading(true)
        try {

            await deleteContract(countryName)

            dispatch(setDeleteContractState(countryName))
            closeModal()
        } catch (error) {
            console.log("Error", error)
        }

        setLoading(false)
    }

        
    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column height-100 flex-jc-sb" >
            <div className="flex flex-d-column mt-50">
                <ConfirmButton text={t("General:label_131")} width="100%" />
            </div>

                { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default React.memo( FormDeleteContractModal )