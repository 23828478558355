import { useCallback, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { orderManageUsersReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersDataSlice"
import { checkAllCheckboxesManageUsers } from "../../../../redux/slices/components/ManageUsers/manageUsersGroupedDataSlice"
import { RootState, useAppDispatch } from "../../../../redux/store/store"
import ArrowColumn from "../../../UIcomponents/ArrosColumn/ArrowColumn"
import "./HeaderTableManageUsers.css"

const HeaderTableManageUsers = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { page } =useSelector((state:RootState) => state.manageUsersData)
    const { orderColumn, orderDirection, usersToShow} = useSelector((state: RootState) => state.manageUsersData)
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const previousPage = useRef(page)

    const orderArray = useCallback(
      ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {
        dispatch(orderManageUsersReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
      },
      [],
    )

      useEffect(() => {
        
        if(selectAll)
            dispatch(checkAllCheckboxesManageUsers({ users: usersToShow }))
        else
            dispatch(checkAllCheckboxesManageUsers({ users: [] }))
      }, [selectAll])
      

      useEffect(() => {
  
        if(previousPage.current !== page){
            previousPage.current = page
            setSelectAll(false)
        }
        return () => {
        }
      }, [page])
      

    return (
        <div className="ff-roboto fs-14 fw-500 con-header-table-users flex" >

            <div className="item1-table-user center">
                <input 
                    className="pointer"
                    type="checkbox"  
                    checked={selectAll}
                    onChange={() => {
                        setSelectAll(!selectAll)
                    }}
                />
            </div>
            
            <div className="item2-table-user center-start">
                <p className="fs-13 fs-600">{t("General:label_134")}</p>
            </div>

            <div className="item3-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_135")}
                    orderColumn={orderColumn} 
                    columnName={"id"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item4-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_136")}
                    orderColumn={orderColumn} 
                    columnName={"lastName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item5-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_137")}
                    orderColumn={orderColumn} 
                    columnName={"firstName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item6-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_138")}
                    orderColumn={orderColumn} 
                    columnName={"roleId"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="item7-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_139")}
                    orderColumn={orderColumn} 
                    columnName={"countryName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

        </div>
    )
}

export default HeaderTableManageUsers