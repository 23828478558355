import { useState, useEffect } from "react"
import { useAppDispatch } from "../../../../redux/store/store"
import { removeVerifiedPictureImageReducer, UserProfileImage } from "../../../../redux/slices/components/VerifyPicture/verifyPictureDataSlice"
import { getProfilePictureByUserId } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { verifyUserProfileImage } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import LoadingSpinner from "../../../LoadingSpinner"
import "./SliderCard.css"
import { useTranslation } from "react-i18next"
import { FaExpandAlt, FaTimes } from "react-icons/fa"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

interface SliderCardProps {
    user: UserProfileImage,
    index: number
    editable: boolean
}

const SliderCard = ({ user, index, editable }: SliderCardProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const [showExpandedIdImage, setShowExpandedIdImage] = useState<boolean>(false);
    const [loadingValidation, setLoadingValidation] = useState<boolean>(false)
    const [imgUrl, setImgUrl] = useState<string>("")

    const handleSliderScroll = (index: number) => {
        const card = document.getElementById(`slider-profile-img-${index.toString()}`);
        const scrollContainer = document.getElementById("con-gray-slider-profile-picture");
        if(card && scrollContainer){
          const grayContainerData = scrollContainer.getBoundingClientRect();

          const cardData = card.getBoundingClientRect();

          //const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft - grayContainerData.left)
          const cardLeftWithContainerBorderLeftPosition = (card.offsetLeft)

          const newPosition = cardLeftWithContainerBorderLeftPosition - (grayContainerData.width/2) + (cardData.width/2)

          scrollContainer.scrollTo({
            left: newPosition,
            behavior: "smooth" || 'auto'
          })
        }
    }
      
    useEffect(() => {
        getProfilePictureByUserId(user.id, "mid")
        .then((url) => {
            setImgUrl(url)
        }).catch(() => {})
        
    }, [])
    

    const validPicture = () => {
        setLoadingValidation(true);
        verifyUserProfileImage(user.id, { newVerifiedState: true })
        .then((res) => {
            dispatch(removeVerifiedPictureImageReducer({ id: user.id, user: user}))
        }).finally(() => { setLoadingValidation(false) })
    }
    
    const inValidPicture = () => {
        setLoadingValidation(true);
        verifyUserProfileImage(user.id, { newVerifiedState: false })
        .then((res) => {
            dispatch(removeVerifiedPictureImageReducer({ id: user.id, user: user}))
        }).finally(() => { setLoadingValidation(false) })
    }

    return (
        <div id={`slider-profile-img-${index.toString()}`} className="con-slider-card p-relative flex flex-d-column flex-jc-end" >
            {
                loadingValidation && <LoadingSpinner fullPage={false} />
            }
            <div className="slider-card flex flex-d-column flex-jc-end">

                <div 
                    className="profile-img-view pointer" 
                    style={{ "backgroundImage":  `url(${imgUrl})` }}
                    onClick={() => setShowExpandedIdImage(true)}
                >
                    <div className="profile-img-hover center">
                        <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} className="fs-30 icon-expand-id-img" />
                    </div>
                </div>

                {
                    showExpandedIdImage &&
                    <div className="fixed-all-screen background-dark center flex-d-column">
                        <div 
                            className="cer-img-expanded" 
                            style={{ "backgroundImage":  `url(${imgUrl})`}}
                        ></div>
                        <div onClick={() => setShowExpandedIdImage(false)} className="center circle mt-20 pointer con-icon-close-cer-img"> 
                            <FontAwesomeIcon icon={regular("times")} className="fs-18 c-white" />
                        </div>
                    </div>
                }


                <div className="center" style={{ height: "9rem" }}>
                    <p className="fs-18 fw-600 text-align-center">{`${user.firstName} ${user.lastName}`}</p>
                </div>

                {
                    editable &&
                    <div className="con-btns-card-slider center">
                        <button
                            className="btn-slider-card fw-600  btn-slider-card-invalid pointer mr-6 color-persian-red"
                            onClick={inValidPicture}
                        >
                            {t("General:label_057")}
                        </button>

                        <button
                            className="btn-slider-card fw-600 btn-slider-card-valid pointer ml-6 color-green-pass"
                            onClick={validPicture}
                        >
                            {t("General:label_056")}
                        </button>
                    </div>
                }


            </div>
        </div>
    )
}

export default SliderCard