import Icon from '../../../utils/icons/Icon'
import "./SideBarOption.css";
import { useNavigate } from 'react-router';
import { GetScreenName } from '../../../utils/staticVariables/staticVariables';
import { NavLink } from "react-router-dom";
import React from 'react';

interface SideBarOptionProps {
    name: string,
    icon: string,
    path: string,
    isSelected: boolean,
    numberNotifications: number,
    color?: string, 
}


const SideBarOption = ({name, icon, path, isSelected, numberNotifications, color}: SideBarOptionProps) => {

    const navigate = useNavigate();

    return (
      <NavLink 
          to={path} 
          onClick={() => {navigate(path)}} 
          className={({ isActive }) => (`container-option ${isActive ? "container-option-selected" : "container-option-no-selected"}`)}
      >
        <div className="container-logo-name">
            <Icon name={ icon } cssClass={"sidebar-option-icon sidebar-option-icon-img"} />
            <p className="text-option fs-16-vh" style={{"color": color ? color : ""}}>{ GetScreenName(name) }</p>
        </div>
        { numberNotifications > 0 && <p className="number-notifications">{ numberNotifications }+</p> }
      </NavLink>
    )
}

export default React.memo( SideBarOption )