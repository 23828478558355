import { useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { changeIndexTabReducer, GetOfferByIdThunk, GetOfferByUsersThunk } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { isUUID } from '../../../utils/verifications/uuid/uuid'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'
import "./HeaderManageOffers.css"

const HeaderManageOffers = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const resetSearch = useRef<any>()

  const indexTab = useSelector((state: RootState) => state.manageOffersData.indexTab);
  const limit = useSelector((state: RootState) => state.manageOffersData.limit);

  const submitSearchBar = (searchString: string) => {
      dispatch(changeIndexTabReducer({ indexTab: -1 }))
      if(isUUID(searchString)){
          dispatch(GetOfferByIdThunk({ id: searchString }))
      }else{
          dispatch(GetOfferByUsersThunk({ page: 1, limit: 100,  bringTotal: true, stringToSearch: searchString }))
      }
  }

  const searchSubmit = useCallback(
    (searchString: string) => {
        submitSearchBar(searchString)
  },[],)
  
  useEffect(() => {
  
    if(indexTab>0 && resetSearch.current)
        resetSearch.current.submit()

    return () => {
    }
  }, [indexTab])


  return (
    <div className="flex flex-jc-sb con-header-offers">
        <p className="title-screen">{t("General:label_028")}</p>
        <SearcherBar style={{ width: "28rem" }} ref={resetSearch} searchSubmit={searchSubmit} placeholder={t("General:label_029")} />
    </div>
  )
}

export default HeaderManageOffers