import React, { useState } from 'react'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { useTranslation } from 'react-i18next'
import { MyYup } from '../../../../utils/verifications/myYup'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { Role, deleteRoleReducer, openCloseModalRoleReducer } from '../../../../redux/slices/components/ManageRoles/manageRolesSlice'
import { deleteRole } from '../../../../utils/requestsUtils/functionsRequests/roles/roles'
import LoadingSpinner from '../../../LoadingSpinner'
import { ValuesForm } from '../../../../utils/verifications/Forms'


const ModalRemoveRole = () => {

    const { t, i18n } = useTranslation()

    const dispatch = useAppDispatch()

    const roles = useSelector((state: RootState) => state.manageRoles.roles)

    const [loading, setLoading] = useState(false)
    const [inputValues, setInputValues] = useState<ValuesForm>({
        roleName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
    });  

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
    
    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        const roleName = inputValues["roleName"].value
        const role = roles.find(r => r.name === roleName)
        if(!role)
            return
        const id_Role = role.id
        
        setLoading(true)
        deleteRole(id_Role)
        .then(() => {
            dispatch( deleteRoleReducer(id_Role) )
            dispatch( openCloseModalRoleReducer("") )
        }).catch(() => {
            alert("General:Error removing role, please report to Gimwork team")
        })
        .finally(() => { 
            setLoading(false)
        })
    }

    return (
        <form onSubmit={handleSubmit} className="blank-wrapper-modals ff-poppins p-relative flex flex-d-column flex-jc-sb" style={{ height: "65vh" }}>
            
            { loading && <LoadingSpinner fullPage={true} /> }

            <div className="">
                <p className="fs-28">
                    {t("General:label_248")}
                </p>

                <p className="fs-14 mt-40 mb-20">
                    {t("General:label_249")}
                </p>

                

                <div className="flex flex-d-column con-label-input-modal">
                    <div className="con-input">
                        <select
                            name="roleName"
                            className="input-modal pr-10"
                            onChange={(e: any) => {
                                checkErrors(e)
                            }}
                            value={inputValues["roleName"].value}
                        >
                            <option value="">- {t("General:label_250")} -</option>
                            {
                                roles.filter(r => !r.permanent).map((role: Role, index: number) => (
                                    <option 
                                        key={index}
                                        value={role.name}
                                    >
                                        { role.name }
                                    </option>

                                ))
                            }
                        </select>
                        {   inputValues["roleName"].error && (
                                <p className="error-input-text mt-8">{inputValues["roleName"].error  || ""}</p>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmButton width="100%" text={t("General:label_016")} />
        </form>
    )
}

export default ModalRemoveRole