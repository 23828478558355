import axios, { AxiosRequestConfig } from "axios"
import { GimGimmerAll } from "../../../../../redux/slices/components/ManageReports/manageReportsDataSlice"
import { 
    getUserCredentials,
    adminApiUrl
} from "../../../axiosUtils"
import { MovementSupport, SupportHierarchy, UserReport } from "../../../../dbModels"
import { TimeBanned } from "../../../../../components/ManageReportsScreen/DetailedUserReportScreen/LeftSideUserReport/InformationTab/BanUserFields/BanUserFields"


type GetUserReportsBody = {
    LastEvaluatedKey?: string,
    limit: number,
    bringTotal: boolean
    bringAllSupportsCounter?: boolean
    openIncident?: boolean
    gimGimmerAll: GimGimmerAll, 
    userId?: string, 
    supportSent?: number, 
    justSupportLevel?: boolean
}

export type TotalCounterSupports = {
    Support_1: number
    Support_2: number
    Support_3: number
    Support_Admin: number
}

type ResponseReports = { 
    results: UserReport[] 
    LastEvaluatedKey?: string 
    total: number 
    totalsBySupport?: TotalCounterSupports
}

export const getUserReports = (body: GetUserReportsBody) => {
    return new Promise((resolve: (value: ResponseReports) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: body
            };
            axios.get(`${adminApiUrl}/v1/reports/user`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getUserReportById = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/reports/user/${id}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateUserReportSeenWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/seen`, { }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/seen`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type StatusUserReport = "ACCEPTED" | "DECLINED"

export const updateAcceptRefuseUserReport = (id: string, status: StatusUserReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/accept-refuse`, { status }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/accept-refuse`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateAssignmentWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/assignment`, {  }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/assignment`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const openIncidentWeb = (id: string) => {
    return new Promise((resolve: (value: { newMovement: MovementSupport }) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/incident`, {  }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/incident`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export type ResponseMoveBackUp = { 
    newMovement: MovementSupport,
    newHierarchy: SupportHierarchy 
}

export const moveBackUpWeb = (id: string) => {
    return new Promise((resolve: (value: ResponseMoveBackUp) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/move-back-up`, {  }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/move-back-up`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type BodyBanUser = {
    reason: string
    time: TimeBanned
    duration?: number
}

export const banUser = (id: string, body: BodyBanUser) => {
    return new Promise((resolve: (value: ResponseMoveBackUp) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/user/${id}/ban`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/user/${id}/ban`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 