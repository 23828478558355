import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../redux/store/store'
import LeftSideBugReport from './LeftSideBugReport/LeftSideBugReport'
import RightSideBugReport from './RightSideBugReport/RightSideBugReport'
import { resetDetailedBugReportReducer } from '../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'

const DetailedBugReportScreen = React.memo(() => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch( resetDetailedBugReportReducer() )
        }
    }, [])
    
    
    return (
        <div className="aux-scroll flex width-100 ff-poppins z-index-1" style={{ zIndex: 1, backgroundColor: "white" }}>
            <LeftSideBugReport />
            <RightSideBugReport />
        </div>
    )
})

export default React.memo( DetailedBugReportScreen )