import { useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

type MapHandlerProps = {
    //place: google.maps.places.PlaceResult | null;
    mapAlignment: { bounds: google.maps.LatLngBounds | null, location: google.maps.LatLng | null, zoom: number | null, latitude: number | null, longitude: number | null }
    marker: google.maps.marker.AdvancedMarkerElement | null;
}
  
const MapHandler = ({ mapAlignment, marker }: MapHandlerProps) => {
    const map = useMap();
  
    useEffect(() => {
      if (!map || !mapAlignment || !marker) return;
      /*
      if (place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport);
      }*/
      if(mapAlignment.bounds){
          map.fitBounds(mapAlignment.bounds);
      }

      if(mapAlignment.latitude && mapAlignment.longitude){
          marker.position = {
              lat: mapAlignment.latitude,
              lng: mapAlignment.longitude
          }
      }

      if(mapAlignment.zoom){
          map.setZoom(mapAlignment.zoom)
      }

      //marker.position = place.geometry?.location;
    }, [map, mapAlignment, marker]);
  
    return null;
  };

  export default MapHandler