import React, { useEffect, useState } from 'react'
import { getProfilePictureByUserId, getUserDataById } from '../../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import LoadingSpinner from '../../../../LoadingSpinner'
import { DatesIncident } from '../../../../../utils/dbModels'
import { getCardDate } from '../../../../../utils/dates/dates'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAppDispatch } from '../../../../../redux/store/store'
import { updateUserAssignedBugReportReducer } from '../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import { updateUnAssignmentWeb } from '../../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'

type CardSupportProps = {
    id: string
    datesIncident?: DatesIncident
    isMyUserAssigned: boolean
    reportId: string
}

type UserData = {
    name: string
}

const CardAssignedBugReport = ({ 
    id,
    datesIncident,
    isMyUserAssigned,
    reportId
 }: CardSupportProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [user, setUser] = useState<UserData | null>(null)
    const [imgUrl, setImgUrl] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)


    const getUserData = () => {

        setLoading(true)
        getUserDataById(id, { firstName: 1, lastName: 1 })
        .then((user: any) => {
            setUser({
                name: `${user.firstName} ${user.lastName}`,
            })
        }).catch(() => {

        }).finally(() => { setLoading(false) })

        getProfilePictureByUserId(id, "small")
        .then((url) => {
            setImgUrl(url)
        }).catch(() => {

        })
    }

    useEffect(() => {
        
        getUserData()

    }, [])

    const removeAssignment = () => {
        setLoading(true)
        updateUnAssignmentWeb(reportId)
        .then(() => {
            dispatch( updateUserAssignedBugReportReducer("") )
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="p-relative card-support border-radius-6 p-relative" style={{ width: "95%" }}>

            { loading && <LoadingSpinner fullPage={false} /> }

            <p className="fs-13 c-oslo-gray">{t("Reports:user:label_01")}</p>

            <div className="flex mt-6">

                
                <img 
                    src={imgUrl ?? "/user.svg"} 
                    className="img-6rem-circle" 
                    //onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}    
                />


                <div className=" ml-14">
                    <p className="fs-17 color-text mt-22">{user?.name}</p>

                    { datesIncident && <p className="fs-13 color-text mt-6">{`${t("Reports:user:label_02")} ${getCardDate(datesIncident?.startTime)}`}</p> }
                    { datesIncident?.endTime && <p className="fs-13 color-text mt-6">{`${t("Reports:user:label_03")} ${getCardDate(datesIncident?.endTime)}`}</p> }
                </div>

            </div>

            {
                isMyUserAssigned &&
                <div className="p-absolute" style={{ right: "1rem", top: "1rem" }}>
                    <button onClick={removeAssignment} className="p-3 pointer">
                        <FontAwesomeIcon icon={solid("times")} className="c-manatee fs-14" />
                    </button>
                </div>
            }


        </div>
    )
}

export default React.memo( CardAssignedBugReport )