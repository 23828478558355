import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getCreationDate, getWhenDate } from '../../../../utils/dates/dates'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import { ResponseMoveBackUp, TotalCounterSupports, getUserReportById, getUserReports, moveBackUpWeb } from '../../../../utils/requestsUtils/functionsRequests/reports/user/reportsFunctions'
import { Message, MovementSupport, Support, UserReport } from '../../../../utils/dbModels'

export type GimGimmerAll = "Gim" | "Gimmer" | "All"
export type ActionReport = "OPEN_INCIDENT" | "MOVE_BACK_UP" 
export type StateUserReport = "Accept" | "Refuse" | "Ban" | "Open_Incident" | "Move_Back_Up"
export type UserReportType = "RequestBy" | "PersonInvolved" 

type GetReportsProperties = {
   results: UserReportTableItem[], 
   LastEvaluatedKey?: string, 
   page: number, 
   limit: number, 
   bringTotal: boolean, 
   total: number, 
   totalCounterSupports?: TotalCounterSupports 

   openIncident?: boolean
   gimGimmerAll: GimGimmerAll, 
   userId?: string, 
   supportSent?: number, 
   justSupportLevel?: boolean
}

export type ExtraDataUserReport = {
    LastEvaluatedKey?: string,
    page: number,
    limit: number,
    bringTotal: boolean
    bringAllSupportsCounter?: boolean
    openIncident?: boolean
    gimGimmerAll: GimGimmerAll, 
    userId?: string, 
    supportSent?: number, 
    justSupportLevel?: boolean
}


export type UserReportTableItem = UserReport & {
    timestampMilliseconds: number
    creationDate?: string
    seen: boolean
}

export type ExtraUserReport = {
    id: string
    firstName: string
    lastName: string
    gimStars: string
    gimmerStars: string
    email: string
    imgUri: string
}

export type ExtraUserSUPPORT = {
  id: string
  firstName: string
  lastName: string
}

export type TypeUserReport = "Support_1" | "Support_2" | "Support_3" |"Support_Admin" | "RequestBy" | "UserInvolved"

const addExtraDetailsMessages = (messages: Message[]) => {
    let previousUserId: string | null = null
    for(let i=0; i<messages.length; i++){
        if(messages[i].userID !== previousUserId){
            messages[i].isFirstGroupMessage = true 
        }
        previousUserId = messages[i].userID
    }
}
  
const addRemainingFieldsuserReport = (userReport: UserReport): UserReportTableItem => {
  return {
    ...userReport,
    creationDate: userReport.timestamp,
    seen: userReport.seen ?? false,
    timestampMilliseconds: new Date(userReport.timestamp).getTime()
  }
}

export const GetUsersReportsThunk = createAsyncThunk(
    'manageReportsDataSlice/GetUsersReportsThunk',
    async ({ LastEvaluatedKey, page, limit, bringTotal, bringAllSupportsCounter, openIncident, gimGimmerAll, userId, supportSent, justSupportLevel }: ExtraDataUserReport) => {
      try { 
        const res = await getUserReports({ LastEvaluatedKey, limit, bringTotal, bringAllSupportsCounter, openIncident, gimGimmerAll, userId, supportSent, justSupportLevel })
        const results: UserReportTableItem[] = res.results.map((userReport: UserReport) => {
            return addRemainingFieldsuserReport(userReport)
        })
        return {
            results: results,
            LastEvaluatedKey: res.LastEvaluatedKey,
            page,
            limit,
            bringTotal,
            total: res.total,
            totalCounterSupports: res.totalsBySupport,
            openIncident, 
            gimGimmerAll, 
            userId, 
            supportSent, 
            justSupportLevel
        }
      } catch (error) {
        console.log("error", error)
        return {
            results: [] as UserReportTableItem[],
            page,
            limit,
            bringTotal,
            total: 0,
            totalCounterSupports: undefined,
            openIncident, 
            gimGimmerAll, 
            userId, 
            supportSent, 
            justSupportLevel
        }
      }
    }
)

export const GetUsersReportsBySearchThunk = createAsyncThunk(
  'manageReportsDataSlice/GetUsersReportsBySearchThunk',
  async ({ stringToSearch, limit, bringTotal, page }: { page: number, stringToSearch: string, limit: number, bringTotal: boolean }) => {
    try { 
      //const res = await getUser({ stringToSearch, limit, bringTotal })
      const res : any = {}
      res.results = res.results.map((userReport: UserReportTableItem) => {
        addRemainingFieldsuserReport(userReport)
        return userReport
      })
      return {
        results: res.results as UserReportTableItem[],
        LastEvaluatedKey: res.LastEvaluatedKey ?? "",
        page,
        limit,
        bringTotal,
        total: res.total,
        stringToSearch
      }
    } catch (error) {
      console.log("error", error)
      return {
        results: [] as UserReportTableItem[],
        page,
        limit,
        bringTotal,
        total: 0,
        stringToSearch
      }
    }
  }
)

export const GetUserReportByIdSearchBarThunk = createAsyncThunk(
  'manageReportsDataSlice/GetUserReportByIdSearchBarThunk',
  async ({ id }: { id: string }) => {
    try { 
      const res = await getUserReportById(id)
      return {
        userReport: res.userReport as UserReportTableItem
      }
    } catch (error) {
      console.log("error", error)
      return {
        userReport: {} as UserReportTableItem
      }
    }
  }
)


export const GetUserReportByIdThunk = createAsyncThunk(
  'manageReportsDataSlice/GetUserReportByIdThunk',
  async ({ id}: { id: string}) => {
    try { 
      const res = await getUserReportById(id)
      return {
        userReport: res.userReport as UserReportTableItem
      }
    } catch (error) {
      console.log("error", error)
      return {
        userReport: {} as UserReportTableItem
      }
    }
  }
)


export const MoveBackUpThunk = createAsyncThunk(
  'manageReportsDataSlice/MoveBackUpThunk',
  async ({ id }: { id: string }) => {
    try { 
      const { newHierarchy, newMovement } = await moveBackUpWeb(id)
      return {
          newHierarchy,
          newMovement
      }
    } catch (error) {
      console.log("error", error)
      return {} as ResponseMoveBackUp
    }
  }
)


export const numberUserReportsByPage: number[] = [ 10, 25, 50, 100 ]

type InitialState = {
  stringToSearch: string
  total: number
  indexTab: number
  limit: number,
  page: number
  type: string

  indexTabRows: number

  openIncident?: boolean
  gimGimmerAll: GimGimmerAll, 
  userId?: string, 
  supportSent?: number, 
  justSupportLevel?: boolean

  userReportsIds: string[]
  userReportsToShow: UserReportTableItem[]
  LastEvaluatedKey?: string
  loadingContainer: boolean
  orderColumn: string
  orderDirection: boolean
  userReport: UserReportTableItem
  loadingIndividualPage: boolean
  userRequestBy: ExtraUserReport | null
  userInvolved: ExtraUserReport | null

  support1: ExtraUserReport
  support2: ExtraUserReport
  support3: ExtraUserReport
  adminSupport: ExtraUserReport

  totalCounterSupports?: TotalCounterSupports
  currentSupport: Support
  banUserButtonPressed: boolean
  errorMessageSupportChanged: boolean
  afterMoveBackUp: boolean

  messagesRequestBy: Message[]
  messagesPersonInvolved: Message[]
}

const initialState: InitialState= {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    indexTabRows: 0,
    limit: numberUserReportsByPage[0],
    page: 1,
    type: "",
    userReportsIds: [] as string[],
    userReportsToShow: [] as UserReportTableItem[],
    LastEvaluatedKey: "",
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    userReport: {} as UserReportTableItem,
    loadingIndividualPage: true,
    userRequestBy: null,
    userInvolved: null,
    support1: {} as ExtraUserReport,
    support2: {} as ExtraUserReport,
    support3: {} as ExtraUserReport,
    adminSupport: {} as ExtraUserReport,

    totalCounterSupports: undefined,
    currentSupport: "Support_1",
    banUserButtonPressed: false,
    errorMessageSupportChanged: false,
    afterMoveBackUp: false,
    
    openIncident: false,
    gimGimmerAll: "All", 
    userId: undefined, 
    supportSent: undefined, 
    justSupportLevel: undefined,

    messagesRequestBy: [],
    messagesPersonInvolved: []
}

export const manageUserReportsDataSlice = createSlice({
  name: 'manageUserReportsData',
  initialState,
  reducers: {
      setUserReportReducer: (state, action: PayloadAction<{ type: TypeUserReport, user: ExtraUserReport }>) => {
          const { type, user } = action.payload
          state.type = type
          if(type === "Support_1") state.support1 = user
          else if(type === "Support_2") state.support2 = user
          else if(type === "Support_3") state.support3 = user
          else if(type === "Support_Admin") state.adminSupport = user
          else if(type === "RequestBy") state.userRequestBy = user
          else state.userInvolved = user

          return state
      },
      setErrorMessageSupportChanged: (state, action: PayloadAction<boolean>) => {
          state.errorMessageSupportChanged = action.payload
          return state
      },
      setAfterMoveBackUp: (state, action: PayloadAction<boolean>) => {
          state.afterMoveBackUp = action.payload
          return state
      },
      changeSupportReducer: (state, action: PayloadAction<Support>) => {
          state.currentSupport = action.payload
          //if(action.payload === "Support_Admin" || action.payload === "Support_3")
              //state.incidentOpened = true
          return state
      },
      setLoadingUserReportReducer: (state, action: PayloadAction<boolean>) => {
          state.loadingIndividualPage = action.payload
          return state
      },
      openIncidentReducer: (state, action: PayloadAction<MovementSupport>) => {
          state.userReport.openIncident = true
          state.userReport.movements?.push(action.payload)
          return state
      },
      banUserButtonPressedReducer: (state) => {
        state.banUserButtonPressed = true
        return state
      },
      changeIndexTabReducerUserReport: (state, action: PayloadAction<number>) => {
          state.indexTab = action.payload
          return state
      },
      changeIndexTabRowsReducer: (state, action: PayloadAction<number>) => {
        state.indexTabRows = action.payload
        return state
    },
      setMessagesReducer: (state, action: PayloadAction<{ messages: Message[], type: UserReportType}>) => {
          const { messages, type } = action.payload
          addExtraDetailsMessages( messages )
          if(type === "RequestBy")
              state.messagesRequestBy = messages
          else
              state.messagesPersonInvolved = messages
          return state
      },
      setAddMessageReducer: (state, action: PayloadAction<{ message: Message, type: UserReportType }>) => {
          const { message, type } = action.payload
          if(type === "RequestBy"){
              if(state.messagesRequestBy.find((m) => m.id === message.id))
                  return state
              state.messagesRequestBy.push(message)
          }
          else{
              if(state.messagesPersonInvolved.find((m) => m.id === message.id))
                  return state
              state.messagesPersonInvolved.push(message)
          }
          return state
      },
      setMessageAsViewedReducer: (state, action: PayloadAction<{ messageId: string, type: UserReportType }>) => {
          const { messageId, type } = action.payload
          if(type === "RequestBy"){
              for(const message of state.messagesRequestBy){
                  if(message.id === messageId)
                      message.viewed = true
              }
          }  
          else{
            for(const message of state.messagesPersonInvolved){
              if(message.id === messageId)
                  message.viewed = true
          }
          }
          return state
      },
      updateSeenUserReportReducer: (state, action: PayloadAction<{ id: string }>) => {
          state.userReportsToShow = state.userReportsToShow.map((userReport: UserReportTableItem) => {
            if(userReport.id === action.payload.id)
                userReport.seen = true
              //userReport.status = "OPENED"
              
            return userReport
          })
          return state
      },
      removeDeletedUserReportReducer: (state, action: PayloadAction<{ id: string }>) => {
        const { id } = action.payload
        if(id === state.LastEvaluatedKey && state.userReportsIds.length > 1){
          //const position = state.userReportsIds.indexOf(id)
          const userReport = state.userReportsToShow.find((userReport: UserReportTableItem) => userReport.id === action.payload.id)
          state.LastEvaluatedKey = userReport?.id 
        }
        state.userReportsIds = state.userReportsIds.filter((userReport: string) => userReport !== action.payload.id)
        state.userReportsToShow = state.userReportsToShow.filter((userReport: UserReportTableItem) => userReport.id !== action.payload.id)
        state.total = state.total - 1
        return state
      },
      changeIndexTabReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
          state.userReportsIds = []
          state.indexTab = action.payload.indexTab
          return state
      },
      orderUserReportsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
          const { orderColumn, orderDirection  } = action.payload
            
          state.userReportsToShow = state.userReportsToShow.map((userReport: UserReportTableItem) => {

            //const user = UserInfoCache.userNameCache.get(userReport.userId)
            //if(user?.name)
            //  userReport.userName = user.name

            return userReport

          })
          state.userReportsToShow = getOrderedState({ orderColumn, orderDirection, array: state.userReportsToShow })
          state.orderColumn = orderColumn
          state.orderDirection = orderDirection
                  
          return state
      },
      resetDetailedUserReportReducer: (state) => {
          return initialState
      },
      resetManageUserReportsReducer: () => {
          return initialState
      }
  },

  extraReducers: (builder) => {
    builder.addCase(GetUsersReportsThunk.fulfilled, (state, action: PayloadAction<GetReportsProperties>) => {

        const { 
            results, 
            page, 
            LastEvaluatedKey, 
            limit, 
            bringTotal, 
            total, 
            totalCounterSupports,
            openIncident,
            gimGimmerAll, 
            userId, 
            supportSent, 
            justSupportLevel } = action.payload  

        state.openIncident = openIncident
        state.gimGimmerAll = gimGimmerAll
        state.userId = userId
        state.supportSent = supportSent
        state.justSupportLevel = justSupportLevel 

        let lastIndex = 0
        for(let i=0; i < results.length; i++){
            const skip = state.limit * (page-1)
            state.userReportsIds[skip+i] = results[i].id
            lastIndex = skip+i
        }

        if(totalCounterSupports)
            state.totalCounterSupports = totalCounterSupports
        
        state.userReportsIds = state.userReportsIds.slice(0,lastIndex+1)

        const itemsOrderedByDate = getOrderedState({ 
            array: results, 
            orderColumn: state.orderColumn ? state.orderColumn : "createdDate", 
            orderDirection: state.orderColumn ? state.orderDirection : false
        })
        state.userReportsToShow = itemsOrderedByDate
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? ""
        state.limit = limit ?? 0
        if(bringTotal)
            state.total = total
        state.stringToSearch = ""
        return state
                
      }).addCase(GetUsersReportsThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetUsersReportsThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      

      .addCase(GetUserReportByIdSearchBarThunk.fulfilled, (state, action: PayloadAction<{ userReport: UserReportTableItem }>) => {
        const { userReport } = action.payload
        if(userReport.id){
            addRemainingFieldsuserReport(userReport)
            state.userReportsToShow = new Array(userReport)
            state.userReportsIds = new Array(userReport.id)
            state.total = 1
        }else{
            state.userReportsToShow = []
            state.userReportsIds = []
            state.total = 0
        }
        state.loadingContainer = false
        state.stringToSearch = ""
        return state

      })
      .addCase(GetUserReportByIdSearchBarThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetUserReportByIdSearchBarThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })


      .addCase(GetUserReportByIdThunk.fulfilled, (state, action: PayloadAction<{ userReport: UserReportTableItem }>) => {
          const { userReport } = action.payload
          if(userReport.id){
            addRemainingFieldsuserReport(userReport)
            state.userReport = userReport
          }
          state.loadingIndividualPage = false
          return state

      })
      .addCase(GetUserReportByIdThunk.pending, (state, action: any) => {
          state.loadingIndividualPage = true
          return state     
      }).addCase(GetUserReportByIdThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingIndividualPage = false
          return failState
      })
      
      

      .addCase(GetUsersReportsBySearchThunk.fulfilled, (state, action: PayloadAction<{ results: UserReportTableItem[], LastEvaluatedKey?: string, page: number, limit: number, bringTotal: boolean, total: number, stringToSearch: string }>) => {
          const { results, page, LastEvaluatedKey, bringTotal, total, stringToSearch } = action.payload
          state.userReportsIds = results.map(( userReport: UserReportTableItem ) => (userReport.id))
          state.userReportsToShow = results
          state.loadingContainer = false
          state.page = page
          state.LastEvaluatedKey = LastEvaluatedKey ?? ""
          if(bringTotal)
            state.total = total
          state.total = 0
          state.loadingContainer = false
          state.stringToSearch = stringToSearch
          return state
      }).addCase(GetUsersReportsBySearchThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetUsersReportsBySearchThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })
/*
      .addCase(MoveBackUpThunk.fulfilled, (state, action: PayloadAction<ResponseMoveBackUp>) => {
        
        const { newMovement, newHierarchy } = action.payload
        state.userReport.support = newHierarchy

        for(let movement of state.userReport.movements){
          if(movement.userId === newMovement.userId)
            movement = newMovement
        }

        state.userReport.userAssigned = undefined
        state.loadingIndividualPage = false
        return state
      }).addCase(MoveBackUpThunk.pending, (state, action: any) => {
        state.loadingIndividualPage = true
        return state     
      }).addCase(MoveBackUpThunk.rejected, (state, action: any) => {
          state.loadingIndividualPage = false
          return state     
      })
      */
  },

});

// Action creators are generated for each case reducer function
export const {  
  changeIndexTabReducerUserReport,
  updateSeenUserReportReducer, 
  removeDeletedUserReportReducer,
  changeIndexTabReducer,
  resetManageUserReportsReducer,
  orderUserReportsReducer,
  resetDetailedUserReportReducer,
  setUserReportReducer,
  changeSupportReducer,
  openIncidentReducer,
  banUserButtonPressedReducer,
  setLoadingUserReportReducer,
  setErrorMessageSupportChanged,
  setMessagesReducer,
  setAddMessageReducer,
  setMessageAsViewedReducer,
  changeIndexTabRowsReducer,
  setAfterMoveBackUp
} = manageUserReportsDataSlice.actions

export default manageUserReportsDataSlice.reducer