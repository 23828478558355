export const capitalize = (s: string) => {
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPaginatedData = (page: number, limit: number, total: number) => {

	const startCounter = page * limit - limit
	const endCounter = page * limit
	
	return `${startCounter === 0 ? 1 : startCounter} - ${endCounter > total ? total : endCounter}`
}

export const capitalizeAllWords = (s: string) => {
	const words = s.split(' ');
	const capitalizedWords = words.map(word => {
	  	return word.charAt(0).toUpperCase()   + word.slice(1);
	});
	return capitalizedWords.join(' ');
};