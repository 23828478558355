import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { JobCategoryJob } from './managerJobDataSlice';

export interface JobGrouped {
    categoryId: string,
    categoryName: string,
    id: string,
    jobName: string,
    active: boolean,
    isCertifReq: boolean,
    isPool: boolean,
    isGimmable: boolean
}

const initialState = {
    jobsGrouped: [] as JobGrouped[]
}

export const managerJobsGroupedDataSlice = createSlice({
  name: 'managerJobsGroupedData',
  initialState,
  reducers: {

    addJobCategoryJobReducer: (state, action: PayloadAction<JobGrouped>) => {
      state.jobsGrouped.push(action.payload)
      return state
    },

    removeJobCategoryJobReducer: (state, action: PayloadAction<{ categoryId: string, id: string}>) => {

        state.jobsGrouped = state.jobsGrouped.filter((job: JobGrouped) => {
            return !(job.id === action.payload.id && job.categoryId === action.payload.categoryId) 
        })
        return state
    },
    clearJobsGroupedByCategory: (state, action: PayloadAction<{ categoryId: string }>)  => {
        state.jobsGrouped = state.jobsGrouped.filter((job: JobGrouped) => job.categoryId !== action.payload.categoryId )
        return state
    },
    checkAllCheckboxesJobs: (state, action: PayloadAction<{ jobs: JobGrouped[] }>) => {
      const { jobs } = action.payload

      state.jobsGrouped = state.jobsGrouped.concat(jobs)
      return state
    },
    removeAllCheckboxesJobs: (state, action: PayloadAction<{ categoryId: string }>) => {
        const { categoryId } = action.payload
        state.jobsGrouped = state.jobsGrouped.filter((job: JobGrouped) => job.categoryId !== categoryId )
        return state
    },
    resetManagerJobsGroupedDataSlice: (state) => {
      state = initialState
      return state
    },
  }
});

// Action creators are generated for each case reducer function
export const {  
    addJobCategoryJobReducer,
    removeJobCategoryJobReducer,
    clearJobsGroupedByCategory,
    resetManagerJobsGroupedDataSlice,
    checkAllCheckboxesJobs,
    removeAllCheckboxesJobs
} = managerJobsGroupedDataSlice.actions

export default managerJobsGroupedDataSlice.reducer