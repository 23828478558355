import {
	faCircleCheck,
	faCircleXmark,
	faClock,
	faHandHoldingDollar,
	faHandHoldingUsd,
	faHandshake,
	faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import momentNormal from "moment";
import moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/fr';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import IDCard from '../../../assets/icons/Id-fail.svg';
import CertificateApprove from '../../../assets/svg/certificateApprove.svg';
import CertificateDeclined from '../../../assets/svg/certificateDeclined.svg';
import { faCertificate, faIdCard, faSealExclamation } from '@fortawesome/pro-regular-svg-icons';
import DateReload from '../DateReload/DateReload';
import { Colors } from '../../../utils/colors/colors';
import LoadingSpinner from '../../LoadingSpinner';
import { convertPythonTimestamp } from '../../../utils/dates/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { getJobS3ImgUri } from '../../../utils/staticVariables/staticVariables';
import { getProfilePictureByUserId, getUserDataById } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions';
import UserImgNotification from '../UiComponents/UserImgNotification/UserImgNotification';

type GeneralNotifProps = {
	item: {
		viewed: boolean;
		id: string;
		notifData: any;
		notifMsg?: string;
		timestamp?: number;
		iso_timestamp?: string
	};
	deleteNotification: (transactionID: string) => void;
	t: any
	i18n: any;
	onSetNotificationAsView(id: string): void;
};

class GeneralNotif extends React.Component<GeneralNotifProps, any> {
	height: number;

	constructor(props: GeneralNotifProps) {
		super(props);
		this.onPress = this.onPress.bind(this);

		this.height = 12;
		this.renderBody = this.renderBody.bind(this);
		this.renderPicture = this.renderPicture.bind(this);
		this.onEvent = this.onEvent.bind(this);
		this.onDeleteNotification = this.onDeleteNotification.bind(this);
	}

	override componentWillUnmount() {
		this.setState = () => {
			return;
		};
	}

	onPress() {
		if (!this.props.item.viewed) {
			this.props.onSetNotificationAsView(this.props.item.id);
		}

		this.onEvent();
	}

	onDeleteNotification(notificationID: string) {
		this.props.deleteNotification(notificationID);
	}

	override shouldComponentUpdate(nextProps: any) {
		if (this.props.item.viewed !== nextProps.item.viewed) {
			return true;
		}
		return false;
	}

	onEvent() { }

	renderBody() {
		return (
			<div style={{ width: '84%', justifyContent: 'center' }}>
				<p>
					{this.props.item.notifMsg?.toString()}
				</p>

				<DateReload
					date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
					type={"NOTIFICATION"}
					style={{
						width: '90%',
						color: '#92959D',
						//fontFamily: Font.regular,
					}}
				/>
			</div>
		);
	}

	renderPicture() {
		return (
			<div
				style={{
					width: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<div
					style={{
						height: "6vh",
						width: "6vh",
						borderWidth: 2,
						borderColor: Colors.gwBlueGrey,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<FontAwesomeIcon
						icon={faClock}
						className="fs-14-vh"
						style={{ color: Colors.gwBlueGrey }}
					/>
				</div>
			</div>
		);
	}

	override render() {
		return (
			<div
				onClick={this.onPress}
				className="width-100 center height-notifications grid-notifications"
				style={{
					backgroundColor: this.props.item.viewed ? 'white' : '#EFEFEF',
				}}
			>
				<div
					style={{
						flex: 1,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					{this.renderBody()}
				</div>
				<div>
					{this.renderPicture()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = {};

class NotificationClass extends GeneralNotif {
	constructor(props: GeneralNotifProps) {
		super(props);
		this.onPress = this.onPress.bind(this);
	}

	override onEvent() { }
}

export const Notification = connect(mapStateToProps)(withTranslation()(NotificationClass));

class RequestNotificationClass extends GeneralNotif {
	constructor(props: GeneralNotifProps & { navigation: any }) {
		super(props);
		this.onPress = this.onPress.bind(this);
		this.height = 15;
		this.getTime = this.getTime.bind(this);
		this.checkIfRequestExists = this.checkIfRequestExists.bind(this);
		moment.locale(this.props.i18n.language);
		this.state = {
			photoUri: '',
			name: '',
		};
		this.checkIfRequestExists(props.item.notifData.requestId, props.item.id);
	}

	override onEvent() {
		// @ts-expect-error TS(2339): Property 'navigation' does not exist on type 'Read... Remove this comment to see the full error message
		this.props.navigation.navigate('OpportunityInfo', {
			requestId: this.props.item.notifData.requestId,
			notifId: this.props.item.id,
		});
	}

	getTime() {
		const date = this.props.item.notifData?.startTime ?? this.props.item.notifData?.date
		const month = capitalize(momentNormal(date).format("MMMM"))
		const day = capitalize(momentNormal(date).format("DD"))
		let text = ""
		if (this.props.i18n.language === "es") {
			text = `${day} de ${month}`
		} else {
			text = `${day} ${month}`
		}
		return text;
	}

	// @ts-expect-error TS(2416): Property 'shouldComponentUpdate' in type 'RequestN... Remove this comment to see the full error message
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.item.viewed !== nextProps.item.viewed) {
			return true;
		}

		if (this.state.photoUri !== nextState.photoUri || this.state.name !== nextState.name) {
			return true;
		}
		return false;
	}

	checkIfRequestExists(preTransactionID: string, notificationID: string) {
        /*
		getRequestInformation(preTransactionID)
			.then((data: any) => {
			})
			.catch((error: any) => {
				if (error.response.status >= 400 && error.response.status < 500) {
					this.onDeleteNotification(notificationID);
				}
			});*/
	}

	override componentDidMount() {
		const userId = this.props.item.notifData.fromUserId;
		const getProfilePic = async () => {
			try {
				const picture = await getProfilePictureByUserId(userId, "small");
                
				const { lastName, firstName } = await getUserDataById(userId, { firstName: 1, lastName: 1 }); 
				this.setState({
					photoUri: picture,
					name: `${firstName} ${lastName}`,
				});
                
			} catch (error) {
				this.onDeleteNotification(this.props.item.id);
			}
		};
		getProfilePic();
	}

	override renderPicture() {
		return (
			<div className="width-100 flex">
				<div
					className="center"
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderWidth: 2,
						borderColor:
							this.props.item.notifData.type === 'Gimmer'
								? Colors.secondaryColor
								: Colors.mainColor,

						alignItems: 'center',
						justifyContent: 'center',
					}}>
					{this.props.item.notifData.type === 'Gimmer' ? (
						this.props.item.notifData.categoryLogo &&
							this.props.item.notifData.categoryLogo !== '' ? (
							<div className="center red-png-image">
								<img
									className="red-png-image"
									src={getJobS3ImgUri(this.props.item.notifData.categoryLogo)}
									style={{ height: "3rem", width: "3rem" }}
								/>
							</div>


						) : (
							<FontAwesomeIcon
								icon={faHandshake}
								className="fs-20-vh"
								style={{ color: Colors.secondaryColor }}
							/>
						)
					) : (
						<FontAwesomeIcon
							icon={faHandHoldingUsd}
							className="fs-20-vh"
							style={{ color: Colors.mainColor }}
						/>
					)}
				</div>
			</div>
		);
	}

	override renderBody() {
		return (
			<div className="width-100">
				<div className="width-100 flex flex-ai-center height-100">

					<div className="height-100 mr-6" style={{ height: '100%' }}>
						<UserImgNotification photoUri={this.state.photoUri} />
					</div>


					<div className="flex-1 height-100">
						<p className="fs-18-vh color-text pr-10">
							{this.props.item.notifData.type === 'Gimmer'
								? this.props.t('Notifications:index:label_50')
								: this.props.t('Notifications:index:label_3')}
						</p>

						<p className="fs-13-vh color-text mb-4 mt-4">
							{this.props.item.notifData.type === 'Gimmer'
								? `${this.state.name} ${this.props.t(
									'Notifications:index:label_4',
								)} ${this.props.t(
									`Jobs:${this.props.item.notifData.jobName.replace(
										/\s/g,
										'',
									)}`,
								)} ${this.props.t(
									'Notifications:index:label_5',
								)} ${this.getTime()}!`
								: `${this.state.name} ${this.props.t(
									'Notifications:index:label_6',
								)} ${this.props.t(
									`Jobs:${this.props.item.notifData.jobName.replace(
										/\s/g,
										'',
									)}`,
								)} ${this.props.t(
									'Notifications:index:label_5',
								)} ${this.getTime()}!`}
						</p>

						<DateReload
							date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
							type={"NOTIFICATION"}
							style={{
								color: '#92959D',
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export const RequestNotification = connect(mapStateToProps)(
	withTranslation()(RequestNotificationClass),
);

class DeclinedRequestNotificationClass extends GeneralNotif {
	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);
		this.onPress = this.onPress.bind(this);
		this.height = 15;

		moment.locale(this.props.i18n.language);
		this.state = {
			photoUri: '',
			name: '',
		};
	}

	override onEvent() { }

	//@ts-expect-error
	override shouldComponentUpdate(nextProps: any, nextState: any) {
		if (this.props.item.viewed !== nextProps.item.viewed) {
			return true;
		}

		if (this.state.photoUri !== nextState.photoUri || this.state.name !== nextState.name) {
			return true;
		}
		return false;
	}

	override componentDidMount() {
		const userId = this.props.item.notifData.fromUserId;
		const getProfilePic = async () => {
			try {
				const picture = await getProfilePictureByUserId(userId, "small");
                /*
				const { lastName, firstName } = await GetUserInformation(userId);
				this.setState({
					photoUri: picture,
					name: `${firstName} ${lastName}`,
				});
                */
			} catch (error) {
				console.log('error');
				this.setState({ photoUri: '', name: '' });
				console.log(`ERROR Fetch logo -- ${error}`);
				this.onDeleteNotification(this.props.item.id);
			}
		};
		getProfilePic();
	}

	override renderPicture() {
		return (
			<div
				style={{
					width: '100%',

					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<div
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderColor:
							this.props.item.notifData.type === 'Gimmer'
								? Colors.secondaryColor
								: Colors.mainColor,

						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<FontAwesomeIcon
						icon={faCircleXmark}
                        className="fs-14-vh"
						style={{
							color:
								this.props.item.notifData.type === 'Gimmer'
									? Colors.secondaryColor
									: Colors.mainColor,
						}}
					/>
				</div>
			</div>
		);
	}

	override renderBody() {
		// 1705955369486 1705626986.619884 1705626986.619884
		return (
			<div className="width-100">
				<div className="width-100 flex flex-ai-center height-100">


					<div className="height-100 mr-6" style={{ height: '100%' }}>
						<UserImgNotification photoUri={this.state.photoUri} />
					</div>

					<div style={{ flex: 4, height: '100%', marginLeft: "1rem" }}>
						<p
                            className="fw-600"
							style={{
								color: Colors.TEXT,
							}}>
							{this.props.item.notifData.type === 'Gimmer'
								? this.props.t('Notifications:index:label_7')
								: this.props.t('Notifications:index:label_8')}
						</p>
						<p  
                            className="fs-14-vh"
							style={{
								color: Colors.TEXT,
							}}>
							{this.props.item.notifData.type === 'Gimmer'
								? `${this.state.name} ${this.props.t(
									'Notifications:index:label_9',
								)} ${this.props.t(
									`Jobs:${this.props.item.notifData.jobName.replace(
										/\s/g,
										'',
									)}`,
								)}`
								: `${this.state.name} ${this.props.t(
									'Notifications:index:label_10',
								)} ${this.props.t(
									`Jobs:${this.props.item.notifData.jobName.replace(
										/\s/g,
										'',
									)}`,
								)}`}
						</p>

						<DateReload
							date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
							type={"NOTIFICATION"}
							style={{
								color: '#92959D',
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export const DeclinedRequestNotification = connect(mapStateToProps)(
	withTranslation()(DeclinedRequestNotificationClass),
);

class PublishOfferClass extends GeneralNotif {
	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);

		moment.locale(this.props.i18n.language);
		this.onPress = this.onPress.bind(this);
	}

	override renderPicture() {
		return (
			<div className="center width-100">
				<div
					className="center"
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderRadius: 10,
						borderWidth: 2,
						borderColor:
							this.props.item.notifData.profileType === 'Gimmer'
								? Colors.secondaryColor
								: Colors.mainColor,
					}}>
					<FontAwesomeIcon
						icon={
							this.props.item.notifData.profileType === 'Gimmer'
								? faHandshake
								: faHandHoldingDollar
						}
						className="fs-20-vh"
						style={{
							color:
								this.props.item.notifData.profileType === 'Gimmer'
									? Colors.secondaryColor
									: Colors.mainColor,
						}}
					/>
				</div>
			</div>
		);
	}

	override renderBody() {
		return (
			<div className="width-100">
				<p className="fs-18-vh color-text">
					{this.props.item.notifData.profileType === 'Gimmer'
						? this.props.t('Notifications:index:label_24')
						: this.props.t('Notifications:index:label_25')}
				</p>

				<p
					style={{
						//fontFamily: Font.regular,
						fontSize: "1.55vh",
						width: '100%',
						color: Colors.TEXT,
					}}>
					{this.props.item.notifData.profileType === 'Gimmer'
						? this.props.t('Notifications:index:label_26')
						: this.props.t('Notifications:index:label_27')}
				</p>

				<DateReload
					date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
					type={"NOTIFICATION"}
					style={{
						color: '#92959D',
					}}
				/>
			</div>
		);
	}
}
export const PublishOffer = connect(mapStateToProps)(withTranslation()(PublishOfferClass));

class VerificationNotifClass extends GeneralNotif {
	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);

		moment.locale(this.props.i18n.language);
	}

	override onEvent() {
		if (
			this.props.item.notifData.receivedFromType === 'VERIFICATION_PROFILE' ||
			this.props.item.notifData.receivedFromType === 'VERIFICATION_ID'
		) {
			// @ts-expect-error TS(2339): Property 'ModalProfile' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.ModalProfile.current.expand();
		}
	}

	override renderPicture() {
		return (
			<div
				style={{
					width: '100%',

					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<div
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderColor:
							this.props.item.notifData.action === 'approve'
								? Colors.approve
								: Colors.block,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					{this.props.item.notifData.receivedFromType === 'VERIFICATION_ID' ? (
                        <FontAwesomeIcon 
                            icon={faIdCard}
                            style={{
                                color: this.props.item.notifData.action === 'approve'
                                ? Colors.approve
                                : Colors.block,
                                height: "3.3vh"
                            }}
                        />
					) : (
						<FontAwesomeIcon
							icon={faUserTie}
							style={{
								color:
									this.props.item.notifData.action === 'approve'
										? Colors.approve
										: Colors.block,
                                        height: "3.3vh"
							}}
						/>
					)}
				</div>
			</div>
		);
	}

	override renderBody() {
		return (
			<div
				style={{
					width: '80%',
				}}>
				<p className="fs-14-vh" >
					{this.props.item.notifData.receivedFromType === 'VERIFICATION_PROFILE'
						? this.props.item.notifData.action === 'approve'
							? this.props.t('Notifications:index:label_11')
							: this.props.t('Notifications:index:label_12')
						: this.props.item.notifData.action === 'approve'
							? this.props.t('Notifications:index:label_13')
							: this.props.t('Notifications:index:label_14')}
				</p>

				<p className="fs-14-vh" style={{ width: '100%' }}>
					{this.props.item.notifData.receivedFromType === 'VERIFICATION_PROFILE'
						? this.props.item.notifData.action === 'approve'
							? this.props.t('Notifications:index:label_15')
							: this.props.t('Notifications:index:label_16')
						: this.props.item.notifData.action === 'approve'
							? this.props.t('Notifications:index:label_17')
							: this.props.t('Notifications:index:label_18')}
				</p>
				<p
                    className="fs-14-vh mb-1"
					style={{
						color: '#92959D',
					}}>
					{moment(convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)).fromNow()}
				</p>
			</div>
		);
	}
}
export const VerificationNotif = connect(mapStateToProps)(
	withTranslation()(VerificationNotifClass),
);

class VerificationCertifNotifClass extends GeneralNotif {
	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);
		moment.locale(this.props.i18n.language);
	}

	override onEvent() { }

	override renderPicture() {
		return (
			<div
				style={{
					width: '100%',

					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<div
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderColor:
							this.props.item.notifData.action === 'approve'
								? Colors.approve
								: Colors.block,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					{this.props.item.notifData.action === 'approve' ? (
						<FontAwesomeIcon
                            icon={faCertificate} 
                            style={{
                                color: this.props.item.notifData.action === 'approve'
                                ? Colors.approve
                                : Colors.block,
                                height: "3.3vh"
                            }}
                        />
					) : (
						<FontAwesomeIcon
                            icon={faSealExclamation} 
                            style={{
                                color: this.props.item.notifData.action === 'approve'
                                ? Colors.approve
                                : Colors.block,
                                height: "3.3vh"
                            }}
                        />
					)}
				</div>
			</div>
		);
	}

	renderBody() {
		return (
			<div
				style={{
					width: '80%',
				}}>
				<p className="fw-600">
					{this.props.item.notifData.action === 'approve'
						? this.props.t('Notifications:index:label_19')
						: this.props.t('Notifications:index:label_20')}
				</p>

				<p style={{ width: '100%' }}>
					{this.props.item.notifData.action === 'approve'
						? `${this.props.t('Notifications:index:label_21')} ${this.props.t(
							`Jobs:${this.props.item.notifData.jobName.replace(/\s/g, '')}`,
						)} ${this.props.t('Notifications:index:label_22')}`
						: `${this.props.t('Notifications:index:label_21')} ${this.props.t(
							`Jobs:${this.props.item.notifData.jobName.replace(/\s/g, '')}`,
						)} ${this.props.t('Notifications:index:label_23')}`}
				</p>
				<p
                    className="mb-1"
					style={{
						color: '#92959D',
					}}>
					{moment(convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)).fromNow()}
				</p>
			</div>
		);
	}
}

export const VerificationCertifNotif = connect(mapStateToProps)(
	withTranslation()(VerificationCertifNotifClass),
);

class NewTransactionClass extends GeneralNotif {
	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);
		this.checkIfTransactionStillExists = this.checkIfTransactionStillExists.bind(this);

		moment.locale(this.props.i18n.language);
		this.state = {
			photoUri: '',
			name: '',
		};
		this.checkIfTransactionStillExists(props.item.notifData.transactionId, props.item.id);
	}

	// @ts-expect-error TS(2416): Property 'shouldComponentUpdate' in type 'NewTrans... Remove this comment to see the full error message
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.item.viewed !== nextProps.item.viewed) {
			return true;
		}

		if (this.state.name !== nextState.name) {
			return true;
		}
		return false;
	}

	override componentDidMount() {
		const userId = this.props.item.notifData.fromUserId;

		const getProfilePic = async () => {
			try {
				const picture = await getProfilePictureByUserId(userId, "small");
                /*
				const { lastName, firstName } = await GetUserInformation(userId);

				this.setState({
					photoUri: picture,
					name: `${firstName} ${lastName}`,
				});*/
			} catch (error) {
				console.log(`ERROR Fetch logo -- ${error}`);
				this.onDeleteNotification(this.props.item.id);
			}
		};
		getProfilePic();
	}

	override onEvent() {
		// @ts-expect-error TS(2339): Property 'navigation' does not exist on type 'Read... Remove this comment to see the full error message
		this.props.navigation.navigate('TransactionInfo', {
			id: this.props.item.notifData.transactionId,
		});
	}

	checkIfTransactionStillExists(transactionID: string, notificationID: string) {
        /*
		fetchTransaction(transactionID)
			.then(() => { })
			.catch((error: any) => {
				if (error.response.status >= 400 && error.response.status < 500) {
					super.onDeleteNotification(notificationID);
				}
			});*/
	}

	override renderPicture() {
		return (
			<div className="center">
				<div
					style={{
						height: "6.72vh",
						width: "6.72vh",
						borderColor:
							this.props.item.notifData.type === 'Gimmer'
								? Colors.secondaryColor
								: Colors.mainColor,
					}}>
					<FontAwesomeIcon
						icon={faCircleCheck}
                        className="fs-26-vh"
						style={{
							color:
								this.props.item.notifData.type === 'Gimmer'
									? Colors.secondaryColor
									: Colors.mainColor,
						}}
					/>
				</div>
			</div>
		);
	}

	override renderBody() {
		if (this.props.item.notifData.typeofNotyf === 'New-event') {
			return (
				<div className="width-100">
					<p className="color-text fs-18-vh">
						{this.props.item.notifData.type === 'Gimmer'
							? this.props.t('Notifications:index:label_27')
							: this.props.t('Notifications:index:label_28')}
					</p>

					<p className="fs-13-vh color-text mb-4 mt-4">
						{`${this.props.t('Notifications:index:label_29')} ${this.props.t(
							`Jobs:${this.props.item.notifData.jobName.replace(/\s/g, '')}`,
						)}`}
					</p>

					<DateReload
						date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
						type={"NOTIFICATION"}
						style={{
							color: '#92959D',
						}}
					/>
				</div>
			);
		}

		return (
			<div className="width-100">
				<div className="width-100 flex flex-ai-center height-100">


					<div className="height-100 mr-6" style={{ height: '100%' }}>
						<UserImgNotification photoUri={this.state.photoUri} />
					</div>
					<div className="height-100">
						<p className="fw-600 fs-13-vh color-text">
							{this.props.item.notifData.type === 'Gim'
								? this.props.t('Notifications:index:label_30')
								: this.props.t('Notifications:index:label_31')}
						</p>
						<p
                            className="fs-14-vh"
							style={{
								width: '100%',
								color: Colors.TEXT,
							}}>
							{`${this.state.name} ${this.props.t('Notifications:index:label_32')}`}
						</p>

						<DateReload
							date={convertPythonTimestamp(this.props.item.iso_timestamp ?? this.props.item.timestamp ?? 0)}
							type={"NOTIFICATION"}
							style={{
								color: '#92959D',
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export const NewTransaction = connect(mapStateToProps)(withTranslation()(NewTransactionClass));

export default connect(mapStateToProps, mapDispatchToProps)(GeneralNotif);
