import { useState } from "react";
import "./SliderRadius.css";

type SliderRadiusProps = {
    valueSlider: number
    color: string
    onChange: (n: number) => void
}

// Size steps
const MAX = 30;

const SliderRadius = ({ valueSlider, onChange, color }: SliderRadiusProps) => {

    const getBackgroundSize = () => {
        return { backgroundSize: `${(valueSlider * 100) / MAX}% 100%`, backgroundImage: `linear-gradient(#${color}, #${color})` };
    };
   
    return (
        <div className="con-slider-radius flex flex-ai-center pr-12 pl-12">
            <p className="fs-18-vh">{"Set your work zone radius"}</p>
            <input
                className="slider-radius flex-1 mr-10 ml-10"
                type="range"
                min="0"
                max={MAX}
                onChange={(e) => onChange( Number(e.target.value) )}
                style={getBackgroundSize()}
                value={valueSlider}
            />
            <p className="fs-14-vh color-text">{`${valueSlider} km`}</p>
        </div>
    );
}

export default SliderRadius