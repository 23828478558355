import React from 'react'
import "./DashboardContent.css"
import DashboardHeaderTable from './DashboardHeaderTable/DashboardHeaderTable'
import DashboardCircularChart from './DashboardCircularChart/DashboardCircularChart'
import DashboardIndicator from './DashboardIndicator/DashboardIndicator'

const DashboardContent = () => {
    return (
        <div className="flex flex-d-column flex-1 flex-ai-center pt-6 pb-6 con-dashboard-content">
            <DashboardHeaderTable />
            <div className="p-relative flex-1 width-100">
                <div className="aux-scroll" style={{ left: ".6rem" }}>
                    <DashboardCircularChart />
                    <DashboardIndicator />
                </div>
            </div>
        </div>
    )
}

export default React.memo( DashboardContent )