import React, { useEffect, useState } from 'react'
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../LoadingSpinner'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import { getUrlPutGeneralInformation } from '../../../../../utils/requestsUtils/functionsRequests/generalInformation/generalInformationFunction'
import axios from 'axios'
import { setAddContractState } from '../../../../../redux/slices/components/Rules/rulesSlice'
import { getUrlPutRules } from '../../../../../utils/requestsUtils/functionsRequests/rules'
import "./FormAddContractModal.css"
import { LanguageLabel } from '../../../../../redux/slices/authSlice'
import { Countries, Country, Languages } from '../../../../../utils/staticVariables/staticVariables'

type FormAddContractModalProps = {
    closeModal: () => void
}

const FormAddContractModal = ({ closeModal }: FormAddContractModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        countryName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
        language: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
        contract: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
    });  




    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        setLoading(true)
        try {
            console.log("language", inputValues["language"].value)
            const urlSigned = await getUrlPutRules(inputValues["countryName"].value, inputValues["language"].value as LanguageLabel)
            console.log("urlSigned", urlSigned)
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                }
            }
            const fileData = JSON.stringify( inputValues["contract"].value )
            const blob = new Blob([fileData], { type: "text/plain" });
            
            await axios.put(urlSigned, blob, config)

            dispatch(setAddContractState({
                countryName: inputValues["countryName"].value as Country,
                contract: {
                    [inputValues["language"].value]: inputValues["contract"].value
                },
            }))
            closeModal()
        } catch (error) {
            console.log("Error", error)
        }

        setLoading(false)

    }

    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
    

    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    useEffect(() => {
      
        
    }, [inputValues["language"].value])
    

    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column height-100 flex-jc-sb" >

            <div className="flex flex-d-column mt-10 mb-10">
                <label className="fs-14 black fw-600" htmlFor="categoryName">{t("General:label_117")} <span className="c-asterik">*</span></label>
                <div className="con-input">
                    <select
                        name="countryName"
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["countryName"].value}
                    >
                        <option value="">--{t("General:label_118")}--</option>
                        {
                            Countries.map((countryName: string, index: number) => (
                                    <option 
                                        key={index}
                                        value={countryName}
                                    >
                                        { countryName }
                                    </option>

                            ))
                        }
                    </select>
                    { inputValues["countryName"].error && (
                        <p className="error-input-text">{inputValues["countryName"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex flex-d-column con-label-input-modal">
                <label className="fs-14 black fw-600" htmlFor="language">{t("General:label_271")} <span className="c-asterik">*</span></label>
                <div className="con-input">
                    <select
                        name="language"
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["language"].value}
                    >
                        <option value="">--{t("General:label_118")}--</option>
                        {
                            Languages.map((language: string, index: number) => (
                                    <option 
                                        key={index}
                                        value={language}
                                    >
                                        { language }
                                    </option>

                            ))
                        }
                    </select>
                    { inputValues["language"].error && (
                        <p className="error-input-text">{inputValues["language"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex flex-d-column mt-10 mb-10">
                <div className="con-input">
                    <textarea
                        id="contract"
                        name="contract"
                        //type="text"
                        placeholder={t("General:label_258") ?? ""}
                        className="input-modal contract-height-text-area p-12 resize-none"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["contract"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                    />

                </div>
                <div className="p-relative">
                    { inputValues["contract"].error && (
                        <p className="error-input-text" style={{ top: "-.9rem" }}>{inputValues["contract"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex flex-d-column mt-10">
                <ConfirmButton text={t("General:label_131")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default React.memo( FormAddContractModal )