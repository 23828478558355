import React, { useEffect } from 'react'
import HeaderProductRequest from '../../../components/ProductsRequest/HeaderProductRequest/HeaderProductRequest'
import RequestProductsModals from '../../../components/ProductsRequest/RequestProductsModals/RequestProductsModals'
import AlertMessageRequestProduct from '../../../components/ProductsRequest/AlertMessageRequestProduct/AlertMessageRequestProduct'
import ContainerSelectorRequestProduct from '../../../components/ProductsRequest/ContainerSelectorRequestProduct/ContainerSelectorRequestProduct'
import ContainerProductsRequests from '../../../components/ProductsRequest/ContainerProductsRequests/ContainerProductsRequests'
import PaginationRequestProductsTableRow from '../../../components/ProductsRequest/PaginationRequestProductsTableRow/PaginationRequestProductsTableRow'
import { useAppDispatch } from '../../../redux/store/store'
import { getAllJobsCategoriesChunk } from '../../../redux/slices/components/ManagerJobs/managerJobDataSlice'

const ProductsRequestScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
      
        dispatch( getAllJobsCategoriesChunk() )
      return () => {
        
      }
    }, [])
    

    return (
        <div className="flex flex-d-column height-100 ">
            <HeaderProductRequest />
            <AlertMessageRequestProduct />
            <ContainerSelectorRequestProduct />
            <ContainerProductsRequests />
            <PaginationRequestProductsTableRow/>
            <RequestProductsModals />
        </div>
    )
}

export default ProductsRequestScreen