import React from 'react'
import { useTranslation } from 'react-i18next'
import DashboardContent from '../../../../components/Dashboard/DashboardContent/DashboardContent'

const DashboardScreen = () => {

    const { t } = useTranslation()

    return (
        <div className="flex flex-d-column height-100 pl-10" style={{ paddingBottom: "3.5%" }}>
            <p className="title-screen mb-26">{t("General:label_252")}</p>
            <DashboardContent />
        </div>
    )
}

export default React.memo( DashboardScreen )