import React from 'react'
import { HistoryTransaction } from '../../../utils/dbModels/HistoryTransaction'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import CardTransactionHistory from './CardTransactionHistory/CardTransactionHistory'
import useGetJobName from '../../hooks/useGetJobName/useGetJobName'
import CardTransactionHistoryWithDate from './CardTransactionHistoryWithDate/CardTransactionHistoryWithDate'

export type TransactionDate = (HistoryTransaction & { date?: string })

type ListCardsTransactionHistoryProps = {
    transactions: TransactionDate[]
    onPressCard?: (id: string) => void
}

const ListCardsTransactionHistory = ({ transactions, onPressCard }: ListCardsTransactionHistoryProps) => {

    const userId = useSelector((state: RootState) => state.auth.user.id)

    return (
        <div className="p-relative mt-14">
            {
                transactions.map((transaction) => 
                    <CardTransactionHistoryWithDate 
                        key={transaction.id}
                        transaction={transaction}
                        onPressCard={onPressCard}
                        userId={userId}
                    />
                )
            }
        </div>
    )
}

export default React.memo( ListCardsTransactionHistory )