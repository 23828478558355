
import React from 'react';
import AppRouter from '../../router/AppRouter'
import "./MainComponent.css"

const MainComponent = () => {
    return (
        <AppRouter />
    )
}

/*

<AppRouter />
        <div className="container-screens">
        <div className="container-content">
            <div className="container-sidebar">
                <SideBar />
            </div>
            <div className="container-screen">
                <div className={`container-aux-design-screen ${urlsThatRequiredMoreWidth.includes(location.pathname || "") ? "container-aux-design-screen-bigger" : ""}`} >
                    <AppRouter />
                </div>
            </div>
        </div>
    </div>
*/

export default React.memo( MainComponent )