import React, { useState } from 'react'
import { StatusReport } from '../../../../utils/dbModels'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { updateStatusAppBugWeb } from '../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { setEndDateIncidentBugReportReducer, setNewStatusBugReportReducer, updateStatusBugReportReducer } from '../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import LoadingSpinner from '../../../LoadingSpinner'
import "./ButtonsBugReport.css"
import { useTranslation } from 'react-i18next'

const ButtonsBugReport = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const idBugReport = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)
    const status = useSelector((state: RootState) => state.manageBugReportsData.bugReport.status)


    const handleStatus = (newStatus: StatusReport) => {
        setLoading(true)
        updateStatusAppBugWeb(idBugReport, newStatus)
        .then(() => {
            dispatch( setNewStatusBugReportReducer({ newStatus }) )
            dispatch( updateStatusBugReportReducer({ id: idBugReport, status: newStatus }) )
            dispatch( setEndDateIncidentBugReportReducer() )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }


    return (
        <div className="flex con-buttons-bug-reports p-relative">

            {
                status !== "OPENED" &&
                    <button 
                    onClick={() => handleStatus("OPENED")} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_04")}
                </button>
            }

            {
                status !== "PENDING" &&
                <button 
                    onClick={() => handleStatus("PENDING")} 
                    className="btn-move-bug-report back-up-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_05")}
                </button>
            }

            {
                status !== "CLOSED" &&
                <button 
                    onClick={() => handleStatus("CLOSED")} 
                    className="btn-move-bug-report ban-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_06")}
                </button>
            }

            { loading && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default React.memo( ButtonsBugReport )