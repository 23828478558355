import { useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { resetManageUsersModalsReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice"
import { RootState, useAppDispatch } from "../../../../redux/store/store"
import { notifEmailUser, NotifEmailUserBody } from "../../../../utils/requestsUtils/functionsRequests/notify/notifyFunctions"
import { MyYup } from "../../../../utils/verifications/myYup"
import LoadingSpinner from "../../../LoadingSpinner"
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./SendMessageUserModal.css"

const SendMessageUserModal = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { id, mail } = useSelector((state: RootState) => state.manageUsersModals.sendMessageUserModalData)

    const [loading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const [sendByEmail, setSendByEmail] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if(!message){
            setErrorMessage(t("General:label_151") ?? "")
            return
        }

        const body: NotifEmailUserBody ={
            sendEmail: false,
            message,
            userId: id,
            titleNotification: "Admin",
            descripNotification: "New message from admin",
            data: {
                receivedFromType: "admin-message"
            }
        }

        if(sendByEmail){
            body.sendEmail = true
            body.emailFields = {
                subject: "About your account",
                emails: [mail]
            }
        }

        setLoading(true)
        notifEmailUser(body)
        .then(() => {
            dispatch(resetManageUsersModalsReducer())
        }).finally(() => { setLoading(false) })

    }

    return (
        <form onSubmit={handleSubmit} className="blank-wrapper-modals">
            { loading && <LoadingSpinner fullPage={true} /> }
            <TitleModal title={`${t("General:label_144")}`} />

            <p className="fw-600 fs-14 mt-30 mb-10">
                {t("General:label_145")}
            </p>

            <textarea
                value={message}
                onChange={(e: any) => {
                    const msg = e.target.value

                    // this always will return error if we send empty string
                    let { errorMsg } = new MyYup(msg)
 
                    if(!msg){
                        errorMsg = ""
                    }
                        
                    if(errorMsg){
                        setErrorMessage(errorMsg)
                    }else{
                        setMessage(msg)
                        setErrorMessage("")
                    }
                    
                }}
                placeholder={t("General:label_146") ?? ""}
                className="send-message-text-area border-radius-3 width-100 fs-14 resize-none"
            />



            <div className="p-relative mt-2" style={{ height: "3rem" }}>
                {
                    <p className="p-absolute color-error fs-12">{errorMessage}</p>
                }
            </div>

            <div className="flex flex-ai-center mb-40">
                <input 
                    type="checkbox" 
                    checked={sendByEmail}
                    onChange={() => setSendByEmail(!sendByEmail)}
                />
                <p className="fs-14 fw-500 ml-6"> {t("General:label_147")} </p>
            </div>
            
            <ConfirmButton text={t("General:label_016")} width="100%" />
        </form>
    )
}

export default SendMessageUserModal