import React from 'react'
import BarProgressSignUp from '../../BarProgressSignUp/BarProgressSignUp'
import "./PercentageBarWrapper.css"

type PercentageBarprops = {
    children: React.ReactNode
    percentageBar?: number
}

const PercentageBarWrapper = ({ children, percentageBar }: PercentageBarprops) => {
    return (
        <div className="width-100 flex flex-ai-center flex-d-column height-flex-100" style={{ }}>

            <div className="width-100 height-100 container-screens-signup" >
                <div className="flex flex-ai-center flex-jc-c" style={{  height: "9vh" }}>
                    <BarProgressSignUp percentageBar={percentageBar ?? 0} />
                </div>
                <div className="con-children-signup">
                { children }
                </div>
            </div>
            
        </div>
    )
}

export default React.memo( PercentageBarWrapper )