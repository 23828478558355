import React from 'react'
import "./InforMoneyEarnedCard.css"

type InforMoneyEarnedCard = {
    title: string
    description: string
}

const InforMoneyEarnedCard = ({ title, description }: InforMoneyEarnedCard) => {
    return (
        <div className="center flex-d-column info-money-card width-100 height-100">
            <p className="fs-14 fw-700 mb-4 text-align-center">{title}</p>
            <p className="fs-14 mt-4 text-align-center">{description}</p>
        </div>
    )
}

export default InforMoneyEarnedCard