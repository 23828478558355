import React, { useEffect, useState } from 'react'
import { ActionReport } from '../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import "./CardSupport.css"
import { RootState } from '../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { getProfilePictureByUserId, getUserDataById } from '../../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import LoadingSpinner from '../../../../LoadingSpinner'
import { Support } from '../../../../../utils/dbModels'
import { getCardDate } from '../../../../../utils/dates/dates'
import { useTranslation } from 'react-i18next'

type CardSupportProps = {
    type: Support
    actions: ActionReport[]
    userId: string
    start: string
    end?: string
}

type UserData = {
    name: string
}

const CardSupport = ({ 
    type,
    actions,
    userId,
    start,
    end
 }: CardSupportProps) => {

    const { t } = useTranslation()

    const [user, setUser] = useState<UserData | null>(null)
    const [imgUrl, setImgUrl] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const getUserData = () => {

        setLoading(true)
        getUserDataById(userId, { firstName: 1, lastName: 1 })
        .then((user: any) => {
            setUser({
                name: `${user.firstName} ${user.lastName}`,
            })
        }).catch(() => {

        }).finally(() => { setLoading(false) })

        getProfilePictureByUserId(userId, "small")
        .then((url) => {
            setImgUrl(url)
        }).catch(() => {

        })
    }

    useEffect(() => {
        
        getUserData()

    }, [])
console.log("type", type)
    return (
        <div className="p-relative card-support border-radius-6 mb-16">

            { loading && <LoadingSpinner fullPage={false} /> }

            <p className="fs-12 c-oslo-gray">{t(`Reports:user:${type}`)}</p>

            <div className="flex mt-10">

                <img 
                    src={imgUrl ?? "/user.svg"} 
                    className="img-6rem-circle" 
                    //onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}    
                />


                <div className="ml-14">
                    <div className="flex flex-ai-center" style={{ height: "6rem" }}>
                        <p className="fs-17 color-text fw-500">{user?.name}</p>
                    </div>

                    <div className="flex">
                        <p className="fs-13 color-text">{`${t("Reports:user:label_14")} `}</p>
                        <div className="ml-6">
                            {
                                actions.map((action, index) => {
                                    return(
                                        <p key={action} className={`fs-12 color-text ${index > 0 ? "mt-4" : ""}`} >{`${action}`}</p>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <p className="fs-12 color-text mt-10">{`${t("Reports:user:label_02")} ${getCardDate(start)}`}</p>
                    { end && <p className="fs-12 color-text mt-10">{`${t("Reports:user:label_03")} ${getCardDate(end)}`}</p> }
                </div>

            </div>

        </div>
    )
}

export default React.memo( CardSupport )