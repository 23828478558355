import React from 'react'
import { RootState, useAppSelector } from '../../../../redux/store/store'
import { useTranslation } from 'react-i18next';

const MyUserStatus = () => {

    const { t } = useTranslation()

    const firstName = useAppSelector((state: RootState) => state.auth.user.firstName);
    const lastName = useAppSelector((state: RootState) => state.auth.user.lastName);
    const imgUrl = useAppSelector((state: RootState) => state.auth.user.imgUrl)

    return (
        <div className="flex flex-ai-center">
            <div className="p-relative">
                <img 
                    className="circle"
                    style={{ width: "5.9vh", height: "5.9vh" }} 
                    alt="profile" 
                    src={imgUrl ? imgUrl : "/user.svg"}
                    onError={(e) => { 
                        (e.target as HTMLImageElement).src = "/user.svg" }
                    }
                    //onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }} 
                />
                <div className="p-absolute white-bg circle" style={{ bottom: ".3rem", right: ".3rem", padding: "2px" }}>
                    <div className="mantis-bg circle" style={{ width: "1rem", height: "1rem" }}></div>
                </div>
            </div>
            <div className="ml-14">
                <p className="fs-16 fw-500">{`${firstName} ${lastName}`}</p>
                <p className="fs-12 color-mantis mt-6">{t("Sessions:label_001")}</p>
            </div>
        </div>
    )
}

export default React.memo( MyUserStatus )