import React from 'react'
import { TransactionHistoryState } from '../../../utils/dbModels/HistoryTransaction'
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'

type StateTransactionHistoryLabelProps = {
    state: TransactionHistoryState
    type: GimGimmer
    height?: string | number
    sizeLetter?: string | number
}

const StateTransactionHistoryLabel = ({ state, type, height = "2.8rem", sizeLetter="fs-13" }: StateTransactionHistoryLabelProps) => {
  
    let classes = "border-radius-3 center pl-14 pr-14 mt-1"
    let text = ""
    if(state === "FINISH"){
        if(type === "Gim"){
            classes += " gim-bg"
        }
        else{
            classes += " gimmer-bg"
        }
        text = "Finished and paid"
    }else{
        classes += " alert-bg"
        text = "Cancelled"
    }
        

    return (
        <div className={classes} style={{ height }}>
            <p className={`c-white fw-500 ${sizeLetter}`}>{text}</p>
        </div>
    )
}

export default StateTransactionHistoryLabel