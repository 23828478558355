import BottomRowTableUsers from "./BottomRowTableUsers/BottomRowTableUsers"
import ContainerRowsTableUsers from "./ContainerRowsTableUsers/ContainerRowsTableUsers"
import HeaderTableManageUsers from "./HeaderTableManageUsers/HeaderTableManageUsers"
import "./TableManageUsers.css"

const TableManageUsers = () => {
  return (
    <div className="flex flex-1 flex-d-column width-100 mt-24" >
        <HeaderTableManageUsers />
        <ContainerRowsTableUsers />
        <BottomRowTableUsers />
    </div>
  )
}

export default TableManageUsers