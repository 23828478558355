import { CurrencyCode, LanguageLabel } from "../../redux/slices/authSlice";

export const getUserLanguageBrowser = (): LanguageLabel => {
    const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
        
    const publicLanguage = (localStorage.getItem(localStorageLanguage) ?? userLocale) as LanguageLabel

    if(publicLanguage !== "en" && publicLanguage !== "es" && publicLanguage !== "fr")
        return "es"

    return publicLanguage
}

export const localStorageLanguage = "lang-gimwork"

export const localStorageCurrency = "currency-gimwork"

export const getUserCurrencyBrowser = (): CurrencyCode => {
    const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    const publicLanguage = (localStorage.getItem(localStorageLanguage) ?? userLocale) as LanguageLabel

    let defaultCurrency = "MXN"
    if(publicLanguage === "en")
        defaultCurrency = "USD"
    else if(publicLanguage === "fr")
        defaultCurrency = "EUR"
        
    const publicCurrency = (localStorage.getItem(localStorageCurrency) ?? defaultCurrency) as CurrencyCode

    if(publicCurrency !== "EUR" && publicCurrency !== "MXN" && publicCurrency !== "USD")
        return "MXN"

    return publicCurrency
}