import { useState } from 'react'
import { useAppDispatch } from '../../../../redux/store/store';
import { RootState } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../LoadingSpinner';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import { clearJobsGroupedByCategory, } from '../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import "./EditMultipleJobsModal.css"
import { editMultipleJobs } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobsFunctions';
import MyCheckbox from '../../../UIcomponents/MyCheckbox/MyCheckbox';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { JobCategoryJob, updateEditedJobsGroupedByCategory } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type inputValuesEditMultiple = {
  categoryId: string,
  id: string,
  active: boolean,
  isCertifReq: boolean,
  isPool: boolean,
  isGimmable: boolean
}

const EditMultipleJobsModal = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { categoryId } = useSelector((state: RootState) => state.managerJobModals.EditMultipleJobsModal);
  const jobsGrouped = useSelector((state: RootState) => state.managerJobsGroupedData.jobsGrouped).filter((job: JobCategoryJob) => job.categoryId === categoryId);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<inputValuesEditMultiple[]>(jobsGrouped.map((jobGrouped: JobCategoryJob) => ({
    categoryId: jobGrouped.categoryId,
    id: jobGrouped.id,
    active: jobGrouped.active,
    isCertifReq: jobGrouped.isCertifReq,
    isPool: jobGrouped.isPool,
    isGimmable: jobGrouped.isGimmable
  })))

  const handleSubmit = (e :any) => {
      e.preventDefault();
      
      const jobsEdited: JobCategoryJob[] = []
      inputValues.forEach((editedJob: inputValuesEditMultiple) => {
            const job = jobsGrouped.find((job) => job.id === editedJob.id)
            if(job){
                jobsEdited.push({                 
                  ...job,
                    active: editedJob.active,
                    isCertifReq: editedJob.isCertifReq,
                    isPool: editedJob.isPool,
                    isGimmable: editedJob.isGimmable
                })
            }
        })

      setLoading(true)
      editMultipleJobs(jobsEdited)
      .then((res) => {

          console.log("jobsEdited", jobsEdited)
          dispatch(updateEditedJobsGroupedByCategory({ categoryId, jobsGrouped: jobsEdited }))
          dispatch(clearJobsGroupedByCategory({ categoryId }))
          dispatch(resetValueFromState())
      }).catch((error) => {
          console.log("error", error)
      }).finally(() => { setLoading(false) })
  }

  const handleCheckbox = (e: any, index: number, inputName: string) => {
    const currentValue = inputValues[index][inputName as keyof inputValuesEditMultiple];
    inputValues[index] = {...inputValues[index], [inputName]: !currentValue}
    setInputValues(inputValues.slice())
  }

  const closeModal = () => {
    dispatch(resetValueFromState())
  }

  return (
      <div className="blank-wrapper-modals p-relative" style={{ height: "65vh" }}>
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <form onSubmit={handleSubmit} className="flex flex-d-column flex-jc-sb height-100">
          
          <TitleModal title={t("General:label_099")} />
          
          <div className="p-relative flex-1 mb-30">

            <div className="mt-10 aux-scroll">
              {
                jobsGrouped.map((jobGrouped: JobCategoryJob, index: number) => {
                  return(
                    <div className="mt-30" key={index}>
                      <p className="fw-600 fs-16 mb-14">{jobGrouped.jobName}</p>

                      <div className="flex flex-jc-sb fs-14 fw-500">
                          <div className="con-item-edit-multiple-checkbox center">
                              <p className="mr-4">{t("General:label_100")}</p>
                              <MyCheckbox
                                onChange={(e: any) => handleCheckbox(e,index,"active")}
                                value={inputValues[index]["active"]}
                                size="1.8rem"
                                iconSize="1.1rem"
                              />
                          </div>

                          <div className="con-item-edit-multiple-checkbox center">
                              <p className="mr-4">{t("General:label_094")}</p>
                              <MyCheckbox
                                onChange={(e: any) => handleCheckbox(e,index,"isCertifReq")}
                                value={inputValues[index]["isCertifReq"]}
                                size="1.8rem"
                                iconSize="1.1rem"
                              />
                          </div>

                          <div className="con-item-edit-multiple-checkbox center">
                              <p className="mr-4">{t("General:label_095")}</p>
                              <MyCheckbox
                                onChange={(e: any) => handleCheckbox(e,index,"isPool")}
                                value={inputValues[index]["isPool"]}
                                size="1.8rem"
                                iconSize="1.1rem"
                              />
                          </div>
                    
                          <div className="con-item-edit-multiple-checkbox center">
                              <p className="mr-4">{t("General:label_096")}</p>
                              <MyCheckbox
                                onChange={(e: any) => handleCheckbox(e,index,"isGimmable")}
                                value={inputValues[index]["isGimmable"]}
                                size="1.8rem"
                                iconSize="1.1rem"
                              />
                          </div>
                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>

          <br />
          <br />
          <br />
          <ConfirmButton text={t("General:label_016")} width='100%' />

          { loading && <LoadingSpinner fullPage={true} /> }
          </form>
      </div>
  )
}

export default EditMultipleJobsModal