import React, { useCallback, useState } from 'react'
import ArrowGoBackButton from '../../../UIcomponents/ArrowGoBackButton/ArrowGoBackButton'
import "./EnterCode.css"
import LoadingSpinner from '../../../LoadingSpinner'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import VerificationInput from 'react-verification-input'

type EnterCodeprops = {
    goBack: (index: number) => void
    nextStep: (index: number) => void
    email: string
    newPassword: string
}

const EnterCode = ({ goBack, nextStep, email, newPassword }: EnterCodeprops) => {

    const [code, setCode] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [numberOfAttempts, setNumberOfAttempts] = useState(0)

    const GoBack = useCallback(
        () => {
            goBack(2)
        },
        [],
    )

    const handleCode = (code: string) => {

        if(error)
            setError("")

        if(code.length > 6)
            return
        
        setCode(code)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if(error)
            return

        setLoading(true)
        console.log("code", email.replace("@", "_"), code, newPassword)
        confirmResetPassword({ username: email.replace("@", "_"), confirmationCode: code, newPassword })
        .then(() => {
            nextStep(4)
        }).catch((error) => {
            console.log("error", error)
            setError("Server error")
            setNumberOfAttempts(numberOfAttempts + 1)
        }).finally(() => {
            setLoading(false)
        })
    }

    const resentCode = () => {
        resetPassword({ username: email })
        .then(() => {

        }).catch(() => {
            setError("Error in cognito server")
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <form className="con-forgot-password">
            <div className="flex">
                <ArrowGoBackButton goBack={GoBack} />
            </div>

            <div className="center mt-30">
                <img className="img-gimwork-logo" src="./assets/images/logo-W-gimwork.svg" alt="gimwork" />
            </div>

            <p className="fs-13 color-text mt-8 text-align-center mt-40">{
                `We have sent a security code to ${email} `
            }</p>
            <p className="fs-13 color-text mt-2 text-align-center">{
                `You might have to check your spam folder`
            }</p>

            <div className="p-relative flex flex-d-column width-100 mt-40">
                <div className=" p-relative padding-to-show-error">
                    <VerificationInput
                        onChange={(e: any) => {
                            handleCode(e)
                        }}
                        value={code}
                        length={6}
                        validChars={"0-9"}
                        classNames={{
                            container: "container",
                            character: "cell color-text",
                            characterInactive: "inactive-cell",
                            characterSelected: "selected-cell",
                            characterFilled: "filled-cell",
                        }}
                    />
                    { error && (
                        <p className="p-absolute error-input-txt " style={{ bottom: "-.8rem" }}>{error}</p>
                    )}
                </div>
            </div>

            <div className="center">
                <button
                    type="button"
                    disabled={loading}
                    className="pointer"
                    onClick={resentCode}
                >
                    <p className="color-text underline fs-13 text-align-center mt-10 mb-40">Resend code</p>
                </button>
            </div>

            {
                loading ?
                <div className="center width-100 p-relative" style={{ height: "8rem", }}>
                    <LoadingSpinner fullPage={false} />
                </div>
                :
                <button 
                    onClick={handleSubmit} 
                    className="dark-gray-bg center width-100 btn-next-forgot-password" 
                    type="submit"
                >
                    <p className="fs-15 c-white">{"Next"}</p>
                </button>
            }

        </form>
    )
}

export default React.memo( EnterCode )