import React, { useCallback } from 'react'
import HeaderTableMarketplace from './HeaderTableMarketplace/HeaderTableMarketplace'
import MarketplaceRows from './MarketplaceRows/MarketplaceRows'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import PaginatedMarketplaceTableRow from './PaginatedMarketplaceTableRow/PaginatedMarketplaceTableRow';

const TableMarketplace = () => {

    const indexTab = useSelector((state: RootState) => state.marketplace.indexTab);
    const marketplaceProductsBoughtToShow = useSelector((state: RootState) => state.marketplace.marketplaceProductsBoughtToShow)
    const marketplaceProductsPublishedToShow = useSelector((state: RootState) => state.marketplace.marketplaceProductsPublishedToShow);

    const onPressProduct = useCallback(
        () => {
            
        },
        [],
    )
    

    return (
        <div className="flex flex-d-column flex-1">
            <HeaderTableMarketplace />
            <MarketplaceRows 
                products={indexTab === 1 ? marketplaceProductsPublishedToShow : marketplaceProductsBoughtToShow}
                type={indexTab === 1 ? "Marketplace" : "Purchased"}
                onPress={onPressProduct}
            />
            <PaginatedMarketplaceTableRow />
        </div>
    )
}

export default React.memo( TableMarketplace ) 