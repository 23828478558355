import React, { useCallback, useEffect, useState } from 'react'
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import "./ModalLanguageCurrency.css"
import ContentModalLanguageCurrency from './ContentModalLanguageCurrency/ContentModalLanguageCurrency'
import { CurrencyCode, LanguageLabel, setShowModalLanguageCurrency } from '../../../redux/slices/authSlice'
import { getUserCurrencyBrowser, getUserLanguageBrowser } from '../../../utils/preferences/preferences'

const excludeIds = ["globe-btn"]

type ModalLanguageCurrencyProps = {
    isLogged: boolean
}

const ModalLanguageCurrency = ({ isLogged }: ModalLanguageCurrencyProps) => {

    const dispatch = useAppDispatch()

    const publicLanguage = getUserLanguageBrowser()
    const publicCurrency = getUserCurrencyBrowser()

    const [languageNotLogged, setLanguageNotLogged] = useState(publicLanguage)
    const [currencyNotLogged, setCurrencyNotLogged] = useState(publicCurrency)

    const showModalLanguageCurrency = useSelector((state: RootState) => state.auth.showModalLanguageCurrency)
    const languagePreference = useSelector((state: RootState) => state.auth.user.preferences?.language)
    const currencyPreference = useSelector((state: RootState) => state.auth.user.preferences?.currency)

    const onClose = useCallback(
        () => {
            dispatch( setShowModalLanguageCurrency(false) )
        },
        [],
    )

    const onHandleNotLoggedLanguage = useCallback(
        (lang: LanguageLabel) => {
            setLanguageNotLogged(lang)
        },
        [],
    )

    const onHandleNotLoggedCurrency = useCallback(
        (code: CurrencyCode) => {
            setCurrencyNotLogged(code)
        },
        [],
    )

    if(!showModalLanguageCurrency)
        return <></>

    return (
        <div className="background-gray center" style={{ zIndex: 1 }}>
            <ClickOutside onPress={onClose} exclude={excludeIds} >
                <ContentModalLanguageCurrency 
                    onClose={onClose} 
                    languagePreference={isLogged ? languagePreference : languageNotLogged} 
                    currencyPreference={isLogged ? currencyPreference?.currCode : currencyNotLogged}
                    isLogged={isLogged}
                    onHandleNotLoggedLanguage={onHandleNotLoggedLanguage}
                    onHandleNotLoggedCurrency={onHandleNotLoggedCurrency}
                />
            </ClickOutside>
        </div>
    )
}

export default React.memo( ModalLanguageCurrency )