import React from 'react'
import "./CardPreferences.css"

type CardPreferencesProps = {
    id: string
    title: string
    description: string
    selected: boolean
    onPress: (id: string) => void
}

const CardPreferences = ({ id, title, description, selected, onPress }: CardPreferencesProps) => {
    return (
        <button 
            onClick={() => onPress(id)}
            disabled={selected}
            className={`card-preference border-radius-6 flex flex-d-column flex-jc-sb mr-10 ${selected ? "border-card-preference" : "pointer"} `}
        >
            <p className="fs-14 c-black">{title}</p>
            <p className="fs-14 txt-description-prefe">{description}</p>
        </button>
    )
}

export default React.memo( CardPreferences )