import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getRequestedProducts } from '../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'

export type TypeProduct = "DIGITAL" | "PHYSICAL"
export type StatusProduct = "REQUESTED" | "VALIDATED" | "REJECTED"


type ProductCommonData = {
    id:             string
    jobId:          string
    typeProduct:    TypeProduct
    status:         StatusProduct
    RFCcode:        string
    IVA_GIMMER:     number
	IVA_GIMWORK:	number
	ISR:			number
	RMPC:			number
    userId:         string
    productName:    string
    description:    string
    updatedAt:      string
    price?:          number
}

export type DigitalProduct = {
    fileType:       string[]
    typeProduct:    "DIGITAL"
} & ProductCommonData

export type PhysicalProduct = {
    typeProduct:    "PHYSICAL"
} & ProductCommonData

export type ProductDB = DigitalProduct | PhysicalProduct


export const GetProductsRequestedThunk = createAsyncThunk(
    'products/GetProductsRequestedThunk',
    async () => {
      try { 

          const { items } = await getRequestedProducts()
    
          return {
            items,
          }
      } catch (error) {
          console.log("error", error)
          return {
            items: []
          }
      }
    }
)


const initialState = {
    loading: false,
    products: [] as ProductDB[]
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {

        removeProductReducer: (state, action: PayloadAction<string>) => {
            const productId = action.payload
            state.products = state.products.filter((product) => product.id !== productId)
            return state
        },
        resetProductsReducer: () => {
            return initialState
        }
    },

    extraReducers: (builder) => {
        builder.addCase(GetProductsRequestedThunk.fulfilled, (state, action: PayloadAction<{ items: ProductDB[] }>) => {
            const { items } = action.payload  

            state.products = items
            state.loading = false
            return state
        }).addCase(GetProductsRequestedThunk.pending, (state, action: any) => {
            state.loading = true
            return state     
        }).addCase(GetProductsRequestedThunk.rejected, (state, action: any) => {
            return initialState
        })
    },

});

// Action creators are generated for each case reducer function
export const {  
    removeProductReducer,
  resetProductsReducer,
} = productsSlice.actions

export default productsSlice.reducer