import { createSlice, PayloadAction  } from '@reduxjs/toolkit'
import { ManageUser } from './manageUsersDataSlice';

const initialState = {
    usersGrouped: [] as ManageUser[],
}

export const manageUsersGroupedDataSlice = createSlice({
  name: 'usersGroupedData',
  initialState,
  reducers: {
    addUserGroupedReducer: (state, action: PayloadAction<ManageUser>) => {
      state.usersGrouped.push(action.payload)
      return state
    },
    removeUserGroupedReducer: (state, action: PayloadAction<{ id: string}>) => {
        state.usersGrouped = state.usersGrouped.filter((user: ManageUser) => {
            return user.id !== action.payload.id
        })
        return state
    },
    checkAllCheckboxesManageUsers: (state, action: PayloadAction<{ users: ManageUser[] }>) => {
      const { users } = action.payload
      state.usersGrouped = users
      return state
    },
    resetManageUsersGroupedReducer: (state) => {
        state = initialState
        return state
    },
  }
});

export const {  
    addUserGroupedReducer,
    removeUserGroupedReducer,
    resetManageUsersGroupedReducer,
    checkAllCheckboxesManageUsers,
} = manageUsersGroupedDataSlice.actions

export default manageUsersGroupedDataSlice.reducer