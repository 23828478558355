import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import { GetUsersReportsThunk, UserReportTableItem, resetManageUserReportsReducer } from "../../../redux/slices/components/ManageReports/manageReportsDataSlice";
import "./ReportRows.css"
import UserReportRow from "./ReportRow/ReportRow";

const ReportRows = () => {

  const dispatch = useAppDispatch()

  const userReportsToShow = useSelector((state: RootState) => state.manageUserReports.userReportsToShow);
  const loadingContainer = useSelector((state: RootState) => state.manageUserReports.loadingContainer);
  const limit = useSelector((state: RootState) => state.manageUserReports.limit);
  const page = useSelector((state: RootState) => state.manageUserReports.page);
  const myUserId = useSelector((state: RootState) => state.auth.user.id)

  const openIncident = useSelector((state: RootState) => state.manageUserReports.openIncident);
  const gimGimmerAll = useSelector((state: RootState) => state.manageUserReports.gimGimmerAll);
  const userId = useSelector((state: RootState) => state.manageUserReports.userId);
  const supportSent = useSelector((state: RootState) => state.manageUserReports.supportSent);
  const justSupportLevel = useSelector((state: RootState) => state.manageUserReports.justSupportLevel);
  
  useEffect(() => {
    dispatch(GetUsersReportsThunk({ 
        page: 1, 
        limit, 
        bringTotal: true, 
        bringAllSupportsCounter: true, 
        gimGimmerAll,
        openIncident,
        userId,
        supportSent,
        justSupportLevel
    }))
    return () => {
        //dispatch(resetManageUserReportsReducer())
    }
}, [])

  return (
    <div className="container-reports-rows flex flex-1 flex-d-column ff-roboto">
      <div className="aux-scroll-reports">

            { 
                loadingContainer && <LoadingSpinner fullPage={false} /> 
            }
            {
                
                userReportsToShow.map((userReport: UserReportTableItem) => {
                    return(
                        <UserReportRow
                            key={userReport.id}
                            userReport={userReport}
                            supportId={myUserId}
                        />
                    )
                })
                
            }

        </div>
    </div>
  )
}

export default React.memo( ReportRows )