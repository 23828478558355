import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { getBugReportById, getBugReports } from '../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { BugReport, PriorityReport, StatusReport } from '../../../../utils/dbModels'

export type TotalCountersStatus = {
    opened: number
    pending: number
    closed: number
}       

export type LastEvaluatedKeyBugReports = {
    id: string
    timestamp?: string
    status?: StatusReport
}

type GetBugReportsResponse = { 
    results: BugReportTableItem[], 
    LastEvaluatedKey?: LastEvaluatedKeyBugReports, 
    page: number, 
    limit: number, 
    bringTotal: boolean, 
    total: number, 
    status?: StatusReport 
    totalCounterStatus?: TotalCountersStatus
}

export type ExtraDataBugReport = {
    LastEvaluatedKey?: LastEvaluatedKeyBugReports,
    page: number,
    limit: number,
    bringTotal: boolean
    status?: StatusReport
}

export type BugReportTableItem = BugReport & {
    timestampMilliseconds: number
    creationDate?: string
    seen: boolean
}
  
const addRemainingFieldsbugReport = (bugReport: BugReportTableItem) => {
  bugReport.creationDate = bugReport.timestamp
  bugReport.seen = bugReport.seen ?? false
  bugReport.timestampMilliseconds = new Date(bugReport.timestamp).getTime()
}

export const GetBugReportsThunk = createAsyncThunk(
    'manageBugReportsDataSlice/GetBugReportsThunk',
    async ({ LastEvaluatedKey, page, limit, bringTotal, status }: ExtraDataBugReport) => {
      try { 

        let LastEvaluatedKeyStr = JSON.stringify(LastEvaluatedKey)
        const res = await getBugReports({ LastEvaluatedKey: LastEvaluatedKeyStr, limit, bringTotal, status })
        res.results = res.results.map((bugReport: BugReportTableItem) => {
            addRemainingFieldsbugReport(bugReport)
            return bugReport
        })
        return {
          results: res.results as BugReportTableItem[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          page,
          limit,
          bringTotal,
          total: res.total,
          status,
          totalCounterStatus:  res.totalCounterStatus
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as BugReportTableItem[],
          page,
          limit,
          bringTotal,
          total: 0
        } 
      }
    }
)

export const GetBugReportsBySearchThunk = createAsyncThunk(
  'manageBugReportsDataSlice/GetBugReportsBySearchThunk',
  async ({ stringToSearch, limit, bringTotal, page }: { page: number, stringToSearch: string, limit: number, bringTotal: boolean }) => {
    try { 
      //const res = await getUser({ stringToSearch, limit, bringTotal })
      const res : any = {}
      res.results = res.results.map((bugReport: BugReportTableItem) => {
        addRemainingFieldsbugReport(bugReport)
        return bugReport
      })
      return {
        results: res.results as BugReportTableItem[],
        LastEvaluatedKey: res.LastEvaluatedKey ?? "",
        page,
        limit,
        bringTotal,
        total: res.total,
        stringToSearch
      }
    } catch (error) {
      console.log("error", error)
      return {
        results: [] as BugReportTableItem[],
        page,
        limit,
        bringTotal,
        total: 0,
        stringToSearch
      }
    }
  }
)

export const GetBugReportByIdSearchBarThunk = createAsyncThunk(
    'manageBugReportsDataSlice/GetBugReportByIdSearchBarThunk',
    async ({ id }: { id: string }) => {
        try { 
            const res = await getBugReportById(id)
            return {
              bugReport: res.bugReport as BugReportTableItem
            }
        } catch (error) {
            return {
              bugReport: {} as BugReportTableItem
            }
        }
    }
)

export const GetBugReportByIdThunk = createAsyncThunk(
    'manageBugReportsDataSlice/GetBugReportByIdThunk',
    async ({ id }: { id: string }) => {

        return getBugReportById(id)
        .then((res) => {
            return {
              bugReport: res.bugReport as BugReportTableItem
            }
        }).catch(() => {
            throw("ERROR_GETTING_APP_REPORT")
        })

    }
)

export const numberBugReportsByPage: number[] = [ 10, 25, 50, 100 ]

type initialStateType = {
  stringToSearch: string
  total: number
  indexTab: number
  limit: number
  page: number
  type: string
  bugReportsIds: { id: string, timestamp?: string }[]
  bugReportsToShow: BugReportTableItem[],
  LastEvaluatedKey?: LastEvaluatedKeyBugReports,
  loadingContainer: boolean,
  orderColumn: string,
  orderDirection: boolean,
  bugReport: BugReportTableItem,
  loadingIndividualPage: boolean
  status?: StatusReport
  totalCounterStatus?: TotalCountersStatus
}

const initialState: initialStateType = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    limit: numberBugReportsByPage[0],
    page: 1,
    type: "",
    bugReportsIds: [],
    bugReportsToShow: [] as BugReportTableItem[],
    LastEvaluatedKey: { id: "" },
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    bugReport: {} as BugReportTableItem,
    loadingIndividualPage: true,
    status: undefined
}

export const manageBugReportsDataSlice = createSlice({
  name: 'manageBugReportsData',
  initialState,
  reducers: {

    updateSeenBugReportReducer: (state, action: PayloadAction<{ id: string }>) => {
        state.bugReportsToShow = state.bugReportsToShow.map((bugReport: BugReportTableItem) => {
          if(bugReport.id === action.payload.id)
              bugReport.seen = true
              //bugReport.status = "OPENED"
          return bugReport
        })
        return state
    },
    /*
    removeDeletedBugReportReducer: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      if(id === state.LastEvaluatedKey?.id && state.bugReportsIds.length > 1){
        //const position = state.bugReportsIds.indexOf(id)
        const bugReport = state.bugReportsToShow.find((bugReport: BugReportTableItem) => bugReport.id === action.payload.id)
        if(state.LastEvaluatedKey){
          state.LastEvaluatedKey?.id = bugReport?.id 
        }
          
      }
      state.bugReportsIds = state.bugReportsIds.filter((bugReport: string) => bugReport !== action.payload.id)
      state.bugReportsToShow = state.bugReportsToShow.filter((bugReport: BugReportTableItem) => bugReport.id !== action.payload.id)
      state.total = state.total - 1
      return state
    },
    */
    changeIndexTabReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
      state.bugReportsIds = []
      state.indexTab = action.payload.indexTab
      return state
    },
    orderBugReportsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
      const { orderColumn, orderDirection  } = action.payload
        /*
      state.bugReportsToShow = state.bugReportsToShow.map((bugReport: BugReportTableItem) => {

        //const bug = UserInfoCache.bugNameCache.get(bugReport.bugId)
        //if(bug?.name)
        //  bugReport.bugName = bug.name

        return JSON.parse( JSON.stringify(bugReport) )

      })*/
      state.bugReportsToShow = getOrderedState({ orderColumn, orderDirection, array: state.bugReportsToShow })
      state.orderColumn = orderColumn
      state.orderDirection = orderDirection
              
      return state
    },
    updateStatusBugReportReducer: (state, action: PayloadAction<{ id: string, status: StatusReport }>) => {
      state.bugReportsToShow = state.bugReportsToShow.map((bugReport: BugReportTableItem) => {
        if(bugReport.id === action.payload.id)
            bugReport.status = action.payload.status

        return bugReport
      })
      return state
    },
    updateUserAssignedBugReportReducer: (state, action: PayloadAction<string>) => {
      state.bugReport.userAssigned = action.payload
      return state
    },
    updatePriorityBugReportReducer: (state, action: PayloadAction<{ id: string, priority: PriorityReport }>) => {
      state.bugReportsToShow = state.bugReportsToShow.map((bugReport: BugReportTableItem) => {
        if(bugReport.id === action.payload.id)
            bugReport.priority = action.payload.priority
  
        return bugReport
      })
      return state
    },
    /*
    setDetailedBugReportReducer: (state, action: PayloadAction<{ bugReport: BugReportTableItem }>) => {
      state.bugReport = action.payload.bugReport 
      return state
    },
    */
    setLoadingBugReportReducer: (state, action: PayloadAction<boolean>) => {
      state.loadingIndividualPage = action.payload
      return state
    },
    setNewStatusBugReportReducer: (state, action: PayloadAction<{newStatus: StatusReport}>) => {
      state.bugReport.status = action.payload.newStatus  
      return state
    },
    setEndDateIncidentBugReportReducer: (state) => {
      if(state.bugReport.datesIncident)
        state.bugReport.datesIncident.endTime = new Date().toISOString()
      return state
    },
    setNewPriorityBugReportReducer: (state, action: PayloadAction<{newPriority: PriorityReport}>) => {
      state.bugReport.priority = action.payload.newPriority  
      return state
    },
    resetDetailedBugReportReducer: (state) => {
      state.bugReport = {} as BugReportTableItem
      state.loadingIndividualPage = true
      return state
    },
    resetManageBugReportsReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetBugReportsThunk.fulfilled, (state, action: PayloadAction<GetBugReportsResponse>) => {

      const { results, page, LastEvaluatedKey, limit, bringTotal, total, status, totalCounterStatus } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          state.bugReportsIds[skip+i] = { id: results[i].id, timestamp: results[i].timestamp }
          lastIndex = skip+i
      }
      
        state.bugReportsIds = state.bugReportsIds.slice(0,lastIndex+1)

        const itemsOrderedByDate = getOrderedState({ 
            array: results, 
            orderColumn: state.orderColumn ? state.orderColumn : "creationDate", 
            orderDirection: state.orderColumn ? state.orderDirection : false
        })
        state.bugReportsToShow = itemsOrderedByDate
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? { id: "" }
        state.limit = limit ?? 0
        state.status = status
        if(bringTotal)
            state.total = total

        if(totalCounterStatus)
            state.totalCounterStatus = totalCounterStatus

        state.stringToSearch = ""
        return state
                
      }).addCase(GetBugReportsThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetBugReportsThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      


      .addCase(GetBugReportByIdSearchBarThunk.fulfilled, (state, action: PayloadAction<{ bugReport: BugReportTableItem }>) => {
        const { bugReport } = action.payload
        if(bugReport.id){
          addRemainingFieldsbugReport(bugReport)
          state.bugReportsToShow = new Array(bugReport)
          state.bugReportsIds = new Array({ id: bugReport.id, timestamp: bugReport.timestamp })
          state.total = 1
        }else{
          state.bugReportsToShow = []
          state.bugReportsIds = []
          state.total = 0
        }
        state.loadingContainer = false
        state.stringToSearch = ""
        return state

      })
      .addCase(GetBugReportByIdSearchBarThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetBugReportByIdSearchBarThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })



      .addCase(GetBugReportByIdThunk.fulfilled, (state, action: PayloadAction<{ bugReport: BugReportTableItem }>) => {
        const { bugReport } = action.payload
        if(bugReport.id){
          addRemainingFieldsbugReport(bugReport)
          state.bugReport = bugReport
        }
        state.loadingIndividualPage = false
        return state

      })
      .addCase(GetBugReportByIdThunk.pending, (state, action: any) => {
        state.loadingIndividualPage = true
          return state     
      }).addCase(GetBugReportByIdThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingIndividualPage = false
          return failState
      })
      


      .addCase(GetBugReportsBySearchThunk.fulfilled, (state, action: PayloadAction<{ results: BugReportTableItem[], LastEvaluatedKey?: LastEvaluatedKeyBugReports, page: number, limit: number, bringTotal: boolean, total: number, stringToSearch: string }>) => {
          const { results, page, LastEvaluatedKey, bringTotal, total, stringToSearch } = action.payload
          state.bugReportsIds = results.map(( bugReport: BugReportTableItem ) => ({ id: bugReport.id, timestamp: bugReport.timestamp }))
          state.bugReportsToShow = results
          state.loadingContainer = false
          state.page = page
          state.LastEvaluatedKey = LastEvaluatedKey ?? undefined
          if(bringTotal)
            state.total = total
          state.total = 0
          state.loadingContainer = false
          state.stringToSearch = stringToSearch
          return state
      }).addCase(GetBugReportsBySearchThunk.pending, (state, action: any) => {
          state.loadingContainer = true
          return state     
      }).addCase(GetBugReportsBySearchThunk.rejected, (state, action: any) => {
          const failState = {...initialState}
          failState.loadingContainer = false
          return failState
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
  updateSeenBugReportReducer, 
  changeIndexTabReducer,
  resetManageBugReportsReducer,
  orderBugReportsReducer,
  updateStatusBugReportReducer,
  updatePriorityBugReportReducer,
  setNewStatusBugReportReducer,
  setNewPriorityBugReportReducer,
  resetDetailedBugReportReducer,
  setLoadingBugReportReducer,
  setEndDateIncidentBugReportReducer,
  updateUserAssignedBugReportReducer
} = manageBugReportsDataSlice.actions

export default manageBugReportsDataSlice.reducer