import React, { useState } from 'react'
import "./FooterLogin.css"
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const FooterLogin = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [logosSocialMedia] = useState([
        { icon: <FaFacebookSquare className="fs-22" />, link: "https://www.google.com/" },
        { icon: <FaTwitter className="fs-22" />,        link: "https://www.google.com/" },
        { icon: <FaYoutube className="fs-22" />,        link: "https://www.youtube.com/channel/UCChkGMMruHoC_bFGgwekedA" },
        { icon: <FaLinkedin className="fs-22" />,       link: "https://www.linkedin.com/company/gimwork/" },
        { icon: <FaInstagram className="fs-22" />,      link: "https://www.google.com/" }
    ])

    const goToScreen = (screen: string) => {
        window.location.href= `https://gimwork.com/${screen}`;
    }

    const goToInternalScreen = (screen: string) => {
        navigate(`/${screen}`)
    }

    return (
        <footer className="footer-container center">

            <div className="cotainer-login width-100">

                <div className="flex-row-column flex-jc-sb">
                    <a href="https://www.gimwork.com">
                        <img className="navbar-logo" src={"./assets/images/LOGO.svg"} alt="Logo" />
                    </a>

                    <p className="c-white fs-16 help-center-footer"> {t("General:label_220")} </p>
                </div>

                <div className="flex-row-column mt-60">

                    <div className="flex flex-d-column">
                        <p className="c-white fs-20 fw-600 mb-4" > {t("General:label_221")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("about-us")}> {t("General:label_222")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("investors-relations")} > {t("General:label_223")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("carrers")}> {t("General:label_224")} </p>
                    </div>

                    <div className="flex flex-d-column column-margin-left">
                        <p className="c-white fs-20 fw-600 mb-4 title-column-footer"> {t("General:label_225")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("how-gimwork-works")}> {t("General:label_226")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("our-offerings")}> {t("General:label_227")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("be-a-gim")}> {t("General:label_228")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("be-a-gimmer")}> {t("General:label_229")} </p>
                    </div>

                    <div className="flex flex-d-column column-margin-left">
                        <p className="c-white fs-20 fw-600 mb-4 title-column-footer"> {t("General:label_230")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("blog")}> {t("General:label_231")} </p>
                        <p className="c-white fs-16 mt-20 pointer" onClick={() => goToScreen("safety")}> {t("General:label_232")} </p>
                    </div>

                </div>



                <div className="flex flex-ai-center mt-60 con-icons-footer">
                    {
                        logosSocialMedia.map((logo, index) => {

                            return(
                                <a key={index} href={logo.link} className="item-logo-footer center" target="_blank">
                                    {logo.icon}
                                </a>
                            )
                        })
                    }
                </div>
                
                <div className="flex mt-60">

                    <a href="https://play.google.com/" target="_blank">
                        <img
                            className="store-logo pointer"
                            src="https://www.gimwork.com/wp-content/uploads/2023/07/google-play-badge.png" 
                            alt="app sotre logo"
                        />
                    </a>

                    <a href="https://www.apple.com/app-store" target="_blank">
                        <img
                            className="store-logo ml-10 pointer"
                            src="https://www.gimwork.com/wp-content/uploads/2023/07/Download_on_the_App_Store_Badge_US-UK.png" 
                            alt="app sotre logo"
                        />
                    </a>

                </div>

                <div className="flex-row-column flex-jc-sb mt-50">
                    <p className="rights-reserved-footer">
                        {t("General:label_219")}
                    </p>

                    <div className="flex-row-column container-extra-links-footer">
                        <button className="pointer" onClick={() => goToInternalScreen("privacy-policy")}>
                            <p className="extra-links-footer">{t("General:label_233")}</p>
                        </button>
                        <button className="pointer" onClick={() => goToInternalScreen("legal-notice")}>
                            <p className="extra-links-footer">{t("General:label_235")}</p>
                        </button>
                        {
                            /*
                                <button>
                                    <p className="extra-links-footer">{t("General:label_234")}</p>
                                </button>
                            */
                        }
                        <button className="pointer" onClick={() => goToInternalScreen("terms-and-conditions")}>
                            <p className="extra-links-footer">{t("General:label_221")}</p>
                        </button>
                    </div>
                </div>

            </div>

        </footer>
    )
}

export default FooterLogin