import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import LoadingSpinner from '../../LoadingSpinner'
import ProductsRequestsItems from './ProductsRequestsItems/ProductsRequestsItems'
import { ProductDB } from '../../../redux/slices/components/Products/ProductsSlice'
import { showAddRequestProductModalReducer } from '../../../redux/slices/components/RequestProducts/requestProductsDataSlice'

const ContainerProductsRequests = () => {

    const dispatch = useAppDispatch()

    const products = useSelector((state: RootState) => state.requestProductsData.productsToShow)
    const loadingContainer = useSelector((state: RootState) => state.requestProductsData.loadingContainer)

    const onPress = useCallback(
        (product: ProductDB) => {
            dispatch(showAddRequestProductModalReducer(product))
        },
        [],
    )
    
    return (
        <div className="flex-1 p-relative mt-20">
            <div className="flex">
            {
                products.map((product) => {
                    return(
                        <ProductsRequestsItems key={product.id} onPress={onPress} product={product} />
                    )
                })
            }
            </div>
            { loadingContainer && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default React.memo( ContainerProductsRequests )