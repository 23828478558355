import { FaCheck } from 'react-icons/fa'

interface MyCheckboxCircularProps {
    onChange: (arg: string) => void,
    valueCheckBox: boolean,
    value: string
    size?: string,
    sizeInner?: string,
    backgroundColor?: string,
    borderThickness?: string,
    borderColor?: string,
}

const MyCheckboxCircular = ({ onChange, value,
    size = "1rem", sizeInner = ".8rem", backgroundColor = "black", 
    borderThickness = "1px", borderColor = "#000", valueCheckBox
 }: MyCheckboxCircularProps) => {


    return (
        <div style={{
            width: size,
            height: size,
            border: `${borderThickness} solid ${borderColor}`,
            borderRadius: "50%",
            cursor: "pointer",
            position: "relative" 
        }}
            onClick={(e) => onChange(value)}
        >
            { 
                valueCheckBox && 
                <div 
                    style={{
                        borderRadius: "50%",
                        width: sizeInner,
                        height: sizeInner,
                        backgroundColor,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)"
                    }}
                >

                </div>
            }
        </div>
    )
}

export default MyCheckboxCircular