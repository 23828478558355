import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ManageUser } from '../../../../redux/slices/components/ManageUsers/manageUsersDataSlice'
import { resetManageUsersModalsReducer } from '../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import LoadingSpinner from '../../../LoadingSpinner'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./EditMultipleUsersRolesModal.css"

const EditMultipleUsersRolesModal = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const { usersGrouped } = useSelector((state: RootState) => state.manageUsersGroupedData)

    const closeModal = () => {
        dispatch(resetManageUsersModalsReducer())
    }
    

    return (
        <div className="blank-wrapper-modals p-relative">
            <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
                <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
            </button>
            <TitleModal title={`${t("General:label_150")}`} />

            <div className="p-relative con-multiple-inputs-edit-roles">

                <div className="flex flex-d-column  aux-scroll">
                    {
                        usersGrouped.map((user: ManageUser) => {
                            return(
                                <div key={user.id} className="mb-20">
                                    <label className="fs-14 black fw-600" htmlFor="role">{`${user.firstName} ${user.lastName}`} </label>
                                    <div className="con-input mb-280">
                                                <select
                                                    name="role"
                                                    className="input-modal-role"
                                                    onChange={(e: any) => {
                                                        //setRoleValue(e.target.value)
                                                    }}
                                                    //value={roleValue}
                                                >
                                                    <option 
                                                        value={user.roleId}
                                                    >
                                                        {user.roleId}
                                                    </option>
                                            </select>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            <ConfirmButton text={t("General:label_016")} width="100%" />

            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default EditMultipleUsersRolesModal