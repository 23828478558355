import { Colors } from "../../../../../../utils/colors/colors"


interface TitleNotificationProps {
	title: string
}

export const TitleNotification = ({ title }: TitleNotificationProps) => {

	return (
		<p className="fs-18-vh" style={{ color: Colors.TEXT }}>
			{title}
		</p>
	)
}
