import  { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from "../../../redux/store/store"
import { useAppDispatch  } from '../../../redux/store/store';
import JobDropdownByCountry from './JobDropdownsByCountry/JobDropdownsByCountry'
import "./JobDropdowns.css"
import { CategoryJob, SortEvent, getAllJobsCategoriesChunk } from '../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import LoadingSpinner from '../../LoadingSpinner';
import React from 'react';

type JobDropdownsUIProps = {
    categoryJobs: CategoryJob[];
    countryNames: string[];
    orderJobs: SortEvent[];
}

const JobDropdownsUI = React.memo(({
    categoryJobs,
    countryNames,
    orderJobs
}:JobDropdownsUIProps) => {

  return(
      <>
          {
            countryNames?.map((countryName: string, index: number) => (
              <JobDropdownByCountry 
                  key={index}
                  countryName={countryName}
                  categoriesJobs={categoryJobs.filter((m: CategoryJob) => m.country === countryName)}
                  orderJobs={orderJobs}
              />
            ))
        }
      </>
  )
})

const JobDropdowns = React.memo(() => {

    const dispatch = useAppDispatch()

    const loading = useSelector((state: RootState) => state.managerJobData.loading);
    const categoryJobs = useSelector((state: RootState) => state.managerJobData.categoryJobs);
    const countryNames = useSelector((state: RootState) => state.managerJobData.countryNames);
    const orderJobs = useSelector((state: RootState) => state.managerJobData.orderJobs);

    useEffect(() => {

        dispatch(getAllJobsCategoriesChunk())
        return () => {
        }
    }, []);

    return (
      <div className="container-job-dropdowns">
          <div className="aux-scroll">
              { loading && <LoadingSpinner fullPage={false} backgroundColor="#fff" /> }

              <JobDropdownsUI 
                  categoryJobs={categoryJobs}
                  countryNames={countryNames}
                  orderJobs={orderJobs}
              />

          </div>
      </div>
    )
})

export default JobDropdowns