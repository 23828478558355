import HeaderManageBugReportScreen from '../../../../components/ManageBugReportScreen/HeaderManageBugReportScreen/HeaderManageBugReportScreen'
import HeaderTableManageBugReport from '../../../../components/ManageBugReportScreen/HeaderTableManageBugReport/HeaderTableManageBugReport'
import BugReportRows from '../../../../components/ManageBugReportScreen/BugReportRows/BugReportRows'
import PaginationBugReportTableRow from '../../../../components/ManageBugReportScreen/PaginationBugReportTableRow/PaginationBugReportTableRow'
import ButtonsBugReports from '../../../../components/ManageBugReportScreen/ButtonsBugReports/ButtonsBugReports'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../../redux/store/store'
import { resetManageBugReportsReducer } from '../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'


const ManageBugsReportsScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {

        return () => {
            dispatch(resetManageBugReportsReducer())
        }
    }, [ ])
  
    return (
        <div className="flex flex-d-column height-100 p-relative">
            <HeaderManageBugReportScreen />
            <ButtonsBugReports />
            <HeaderTableManageBugReport />
            <BugReportRows />
            <PaginationBugReportTableRow />

            { 
             //show && <DetailedBugReportScreen /> 
            }
        </div>
    )
}

export default ManageBugsReportsScreen