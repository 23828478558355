import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import { orderHistoryReducer } from '../../../redux/slices/components/MyHistory/myHistoryDataSlice';
import "../TableProducts.css"


const HeaderTableManageProducts = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.manageProductsData.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.manageProductsData.orderColumn)
    const typeProduct = useSelector((state: RootState) => state.manageProductsData.typeProduct)

    const orderArray = useCallback(
            ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {  
                dispatch(orderHistoryReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
            },
            [],
    )

    return (
        <div className="flex con-header-table-history ff-roboto">

            <div className="products-item1 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("ManageProducts:table:label_03")}
                    orderColumn={orderColumn} 
                    columnName={"productName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="products-item2 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("ManageProducts:table:label_04")}
                    orderColumn={orderColumn} 
                    columnName={"description"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="products-item3 center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={"IVA GIMWORK"}
                    orderColumn={orderColumn} 
                    columnName={"IVA_GIMWORK"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="products-item4 center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={"IVA GIMMER"}
                    orderColumn={orderColumn} 
                    columnName={"IVA_GIMMER"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="products-item5 center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={"RMPC"}
                    orderColumn={orderColumn} 
                    columnName={"RMPC"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="products-item6 center fs-14 mt-4 c-manatee">
                {
                    typeProduct === "DIGITAL" &&
                    <p className="fs-14-vh c-oslo-gray">{t("ManageProducts:table:label_05")}</p>
                }
            </div>

            <div className="products-item7 center fs-14 mt-4 c-manatee">
                <p className="fs-14-vh c-oslo-gray">{t("ManageProducts:table:label_06")}</p>
            </div>

            <div className="products-item8 center fs-14 mt-4 c-manatee">
                <p className="fs-14-vh c-oslo-gray">{t("ManageProducts:table:label_07")}</p>
            </div>
        
        </div>
    )
}

export default React.memo( HeaderTableManageProducts )