import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import "./TabsUserReportChat.css"

type TabsUserReportChatProps = {
    tabUsers: number
    changeTab: (arg: number) => void
}

const TabsUserReportChat = ({ tabUsers, changeTab }: TabsUserReportChatProps) => {

    const requestByUserType = useSelector((state: RootState) => state.manageUserReports.userReport.requestByUserType)

    const firstNameRequestBy = useSelector((state: RootState) => state.manageUserReports.userRequestBy?.firstName)
    const lastNameRequestBy = useSelector((state: RootState) => state.manageUserReports.userRequestBy?.lastName)
    const imgUriRequestBy = useSelector((state: RootState) => state.manageUserReports.userRequestBy?.imgUri)

    const firstNameUserInvolved = useSelector((state: RootState) => state.manageUserReports.userInvolved?.firstName)
    const lastNameUserInvolved = useSelector((state: RootState) => state.manageUserReports.userInvolved?.lastName)
    const imgUriUserInvolved  = useSelector((state: RootState) => state.manageUserReports.userInvolved?.imgUri)

    return (
        <div className="flex" style={{ marginTop: "3.5vh" }}>

            <div onClick={() => changeTab(0)} className={`user-report-tab width-50 center ${tabUsers === 0 ? "tab-chat-selected" : "tab-chat-unselected"}`}>
                <img 
                    alt="user"
                    src={imgUriRequestBy ?? "/user.svg"} 
                    className="img-tab-user-report circle" 
                />
                <p className={`color-dark-gim fw-500 fs-16 ml-16 ${requestByUserType === "Gim" ? "color-dark-gim" : "color-dark-gimmer"}`} >
                    {`${firstNameRequestBy} ${lastNameRequestBy}`}
                </p>
            </div>

            <div onClick={() => changeTab(1)} className={`user-report-tab width-50 center ${tabUsers === 1 ? "tab-chat-selected" : "tab-chat-unselected"}`}>
                <img 
                    alt="user"
                    src={imgUriUserInvolved ?? "/user.svg"} 
                    className="img-tab-user-report circle" 
                />
                <p className={`color-dark-gim fw-500 fs-16 ml-16 ${requestByUserType !== "Gim" ? "color-dark-gim" : "color-dark-gimmer"}`}>
                    {`${firstNameUserInvolved} ${lastNameUserInvolved}`}
                </p>
            </div>

        </div>
    )
}

export default React.memo( TabsUserReportChat )