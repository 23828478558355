import React from 'react'
import { ProductType } from '../../../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from 'moment'
import "./MarketplaceRow.css"
import { FileProduct, MarketplaceProduct } from '../../../../../redux/slices/authSlice'
import { TypeProduct } from '../../../../../redux/slices/components/Products/ProductsSlice'

type MarketplaceRowProps = {
    id: string
    productName: string
    price: number
    date: string
    description: string
    type: ProductType
    File?: FileProduct
    jobId: string
    productId: string
    typeProduct: TypeProduct
    onPress: () => void
    openDeleteModal: (id: string, productName: string, type: ProductType) => void
    openEditModal: (product: MarketplaceProduct) => void
    downloadFile: (idProduct: string, Name: string, type: ProductType, fileType: string) => void
}

const MarketplaceRow = ({
    id,
    productName,
    price,
    date,
    description,
    type,
    File,
    jobId,
    productId,
    typeProduct,
    onPress,
    openDeleteModal,
    openEditModal,
    downloadFile
}: MarketplaceRowProps) => {

    const OpenEditModal = () => {
        openEditModal({
            id,
            date,
            description,
            price,
            productName,
            File,
            jobId,
            productId,
            typeProduct
        })
    }

    return (
        <div onClick={onPress} className="marketplace-row-card flex mt-8">
            <div className="marketplace-item1 flex flex-ai-center fs-13 mt-4 c-manatee ">
                <p className="fs-13 fw-600">{productName}</p>
            </div>

            <div className="marketplace-item2 flex flex-ai-center fs-13 mt-4 center">
                <p className="fs-13 fw-600">{`${price.toFixed()} $`}</p>
            </div>
            
            <div className="marketplace-item3 flex flex-ai-center fs-13 mt-4 center">
                {
                    type === "Purchased" &&
                    <p className="fs-13 fw-600">{moment(date).fromNow()}</p>
                }
            </div>
            
            <div className="marketplace-item4 flex flex-ai-center flex-jc-center fs-13 mt-4">
                <p className="fs-13 fw-600 text-3-points" style={{ height: "1.6rem"}}>{description}</p>
            </div>
            
            <div className="marketplace-item5 center fs-13 mt-4">
                {
                    File &&
                    <p className="fs-13 fw-600">{File.type}</p>
                }
            </div>
            
            <div className="marketplace-item6 center fs-13 mt-4">
                {  
                    File && 
                    <button 
                        //onClick={() => downloadFile(id, File.name, type, File.type)}  
                    >
                        <FontAwesomeIcon icon={solid("check")} className="" />
                    </button> }
            </div>
            
            <div className="marketplace-item7 center fs-13 mt-4">
                {
                    type === "Marketplace"
                    ?   <button className="pointer" onClick={OpenEditModal} > <FontAwesomeIcon icon={solid("pen")} className="" /> </button>
                    :  (File 
                        ?  <button className="pointer" onClick={() => downloadFile(id, File.name, type, File.type)} > <FontAwesomeIcon icon={solid("download")} className="" /> </button> 
                        : <></>)
                }
            </div>
            
            <div className="marketplace-item8 fs-13 mt-4 center">
                <button
                    onClick={() => openDeleteModal(id, productName, type)}
                    className="pointer"
                >
                    <FontAwesomeIcon icon={solid("trash")} />
                </button>
            </div>

        </div>
    )
}

export default React.memo( MarketplaceRow )