import { useEffect, useRef } from "react"
import { useAppDispatch } from "../../../redux/store/store";
import { removeVerifiedPictureImageReducer, setLoadingPictureImageSliderReducer, undoProfilePictureThunk, UserProfileImage } from '../../../redux/slices/components/VerifyPicture/verifyPictureDataSlice';
import { verifyUserProfileImage } from "../../../utils/requestsUtils/functionsRequests/user/userFunctions";
import SliderCard from './SliderCard/SliderCard';
import "./SliderPicture.css"
import useCheckAccess from "../../hooks/useCheckAccess/useCheckAccess";

interface SliderPictureProps {
    sliderData: UserProfileImage[],
    lastUserVerified: UserProfileImage
}

const SliderPicture = ({ sliderData, lastUserVerified }: SliderPictureProps) => {
    
    const accessEdit = useCheckAccess({ id_Category: "Verify", id_Policy: "Profile_Picture", id_Action: "Edit" })

    const dispatch = useAppDispatch()

    // This ref persistent variables are neccessary because the props are not
    // updated in event listeners of keys
    const persistentSliderData = useRef<UserProfileImage[]>(sliderData)
    persistentSliderData.current = sliderData

    const persistentLastUserVerified  = useRef<UserProfileImage>(lastUserVerified)
    persistentLastUserVerified.current = lastUserVerified
    
    const undo = () => {
        dispatch(undoProfilePictureThunk(persistentLastUserVerified.current))
    }

    const verifyPicture = (newVerifiedState: boolean) => {
        const userToUpdate = persistentSliderData.current[0]
        dispatch(setLoadingPictureImageSliderReducer(true))
        verifyUserProfileImage(userToUpdate.id, { newVerifiedState })
        .then((res) => {
            dispatch(removeVerifiedPictureImageReducer({ id: userToUpdate.id, user: userToUpdate}))
        }).catch((error) => {
            console.log("error", error)
        }).finally(() => { dispatch(setLoadingPictureImageSliderReducer(false)) })
    }


    useEffect(() => {
      
        const keyPressed = (e: any) => {
            
            switch (e.key) {
                case "ArrowRight":
                    if(persistentSliderData.current[0]?.id)
                        verifyPicture(true)
                break;
                case "ArrowDown":
                    if(persistentSliderData.current[0]?.id)
                        verifyPicture(false)
                break; 
                case "ArrowLeft":
                    if(persistentLastUserVerified.current.id)
                        undo()
                break;
                default:
                    break;
            }
            
        }

        document.addEventListener("keyup", keyPressed)
    
        return () => {
                document.removeEventListener("keyup", keyPressed)
        }
    }, [])
    

    return (
        <div className="con-rec-gray-slider">
            <div id="con-gray-slider-profile-picture" className="rec-gray-slider p-relative"> 

                {
                    sliderData.map((user: UserProfileImage, index: number) => {
                        return(
                            <SliderCard 
                                key={user.id}
                                user={user}
                                index={index}
                                editable={accessEdit}
                            />
                        )
                    })
                }
                <div className="p-absolute cover-picture-card" style={{ left: 0 }}></div>
                <div className="p-absolute cover-picture-card" style={{ right: 0 }}></div>
            </div>
        </div>
    )
}

export default SliderPicture