import React from 'react'

type DateTextReloadProps = {
    text: string
    style: any
}

const DateTextReload = ({
    text,
    style
}: DateTextReloadProps) => {
    return (
        <p style={style}>
            {text}
        </p>
    )
}

export default React.memo( DateTextReload );