
import { FaHouseUser, FaLaptop, FaWalking } from 'react-icons/fa'
import { WorkPreference } from '../../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import "./WhereIcons.css"

const WhereIcons = ({ outside, inside, remote }:WorkPreference) => {

  return (
    <div className="con-where-icons flex">

        {
            remote &&
            <div className="con-where-icon">
                <div className="where-icon center circle c-white fs-14 where-icon-first"><FaLaptop /></div>
            </div>
        }

        {
            inside.latitude && inside.longitude &&
            <div className="con-where-icon">
                <div className="where-icon center circle c-white fs-14 where-icon-second"><FaHouseUser /></div>
            </div>
        }

        {
            outside.latitude && outside.longitude &&
            <div className="con-where-icon">
                <div className="where-icon center circle c-white fs-14 where-icon-third"><FaWalking /></div>
            </div>
        }

    </div>
  )
}

export default WhereIcons