import React, { useEffect, useState } from 'react'
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../LoadingSpinner'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import { ContractInfo, setEditContractState } from '../../../../../redux/slices/components/Rules/rulesSlice'
import { getUrlPutRules } from '../../../../../utils/requestsUtils/functionsRequests/rules'
import axios from 'axios'
import "./FormEditContractModal.css"
import { LanguageLabel } from '../../../../../redux/slices/authSlice'
import { Languages } from '../../../../../utils/staticVariables/staticVariables'

type FormAddContractModalProps = {
    contractInfo: ContractInfo
    closeModal: () => void
}

const FormEditContractModal = ({ contractInfo, closeModal }: FormAddContractModalProps) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        contract: { error: "", value: contractInfo.contract["en"] ?? "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
        language: { error: "", value: "en", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().justLetters()},
    });  


    const handleSubmit = async(e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        setLoading(true)
        try {

            const urlSigned: string = await getUrlPutRules(contractInfo.countryName, inputValues["language"].value as LanguageLabel)

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                }
            }
            const fileData = JSON.stringify( inputValues["contract"].value )
            const blob = new Blob([fileData], { type: "text/plain" });
            
            await axios.put(urlSigned, blob, config)

            dispatch(setEditContractState({
                countryName: contractInfo.countryName,
                language: inputValues["language"].value as LanguageLabel,
                contract: inputValues["contract"].value,
            }))
            closeModal()
        } catch (error) {
            console.log("Error", error)
        }

        setLoading(false)

    }
    console.log("contractInfo", contractInfo)
    const checkErrors = (e: any) => {
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)


        let contract = ""
        let auxInputValues: any = { }
        if(e.target.name === "contract"){
            auxInputValues = {...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }, }
        }
        else{
            contract = contractInfo.contract[value as LanguageLabel] ?? ""
            auxInputValues = { ...auxInputValues, 
                [e.target.name]: {...inputValues[e.target.name], value, error: "" },  
                ["contract"]: {...inputValues["contract"], value: contract, error: "" },  
            }
        }
            

        if(result.errorMsg){
            setInputValues({...auxInputValues, 
                error: result.errorMsg
            })
        }
        else{
            setInputValues({...auxInputValues
            })

        }
            
    }
    

    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)

        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }
    

    return (
        <form onSubmit={handleSubmit} className="flex flex-d-column height-100 flex-jc-sb" >

            <div className="flex flex-d-column width-100">

                <div className="flex flex-d-column con-label-input-modal">
                    <label className="fs-14 black fw-600" htmlFor="language">{t("General:label_271")} <span className="c-asterik">*</span></label>
                    <div className="con-input">
                        <select
                            name="language"
                            className="input-modal"
                            onChange={(e: any) => {
                                checkErrors(e)
                            }}
                            value={inputValues["language"].value}
                        >
                            {
                                Languages.map((language: string, index: number) => (
                                        <option 
                                            key={index}
                                            value={language}
                                        >
                                            { language }
                                        </option>

                                ))
                            }
                        </select>
                        { inputValues["language"].error && (
                            <p className="error-input-text">{inputValues["language"].error  || ""}</p>
                        )}
                    </div>
                </div>

                <div className="flex flex-d-column mt-10 mb-10 width-100">
                    <div className="con-input">
                        <textarea
                            id="contract"
                            name="contract"
                            //type="text"
                            placeholder={t("General:label_258") ?? ""}
                            className="input-modal contract-height-text-area p-12 resize-none"
                            onChange={(e: any) => {
                                checkErrors(e)
                            }}
                            value={inputValues["contract"].value}
                            onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                        />

                    </div>
                    <div className="p-relative">
                        { inputValues["contract"].error && (
                            <p className="error-input-text" style={{ top: "-.9rem" }}>{inputValues["contract"].error  || ""}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-d-column mt-10">
                <ConfirmButton text={t("General:label_131")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default React.memo( FormEditContractModal )