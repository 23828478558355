import React from 'react'
import "./TableManageRoles.css"
import HeaderTableRoles from './HeaderTableRoles/HeaderTableRoles'
import ContentTableRoles from './ContentTableRoles/ContentTableRoles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import LoadingSpinner from '../../LoadingSpinner'

const TableManageRoles = () => {

    const loading = useSelector((state: RootState) => state.manageRoles.loading)

    return (
        <div className="con-table-roles p-relative mt-30">
            {
                loading &&
                <LoadingSpinner fullPage={false} />
            }
            <HeaderTableRoles />
            <ContentTableRoles />
        </div>
    )
}

export default React.memo( TableManageRoles )