import React, { useState } from 'react'
import TitleInBorderForInput from '../../../../../UIcomponents/TitleInBorderForInput/TitleInBorderForInput'
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../../utils/verifications/Forms'
import { MyYup } from '../../../../../../utils/verifications/myYup';
import { useTranslation } from 'react-i18next';
import { BodyINE_C, FormINEProps, INE } from '../../../../../../utils/INE/INE';
import ButtonSubmitFormINE from '../ButtonSubmitFormINE/ButtonSubmitFormINE';

const FormTypeC = ({ onSubmit }: FormINEProps) => {

    const { t, i18n } = useTranslation()

    const [inputValues, setInputValues] = useState<ValuesForm>({
        claveElector: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().length(18)},
        numEmision: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().length(2)},
        ocr: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(12).max(13)},
    });  

    const handleSubmit = async(e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput, inputValues))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return


          const body: BodyINE_C = {
              modelo: INE.C,
              claveElector: inputValues["claveElector"].value,
              numEmision: inputValues["numEmision"].value,
              ocr: inputValues["ocr"].value, 
          }

          onSubmit(body)


    }

    return (
        <form onSubmit={handleSubmit}>

            <div className="flex flex-d-column mt-16">
                <div className="con-input padding-to-show-error">
                    <TitleInBorderForInput name={"claveElector"} title={"Clave Elector"} />   
                    <input
                        id="claveElector"
                        name="claveElector"
                        type="text"
                        placeholder={"Clave Elector"}
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e, inputValues, setInputValues)
                        }}
                        value={inputValues["claveElector"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                    />
                    { inputValues["claveElector"].error && (
                        <p className="error-input-text">{inputValues["claveElector"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex flex-d-column mt-16">
                <div className="con-input padding-to-show-error">
                    <TitleInBorderForInput name={"numEmision"} title={"Número de emisión"} />   
                    <input
                        id="numEmision"
                        name="numEmision"
                        type="text"
                        placeholder={"Número de emisión"}
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e, inputValues, setInputValues)
                        }}
                        value={inputValues["numEmision"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                    />
                    { inputValues["numEmision"].error && (
                        <p className="error-input-text">{inputValues["numEmision"].error  || ""}</p>
                    )}
                </div>
            </div>

            <div className="flex flex-d-column mt-16">
                <div className="con-input padding-to-show-error">
                    <TitleInBorderForInput name={"ocr"} title={"Ocr"} />   
                    <input
                        id="ocr"
                        name="ocr"
                        type="text"
                        placeholder={"Ocr"}
                        className="input-modal"
                        onChange={(e: any) => {
                            checkErrors(e, inputValues, setInputValues)
                        }}
                        value={inputValues["ocr"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                    />
                    { inputValues["ocr"].error && (
                        <p className="error-input-text">{inputValues["ocr"].error  || ""}</p>
                    )}
                </div>
            </div>

            <ButtonSubmitFormINE onPress={handleSubmit} />

        </form>
    )
}

export default React.memo( FormTypeC )