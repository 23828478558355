import { useEffect, useState } from "react"
import "./HomeScreen.css"
import { getOffersPublic, GimGimmer } from "../../utils/requestsUtils/functionsRequests/offers/offersFunctions"
import ContainerOfferCards from "../../components/Home/ContainerOfferCards/ContainerOfferCards"
import { useAppDispatch } from "../../redux/store/store"
import { GetOffersHomeThunk } from "../../redux/slices/components/HomeOffers/homeOffersSlice"
import { useSearchParams } from "react-router-dom"
import { isUUID } from "../../utils/verifications/uuid/uuid"
import { canBeInteger } from "../../utils/verifications/strings"
import TwoSelectorBarGimGimmer from "../../components/Home/TwoSelectorBarGimGimmer/TwoSelectorBarGimGimmer"


type HomScreenProps = {
  isPrivate: boolean
}

const HomeScreen = ({ isPrivate }: HomScreenProps ) => {

    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams();

    useEffect(() => {

      const pageParam = searchParams.get("page") ?? "1"
      const limitParam = searchParams.get("limit") ?? "10"
      const jobIdParam = searchParams.get("jobId") ?? undefined
      const typeParam = searchParams.get("type") ?? "DIGITAL"

      let type: GimGimmer = "Gim"
      if(typeParam === "Gimmer")
          type = "Gimmer"

      let page = 10
      if( canBeInteger(pageParam) )
          page = Number(pageParam)

      let limit = 10
      if( canBeInteger(limitParam) )
          limit = Number(limitParam)
      
      let jobId = undefined
      if( jobIdParam && isUUID(jobIdParam) )
          jobId = jobIdParam

      
      dispatch( GetOffersHomeThunk({ type, page, limit, jobId }) )

      
    }, [searchParams.get("type"), searchParams.get("jobId"), searchParams.get("limit"), searchParams.get("page")])
    

    return (
    <div className="container-home-screen">
        { // loadingAuthUser && <LoadingSpinner fullPage={true} />
         }
         <div className="">
            <TwoSelectorBarGimGimmer />
         </div>
        <ContainerOfferCards />
    </div>
  )
}

export default HomeScreen