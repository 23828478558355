import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetUsersThunk, ManageUser, resetManageUsersReducer } from '../../../../redux/slices/components/ManageUsers/manageUsersDataSlice'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import LoadingSpinner from '../../../LoadingSpinner'
import "./ContainerRowsTableUsers.css"
import RowTableUsers from './RowTableUsers/RowTableUsers'

const ContainerRowsTableUsers = () => {
 
    const dispatch = useAppDispatch()

    const usersToShow = useSelector((state: RootState) => state.manageUsersData.usersToShow)
    const limit = useSelector((state: RootState) => state.manageUsersData.limit)
    const loadingContainer = useSelector((state: RootState) => state.manageUsersData.loadingContainer)
    const usersGrouped = useSelector((state: RootState) => state.manageUsersGroupedData.usersGrouped)

    useEffect(() => {
        dispatch(GetUsersThunk({ page: 1, limit, bringTotal: true}))

        return () => {
            dispatch(resetManageUsersReducer())
        }
    }, [])
    

    return (
        <div id={`container-rows-manage-users`} className="container-rows-table-users p-relative width-100 height-100 ff-roboto" >
            { loadingContainer && <LoadingSpinner fullPage={false} /> }
            <div className="aux-scroll">
                {
                    usersToShow.map((user: ManageUser) => {
                        const userGrouped = usersGrouped.some((userGrouped: ManageUser) => user.id === userGrouped.id)
                        return(
                            <RowTableUsers 
                                key={user.id} 
                                user={user} 
                                checkboxValue={userGrouped ? true : false}
                            />
                        )
                    })
                }
            </div> 
        </div>
    )
}

export default ContainerRowsTableUsers