export const Colors = {
    TEXT: "#3A4153",
    DustyGray: "#949494",
    Gim: "#6290C3",
    Gimmer: "#E54B4B",
    PersianRed: "#CE2828",
    MercuryGray: "#E6E6E6",
    Manatee: "#92959D",
    PictonBlue: "#4797F0",
    secondaryColor: '#E54B4B',
    mainColor: '#6290C3',
    flagGreen: "#006837",
    brillantBlue: "#4797F0",
    gwBlueGrey: '#3A4153',
    approve: '#228B22',
    block: '#E80000',
}


type StyleType = "blue" | "green" | "orange" | "violet" | "japonica" | "black" | "black-bold" | "gim" | "gimmer" | "gray" | "rmp" | "share" | "stripe" | "square-result"
export const getStyles = (text: any, style: StyleType) => {
    let str = `<span style="background-color:XX;font-weight:ZZ;font-size: 1.1vh;padding: 0 3px 0 3px;color:WW;border-radius:1px; border: 1px solid YY;">${text}</span>`
    if(style === "blue")
        return str.replace(/XX/, "#ACD6BA")
    else if(style === "green")
        return str.replace(/XX/, "#CBAAFF")
    else if(style === "orange")
        return str.replace(/XX/, "#FEC246")
    else if(style === "violet")
        return str.replace(/XX/, "#C3BEFF")
    else if(style === "japonica")
        return str.replace(/XX/, "#DB6F6F66")
    else if(style === "rmp")
        return str.replace(/XX/, "#BAECFF")
    else if(style === "share")
        return str.replace(/XX/, "#FCE8D2")
    else if(style === "black-bold"){
        str.replace(/XX/, "white")
        return str.replace(/ZZ/, "600")
    }
    else if(style === "black")
        return str.replace(/XX/, "white")
    else if(style === "stripe")
        return str.replace(/WW/, "#82B989")
    else if(style === "gim")
        return str.replace(/WW/, "#6290C3")
    else if(style === "gimmer")
        return str.replace(/WW/, "#E54B4B")
    else if(style === "gray"){
        str.replace(/XX/, "white")
        return str.replace(/WW/, "#877FDE")
    }
    else if(style === "square-result"){
        return str.replace(/YY/, "black").replace(/XX/, "white").replace(/ZZ/, "600")
    }
    return "Error"
}
