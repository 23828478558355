import React, { useEffect, useLayoutEffect, useState } from 'react'
import "./ContentModalLanguageCurrency.css"
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../redux/store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import CardPreferences from '../../CardPreferences/CardPreferences'
import { Currency, CurrencyCode, LanguageLabel, setCurrency, setLanguage } from '../../../../redux/slices/authSlice'
import moment from 'moment'
import { updateCurrencyWeb, updateLanguageWeb } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import LoadingSpinner from '../../../LoadingSpinner'
import { localStorageCurrency, localStorageLanguage } from '../../../../utils/preferences/preferences'

type TabOfferOption = { index: number }

const colorsAnimation = ["gray","black", "black"]

type ContentModalLanguageCurrencyprops = {
    onHandleNotLoggedLanguage: (lang: LanguageLabel) => void
    onHandleNotLoggedCurrency: (code: CurrencyCode) => void
    languagePreference?: LanguageLabel
    currencyPreference?: string
    isLogged: boolean
    onClose: () => void
}

type LanguageItem = {
    name: string
    description: string
    id: string
    selected: boolean
    label: LanguageLabel
}

export type CurrencyItem = {
    currName: string
    //currNamePlural: string,
    currSymbol: string
    currCode: CurrencyCode
    selected: boolean
    //currCountryCode: string,
    //countryName: string
}

const ContentModalLanguageCurrency = ({ 
    onClose, 
    languagePreference, 
    currencyPreference, 
    isLogged,
    onHandleNotLoggedLanguage,
    onHandleNotLoggedCurrency
}: ContentModalLanguageCurrencyprops) => {

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()

    //const navigate = useNavigate()

    //const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(false)

    const [leftLine, setLeftLine] = useState<number>(0)
    const [indexTab, setIndexTab] = useState(1)

    const [languages, setLanguages] = useState<LanguageItem[]>([
        { name: "Español",      description: "México",          id: "Mexico",   selected: languagePreference === "es",  label: "es"     },
        { name: "English",      description: "United States",   id: "Usa",      selected: languagePreference === "en",  label: "en"     },
        { name: "Français",     description: "France",          id: "France",   selected: languagePreference === "fr",  label: "fr"     },
    ])

    const [currencies, setCurrencies] = useState<CurrencyItem[]>([
        {
            currName: "Euro",
            currSymbol: "€",
            currCode: "EUR",
            selected: currencyPreference === "EUR"
        },
        {
            currName: "United States Dollar",
            currSymbol: "$",
            currCode: "USD",
            selected: currencyPreference === "USD"
        },
        {
            currName: "Peso Mexicano",
            currSymbol: "$",
            currCode: "MXN",
            selected: currencyPreference === "MXN"
        }
    ])

    const orderLanguages = (languagesLocal: LanguageItem[]) => {
        const languageSelected = languagesLocal.find((language) => language.selected)
        if(!languageSelected)
            return

        const languagesNotSelected = languagesLocal.filter((language) => !language.selected).sort((a,b) => a.name > b.name ? 1 : -1)

        setLanguages([ languageSelected, ...languagesNotSelected ])
    }

    const orderCurrencies= (currenciesLocal: CurrencyItem[]) => {
        const currencySelected = currenciesLocal.find((currency) => currency.selected)
        if(!currencySelected)
            return

        const currenciesNotSelected = currenciesLocal.filter((currency) => !currency.selected).sort((a,b) => a.currName > b.currName ? 1 : -1)

        setCurrencies([ currencySelected, ...currenciesNotSelected ])
    }

    useLayoutEffect(() => {
        
        orderLanguages(languages)
        orderCurrencies(currencies)

    }, [])


    const marginBetweenOptions = 1; // rem
    const firstWidthOption = 20; // rem
    const secondWidthOption = 15; // rem


    useEffect(() => {
        if(indexTab < 0)
            setLeftLine(0)
        else if(indexTab === 2)
            setLeftLine((1) * (firstWidthOption+marginBetweenOptions))
    }, [indexTab])
    

    const setTab = ({ index }: TabOfferOption) => {

        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line-preferences") 

        if(smallLine){

            let remsToMove = 0
            if(indexTab === 1)
                remsToMove = ((index - indexTab) * (firstWidthOption+marginBetweenOptions) );
            else if(indexTab === 2)
                remsToMove = ((index - indexTab) * (firstWidthOption+marginBetweenOptions) );
            //else
                //remsToMove = ((index - 1) * (widthOption+marginBetweenOptions) ); // case after search bar
            //console.log("leftLine", leftLine, "remsToMove", remsToMove)
            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
            setIndexTab(index)
            //dispatch(changeIndexTabReducerHistory({indexTab : index}))
            //navigate(`/manage-offers?limit=${limit}&page=${1}&type=${typesOffers[index] === "Task" ? "Task" : "Job"}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }

    const onChangeLanguage = (id: string) => {
        let newLanguageLabel: LanguageLabel = "es"
        const newLanguages = languages.map((language) => {
            if(language.id === id)
                newLanguageLabel = language.label
            return {
                ...language,
                selected: language.id === id
            }
        })

        if(isLogged){
            setLoading(true)
            updateLanguageWeb(newLanguageLabel)
            .then(() => {
                orderLanguages(newLanguages)
                i18n.changeLanguage(newLanguageLabel)
                moment.locale(newLanguageLabel); 
                dispatch( setLanguage(newLanguageLabel) )
            }).catch(() => {
    
            }).finally(() => {
                setLoading(false)
            })
        }else{
            orderLanguages(newLanguages)
            i18n.changeLanguage(newLanguageLabel)
            moment.locale(newLanguageLabel); 
            localStorage.setItem(localStorageLanguage, newLanguageLabel)
            onHandleNotLoggedLanguage(newLanguageLabel)
        }


    }


    const onChangeCurrency = (id: string) => {
        let newCurrency: Currency = {
            currName: "Peso Mexicano",
            currSymbol: "$",
            currCode: "MXN",
        }
        const newCurrencies = currencies.map((currency) => {
            if(currency.currCode === id)
                newCurrency = {
                    currName: currency.currName,
                    currSymbol: currency.currSymbol,
                    currCode: currency.currCode,
                }
            return {
                ...currency,
                selected: currency.currCode === id
            }
        })

        if(isLogged){
            setLoading(true)
            updateCurrencyWeb(newCurrency)
            .then(() => {
                dispatch( setCurrency(newCurrency) )
                orderCurrencies(newCurrencies)
            }).catch(() => {
    
            }).finally(() => {
                setLoading(false)
            })

        }else{
            orderCurrencies(newCurrencies)
            localStorage.setItem(localStorageCurrency, newCurrency.currCode)
            onHandleNotLoggedCurrency(newCurrency.currCode)
        }
    }
 
    return (
        <div className="lang-currency-modal">

            <div className="flex flex-jc-end">
                <button onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} className="c-black fs-20 p-3 pointer" />
                </button>
            </div>

            <div className="con-all-options-tab-preferences">

                <div 
                    className={`option-tab-preferences ${indexTab === 1 ? "" : "pointer"}`}  
                    style={{ width: `${firstWidthOption}rem` }}
                    onClick={() => setTab({ index: 1 })}
                >
                    <p className={`text-option-tab-preferences fs-15 ${indexTab === 1  ? "italic text-tab-black" : "c-dove-gray"}`}>{t("Profile:language:label_01")}</p>
                    <div id="1-option" className="option-line"></div>
                </div>

                <div 
                    className={`option-tab-preferences ${indexTab === 2 ? "" : "pointer"}`}
                    style={{ width: `${secondWidthOption}rem` }}
                    onClick={() => setTab({ index: 2  })}
                >
                    <p className={`text-option-tab-preferences fs-15 ${indexTab === 2  ? "italic text-tab-black" : "c-dove-gray"}`}>{t("Profile:language:label_02")}</p>
                    <div id="2-option" className="option-line"></div>
                </div>

                <div 
                    id="small-line-preferences" 
                    style={{ left: `${leftLine.toString()}rem`, width: `${indexTab === 1 ? firstWidthOption : secondWidthOption}rem` }} 
                    className={`small-line-preferences ${colorsAnimation[indexTab]}-line ${indexTab < 0 ? "visibility-hidden" : ""}`}
                ></div>

                <div className="whole-line"></div>

            </div>

            {
                indexTab === 1 &&
                <>
                    <p className="fs-20 color-text mt-26 mb-26">{t("Profile:language:label_03")}</p>
                    <div className="flex">
                        {
                            languages.map((language) => {
                                return(
                                    <CardPreferences 
                                        key={language.id}
                                        id={language.id}
                                        title={language.name}
                                        description={language.description}
                                        selected={language.selected}
                                        onPress={onChangeLanguage}
                                    />
                                )
                            })
                        }

                    </div>
                </>
            }

            {
                indexTab === 2 &&
                <>
                    <p className="fs-20 color-text mt-26 mb-26">{t("Profile:language:label_04")}</p>
                    <div className="flex">
                        {
                            currencies.map((currency) => {
                                return(
                                    <CardPreferences 
                                        key={currency.currCode}
                                        id={currency.currCode}
                                        title={currency.currName}
                                        description={`${currency.currCode} - ${currency.currSymbol}`}
                                        selected={currency.selected}
                                        onPress={onChangeCurrency}
                                    />
                                )
                            })
                        }

                    </div>
                </>
            }

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default React.memo( ContentModalLanguageCurrency )