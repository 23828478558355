import React from 'react'
import { useTranslation } from 'react-i18next'
import "./ButtonsValidateProduct.css"

type ButtonsValidateProductProps = {
    onPress: (value: boolean) => void
}

const ButtonsValidateProduct = ({ onPress }: ButtonsValidateProductProps) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-ai-center width-100 flex-jc-sb">

            <button
                className="btn-slider-card-product fs-15-vh invalid-btn pointer mr-6"
                onClick={() => onPress(false)}
            >
                {t("General:label_057")}
            </button>

            <button
                className="btn-slider-card-product fs-15-vh valid-btn pointer ml-6"
                onClick={() => onPress(true)}
            >
                {t("General:label_056")}
            </button>
            
        </div>
    )
}

export default React.memo( ButtonsValidateProduct )