import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useParams } from 'react-router-dom';
import DetailedOfferScreen from '../../../../../components/ManageAllOffers/DetailedOfferScreen/DetailedOfferScreen'
import { GetOfferByIdThunk } from '../../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'

const OfferScreen = () => {

    const dispatch = useAppDispatch()

    const loadingIndividualPage = useSelector((state: RootState) => state.manageOffersData.loadingIndividualPage)

    const { id } = useParams();

    useLayoutEffect(() => {

        if(id){
            dispatch( GetOfferByIdThunk({ id }) )
        }
    
        return () => {

        };
    }, [])

    return (
        <div className="flex flex-d-column height-100 p-relative">
            {
                loadingIndividualPage ?
                <LoadingSpinner fullPage={false} /> 
                :
                <DetailedOfferScreen />
            }
        </div>
    )
}

export default OfferScreen