import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import { Colors } from '../../../utils/colors/colors'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import "./NameScreen.css"
import { useNavigate } from 'react-router-dom'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { setFirstAndLastNameSignUp } from '../../../redux/slices/components/SignUp/SignUpSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

type NameScreenProps = {
    isPrivate?: boolean
}

const NameScreen = ({ isPrivate }: NameScreenProps) => {

    const { t, i18n } = useTranslation()
    console.log("i18n", i18n.language)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const firstNameRedux = useSelector((state: RootState) => state.signUp.data.firstName)
    const lastNameRedux = useSelector((state: RootState) => state.signUp.data.lastName)

    const [firstName, setFirstName] = useState(firstNameRedux)
    const [lastName, setLastName] = useState(lastNameRedux)


    const onNext = useCallback(
        () => {
            dispatch( setFirstAndLastNameSignUp({ firstName, lastName }) )
            if(isPrivate)
                navigate("/phone-number-federative")
            else
                navigate("/password")
        },
        [firstName, lastName],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center ml-10" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:nameScreen:label_01")}</p>

                    <input 
                        style={{
                            width: "100%",
                            borderBottomWidth: "1px",
                            borderBottomColor: Colors.TEXT,
                            borderBottomStyle: "solid",
                            marginTop: "4.4vh"
                        }}
                        className="fs-24-vh outline-none"
                        placeholder={t("SignUp:nameScreen:label_02") ?? ""}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />

                    <input 
                        style={{
                            width: "100%",
                            borderBottomWidth: "1px",
                            borderBottomColor: Colors.TEXT,
                            borderBottomStyle: "solid",
                            marginTop: "4.4vh"
                        }}
                        className="fs-24-vh outline-none"
                        placeholder={t("SignUp:nameScreen:label_03") ?? ""}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />

                    <p className="fs-16-vh c-text" style={{ marginTop: "2.2vh" }}>{t("SignUp:nameScreen:label_04")}</p>
                    
                </div>

                <div className="mr-20">
                    { ImageSignUp["Name"] }
                </div>
            </div>

            <div className="flex flex-jc-sb ml-10" style={{ marginBottom: "12.2vh" }}>
                {
                    isPrivate ? <div></div> : <ArrowGoBackSignUp onPress={goBack} />
                }

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:nameScreen:label_05")} 
                    disabled={!firstName || !lastName}
                />
            </div>

        </div>
    )
}

export default NameScreen