import React, { useEffect, useState } from 'react'
import InputList from '../../../UIcomponents/InputList/InputList'
import "./InputsSimulator.css"
import { RootState } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { CategoryJob, JobCategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import SimulatorGraphic from '../SimulatorGraphic/SimulatorGraphic'
import CardsPricesSimulator from '../CardsPricesSimulator/CardsPricesSimulator'
import LargeTable from '../LargeTable/LargeTable'
import { useTranslation } from 'react-i18next'
import moment from 'moment'


type Country = {
    img: string
    name: string
    label: string
}

const jobInitialState: JobCategoryJob = {
    active: true,
    id: "",
    imgKey: "",
    isCertifReq: false,
    isGimmable: false,
    isPool: false,
    jobName: "",
    linkedJobs: [],
    pricingInformation: {
        day: {
            price: 100,
            malusGim: 3,
            malusGimmer: 4,
            malusShareGimmer: 5,
            malusShareGim: 7,
            IVA: 16,
            timeTransaction: {
                gimIVA: 8,
                gimmerIVA: 8,
                gimworkIVA: 8,
                ISR: 1,
                RMP: 15
            }
        }
    }
}

const InputsSimulator = () => {

    const { t } = useTranslation()

    const countries: Country[] = [
        { img: "", name: t("ManageJobs:label_14"), label: "" },
        { img: "USA.svg", name: "Usa", label: "" },
        { img: "MEXICO.svg", name: "Mexico", label: "" },
        { img: "FRANCE.svg", name: "France", label: "" },
    ]
    
    const [countrySelected, setcountrySelected] = useState<Country>({} as Country);
    const [jobSelected, setJobSelected] = useState<JobCategoryJob>(jobInitialState)
    const [gimStars, setGimStars] = useState("")
    const [gimmerStars, setGimmerStars] = useState("")
    const [startTime, setStartTime] = useState(jobSelected.pricingInformation.night?.nightHours.startTime ?? "")
    const [endTime, setEndTime] = useState(jobSelected.pricingInformation.night?.nightHours.endTime ?? "")
    const [timeAdded, setTimeAdded] = useState("")
    const [kmCovered, setKmCovered] = useState("")
    const [tip, setTip] = useState("")
    const [price, setPrice] = useState(jobSelected.pricingInformation.day.price.toString())

    useEffect(() => {
      
        setStartTime(jobSelected.pricingInformation.night?.nightHours.startTime ?? "")
        setEndTime(jobSelected.pricingInformation.night?.nightHours.endTime ?? "")
        setPrice(jobSelected.pricingInformation.day.price.toString() ?? "")
    
    }, [jobSelected.pricingInformation.night?.nightHours.startTime, jobSelected.pricingInformation.night?.nightHours.endTime, jobSelected.pricingInformation.day.price])
    
    console.log("startTime out", startTime)
    const [errorTimeHours, setErrorTimeHours] = useState("")
    const [errorGimmerStars, setErrorGimmerStars] = useState("")
    const [errorGimStars, setErrorGimStars] = useState("")
    const [errorProductPrice, setErrorProductPrice] = useState("")
    const [errorTimeAdded, setErrorTimeAdded] = useState("")
    const [errorStartTime, setErrorStartTime] = useState("")
    const [errorEndTime, setErrorEndTime] = useState("")
    const [errorKmCovered, setErrorKmCovered] = useState("")
    const [errorTip, setErrorTip] = useState("")
    const [errorPrice, setErrorPrice] = useState("")

    const [timeHoursFocused, setTimeHoursFocused] = useState(false)
    const [gimmerStarsFocused, setGimmerStarsFocused] = useState(false)
    const [gimStarsFocused, setGimStarsFocused] = useState(false)
    const [productPriceFocused, setProductPriceFocused] = useState(false)
    const [timeAddedFocused, setTimeAddedFocused] = useState(false)
    const [startTimeFocused, setStartTimeFocused] = useState(false)
    const [endTimeFocused, setEndTimeFocused] = useState(false)
    const [kmCoveredFocused, setKmCoveredFocused] = useState(false)
    const [tipFocused, setTipFocused] = useState(false)
    const [priceFocused, setPriceFocused] = useState(false)

    const heightInputs = "2.9vh"

    const categoryJobs = useSelector((state: RootState) => state.managerJobData.categoryJobs)
                        .filter((c: CategoryJob) => {
                            return (countrySelected.name !== "All" && countrySelected.name) ? c.country === countrySelected.name : true
                        } )

    useEffect(() => {
        
        if(jobSelected.id){
            let jobSelectedUpdated: JobCategoryJob | null = null
            categoryJobs.forEach((c) => {
                c.jobs.forEach((j) => {
                    if(j.id === jobSelected.id)
                        jobSelectedUpdated = j
                })
            })

            if(jobSelectedUpdated)
                setJobSelected(jobSelectedUpdated)
        }

    }, [categoryJobs])
    

    const changeCountry = (newCountry: Country) => {
        setcountrySelected(newCountry)
        setJobSelected(jobInitialState)
        setGimStars("5")
        setGimmerStars("5")
        setStartTime("")
        setEndTime("")
        setKmCovered("")
        setTip("")
        setErrorTimeAdded("")
    }

    const eliminateCharactersAreNotNumber = (s: string) => {
        return s.replace(/[^0-9\.]/g, "")
    }

    const checkStarsInput = (value: number) => {
        if(value > 5 || value < 0)
            return "Value must be between 0 and 5" 
    }

    const checkPriceInput = (value: number) => {
        if(value < 0)
            return "Value must be between 0 and 5" 
    }

    const checkHour = (str: string) => {
        if(!moment(str, 'HH:mm').isValid() || !/^\d{2}:\d{2}/.test(str)){
            return "Formato hora no válido HH:MM"
        }
    }

    const handlePriceInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkPriceInput(Number(valueFiltered))

        if(error)
            setErrorPrice(error)
        else{
            if(errorStartTime)
                setErrorPrice("")
        }
        setPrice(value)
    }

    const handleStartTimeInput = (value: string) => {
        const error = checkHour(value)

        if(error)
            setErrorStartTime(error)
        else{
            if(errorStartTime)
                setErrorStartTime("")
        }
        setStartTime(value)
    }

    const handleEndTimeInput = (value: string) => {
        const error = checkHour(value)

        if(error)
            setErrorEndTime(error)
        else{
            if(errorEndTime)
                setErrorEndTime("")
        }
        setEndTime(value)
    }

    const handleGimmerStarsInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkStarsInput(Number(valueFiltered))

        if(error)
            setErrorGimmerStars(error)
        else{
            if(errorGimmerStars)
                setErrorGimmerStars("")
        }
        setGimmerStars(valueFiltered)
    }

    const handleGimStarsInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkStarsInput(Number(valueFiltered))

        if(error)
            setErrorGimStars(error)
        else{
            if(errorGimStars)
                setErrorGimStars("")
        }
        setGimStars(valueFiltered)
    }

    const handleKmCoveredInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkPriceInput(Number(valueFiltered))

        if(error)
            setErrorKmCovered(error)
        else{
            if(errorKmCovered)
                setErrorKmCovered("")
        }
        setKmCovered(valueFiltered)
    }

    const handleTipsInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkPriceInput(Number(valueFiltered))

        if(error)
            setErrorTip(error)
        else{
            if(errorProductPrice)
                setErrorTip("")
        }
        setTip(valueFiltered)
    }

    const handleTimeAddedInput = (value: string) => {
        const valueFiltered = eliminateCharactersAreNotNumber(value)
        const error = checkStarsInput(Number(valueFiltered))

        if(error)
            setErrorTimeAdded(error)
        else{
            if(errorTimeAdded)
                setErrorTimeAdded("")
        }
        setTimeAdded(valueFiltered)
    }

    return (
        <>
        <p className="fs-24-vh fw-600 mb-20">{t("ManageJobs:label_13")}</p>
        <div className="flex" >

            <div className="">
                <div className="padding-to-show-error input-list-simulator">
                    <InputList 
                        text={countrySelected.name ?? "Country"}  
                        width="100%"
                        height={heightInputs}
                        fontSize="fs-14-vh"
                    >
                        {
                            countries
                            .map((c: Country) => {
                                return(
                                    <div
                                        key={c.img}
                                        className="flex flex-ai-center flex-jc-sb pointer pl-14 pr-14 darks-white-bg option-input-simulator"
                                        onClick={() => {
                                            changeCountry(c)
                                        }}
                                    >
                                        <p className="fs-14-vh fw-400 c-black option-input-simulator">{c.name}</p>
                                        {
                                            c.img && 
                                            <img className="country-icon" src={`../assets/flags/${c.img}`} alt={c.img} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </InputList>
                </div>

                <div className="padding-to-show-error input-list-simulator mt-14">
                    <InputList 
                        text={jobSelected?.jobName ?? "Job"}  
                        width="100%"
                        height={heightInputs}
                        fontSize="fs-14-vh"
                    >
                        {
                            categoryJobs
                            .map((category: CategoryJob) => {
                                return(
                                    <div
                                        key={category.id}
                                        className="pointer"
                                    >
                                        <p className="fs-14-vh fw-700 flex flex-ai-center pl-14 pr-14 option-input-simulator">{category.categoryName}</p>
                                        {
                                            category.jobs.map((job: JobCategoryJob) => {
                                                return(
                                                    <button 
                                                        key={job.id} 
                                                        className="darks-white-bg pointer flex flex-ai-center pl-14 pr-14 width-100 pt-4 pb-4" 
                                                        onClick={() => setJobSelected(job)} 
                                                        type="button" 
                                                    >
                                                        <p className="fs-14-vh pl-12 text-align-left">{job.jobName}</p>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </InputList>
                    { !jobSelected.id &&<p className="error-input-list p-absolute">{"Please select a job"}</p>}
                </div>  
            </div>


            <div className="">
                <div className="padding-to-show-error input-stars-simulator ml-30 p-relative">
                    <input 
                        className="input-list-simulator-little width-100 fs-14-vh"
                        placeholder="GIMMER Stars"
                        style={{ height: heightInputs  }}
                        value={gimmerStars}
                        onChange={(e) => handleGimmerStarsInput(e.target.value)}
                        onFocus={()=> setGimmerStarsFocused(true)}
                        onBlur={() => { gimmerStars.length > 0 ? setGimmerStarsFocused(true) : setGimmerStarsFocused(false) }}
                    />
                    { errorGimmerStars &&<p className="error-input-list p-absolute">{errorGimmerStars  || ""}</p>}
                    { gimmerStarsFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Gimmer Stars</p> }
                </div>

                <div className="padding-to-show-error input-stars-simulator ml-30 p-relative mt-17">
                    <input 
                        className="input-list-simulator-little width-100 fs-10 fs-14-vh"
                        placeholder="GIM Stars"
                        style={{ height: heightInputs }}
                        value={gimStars}
                        onChange={(e) => handleGimStarsInput(e.target.value)}
                        onFocus={()=> setGimStarsFocused(true)}
                        onBlur={() => { gimStars.length > 0 ? setGimStarsFocused(true) : setGimStarsFocused(false) }}
                    />
                    { errorGimStars &&<p className="error-input-list p-absolute">{errorGimStars  || ""}</p>}
                    {gimStarsFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Gim Stars</p>}
                </div>
            </div>


                <div className="ml-30">

                    <div className="flex">

                        <div className="padding-to-show-error input-stars-simulator p-relative">
                            <input 
                                className="input-list-simulator-little width-100 fs-14-vh"
                                placeholder="StartTime"
                                style={{ height: heightInputs }}
                                value={startTime}
                                onChange={(e) => handleStartTimeInput(e.target.value)}
                                onFocus={()=> setStartTimeFocused(true)}
                                onBlur={() => { endTime.length > 0 ? setStartTimeFocused(true) : setStartTimeFocused(false) }}
                            />
                            { errorStartTime &&<p className="error-input-list p-absolute">{errorStartTime  || ""}</p>}
                            { startTimeFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Start time</p> }
                        </div>

                        <div className="padding-to-show-error input-stars-simulator p-relative ml-20">
                            <input 
                                className="input-list-simulator-little width-100 fs-14-vh"
                                placeholder="EndTime"
                                style={{ height: heightInputs }}
                                value={endTime}
                                onChange={(e) => handleEndTimeInput(e.target.value)}
                                onFocus={()=> setEndTimeFocused(true)}
                                onBlur={() => { endTime.length > 0 ? setEndTimeFocused(true) : setEndTimeFocused(false) }}
                            />
                            { errorEndTime &&<p className="error-input-list p-absolute">{errorEndTime  || ""}</p>}
                            { endTimeFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">End time</p> }
                        </div>

                    </div>

                    <div className="flex">
                        <div className="padding-to-show-error input-stars-simulator p-relative mt-17">
                            <input 
                                className="input-list-simulator-little width-100 fs-14-vh"
                                placeholder="Time added"
                                style={{ height: heightInputs  }}
                                value={timeAdded}
                                onChange={(e) => handleTimeAddedInput(e.target.value)}
                                onFocus={()=> setTimeAddedFocused(true)}
                                onBlur={() => { timeAdded.length > 0 ? setTimeAddedFocused(true) : setTimeAddedFocused(false) }}
                            />
                            { errorTimeAdded &&<p className="error-input-list p-absolute">{errorTimeAdded  || ""}</p>}
                            { timeAddedFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Time added</p> }
                        </div>

                        <div className="padding-to-show-error input-stars-simulator p-relative mt-17 ml-20">
                            <input 
                                className="input-list-simulator-little width-100 fs-14-vh"
                                placeholder="Price"
                                style={{ height: heightInputs  }}
                                value={price}
                                onChange={(e) => handlePriceInput(e.target.value)}
                                onFocus={()=> setPriceFocused(true)}
                                onBlur={() => { price.length > 0 ? setPriceFocused(true) : setPriceFocused(false) }}
                            />
                            { errorPrice &&<p className="error-input-list p-absolute">{errorPrice  || ""}</p>}
                            { <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Price</p> }
                        </div>
                    </div>
                    
                </div>

                <div className="flex ml-30">
                    <div className="padding-to-show-error input-stars-simulator p-relative">
                        <input 
                            className="input-list-simulator-little width-100 fs-14-vh"
                            placeholder="Km covered"
                            style={{ height: heightInputs  }}
                            value={kmCovered}
                            onChange={(e) => handleKmCoveredInput(e.target.value)}
                            onFocus={()=> setKmCoveredFocused(true)}
                            onBlur={() => { kmCovered.length > 0 ? setKmCoveredFocused(true) : setKmCoveredFocused(false) }}
                        />
                        { errorKmCovered &&<p className="error-input-list p-absolute">{errorKmCovered  || ""}</p>}
                        { kmCoveredFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Km covered</p> }
                    </div>

                    <div className="padding-to-show-error input-stars-simulator p-relative ml-30">
                        <input 
                            className="input-list-simulator-little width-100 fs-14-vh"
                            placeholder="Tip"
                            style={{ height: heightInputs  }}
                            value={tip}
                            onChange={(e) => handleTipsInput(e.target.value)}
                            onFocus={()=> setTipFocused(true)}
                            onBlur={() => { tip.length > 0 ? setTipFocused(true) : setTipFocused(false) }}
                        />
                        { errorTip &&<p className="error-input-list p-absolute">{errorTip  || ""}</p>}
                        { tipFocused && <p className="fs-12-vh fw-500 p-absolute label-input-stars white-bg">Tip</p> }
                    </div>
                </div>


            </div>



            <SimulatorGraphic 
                gimStars={Number(gimStars)}
                gimmerStars={Number(gimmerStars)}
                price={Number(price)}
                malusGim={jobSelected.pricingInformation.day.malusGim}
                malusGimmer={jobSelected.pricingInformation.day.malusGimmer}
                malusShareGim={jobSelected.pricingInformation.day.malusShareGim}
                malusShareGimmer={jobSelected.pricingInformation.day.malusShareGimmer}
                minRevenue={Number(5)}
                tax={jobSelected.pricingInformation.day.IVA}
                timeHours={Number(0)}
                productPrice={Number(0)}
                timeAdded={Number(timeAdded)}
                error={(errorGimStars || errorGimmerStars || errorStartTime || errorEndTime || errorKmCovered || errorTip || errorTimeAdded) ? true : false}
                jobSelected={jobSelected}
                kmCovered={Number(kmCovered)}
                tip={Number(tip)}
            />

            <CardsPricesSimulator 
                job={jobSelected} 
                price={Number(price)}
                startTime={startTime}
                endTime={endTime}
            />


            <LargeTable 
                price={Number(price)}
                job={jobSelected} 
                data={jobSelected.pricingInformation.day}
                timeAdded={Number(timeAdded)}
                gimStars={Number(gimStars)}
                gimmerStars={Number(gimmerStars)}
                kmCovered={Number(kmCovered)}
                tip={Number(tip)}
            />

        </>
    )
}

export default React.memo( InputsSimulator )