import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import { UserReportType } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import MessagesList from '../MessagesList/MessagesList'

type MessagesPersonInvolvedProps = {
    tabUsers: number
    indexTab: number
}

const MessagesPersonInvolved = ({ tabUsers, indexTab}: MessagesPersonInvolvedProps) => {

    const messages = useSelector((state: RootState) => state.manageUserReports.messagesPersonInvolved)

    return (
        <MessagesList tabUsers={tabUsers} indexTab={indexTab}messages={messages} type={"PersonInvolved"} />
    )
}

export default React.memo( MessagesPersonInvolved )